import {
    onUpdateRelations,
    onFetchRelations,
    mapDgPlusCodesToOptions,
    mapDgNatCodesToOptions,
} from './resources/reducerUtils';
import { memoizedMap, normalizeCode, replaceAtIndex } from '../common/utils';

const FETCH_PRINCIPAL_DIAGNOSIS_PROPERTIES = 'DIAGNOSIS_RELATIONS::FETCH_PRINCIPAL_DIAGNOSIS_PROPERTIES';
const SET_PRINCIPAL_DIAGNOSIS_PROPERTIES = 'DIAGNOSIS_RELATIONS::SET_PRINCIPAL_DIAGNOSIS_PROPERTIES';
const FETCH_CATEGORY_NAMES = 'DIAGNOSIS_RELATIONS::FETCH_CATEGORY_NAMES';
const SET_CATEGORY_NAMES = 'DIAGNOSIS_RELATIONS::SET_CATEGORY_NAMES';
const FETCH_GROUPING_RELATIONS = 'DIAGNOSIS_RELATIONS::FETCH_GROUPING_RELATIONS';
const SET_GROUPING_RELATIONS = 'DIAGNOSIS_RELATIONS::SET_GROUPING_RELATIONS';
const FETCH_PRINCIPAL_DGPROP_RELATIONS = 'DIAGNOSIS_RELATIONS::FETCH_PRINCIPAL_DGPROP_RELATIONS';
const SET_PRINCIPAL_DGPROP_RELATIONS = 'DIAGNOSIS_RELATIONS::SET_PRINCIPAL_DGPROP_RELATIONS';
const FETCH_NAME_RELATIONS = 'DIAGNOSIS_RELATIONS::FETCH_NAME_RELATIONS';
const SET_NAME_RELATIONS = 'DIAGNOSIS_RELATIONS::SET_NAME_RELATIONS';
const FETCH_GROUPING_PROPERTIES = 'DIAGNOSIS_RELATIONS::FETCH_GROUPING_PROPERTIES';
const SET_GROUPING_PROPERTIES = 'DIAGNOSIS_RELATIONS::SET_GROUPING_PROPERTIES ';
const SET_VARTYPES = 'DIAGNOSIS_RELATIONS::SET_VARTYPES';
const SET_VARVALS = 'DIAGNOSIS_RELATIONS::SET_VARVALS';
const CLEAR_VARVALS = 'DIAGNOSIS_RELATIONS::CLEAR_VARVALS';
const CLEAR_CODES = 'DIAGNOSIS_RELATIONS::CLEAR_CODES';
const CLEAR_NAT_CODES = 'DIAGNOSIS_RELATIONS::CLEAR_NAT_CODES';
const SET_CODES = 'DIAGNOSIS_RELATIONS::SET_CODES';
const SET_NAT_CODES = 'DIAGNOSIS_RELATIONS::SET_NAT_CODES';

export default function diagnosisRelationsReducer(state = {principal: [], names: [], groupingProperties: [], vartypes: [], varvals: [], codes: [], natCodes: []}, {type, payload}) {
    switch (type) {
    case SET_GROUPING_PROPERTIES:
        return {...state, groupingProperties: payload};
    case SET_PRINCIPAL_DIAGNOSIS_PROPERTIES:
        return {...state, principal: payload};
    case SET_CATEGORY_NAMES:
        return {...state, names: payload};
    case FETCH_GROUPING_RELATIONS: {
        const groupingProperties = onFetchRelations({list: state.groupingProperties, identifier: 'dgprop', id: normalizeCode(payload)});
        return {...state, groupingProperties};
    }
    case SET_GROUPING_RELATIONS: {
        const {relations, id} = payload;
        const groupingProperties = onUpdateRelations({list: state.groupingProperties, identifier: 'dgprop', id: normalizeCode(id), relations});
        return {...state, groupingProperties};
    }
    case FETCH_PRINCIPAL_DGPROP_RELATIONS: {
        const principal = onFetchRelations({list: state.principal, identifier: 'pdgprop', id: payload});
        return {...state, principal};
    }
    case SET_PRINCIPAL_DGPROP_RELATIONS: {
        const {relations, id} = payload;
        const principal = onUpdateRelations({list: state.principal, identifier: 'pdgprop', id, relations});
        return {...state, principal};
    }
    case FETCH_NAME_RELATIONS: {
        const names = onFetchRelations({list: state.names, identifier: 'dgcat', id: payload});
        return {...state, names};
    }
    case SET_NAME_RELATIONS: {
        const {relations, id} = payload;
        const names = onUpdateRelations({list: state.names, identifier: 'dgcat', id, relations});
        return {...state, names};
    }
    case SET_VARTYPES:
        return {...state, vartypes: payload};
    case SET_VARVALS:
        return {...state, varvals: payload};
    case CLEAR_VARVALS:
        return {...state, varvals: []};
    case CLEAR_CODES:
        return {...state, codes: []};
    case CLEAR_NAT_CODES:
        return {...state, natCodes: []};
    case SET_CODES:
        return {...state, codes: payload};
    case SET_NAT_CODES:
        return {...state, natCodes: payload};
    default: return state;
    }
}

export const doFetchDiagnosisRelationVartypes = () => async (dispatch, getState, Api) => {
    const vartypes = await Api.post('/diagnosis/vartypes/fetch');
    dispatch({type: SET_VARTYPES, payload: vartypes});
};

const generateGCodesOptions = (varvals) => {
    const varvalsCopy = [...varvals];
    let offset = 1;
    varvalsCopy.forEach(({varval, varval_text_com}, i) => {
        if (varval.charAt(2) === 'C') {
            varvals.splice(i + offset, 0, {
                varval: replaceAtIndex(varval, 2, 'G'),
                varval_text_com,
            });
            offset += 1;
        }
    });
    return varvals;
};

export const doFetchDiagnosisRelationVarvals = (vartype) => async (dispatch, getState, Api) => {
    dispatch({type: CLEAR_VARVALS});
    let varvals = await Api.post('/diagnosis/varvals/fetch', {payload: {vartype}});
    if (vartype === 'COMPL') varvals = generateGCodesOptions(varvals);
    dispatch({type: SET_VARVALS, payload: varvals});
};

export const doFetchDiagnosisRelationCodes = () => async (dispatch, getState, Api) => {
    dispatch({type: CLEAR_CODES});
    const codes = await Api.post('/diagnosis/features/codes/fetch');
    dispatch({type: SET_CODES, payload: codes});
};

export const doFetchDiagnosisRelationNatCodes = () => async (dispatch, getState, Api) => {
    dispatch({type: CLEAR_NAT_CODES});
    const {country} = getState().locales;
    const payload = {globalFilter: {country}};
    const codes = await Api.post('/diagnosis/features/national_codes/fetch', {payload});
    dispatch({type: SET_NAT_CODES, payload: codes});
};

export const doFetchDiagnosisRelationPrincipalDiagnosisProperties = () => async (dispatch, getState, Api) => {
    dispatch({type: FETCH_PRINCIPAL_DIAGNOSIS_PROPERTIES});
    const {rows} = await Api.post('/diagnosis/principal_diagnosis_property/fetch');
    dispatch({type: SET_PRINCIPAL_DIAGNOSIS_PROPERTIES, payload: rows });
};
export const doFetchDiagnosisRelationPrincipalDiagnosisPropertyRelations = (varval) => async (dispatch, getState, Api) => {
    dispatch({type: FETCH_PRINCIPAL_DGPROP_RELATIONS, payload: varval});
    const {country} = getState().locales;
    const payload = {globalFilter: {country}, varval};
    const relations = await Api.post('/diagnosis/principal_diagnosis_property/relations/fetch', {payload});
    dispatch({type: SET_PRINCIPAL_DGPROP_RELATIONS, payload: {relations, id: varval}});
};

export const doFetchDiagnosisRelationCategoryNames = () => async (dispatch, getState, Api) => {
    dispatch({type: FETCH_CATEGORY_NAMES});
    const {rows} = await Api.post('/diagnosis/feature_names/fetch');
    dispatch({type: SET_CATEGORY_NAMES, payload: rows });
};

export const doFetchDiagnosisRelationDiagnosisNameRelations = (varval) => async (dispatch, getState, Api) => {
    dispatch({type: FETCH_NAME_RELATIONS, payload: varval});
    const {country} = getState().locales;
    const payload = {globalFilter: {country}, varval, vartype: 'DGCAT'};
    const relations = await Api.post('/diagnosis/relations/fetch', {payload});
    dispatch({type: SET_NAME_RELATIONS, payload: {relations, id: varval}});
};

export const doFetchDiagnosisRelationGroupingPropertyNames = () => async (dispatch, getState, Api) => {
    dispatch({type: FETCH_GROUPING_PROPERTIES});
    const groupingPropertyNames = await Api.post('/diagnosis/grouping_names/fetch');
    dispatch({type: SET_GROUPING_PROPERTIES, payload: groupingPropertyNames });
};

export const doFetchDiagnosisRelationDGPropRelations = (varval) => async (dispatch, getState, Api) => {
    dispatch({type: FETCH_GROUPING_RELATIONS, payload: varval});
    const {country} = getState().locales;
    const payload = {globalFilter: {country}, varval, vartype: ['DGPROP', 'SDGPRO']};
    const relations = await Api.post('/diagnosis/relations/fetch', {payload});
    dispatch({type: SET_GROUPING_RELATIONS, payload: {relations, id: varval}});
};

export const actions = {
    doFetchDiagnosisRelationVartypes,
    doFetchDiagnosisRelationVarvals,
    doFetchDiagnosisRelationCodes,
    doFetchDiagnosisRelationNatCodes,
    doFetchDiagnosisRelationPrincipalDiagnosisProperties,
    doFetchDiagnosisRelationPrincipalDiagnosisPropertyRelations,
    doFetchDiagnosisRelationCategoryNames,
    doFetchDiagnosisRelationDiagnosisNameRelations,
    doFetchDiagnosisRelationGroupingPropertyNames,
    doFetchDiagnosisRelationDGPropRelations,
};

export const selectors = {
    getDiagnosisRelationsCodeOptions: memoizedMap((codes) => mapDgPlusCodesToOptions({ codes })),
    getDiagnosisRelationsNatCodeOptions: memoizedMap((codes, includePlusCode) => mapDgNatCodesToOptions({ codes, includePlusCode })),
};
