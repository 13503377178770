/* eslint-disable jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events,jsx-a11y/alt-text */
import React from 'react';
import {func, object, bool} from 'prop-types';
import Close from '../Close';
import './styles.scss';
import RemoveDialog from '../RemoveDialog';

export default function LogicRightClickMenu({
    t, styles, enablePaste, onAddRowBelow, onAddRowAbove, onMoveRowsAbove, onMoveRowsBelow, onRemoveRow, onUndoLastChange, onCopyRow, onPasteRowAbove, onPasteRowBelow, onClose,
}) {
    return (
        <div className="right-click-menu" style={styles} onClick={(e) => e.stopPropagation()}>
            <Close onClick={onClose}/>
            <div onClick={onMoveRowsAbove} className={onMoveRowsAbove ? '' : 'menu-item-disabled'}>{t.moveRowsAbove}</div>
            <div onClick={onAddRowAbove} className={onAddRowAbove ? '' : 'menu-item-disabled'}>{t.addRowAbove}</div>
            <div onClick={onAddRowBelow} className={onAddRowBelow ? '' : 'menu-item-disabled'}>{t.addRowBelow}</div>
            <div onClick={onMoveRowsBelow} className={onMoveRowsBelow ? '' : 'menu-item-disabled'}>{t.moveRowsBelow}</div>
            <div onClick={onUndoLastChange} className={onUndoLastChange ? '' : 'menu-item-disabled'}>{t.undo}</div>
            <RemoveDialog onRemoveRow={onRemoveRow} t={t} />
            <div onClick={onCopyRow}>{t.copyRow}</div>
            <div onClick={enablePaste ? onPasteRowAbove : undefined} className={enablePaste ? '' : 'menu-item-disabled'}>{t.pasteRowAbove}</div>
            <div onClick={enablePaste ? onPasteRowBelow : undefined} className={enablePaste ? '' : 'menu-item-disabled'}>{t.pasteRowBelow}</div>
        </div>
    );
}

LogicRightClickMenu.propTypes = {
    styles: object,
    onAddRowBelow: func,
    onAddRowAbove: func,
    onMoveRowsAbove: func,
    onMoveRowsBelow: func,
    onRemoveRow: func,
    onUndoLastChange: func,
    onCopyRow: func.isRequired,
    enablePaste: bool,
    onPasteRowAbove: func.isRequired,
    onPasteRowBelow: func.isRequired,
};
