import React from 'react';
import {func} from 'prop-types';
import {connect} from 'react-redux';

const ProcNatListMenu = ({t, matchingProcNatItems, onClose, country}) => {
    if (matchingProcNatItems.length === 0) {
        return (<div />);
    }
    return (
        <div className="cell-context-menu" onClick={onClose}>
            <div className="cell-context-description-menu">
                {matchingProcNatItems.map((item) => (
                    <div key={item.rid} className="cell-context-menu-description-item">
                        {country === 'com' && <p>{t.country}: {item.country || '--'}</p>}
                        <p>{t.mbc.procOneColumnName}: {item.proc1 || '--'}</p>
                        <p>{t.textNat}: {item.text_nat || '--'}</p>
                    </div>
                  ))}
            </div>
        </div>
    );
};

ProcNatListMenu.propTypes = {
    onClose: func.isRequired,
};

export default connect(({locales: {t, country}, mbcProcNatOptions: {procNatItems, loading}}, {id}) => {
    const matchingProcNatItems = country === 'com'
        ? procNatItems.filter((row) => row.plus1 === id)
        : procNatItems.filter((row) => row.plus1 === id && row.country === country) || undefined;
    return ({t, matchingProcNatItems, loading});
})(ProcNatListMenu);
