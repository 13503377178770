import React from 'react';
import {string, bool, func, array, any} from 'prop-types';
import ReloadButton from '../../ReloadButton';
import './styles.scss';
import Close from '../../Close';
import Select from '../../Select';
import Spinner from '../../Spinner';

const ContextMenu = ({fetchContent, loading, children, onClose, onChange, value, options, allowEmpty, label = '--Missing label--'}) => {
    const handleChange = (val) => {
        if (val !== null && val !== value) {
            onChange(val);
        }
    };

    return (
        <div className="cell-context-menu">
            <Spinner hidden={!loading} className="context-menu-spinner"/>
            <Close onClick={onClose}/>
            <div className="context-menu-select">
                <ReloadButton onClick={fetchContent}/>
                <Select
                  value={value}
                  label={label}
                  placeholder={label}
                  options={allowEmpty ? [{label: '--', value: ''}, ...options] : [...options]}
                  onChange={handleChange}/>
            </div>
            <div className="cell-context-description-menu">
                {children}
            </div>
        </div>
    );
};

ContextMenu.propTypes = {
    onChange: func.isRequired,
    onClose: func.isRequired,
    options: array.isRequired,
    label: string,
    value: any,
    allowEmpty: bool,
    loading: bool,
    fetchContent: func.isRequired,
};

export default ContextMenu;
