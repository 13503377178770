import {createResolveLatestIf} from '../common/utils';

// TYPES
export const CREATE_ERROR_NOTIFICATION = 'ERROR_NOTIFICATION::CREATE_ERROR_NOTIFICATION';
export const SHOW_ERROR_NOTIFICATIONS = 'ERROR_NOTIFICATION::SHOW_ERROR_NOTIFICATIONS';
export const HIDE_ERROR_NOTIFICATIONS = 'ERROR_NOTIFICATION::HIDE_ERROR_NOTIFICATIONS';
export const DISCARD_ERROR_NOTIFICATION = 'ERROR_NOTIFICATION::DISCARD_ERROR_NOTIFICATION';

// REDUCER
export default function errorNotification(state = {open: false, content: []}, {type, payload}) {
    switch (type) {
    case CREATE_ERROR_NOTIFICATION: {
        const content = [...state.content];
        content.unshift(payload);
        return {...state, content, open: true};
    } case DISCARD_ERROR_NOTIFICATION: {
        const content = state.content.filter((next) => next.id !== payload);
        return {...state, content};
    }
    case HIDE_ERROR_NOTIFICATIONS: {
        const content = state.content
            .map((next) => ({...next, seen: true}));
        return {...state, content, open: false};
    }
    case SHOW_ERROR_NOTIFICATIONS:
        if (state.content.length) {
            return {...state, open: true};
        }
        return state;
    default: return state;
    }
}

// ACTION CREATORS

let ids = 1;
export const createErrorNotification = (info) => ({type: CREATE_ERROR_NOTIFICATION, payload: {...info, id: ids++}});

const lastResolvable = createResolveLatestIf();
export const doDiscardErrorNotification = (id) => async (dispatch, getState) => {
    dispatch({type: DISCARD_ERROR_NOTIFICATION, payload: id});
    const {errorNotification} = getState();
    if (!errorNotification.content.length) {
        await lastResolvable({initialDelay: 450, cancelOn: () => getState().errorNotification !== errorNotification});
        dispatch({type: HIDE_ERROR_NOTIFICATIONS});
    }
};

// THUNKS
export const doToggleShowErrorNotifications = () => (dispatch, getState) => {
    const {errorNotification: {open}} = getState();
    if (open) {
        dispatch({type: HIDE_ERROR_NOTIFICATIONS});
    } else {
        dispatch({type: SHOW_ERROR_NOTIFICATIONS});
    }
};
export const actions = {createErrorNotification, doToggleShowErrorNotifications, doDiscardErrorNotification};
