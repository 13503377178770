import React from 'react';
import {func} from 'prop-types';
import './styles.scss';

export default class ReloadButton extends React.Component {
    state = {clicks: 0};

    render() {
        const {clicks} = this.state;
        return (
            <div key={clicks} className={clicks ? 'reload-container-clicker' : 'reload-container'} onClick={this.onClick}>
                <div className="reload-arrow" />
            </div>
        );
    }

    onClick = () => {
        this.setState(prevState => ({ clicks: prevState.clicks + 1 }));
        this.props.onClick();
    };
}

ReloadButton.propTypes = {
    onClick: func.isRequired,
};
