/* eslint-disable no-await-in-loop, no-restricted-syntax, react/jsx-props-no-spreading */
import {func, object} from 'prop-types';
import React from 'react';
import {connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import Api from '../../../common/Api';
import {createNotification} from '../../../store/notification';
import {createErrorNotification} from '../../../store/errorNotification';
import {doFullBackup} from '../../../store/dumps';
import { readCsvFile } from '../../../common/utils';
import {actionsWrapper} from '../../../store/resources/reducerUtils';
import store from '../../../store';

const FiveHundredMegs = 500000000;

const FullUploadAndRecreateTables = ({t, schema, doFullBackup, createNotification, createErrorNotification}) => {
    const fullUpload = async (files) => {
        await doFullBackup(schema);
        const file = files[0];
        if ((file.type === 'application/zip') || (file.type === 'application/x-zip-compressed')) {
            const data = await readCsvFile({file});
            const subject = 'Restore from backup';
            try {
                const response = await Api.postRaw(`/import/full/${schema}`, {data, headers: {'Content-Type': 'application/zip' }});
                createNotification({subject, data: response});
            } catch (e) {
                console.error(e);
                createErrorNotification({subject, error: e.message});
            }
        }
    };

    const recreateTables = async (first, rest) => {
        await doFullBackup(schema);
        const files = [...first, ...rest];
        for (const [index, file] of files.entries()) {
            const last = index === files.length - 1;
            if (file.name.substr(-4) === '.csv') {
                const data = await readCsvFile({file});
                try {
                    const {duration, modelName} = await Api.postRaw(`/import/recreate/${schema}`, {data, headers: {'Content-Type': 'text/csv', Filename: file.name, last}});
                    const subject = `Recreating ${modelName} ${last ? 'and checking all tables' : ''}`;
                    createNotification({subject, data: {duration}});
                } catch (e) {
                    console.error(e);
                    createErrorNotification({subject: 'Recreating table(s)', error: e.message});
                }
            }
        }
    };

    return (
        <div className="section-container">
            <div className="config-form">
                <h2>{t.fullUploadZip}</h2>
                <div className="config-form-content">
                    <Dropzone
                      onDrop={fullUpload}
                      multiple={false}
                      maxSize={FiveHundredMegs}>
                        {({getRootProps, getInputProps}) => (
                            <div {...getRootProps()} className="drag-and-drop-label-container">
                                <input {...getInputProps()}/>
                                <p>{t.dragAndDrop}</p>
                            </div>
                        )}
                    </Dropzone>
                    <p>{t.fullUploadInstructions}</p>
                </div>
            </div>
            <div className="config-form">
                <h2>{t.recreateTables}</h2>
                <div className="config-form-content">
                    <Dropzone
                      onDrop={recreateTables}
                      multiple
                      maxSize={FiveHundredMegs}>
                        {({getRootProps, getInputProps}) => (
                            <div {...getRootProps()} className="drag-and-drop-label-container">
                                <input {...getInputProps()}/>
                                <p>{t.dragAndDrop}</p>
                            </div>
                        )}
                    </Dropzone>
                    <p>{t.recreateTableInstructions}</p>
                </div>
            </div>
        </div>
    );
};

FullUploadAndRecreateTables.propTypes = {
    t: object.isRequired,
    doFullBackup: func.isRequired,
};

export default connect(
    ({locales: {t, country}, meta: {schema}}) => ({t, country, schema}),
    {...actionsWrapper(store, {doFullBackup, createNotification, createErrorNotification})},
)(FullUploadAndRecreateTables);
