import React from 'react';
import {string, func, array, object, bool, number} from 'prop-types';
import Select from '../Select';
import InputLine from '../InputLine';
import GridMenu from '../GridMenu';
import Checkbox from '../Checkbox';
import Button from '../Button';
import searchIcon from '../../images/search-icon.svg';
import filterIcon from '../../images/filter-icon.svg';
import './styles.scss';
import {DIAGNOSIS_FEATURE_VARTYPES, PROCEDURE_FEATURE_VARTYPES} from '../../common/constants';

const createSortLabel = (column, direction) => <span className="sort-option">{column}<span className="sort-arrow">{direction === 'ASC' ? '↓' : '↑'}</span></span>;

const createSearchResultLabel = ({t, searchCursorLocation, searchResultCount, searchText}) => (
    <p className="row-count">{
        `${searchCursorLocation !== undefined ? (searchCursorLocation + 1) : '--'}/`
        + `${searchResultCount !== undefined ? searchResultCount : '--'}`
        + ` ${t.results} ${searchResultCount !== undefined ? `"${searchText}"` : ''}`
}
    </p>
);

const renderCheckBoxes = (t, title, variable, onChangeFunc) => (
    <div className="menu-item-checkbox-group">
        <p id="in-use">{t[title]}:</p>
        <Checkbox
          checked={variable === true}
          label={t.yes}
          onChange={() => onChangeFunc(variable === true ? undefined : true)} />
        <Checkbox
          checked={variable === false}
          label={t.no}
          onChange={() => onChangeFunc(variable === false ? undefined : false)} />
    </div>
);

const renderCheckBox = (label, variable, onChangeFunc) => (
    <div className="menu-item-checkbox-group">
        <Checkbox
          checked={variable === true}
          label={label}
          onChange={() => onChangeFunc(variable ? undefined : true)} />
    </div>
);

export default function TopMenu({
    t,
    domain,
    children,
    sortOrderOptions,
    sortOrder,
    onOrderChanged,
    filterText,
    onFilterFieldsChanged,
    onFilterTextChanged,
    filterOptions,
    activeFilter,
    onActiveFilterChanged,
    changedResultsFilter,
    onChangedResultsFilterChanged,
    errorsFilter,
    onErrorsFilterChanged,
    // notificationsFilter,
    // onNotificationsFilterChanged,
    exclusiveToORZeroFilter,
    onExclusiveToORZeroFilterChanged,
    exclusiveToDGCATZeroFilter,
    onExclusiveToDGCATZeroFilterChanged,
    searchOptions,
    searchText,
    searchCursorLocation,
    searchResultCount,
    onSearchTextChanged,
    onSearchFieldsChanged,
    moveSearchResultPointer,
    renameColumns = {},
    rowCount,
    onVartypeChanged,
}) {
    return (
        <GridMenu>
            <div className="menu-item" data-type="search">
                <InputLine placeholder={t.search} onChange={onSearchTextChanged} value={searchText} icon={searchIcon}/>
                <Select
                  isSearchable
                  isMulti
                  placeholder={t.searchFromCategory}
                  options={searchOptions.map((option) => ({label: renameColumns[option] || option, value: option}))}
                  onChange={onSearchFieldsChanged}/>
                {createSearchResultLabel({t, searchCursorLocation, searchResultCount, searchText})}
                <div className="menu-item-button-container">
                    <Button text={t.prev} disabled={!searchResultCount} onClick={() => moveSearchResultPointer('prev')}/>
                    <Button text={t.next} disabled={!searchResultCount} onClick={() => moveSearchResultPointer('next')}/>
                </div>
                {/* {doRemoveSelectedRows && (
                <div className="menu-item-button-container">
                    <Button text={t.removeSelectedRows} disabled={_.isEmpty(selectedRows)} onClick={() => doRemoveSelectedRows(selectedRows)}/>
                </div>
                )} */}
            </div>
            <div className="menu-item" data-type="filter">
                <InputLine placeholder={t.filter} value={filterText} onChange={onFilterTextChanged} icon={filterIcon}/>
                <Select
                  isSearchable
                  isMulti
                  placeholder={t.filterFromCategory}
                  options={filterOptions.map((option) => ({label: renameColumns[option] || option, value: option}))}
                  onChange={onFilterFieldsChanged}/>
                {(domain === 'diagnosisFeature' || domain === 'procedureFeature') && (
                <Select
                  isSearchable
                  isMulti
                  placeholder={t.filterByVartype}
                  options={domain === 'diagnosisFeature'
                    ? DIAGNOSIS_FEATURE_VARTYPES.map((vt) => ({label: vt, value: vt}))
                    : PROCEDURE_FEATURE_VARTYPES.map((vt) => ({label: vt, value: vt}))}
                  onChange={onVartypeChanged}/>
                )}
                {onActiveFilterChanged && (renderCheckBoxes(t, 'active', activeFilter, onActiveFilterChanged))}
                {onChangedResultsFilterChanged && (renderCheckBoxes(t, 'changedResults', changedResultsFilter, onChangedResultsFilterChanged))}
                {onErrorsFilterChanged && (renderCheckBoxes(t, 'errors', errorsFilter, onErrorsFilterChanged))}
                {/* {onNotificationsFilterChanged && (renderCheckBoxes(t, 'notifications', notificationsFilter, onNotificationsFilterChanged))} */}
                {onExclusiveToORZeroFilterChanged && (renderCheckBox(t.onlyORZeroFeature, exclusiveToORZeroFilter, onExclusiveToORZeroFilterChanged))}
                {onExclusiveToDGCATZeroFilterChanged && (renderCheckBox(t.onlyZeroDGCAT, exclusiveToDGCATZeroFilter, onExclusiveToDGCATZeroFilterChanged))}
                <p className="row-count">{`${rowCount || '--'} ${t.results}`}</p>
            </div>
            <div className="menu-item" data-type="sort">
                <p className="dropdown-menu-title">{t.sortBy}</p>
                <Select
                  isSearchable
                  isMulti
                  value={sortOrder.map(([column, direction]) => ({label: createSortLabel(renameColumns[column] || column, direction), value: [column, direction]}))}
                  options={sortOrderOptions.reduce((acc, column) => {
                            if (sortOrder.every((e) => (e[0] !== column || e[1] !== 'ASC'))) {
                                acc.push({label: createSortLabel(renameColumns[column] || column, 'ASC'), value: [column, 'ASC']});
                            }
                            if (sortOrder.every((e) => (e[0] !== column || e[1] !== 'DESC'))) {
                                acc.push({label: createSortLabel(renameColumns[column] || column, 'DESC'), value: [column, 'DESC']});
                            }
                            return acc;
                        }, [])}
                  onChange={onOrderChanged}/>
            </div>
            {children}
        </GridMenu>
    );
}

TopMenu.propTypes = {
    t: object,
    domain: string,
    // sort-order
    sortOrderOptions: array.isRequired,
    sortOrder: array.isRequired,
    onOrderChanged: func.isRequired,
    // filter
    filterText: string.isRequired,
    onFilterFieldsChanged: func.isRequired,
    onFilterTextChanged: func.isRequired,
    filterOptions: array,
    activeFilter: bool,
    onActiveFilterChanged: func,
    changedResultsFilter: bool,
    onChangedResultsFilterChanged: func,
    errorsFilter: bool,
    onErrorsFilterChanged: func,
    // notificationsFilter: bool,
    // onNotificationsFilterChanged: func,
    exclusiveToORZeroFilter: bool,
    onExclusiveToORZeroFilterChanged: func,
    exclusiveToDGCATZeroFilter: bool,
    onExclusiveToDGCATZeroFilterChanged: func,
    onVartypeChanged: func,
    // search
    searchOptions: array,
    searchText: string,
    searchCursorLocation: number,
    searchResultCount: number,
    onSearchTextChanged: func,
    onSearchFieldsChanged: func,
    moveSearchResultPointer: func,
    renameColumns: object,
    rowCount: number,
};
