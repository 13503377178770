import React, { useState, useEffect, useRef, useCallback} from 'react';
import FlipMove from 'react-flip-move';
import {func, array, object, number, bool, string, node, shape, oneOf} from 'prop-types';
import TableRow from './TableRow';
import TableHeader from './TableHeader';
import './styles.scss';
import {createDistinctLastTimeout, range} from '../../common/utils';
import useIsMounted from '../../hooks/useIsMounted';
import usePrevious from '../../hooks/usePrevious';

const resolveLast = createDistinctLastTimeout();

const {trunc, min, max, abs, round} = Math;

const DataTable = ({
    domain,
    country,
    doFetchContent,
    stickyRow,
    disableFocus,
    headers,
    dimensions,
    onResize,
    sortOrder,
    onSort,
    selectedRows,
    toggleRowSelection,
    highlightedRow,
    rowKey,
    moveTo,
    nonEditableFields,
    cursor,
    onSelectAllRows,
    onUnSelectAllRows,
    enableRowSelect,
    onColumnClick,
    onColumnRightClick,
    renameColumns,
    storableColumn,
    linkableColumns,
    doStoreCodes,
    doLinkCode,
    doLinkAllCodes,
    selectedCode,
    children,
    onRowScopeChange,
    setCursorLocation,
    onArrowUp,
    onArrowDown,
    onArrowRight,
    readOnly,
    onArrowLeft,
    onTab,
    clearRowFocus,
    rows = [],
    rowHeight = 30,
    onUpdateColumn = (() => console.log('update column given implemented')),
    columnMenu = {},
    nonEditableRowRules = {},
    disableScene = false,
}) => {
    const [offset, setOffset] = useState(0);
    const [offsetBottom, setOffsetBottom] = useState(0);
    const [animate, setAnimate] = useState(false);

    const isMounted = useIsMounted();

    const listRef = useRef(null);

    const prevRows = usePrevious(rows);
    const prevMoveTo = usePrevious(moveTo);
    const prevScrollTop = usePrevious(listRef?.current?.scrollTop);
    const prevAnimate = usePrevious(animate);

    const validOffsetBottom = min(offsetBottom, rows.length); // be 100% sure that list length has not changed
    const validOffset = min(offset, offsetBottom); // be 101% that offset is less than offsetBottom

    const paddingTopHeight = (validOffset * rowHeight) + (stickyRow ? (rowHeight + 6) : 0);
    const paddingBottomHeight = ((rows.length - validOffsetBottom) * rowHeight);
    const rowContainerHeight = ((1 + (validOffsetBottom - validOffset)) * rowHeight);

    const disableRow = (row) => Object.entries(nonEditableRowRules)
        .some(([column, values]) => values.indexOf(row[column]) !== -1);

    const reEnableAnimation = useCallback(() => {
        if (isMounted()) {
            setAnimate(true);
        }
    }, [isMounted]);

    const handleScroll = useCallback(() => {
        let scrollTop; let
            offsetHeight;
        // only animate when rows are moved added or removed
        // FlipMove has to be notified beforehand that next time content changes, it should not display animation
        if (fetch && prevAnimate) setAnimate(false);

        if (listRef?.current) ({ scrollTop, offsetHeight } = listRef.current);

        let offset = round((scrollTop || 1) / rowHeight) - 1; // ensure first visible row gets displayed

        if (offset < 0) {
            offset = 0; // ensure offset is positive
        } else if (offset % 2) {
            offset -= 1; // display rows from event index to enable CSS event/odd row color
        }

        const lastFullyVisibleRow = offset + round((offsetHeight || 1) / rowHeight); // ~
        let offsetBottom = lastFullyVisibleRow + 2; // ensure last row gets rendered

        if (offsetBottom > rows.length) {
            offsetBottom = rows.length; // prevent possible index out of bounds
        }

        if (offset > offsetBottom) {
            offset = offsetBottom; // make sure that offset is less than offsetBottom
        }

        setOffset(offset);
        setOffsetBottom(offsetBottom);

        if (fetch) {
            setAnimate(false); // prevent animation until new data has been displayed
            onRowScopeChange(offset)
                .then(() => {
                    reEnableAnimation(); // re-enable animation when new data has been displayed
                });
        }
    }, [onRowScopeChange, prevAnimate, reEnableAnimation, rowHeight, rows.length]);

    useEffect(() => {
        listRef.current.addEventListener('contextmenu', (e) => e.preventDefault());
        listRef.current.addEventListener('scroll', () => {
            const {scrollTop} = listRef.current;
            if (abs(scrollTop - prevScrollTop) > rowHeight / 2) {
                handleScroll({ fetch: !disableScene });
            }
        }, true);
        handleScroll({ fetch: !disableScene });
    }, [disableScene, handleScroll, prevScrollTop, rowHeight]);

    useEffect(() => {
        if (!rows.length && prevRows?.length) {
            listRef.current.scrollTo(listRef.current.scrollLeft, 0);
            if (animate) setAnimate(false);
        }

        if (moveTo !== undefined && moveTo !== prevMoveTo) {
            listRef.current.scrollTo(listRef.current.scrollLeft, Math.max(0, (rowHeight * moveTo) - rowHeight - 5));
        }

        if (rows.length !== prevRows?.length) {
            handleScroll({ fetch: false }); // redefine visible scope & don't re-fetch content
        }
    }, [rows, animate, handleScroll, prevMoveTo, prevRows?.length, moveTo, rowHeight]);

    const onColumnDoubleClick = ({_button}) => {
        console.log('DataTable double click does not do anything');
    };

    const handlePageUp = (e) => {
        e.preventDefault();
        const { scrollLeft, scrollTop, offsetHeight } = listRef.current;
        const almostPageUp = (scrollTop - ((offsetHeight / 3) * 2));
        listRef.current.scrollTo(scrollLeft, almostPageUp);
        const rowIndex = max(0, trunc((almostPageUp + (offsetHeight / 2.3)) / rowHeight));
        const {columnIndex} = cursor.target;
        setTimeout(() => setCursorLocation({rowIndex, columnIndex}), 0);
    };

    const handlePageDown = (e) => {
        e.preventDefault();
        const {scrollLeft, scrollTop, offsetHeight} = listRef.current;
        const almostPageDown = (scrollTop + ((offsetHeight / 3) * 2));
        listRef.scrollTo(scrollLeft, almostPageDown);
        const rowIndex = min(rows.length - 1, trunc((almostPageDown + (offsetHeight / 2)) / rowHeight));
        const {columnIndex} = cursor.target;
        setTimeout(() => setCursorLocation({rowIndex, columnIndex}), 0);
    };

    /* scroll page half page up if current item is the uppermost item visible */
    const handleArrowUp = () => {
        const {status, target: {rowIndex}} = cursor;
        if (status !== 'scroll' && rowIndex !== 0) {
            const nextRow = rowIndex - 2;
            if (nextRow <= offset) {
                const {scrollLeft, scrollTop, offsetHeight} = listRef.current;
                const halfPageUp = scrollTop - (offsetHeight / 2);
                listRef.current.scrollTo(scrollLeft, halfPageUp < 0 ? 0 : halfPageUp);
            }
            onArrowUp();
        }
    };

    const handleArrowDown = () => {
        const {status, target: {rowIndex}} = cursor;
        if (status !== 'scroll' && rowIndex < rows.length - 1) {
            const nextRow = rowIndex + 1;
            if (nextRow >= offsetBottom) {
                const {scrollLeft, scrollTop, offsetHeight} = listRef.current;
                const halfPageDown = scrollTop + (offsetHeight / 2);
                listRef.current.scrollTo(scrollLeft, halfPageDown);
            }
            onArrowDown();
        }
    };

    const onKeyDown = (event) => {
        // eslint-disable-next-line default-case
        setAnimate(false);
        switch (event.key) {
        case 'Escape':
            clearRowFocus();
            break;
        case 'ArrowRight':
            onArrowRight();
            break;
        case 'ArrowLeft':
            onArrowLeft();
            break;
        case 'Tab':
            onTab();
            break;
        case 'ArrowUp':
            handleArrowUp();
            break;
        case 'ArrowDown':
            handleArrowDown();
            break;
        case 'PageUp':
            handlePageUp(event);
            break;
        case 'PageDown':
            handlePageDown(event);
            break;
        default:
            break;
        }
        resolveLast(10).then(reEnableAnimation);
    };

    return (
        <div
          ref={listRef}
          className="grid-content">
            <TableHeader
              country={country}
              selectable={enableRowSelect}
              storableColumn={storableColumn}
              linkableColumns={linkableColumns}
              doLinkCode={doLinkCode}
              doLinkAllCodes={doLinkAllCodes}
              selectedCode={selectedCode}
              headers={headers}
              onSort={onSort}
              sortOrder={sortOrder}
              dimensions={dimensions}
              onSelectAll={onSelectAllRows}
              onUnSelectAll={onUnSelectAllRows}
              allSelected={!!selectedRows['*']}
              renameColumns={renameColumns}
              onResize={onResize}/>
            {stickyRow}
            <div key="top-padding" style={{height: `${paddingTopHeight}px`}} id="data-grid-padding-top"/>

            <FlipMove
              duration={150}
              staggerDelayBy={0}
              disableAllAnimations={!animate}
              enterAnimation="fade"
              leaveAnimation="fade"
              appearAnimation="accordionVertical"
              className={`grid-rows${disableFocus ? ' disable-focus' : ''}`}
              style={{height: `${rowContainerHeight}px`}}
              onKeyDown={onKeyDown}>
                {range(rows.length > 0 ? offset : 0, min(offsetBottom, rows.length)).map((i) => {
                    if (rows && rows[i]._placeholder_) {
                        return (<div key={`row-placeholder-${i}`} style={{height: `${rowHeight}px`}}/>);
                    }
                    const editable = !(readOnly || disableRow(rows[i]));
                    return (
                        <TableRow
                          domain={domain}
                          country={country}
                          doFetchContent={doFetchContent}
                          selectable={enableRowSelect}
                          storableColumn={storableColumn}
                          linkableColumns={linkableColumns}
                          doStoreCodes={doStoreCodes}
                          doLinkCode={doLinkCode}
                          selectedCode={selectedCode}
                          key={`row-${rows[i][rowKey]}`}
                          rowKey={rowKey}
                          id={rows[i][rowKey]}
                          nonEditableFields={editable ? nonEditableFields : headers}
                          columnMenu={columnMenu?.rowIndex === i ? columnMenu : undefined}
                          onUpdateColumn={editable ? onUpdateColumn : () => undefined}
                          height={rowHeight}
                          headers={headers}
                          toggleRowSelection={toggleRowSelection}
                          selected={!!selectedRows[i] || !!selectedRows['*']}
                          highlighted={highlightedRow === i}
                          onColumnRightClick={editable ? onColumnRightClick : () => undefined}
                          rowIndex={i}
                          cursorColumn={cursor.target.rowIndex === i ? cursor.target.columnIndex : undefined}
                          dimensions={dimensions}
                          sortOrder={sortOrder}
                          displayPlaceholder={!rows[i]}
                          columns={rows[i]}
                          onColumnDoubleClick={onColumnDoubleClick}
                          onColumnClick={onColumnClick}/>
                    );
                    })}
            </FlipMove>
            <div key="bottom-padding" style={{height: paddingBottomHeight}} id="data-grid-padding-bottom" />
            {children}
        </div>
    );
};
DataTable.propTypes = {
    domain: string,
    readOnly: bool,
    country: string,
    doFetchContent: func,
    headers: array.isRequired,
    rows: array.isRequired,
    onRowScopeChange: func.isRequired,
    dimensions: object.isRequired,
    onResize: func.isRequired,
    sortOrder: object.isRequired,
    onSort: func.isRequired,
    rowHeight: number,
    onColumnRightClick: func.isRequired,
    highlightedRow: number,
    disableFocus: bool,
    selectedRows: object.isRequired,
    toggleRowSelection: func,
    rowKey: string.isRequired,
    onUpdateColumn: func,
    moveTo: number,
    columnMenu: object,
    stickyRow: node,
    clearRowFocus: func.isRequired,
    nonEditableFields: array.isRequired,
    nonEditableRowRules: object,
    onArrowUp: func.isRequired,
    onArrowRight: func.isRequired,
    onArrowDown: func.isRequired,
    onArrowLeft: func.isRequired,
    onTab: func.isRequired,
    onColumnClick: func.isRequired,
    setCursorLocation: func.isRequired,
    cursor: shape({
        status: oneOf(['scroll', 'intent', 'active']),
        target: shape({
            row: number,
            column: number,
        }).isRequired,
    }).isRequired,
    onSelectAllRows: func,
    onUnSelectAllRows: func,
    enableRowSelect: bool,
    renameColumns: object,
    disableScene: bool,
    storableColumn: string,
    linkableColumns: array,
    doStoreCodes: func,
    doLinkCode: func,
    doLinkAllCodes: func,
    selectedCode: string,
};

export default DataTable;
