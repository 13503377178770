import React from 'react';
import {string, object, func, array, bool} from 'prop-types';
import HeaderCell from './HeaderCell';
import LinkCode from '../../LinkCode';
import FillRest from './FillRest';
import './styles.scss';

export default function TableHeader({
    country,
    headers,
    selectable,
    storableColumn,
    linkableColumns,
    doLinkAllCodes,
    selectedCode,
    allSelected,
    onSelectAll,
    onUnSelectAll,
    onSort,
    sortOrder,
    dimensions,
    renameColumns = {},
    onResize,
}) {
    return (
        <div className="grid-header">
            {storableColumn && <HeaderCell title="store" width={40}/>}
            <HeaderCell title="#" width={selectable ? 40 : 72}/>
            {selectable && <HeaderCell title="✔" width={32} centeredText onClick={allSelected ? onUnSelectAll : onSelectAll}/>}
            {headers.map((field) => (
                <React.Fragment key={field}>
                    <HeaderCell
                      key={field}
                      title={`${renameColumns[field] || field}`}
                      onClick={() => onSort(field)}
                      sortOrder={sortOrder[field]}
                      width={dimensions[field]}
                      onResize={(width) => onResize({width, field})}/>
                    {(linkableColumns && linkableColumns.includes(field) && selectedCode && country !== 'com')
                    && <LinkCode header column={field} selectedCode={selectedCode} doLinkAllCodes={doLinkAllCodes}/>}
                </React.Fragment>
            ))}
            <FillRest/>
        </div>
    );
}

TableHeader.propTypes = {
    headers: array.isRequired,
    onSort: func.isRequired,
    sortOrder: object.isRequired,
    onResize: func.isRequired,
    dimensions: object.isRequired,
    allSelected: bool.isRequired,
    onSelectAll: func,
    onUnSelectAll: func,
    selectable: bool,
    renameColumns: object,
    storableColumn: string,
    linkableColumns: array,
    selectedCode: string,
};
