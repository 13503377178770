import {
    onUpdateRelations,
    onFetchRelations,
    mapProcPlusCodesToOptions,
    mapProcNatCodesToOptions,
} from './resources/reducerUtils';
import { memoizedMap, normalizeCode } from '../common/utils';

const FETCH_PROPERTY_NAMES = 'PROCEDURE_RELATIONS::FETCH_PROPERTY_NAMES';
const SET_PROPERTY_NAMES = 'PROCEDURE_RELATIONS::SET_PROPERTY_NAMES';
const FETCH_NAME_RELATIONS = 'PROCEDURE_RELATIONS::FETCH_NAME_RELATIONS';
const SET_NAME_RELATIONS = 'PROCEDURE_RELATIONS::SET_NAME_RELATIONS';
const SET_VARTYPES = 'PROCEDURE_RELATIONS::SET_VARTYPES';
const SET_VARVALS = 'PROCEDURE_RELATIONS::SET_VARVALS';
const CLEAR_VARVALS = 'PROCEDURE_RELATIONS::CLEAR_VARVALS';
const CLEAR_CODES = 'PROCEDURE_RELATIONS::CLEAR_CODES';
const CLEAR_NAT_CODES = 'PROCEDURE_RELATIONS::CLEAR_NAT_CODES';
const SET_CODES = 'PROCEDURE_RELATIONS::SET_CODES';
const SET_NAT_CODES = 'PROCEDURE_RELATIONS::SET_NAT_CODES';

export default function procedureRelationsReducer(state = {principal: [], major: [], names: [], vartypes: [], codes: [], natCodes: []}, {type, payload}) {
    switch (type) {
    case SET_PROPERTY_NAMES:
        return {...state, names: payload};
    case FETCH_NAME_RELATIONS: {
        const names = onFetchRelations({list: state.names, identifier: 'procprop', id: normalizeCode(payload)});
        return {...state, names};
    }
    case SET_NAME_RELATIONS: {
        const {relations, id} = payload;
        const names = onUpdateRelations({list: state.names, identifier: 'procprop', id: normalizeCode(id), relations});
        return {...state, names};
    }
    case SET_VARTYPES:
        return {...state, vartypes: payload};
    case SET_VARVALS:
        return {...state, varvals: payload};
    case CLEAR_VARVALS:
        return {...state, varvals: []};
    case CLEAR_CODES:
        return {...state, codes: []};
    case CLEAR_NAT_CODES:
        return {...state, natCodes: []};
    case SET_CODES:
        return {...state, codes: payload};
    case SET_NAT_CODES:
        return {...state, natCodes: payload};
    default: return state;
    }
}

export const doFetchProcedureRelationVartypes = () => async (dispatch, getState, Api) => {
    const vartypes = await Api.post('/procedure/vartypes/fetch');
    dispatch({type: SET_VARTYPES, payload: vartypes});
};

export const doFetchProcedureRelationVarvals = (vartype) => async (dispatch, getState, Api) => {
    dispatch({type: CLEAR_VARVALS});
    const varvals = await Api.post('/procedure/varvals/fetch', {payload: {vartype}});
    dispatch({type: SET_VARVALS, payload: varvals});
};

export const doFetchProcedureRelationCodes = () => async (dispatch, getState, Api) => {
    dispatch({type: CLEAR_CODES});
    const codes = await Api.post('/procedure/features/codes/fetch');
    dispatch({type: SET_CODES, payload: codes});
};

export const doFetchProcedureRelationNatCodes = () => async (dispatch, getState, Api) => {
    dispatch({type: CLEAR_NAT_CODES});
    const {country} = getState().locales;
    const payload = {globalFilter: {country}};
    const codes = await Api.post('/procedure/features/national_codes/fetch', {payload});
    dispatch({type: SET_NAT_CODES, payload: codes});
};

export const doFetchProcedureRelationPropertyNames = () => async (dispatch, getState, Api) => {
    dispatch({type: FETCH_PROPERTY_NAMES});
    const {rows} = await Api.post('/procedure/feature_names/fetch');
    dispatch({type: SET_PROPERTY_NAMES, payload: rows});
};

export const doFetchProcedureRelationProcedureNameRelations = (varval) => async (dispatch, getState, Api) => {
    dispatch({type: FETCH_NAME_RELATIONS, payload: varval});
    const {country} = getState().locales;
    const payload = {globalFilter: {country}, varval};
    const relations = await Api.post('/procedure/relations/fetch', {payload});
    dispatch({type: SET_NAME_RELATIONS, payload: {relations, id: varval}});
};

export const actions = {
    doFetchProcedureRelationVartypes,
    doFetchProcedureRelationVarvals,
    doFetchProcedureRelationCodes,
    doFetchProcedureRelationNatCodes,
    doFetchProcedureRelationPropertyNames,
    doFetchProcedureRelationProcedureNameRelations,
};

export const selectors = {
    getProcedureRelationsCodeOptions: memoizedMap((codes) => mapProcPlusCodesToOptions({ codes })),
    getProcedureRelationsNatCodeOptions: memoizedMap((codes, includePlusCode) => mapProcNatCodesToOptions({ codes, includePlusCode })),
};
