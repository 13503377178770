import React from 'react';
import {Routes, Route} from 'react-router-dom';
import Dumps from './Dumps';
import Admin from './Admin';

const Config = ({mbc}) => (
    <Routes>
        <Route path="dumps" element={<Dumps />} />
        <Route path="admin" element={<Admin mbc={mbc} />} />
    </Routes>
);

export default Config;
