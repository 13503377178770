import React from 'react';
import {array, func} from 'prop-types';
import {connect} from 'react-redux';
import Spinner from '../Spinner';
import './ContextMenu/styles.scss';

const ErrorsAndNotificationsListMenu = ({list = [], onClose, loading}) => (
    <div className="cell-context-menu" onClick={onClose}>
        <Spinner hidden={!loading} className="context-menu-spinner" />
        <div className="cell-context-description-menu">
            {list.map((item) => (
                <div key={item} className="cell-context-menu-description-item">
                    <p>{item}</p>
                </div>
                    ))}
        </div>
    </div>
);

ErrorsAndNotificationsListMenu.propTypes = {
    list: array.isRequired,
    onClose: func.isRequired,
};

export default connect(({locales: {t}}) => ({t}))(ErrorsAndNotificationsListMenu);
