import React from 'react';
import {connect} from 'react-redux';
import {object, func, array} from 'prop-types';
import StickyTableCell from './StickyTableCell';
import Button from '../../Button';
import {createResolveLatestIf} from '../../../common/utils';
import './styles.scss';

class StickyTableRow extends React.Component {
    constructor(props) {
        super(props);
        this.resolveLatestIf = createResolveLatestIf();
    }

    state = {focused: this.props.fields.find((f) => !this.props.nonEditableFields.includes(f))};

    render() {
        const {focused} = this.state;
        const {
            t,
            domain,
            country,
            fields,
            values,
            dimensions,
            onChange,
            nonEditableFields,
            typingDisabledFields,
            columnTypes,
            storableColumn,
            linkableColumns,
            selectedCode,
            allowEmptyOptionColumns,
            onSubmit,
        } = this.props;

        return (
            <div className={`sticky-table-row${storableColumn ? ' with-store' : ''}`} onBlur={this.onBlur}>
                {fields.map((field) => {
                    const disabled = nonEditableFields.indexOf(field) !== -1;
                    const linkable = linkableColumns && linkableColumns.includes(field) && selectedCode && country !== 'com';
                    const typingDisabled = !!typingDisabledFields[field];
                    return (
                        <StickyTableCell
                          key={field}
                          column={field}
                          domain={domain}
                          focused={field === focused}
                          type={columnTypes[field]}
                          allowEmpty={!!allowEmptyOptionColumns[field]}
                          placeholder={disabled ? 'auto generated' : ''}
                          disabled={disabled}
                          typingDisabled={typingDisabled}
                          onChange={disabled && !linkable ? undefined : ({value}) => onChange({[field]: value})}
                          value={values[field]}
                          onFocus={() => this.setState({focused: field})}
                          selectedCode={selectedCode}
                          linkable={linkable}
                          width={dimensions[field] || dimensions.defaultColumnWidth}/>
                    );
                })}
                <div className="sticky-right-container">
                    <div className="relative-container">
                        <Button disabled={!onChange} text={t.saveText} className="button-right-sticky" onClick={onSubmit}/>
                    </div>
                </div>
            </div>
        );
    }

    onBlur = () => {
        const {state} = this;
        this.resolveLatestIf({initialDelay: 100, cancelOn: () => this.state !== state || this.unMounted})
            .then(() => this.setState({focused: undefined}));
    };

    componentWillUnmount() {
        this.unMounted = true;
    }
}

StickyTableRow.propTypes = {
    fields: array.isRequired,
    onChange: func,
    nonEditableFields: array.isRequired,
    onSubmit: func.isRequired,
    columnTypes: object.isRequired,
};

export default connect(({locales: {t}}) => ({t}))(StickyTableRow);
