/* eslint-disable max-len */
import React from 'react';
import {func, bool} from 'prop-types';
import {connect} from 'react-redux';
import './styles.scss';

function Check({color = 'black'}) {
    return (
        <svg className={`svg-check svg-${color}`} viewBox="0 0 12 9" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Symbols">
                <g id="table-area5" transform="translate(-355.000000, -134.000000)" >
                    <path d="M366.002232,136.049107 C366.127233,136.174108 366.189732,136.325892 366.189732,136.504464 C366.189732,136.683037 366.127233,136.834821 366.002232,136.959821 L360.243304,142.71875 C360.118303,142.843751 359.966519,142.90625 359.787946,142.90625 C359.609374,142.90625 359.45759,142.843751 359.332589,142.71875 L355.997768,139.383929 C355.872767,139.258928 355.810268,139.107144 355.810268,138.928571 C355.810268,138.749999 355.872767,138.598215 355.997768,138.473214 L356.908482,137.5625 C357.033483,137.437499 357.185267,137.375 357.363839,137.375 C357.542412,137.375 357.694196,137.437499 357.819196,137.5625 L359.787946,139.537946 L364.180804,135.138393 C364.305804,135.013392 364.457588,134.950893 364.636161,134.950893 C364.814733,134.950893 364.966517,135.013392 365.091518,135.138393 L366.002232,136.049107 Z" id=""/>
                </g>
            </g>
        </svg>
    );
}
function Clear({color = 'black'}) {
    return (
        <svg viewBox="0 0 10 10" className={`svg-clear svg-${color}`} version="1.1" xmlns="http://www.w3.org/2000/svg" >
            <g id="Symbols">
                <g id="edit-rows" transform="translate(-142.000000, -7.000000)" >
                    <polygon id="clear---material" points="151.656 8.688 147.944 12.4 151.656 16.112 150.712 17.056 147 13.344 143.288 17.056 142.344 16.112 146.056 12.4 142.344 8.688 143.288 7.744 147 11.456 150.712 7.744"/>
                </g>
            </g>
        </svg>
    );
}

function BooleanMenu({t, value, onChange, onClose, style, allowNull = false}) {
    return (
        <div className="cell-menu boolean-menu" style={style} onClick={onClose}>
            {allowNull && (
                <div
                  onMouseDown={() => onChange({value: null})}
                  className={value === null ? 'cell-menu-item-selected' : 'cell-menu-item'}/>
            )}
            <div
              onMouseDown={() => onChange({value: true})}
              className={value === true ? 'cell-menu-item-selected' : 'cell-menu-item'}>
                <Check color={value === true ? 'white' : 'grey'} />
                {t.yes}
            </div>
            <div
              onMouseDown={() => onChange({value: false})}
              className={value === false ? 'cell-menu-item-selected' : 'cell-menu-item'}>
                <Clear color={value === false ? 'white' : 'grey'}/>
                {t.no}
            </div>
        </div>
    );
}

BooleanMenu.propTypes = {
    value: bool.isRequired,
    onChange: func.isRequired,
};

export default connect(({locales: {t}}) => ({t}))(BooleanMenu);
