import React from 'react';
import {bool, object} from 'prop-types';
import {connect} from 'react-redux';
import {hideOverlay} from '../../store/meta';
import './styles.scss';
import Close from '../Close';
import Spinner from '../Spinner';
import {stopPropagation} from '../../common/utils';
import {actionsWrapper} from '../../store/resources/reducerUtils';
import store from '../../store';

class Overlay extends React.Component {
    render() {
        const {content, visible, loading, hideOverlay} = this.props;
        return (
            <div className={`overlay-container${!visible ? ' overlay-container-disappear' : ''}`} onClick={hideOverlay}>
                <div className="overlay-content" onClick={stopPropagation}>
                    <Spinner hidden={!loading}/>
                    <Close onClick={hideOverlay}/>
                    {Object.entries(content)
                        .map(([key, value]) => [key, Object.values(value)])
                        .filter(([_, values]) => values.length)
                        .map(([key, values]) => (
                            <div key={key} className="overlay-content-item" >
                                <h5>{key}:</h5>
                                {values.map(({varval, description}) => <p key={varval}>{`${varval} - ${description}`}</p>)}
                            </div>
                        ))}
                </div>
            </div>
        );
    }
}

Overlay.propTypes = {
    content: object.isRequired,
    visible: bool.isRequired,
    loading: bool,
};

export default connect(
    ({meta: {overlay}}) => overlay,
    {...actionsWrapper(store, {hideOverlay})},
)(Overlay);
