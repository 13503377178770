/* eslint-disable react/button-has-type */
import React from 'react';
import './styles.scss';

export default function Button({text, type = 'button', onClick, className, disabled, onMouseLeave}) {
    return (
        <button
          onClick={(e) => {
            if (disabled) {
                e.stopPropagation();
            } else if (onClick) {
                onClick(e);
            }
        }}
          type={type}
          onMouseLeave={onMouseLeave}
          className={`button-default ${className || ''}`}
          disabled={disabled}>{text}
        </button>
    );
}
