import { createDistinctLastTimeout, range, toSet } from '../../common/utils';
import { DEFAULT_COLUMN_WIDTH, MENU_DIMENSIONS } from '../../common/constants';
import {createErrorNotification} from '../errorNotification';

const placeholder = {_placeholder_: true};

export function onSetSearchResults({state, payload}) {
    return {...state, searchResults: {rowNumbers: payload}, loadingContent: false };
}

export function onUpdateRelations({list, identifier, relations, id}) {
    Object.entries({id, list, identifier, relations}).filter(([_, v]) => !v)
        .forEach(([k]) => console.warn(`Missing properties passed to onUpdateRelations ${k}`));
    const index = list.findIndex((it) => it[identifier] === id);
    if (index === -1) {
        console.error(`invalid id ${id}`);
        return list;
    }
    const target = list[index];
    return [
        ...list.slice(0, index),
        {...target, relations: {...relations, loading: false}},
        ...list.slice(index + 1, list.length),
    ];
}

export function onFetchRelations({list, identifier, id}) {
    Object.entries({id, list, identifier}).filter(([_, v]) => !v)
        .forEach(([k]) => console.warn(`Missing properties passed to onFetchRelations ${k}`));
    const index = list.findIndex((it) => it[identifier] === id);
    if (index === -1) {
        console.error(`invalid id ${id}`);
        return list;
    }
    const target = list[index];
    let {relations = {}} = target;
    relations = {...relations, loading: true};
    return [
        ...list.slice(0, index),
        {...target, relations},
        ...list.slice(index + 1, list.length),
    ];
}

export function onMoveSearchResultPointer({state, payload}) {
    const {searchResults} = state;
    if (!searchResults) {
        console.warn('MOVE_SEARCH_RESULT_POINTER should not be dispatched when searchResults is not present');
        return searchResults;
    }
    const {rowNumbers} = searchResults;
    if (!rowNumbers || !rowNumbers.length) {
        console.warn('MOVE_SEARCH_RESULT_POINTER should not be dispatched when searchResults.rowNumbers is not present');
        return searchResults;
    }
    let {pointer} = searchResults;
    if (pointer === undefined) { // prev or next not clicked before this click event, goes here only first time
        pointer = payload === 'next' ? 0 : rowNumbers.length - 1;
    } else if (payload === 'next') {
        pointer = (pointer + 1) % rowNumbers.length;
    } else if (pointer === 0) {
        pointer = rowNumbers.length - 1;
    } else {
        pointer -= 1;
    }
    return {...state, searchResults: {pointer, rowNumbers}, rowHighlight: rowNumbers[pointer]};
}

export function onClearRows({state}) {
    const {search, searchResults} = onClearSearch({state});
    return {
        ...state,
        search,
        searchResults,
        rows: [],
        selectedRows: {},
        rowHighlight: undefined,
        rightClickSelection: undefined,
        focusedItemMenu: undefined,
        cursor: {status: 'scroll', target: {}},
        pendingRow: undefined,
    };
}

export function onClearRowFocus({state}) {
    const nextState = {...state, rightClickSelection: undefined, focusedItemMenu: undefined};
    const {status, target} = state.cursor;/* ? */
    if (status === 'active') {
        nextState.cursor = {status: 'intent', target};
    } else if (status === 'intent') {
        nextState.cursor = {status: 'scroll', target: {}};
    }
    return nextState;
}

export function onClearSearch({state}) {
    return {search: {...state.search, text: '' }, searchResults: undefined};
}

const updateRowHistory = ({ state, id, timestamp, column, prevValue }) => {
    const history = {...state.history};
    const rowHistory = history[id] = id in history ? [...history[id]] : [{timestamp, column, value: prevValue}];
    if (rowHistory[0].timestamp > (timestamp - 5000) && rowHistory[0].column === column) {
        // if rows same column is sequentelly updated with less than 5s in between edits, it is considered as single update
        rowHistory[0] = {...rowHistory[0], timestamp};
    } else {
        rowHistory.unshift({timestamp, column, value: prevValue});
    }
    if (rowHistory.length > state.historySize) rowHistory.pop();
    return history;
};

export function onUpdateColumn({state, payload, identifier}) {
    if (!identifier) throw new Error('onUpdateColumn expected identifier parameter');
    if (!payload.timestamp) throw new Error('onUpdateColumn expected {timestamp} in payload');
    if (!payload.column) throw new Error('onUpdateColumn expected {column} in payload');
    let {rows} = state;
    const {[identifier]: id, column, value} = payload;
    const index = rows.findIndex((row) => row[identifier] === id);

    if (index === -1 && payload.vartype !== 'MDC') throw new Error('onUpdateColumn no row was found with given identifier');

    if (index !== -1) {
        const prevValue = rows[index][column];
        rows = [
            ...rows.slice(0, index),
            {...rows[index], [column]: value},
            ...rows.slice(index + 1, rows.length),
        ];
        const {status, target} = state.cursor;
        const cursor = {target, status};
        if (status === 'intent') {
            cursor.status = 'active';
        }
        const {search, searchResults} = onClearSearch({state});
        let {focusedItemMenu} = state;
        if (focusedItemMenu && focusedItemMenu.row[identifier] === rows[index][identifier]) {
            focusedItemMenu = {...focusedItemMenu, row: rows[index]};
        }

        const {timestamp} = payload;
        const history = updateRowHistory({ state, id, timestamp, column, prevValue });
        return {...state, rows, search, searchResults, cursor, focusedItemMenu, history};
    } else {
        return state;
    }
}

export function onMoveRows({state, payload, identifier}) {
    if (!identifier) {
        throw new Error('onMoveRows expected identifier parameter');
    }
    const {rightClickSelection, selectedRows} = state;
    const index = state.rows.findIndex((row) => row[identifier] === rightClickSelection.row[identifier]);
    const selectedRowData = Object.keys(selectedRows)
        .map((id) => state.rows.find((row) => row[identifier] === id))
        .sort((row1, row2) => (row1.ord < row2.ord ? -1 : 1))
        .map((row) => ({...row, _pending: true}));
    let rows;
    let {rowHighlight} = state;
    if (payload === 'above') {
        rowHighlight = selectedRowData.reduce((acc, row) => (rightClickSelection.row.ord > row.ord ? acc : acc + 1), rowHighlight);
        rows = [
            ...state.rows.slice(0, index).filter((row) => row._placeholder_ || !selectedRows[row[identifier]]),
            ...selectedRowData,
            state.rows[index],
            ...state.rows.slice(index + 1, state.rows.length).filter((row) => row._placeholder_ || !selectedRows[row[identifier]]),
        ];
    } else {
        rowHighlight = selectedRowData.reduce((acc, row) => (rightClickSelection.row.ord < row.ord ? acc : acc - 1), rowHighlight);
        rows = [
            ...state.rows.slice(0, index).filter((row) => row._placeholder_ || !selectedRows[row[identifier]]),
            state.rows[index],
            ...selectedRowData,
            ...state.rows.slice(index + 1, state.rows.length).filter((row) => row._placeholder_ || !selectedRows[row[identifier]]),
        ];
    }
    const {search, searchResults} = onClearSearch({state});
    return {...state, rows, rowHighlight, search, searchResults, rightClickSelection: undefined};
}

export function onToggleRowSelection({state, payload, identifier}) {
    if (!identifier) {
        throw new Error('onToggleRowSelection expected identifier parameter');
    }
    const selectedRows = {...state.selectedRows};
    const {[identifier]: id} = state.rows[payload];
    if (selectedRows[id]) {
        delete selectedRows[id];
    } else {
        selectedRows[id] = true;
    }
    return {...state, selectedRows};
}

export function onAddRow({state, payload, identifier}) {
    if (!identifier) {
        throw new Error('onAddRow expected identifier parameter');
    }
    let top;
    let bottom;
    const index = state.rows.findIndex((row) => row[identifier] === state.rightClickSelection.row[identifier]);
    let {rowHighlight} = state;
    if (payload.position === 'above') {
        rowHighlight++;
        top = state.rows.slice(0, index);
        bottom = state.rows.slice(index, state.rows.length);
    } else {
        top = state.rows.slice(0, index + 1);
        bottom = state.rows.slice(index + 1, state.rows.length);
    }
    const rows = [
        ...top,
        {_pending: true, [identifier]: payload[identifier]},
        ...bottom,
    ];
    const {search, searchResults} = onClearSearch({state});
    return {...state, rows, rowHighlight, search, searchResults, rightClickSelection: undefined};
}

export function onRightClickSelectRow({state, payload}) {
    const {headers, dimensions, pendingRow} = state;
    const {rowHeight} = dimensions;
    const {rowIndex, columnIndex, leftOffset, menuWidth = 100, absoluteLeftOffset = 71} = payload;
    let left = absoluteLeftOffset + range(columnIndex).reduce((acc, i) => acc + (dimensions[headers[i]] || DEFAULT_COLUMN_WIDTH) + 1, leftOffset);/* + 1 pixel/column to compensation the border with */
    const rightMax = (absoluteLeftOffset + headers.reduce((acc, key) => acc + (dimensions[key] || DEFAULT_COLUMN_WIDTH), 0)) - menuWidth;
    if (left > rightMax) {
        left = Math.max(rightMax, 0);
    }
    const top = (rowIndex * rowHeight) + rowHeight;
    const rightClickSelection = {left, top};
    if (pendingRow) {
        rightClickSelection.top += rowHeight;
    }
    rightClickSelection.column = state.headers[columnIndex];
    rightClickSelection.row = state.rows[rowIndex];

    return {...state, rightClickSelection, rowHighlight: rowIndex, cursor: {status: 'scroll', target: {}}, focusedItemMenu: undefined};
}

export function onSetContent({state, payload, identifier, headerOrder = []}) {
    const {count} = payload;
    const offset = payload.offset > 0 ? payload.offset : 0;
    const payloadIds = payload.rows.reduce(toSet(identifier), {});
    function replaceWithPlaceholderIfDuplicate(index) {
        if (state.rows[index] && payloadIds[state.rows[index][identifier]]) { return placeholder; }
        return state.rows[index] || placeholder;
    }

    const top = range(0, offset).map(replaceWithPlaceholderIfDuplicate);
    const bottom = range(offset + payload.rows.length, count).map(replaceWithPlaceholderIfDuplicate);
    const rows = [
        ...top,
        ...payload.rows,
        ...bottom,
    ];
    let {cursor} = state;
    const {status, target} = cursor;
    if (status !== 'scroll' && (!state.rows[target.columnIndex] || rows[target.rowIndex][identifier] !== state.rows[target.rowIndex][identifier])) {
        cursor = {status: 'scroll', target: {}};
    }
    const headers = payload.rows.length ? Object.keys(payload.rows[0]) : state.headers;
    if (headerOrder.length) {
        headers.sort((a, b) => headerOrder.indexOf(b) - headerOrder.indexOf(a));
    }
    return {...state, loadingContent: false, rows, headers, cursor};
}

export function onUpdateOrder({state, payload}) {
    const last = payload[payload.length - 1];
    if (payload.some((order) => !Array.isArray(order))) {
        throw new Error(`Invalid payload for onUpdateOrder
        Expected key value pares ['fieldName', 'ASC'/'DESC'],
        but got ${JSON.stringify(payload, null, 1)}`);
    }
    let order;
    if (state.order.length > payload.length || !last) {
        order = payload;
    } else {
        const current = payload.slice(0, payload.length - 1).filter(([field]) => field !== last[0]);
        order = [...current, last];
    }
    const {search, searchResults} = onClearSearch({state});
    return {...state, order, search, searchResults, rows: []};
}

export function onFieldOrderToggle({state, payload}) {
    let {order} = state;
    const index = order.findIndex(([field]) => field === payload);
    if (index === -1) {
        order = [...order, [payload, 'ASC']];
    } else if (order[index][1] === 'DESC') {
        order = [
            ...order.slice(0, index),
            ...order.slice(index + 1, order.length),
        ];
    } else {
        order = [...order.filter(([field]) => field !== payload), [payload, 'DESC']];
    }
    const {search, searchResults} = onClearSearch({state});
    return {...state, order, search, searchResults, rows: []};
}

export function onShowColumnContextMenu({state, payload}) {
    const {rowIndex, columnIndex} = payload;
    const {headers, rows, dimensions} = state;
    const column = headers[columnIndex];
    const nextState = {...state, rightClickSelection: undefined, rowHighlight: undefined};
    if (MENU_DIMENSIONS[column]) {
        const {rowHeight} = dimensions;
        const focusedItemMenu = {rowIndex, column, row: rows[rowIndex], style: {top: rowHeight - 2, left: 0}};
        return {...nextState, focusedItemMenu};
    }
    return {...nextState, focusedItemMenu: undefined};
}

/**
 * Called when cursor status is intent and user starts typing & when column is clicked
 * Takes a state and payload object {rowIndex: n1, columnIndex: n2}.
 * @param {object} state - state
 * @param {object} payload - @property {Integer} payload.rowIndex @property {Integer} payload.columnIndex
 */
export function onSetFocusedItem({state, payload}) {
    const {rowIndex, columnIndex} = payload;
    const cursor = {status: 'active', target: {rowIndex, columnIndex}};
    return onShowColumnContextMenu({state: {...state, cursor}, payload});
}

export function onCursorLocationChange({state, payload, status = 'intent'}) {
    const {rowIndex, columnIndex} = payload;
    return {
        ...state,
        focusedItemMenu: undefined,
        cursor: {status, target: {columnIndex, rowIndex}},
        rowHighlight: undefined,
    };
}

export function onArrowRight({state}) {
    const {cursor, headers} = state;
    const {status, target: {rowIndex, columnIndex}} = cursor;
    if (status !== 'active' && (columnIndex + 1) < headers.length) {
        const payload = {columnIndex: columnIndex + 1, rowIndex};
        return onCursorLocationChange({state, payload});
    }
    return state;
}

export function onArrowLeft({state}) {
    const {cursor} = state;
    const {status, target: {rowIndex, columnIndex}} = cursor;
    if (status !== 'active' && columnIndex > 0) {
        const payload = {columnIndex: columnIndex - 1, rowIndex};
        return onCursorLocationChange({state, payload});
    }
    return state;
}

export function onArrowDown({state}) {
    const {cursor, rows} = state;
    const {status, target: {rowIndex, columnIndex}} = cursor;
    if (status === 'intent' && (rows.length - 1) > rowIndex) {
        const payload = {columnIndex, rowIndex: rowIndex + 1};
        return onCursorLocationChange({state, payload});
    }
    return state;
}
export function onArrowUp({state}) {
    const {cursor} = state;
    const {status, target: {rowIndex, columnIndex}} = cursor;
    if (status === 'intent' && rowIndex) {
        const payload = {columnIndex, rowIndex: rowIndex - 1 };
        return onCursorLocationChange({state, payload});
    }
    return state;
}

export function onTab({state}) {
    const {cursor, rows, headers} = state;
    const {status, target: {rowIndex, columnIndex}} = cursor;
    if (columnIndex < (headers.length - 1)) {
        const payload = {columnIndex: columnIndex + 1, rowIndex};
        return onCursorLocationChange({state, payload, status});
    } else if (rows.length > (rowIndex + 1)) {
        const payload = {columnIndex: 0, rowIndex: rowIndex + 1};
        return onCursorLocationChange({state, payload, status});
    }
    return state;
}

/**
 * Called when user removes a column from the right-click menu.
 * Takes a state and payload object {rowIndex: n}.
 * Returns state, rows with inputed index given an attribute '_pending'
 * {...state, rows : [{rid: 1, _pending: true, ...}, rightClickSelection: undefined]}
 * @param {object} state - state
 * @param {object} payload - @property {Integer} payload.rowIndex - index of row to be marked as _pending
 */
export function onPreRemoveRow({state, payload}) {
    const {rowIndex} = payload;
    let {rows} = state;
    rows = [
        ...rows.slice(0, rowIndex),
        {...rows[rowIndex], _pending: true},
        ...rows.slice(rowIndex + 1, rows.length),
    ];
    return {...state, rows, rightClickSelection: undefined};
}

export function onUpdateRow({state, payload, identifier}) {
    if (!identifier) {
        throw new Error('missing {identifier} on onUpdateRow');
    } else if (!(identifier in payload)) {
        console.error(`invalid payload ${JSON.stringify(payload, null, 1)}`);
        throw new Error(`Payload does not have identifier:${identifier}`);
    }
    let {rows} = state;
    const index = rows.findIndex((row) => row[identifier] === payload[identifier]);
    if (index === -1) return state; // row is not in the page or batch of rows currently in store -> no update needed
    rows = [
        ...rows.slice(0, index),
        payload,
        ...rows.slice(index + 1, rows.length),
    ];
    return {...state, rows};
}

/**
 * Undo's a change one step backwards. Called via right-click menu when user undos changes to a row.
 * Takes a state, payload with identifier column's name value and table's identifier column's name
 * Returns {...state, rows, history} where rows represent previous changes.
 * @param {object} state - state
 * @param {String} payload - value of identifier column
 * @param {String} identifier - name of table's identifier column
 * @throws "onUndoChange no row was found with given identifier" if row doesn't match identifier
 */
export function onUndoChange({state, payload, identifier}) {
    const history = {...state.history};
    const [lastChange, ...rest] = history[payload] = [...history[payload]];
    const index = state.rows.findIndex((row) => row[identifier] === payload);
    if (index === -1) throw new Error('onUndoChange no row was found with given identifier');
    const {column, value} = lastChange;
    const rows = [
        ...state.rows.slice(0, index),
        {...state.rows[index], [column]: value},
        ...state.rows.slice(index + 1, state.rows.length),
    ];
    if (rest.length) history[payload] = rest;
    else delete history[payload];
    return {...state, rows, history};
}

export function onInitPendingRow({state, payload}) {
    return {...state, rightClickSelection: undefined, focusedItemMenu: undefined, pendingRow: payload || {}, cursor: {status: 'scroll', target: {}}};
}

export function onCopySelectedRow({state, exclude = []}) {
    if (!state.rightClickSelection) {
        console.error('onCopySelectedRow: copy row should now be called when no row is right click selected!');
        return state;
    }
    if (!state.rightClickSelection.row) throw new Error('onCopySelectedRow: Missing rightClickSelection.row');
    const rowCopy = {...state.rightClickSelection.row};
    exclude.forEach((k) => {
        delete rowCopy[k];
    });
    return {...state, rowCopy};
}

const fieldSpecificTimeouts = {};
export function getFieldSpecificTimeout({domain, row, column}) {
    const domainSpecific = fieldSpecificTimeouts[domain] || (fieldSpecificTimeouts[domain] = {});
    const rowSpecific = domainSpecific[row] || (domainSpecific[row] = {});
    return rowSpecific[column] || (rowSpecific[column] = createDistinctLastTimeout());
}

export function mapDgPlusCodesToOptions({ codes }) {
    return codes.map((opt) => ({
        label: `${opt.dg_plus} - ${opt.dg_text_plus}`,
        value: opt.dg_plus,
    }));
}

export function mapDgNatCodesToOptions({ codes }) {
    return codes.map((opt) => ({
        label: `${opt.dg_nat} - ${opt.dg_text_nat}`,
        value: opt.dg_nat,
    }));
}

export function mapProcPlusCodesToOptions({ codes }) {
    return codes.map((opt) => ({
        label: `${opt.proc_plus} - ${opt.proc_text_plus}`,
        value: opt.proc_plus,
    }));
}

export function mapProcNatCodesToOptions({ codes }) {
    return codes.map((opt) => ({
        label: `${opt.proc_nat} - ${opt.proc_text_nat}`,
        value: opt.proc_nat,
    }));
}

export const actionsWrapper = (store, actions) => {
    const wrapped = {};

    Object.keys(actions).forEach((key) => {
        if (typeof actions[key] === 'function') {
            wrapped[key] = (...args) => async () => {
                try {
                    return await store.dispatch(actions[key](...args));
                } catch (error) {
                    console.error(error.message);
                    store.dispatch(createErrorNotification({subject: key, error: error.message}));
                }
            };
        }
    });

    return wrapped;
};

export const selectorsWrapper = (store, selectors) => {
    const wrapped = {};

    Object.keys(selectors).forEach((key) => {
        if (typeof selectors[key] === 'function') {
            wrapped[key] = (...args) => {
                try {
                    return selectors[key](...args);
                } catch (error) {
                    console.error(error.message);
                    store.dispatch(createErrorNotification({subject: key, error: error.message}));
                }
            };
        }
    });

    return wrapped;
};
