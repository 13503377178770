import React from 'react';
import {connect} from 'react-redux';
import FullUploadAndRecreateTables from './FullUploadAndRecreateTables';
import MergeUploadAndReplaceCodes from './MergeUploadAndReplaceCodes';
import Download from './Download';
import ContainerTitle from '../../../components/ContainerTitle';
import '../styles.scss';

const domain = 'dumps';
const Dumps = ({t, downloading}) => (
    <div className="scene-main-content">
        <ContainerTitle text={t.titles[domain]} loading={downloading}/>
        <div className="multi-section-content">
            <Download downloading={downloading}/>
            <FullUploadAndRecreateTables/>
            <MergeUploadAndReplaceCodes/>
        </div>
    </div>
);

export default connect(({locales: {t}, dumps: {downloading}}) => ({t, downloading}))(Dumps);
