import React from 'react';
import {object} from 'prop-types';

export default function ImportSuccessInfo({data, t}) {
    return (
        <div>
            <p>{`${t.timeTaken}: ${data.duration}s`}</p>
            {data.backgroundCheck && <p>{`${t.checkingInBackground}`}</p>}
        </div>
    );
}

ImportSuccessInfo.propTypes = {
    data: object.isRequired,
};
