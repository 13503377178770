import createReduxDomain from './resources/domainFactory';
import { NON_SORTABLE_FIELDS, NON_EDITABLE_FIELDS } from '../common/constants';

const {reducer, types, selectors, actions, middlewares} = createReduxDomain({
    URL: '/diagnosisFeature',
    DOMAIN: 'DIAGNOSIS_FEATURE',
    IDENTIFIER: 'rid',
    nonEditableFields: NON_EDITABLE_FIELDS.diagnosisFeature,
    nonEditableRowRules: {vartype: ['MDC']},
    nonSortableFields: NON_SORTABLE_FIELDS.diagnosisFeature,
    excludeActions: [
        'doLink$Code',
        'doLinkAll$Codes',
        'doSelectLinkable$Code',
        'doSet$ExclusiveToORZeroFilter',
        'doStore$Codes',
    ],
    excludeHeaders: ['rid'],
    updateExtraParams: ['vartype'],
    typingDisabledFields: {
        vartype: true,
        varval: true,
        dg_plus: true,
        d_dg_plus: true,
        active: true,
    },
});
export default reducer;

export {types, actions, selectors, middlewares};
