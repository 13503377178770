import React from 'react';
import {string, func} from 'prop-types';
import {connect} from 'react-redux';
import {doFetchDrgNameOptions, doFetchDrgNameCombinedOptions, selectors} from '../../store/drgNameOptions';
import ContextMenu from './ContextMenu';
import {actionsWrapper} from '../../store/resources/reducerUtils';
import store from '../../store';

class DrgNameMenu extends React.Component {
    render() {
        const {t, id, label, combined, national, onChange, onClose, loading, country, allowEmpty} = this.props;
        const options = loading ? [] : selectors.getIndexedCombinedOptions({combined});
        const drgCom = combined.find((row) => row.drg_com === id) || {};
        const drgNat = national.find((row) => row.drg_com === id && row.country === country && row.active) || {};
        const value = { label: '--', value: id };
        if (drgCom.drg_com) value.label = `${drgCom.drg_com} - ${drgCom.drg_text_com}`;

        return (
            <ContextMenu
              onChange={onChange}
              onClose={onClose}
              options={options}
              label={label}
              fetchContent={this.onFetchContent}
              allowEmpty={allowEmpty}
              value={value}>
                {country !== 'com' && (
                <div className="cell-context-menu-description-item">
                    <p>{t.country}: {drgNat.country || '--'}</p>
                    <p>{t.drgCom}: {drgNat.drg_com || '--'}</p>
                    <p>{t.drgNat}: {drgNat.drg_nat || '--'}</p>
                    <p>{t.description}: {drgNat.drg_text_nat || '--'}</p>
                    <p>{t.mdc}: {drgNat.mdc || '--'}</p>
                </div>
                )}
                <div className="cell-context-menu-description-item">
                    <p>{t.combined}:</p>
                    <p>{t.description}: {drgCom.drg_text_com || '--'}</p>
                    <p>{t.shortName}: {drgCom.drg_text_short_com || '--'}</p>
                    <p>{t.mdc}: {drgCom.mdc || '--'}</p>
                </div>
            </ContextMenu>
        );
    }

    onFetchContent = async () => {
        const {doFetchDrgNameOptions, doFetchDrgNameCombinedOptions} = this.props;
        await Promise.all([doFetchDrgNameOptions(), doFetchDrgNameCombinedOptions()]);
    };
}

DrgNameMenu.propTypes = {
    id: string.isRequired,
    onChange: func.isRequired,
    onClose: func.isRequired,
};

export default connect(
    ({locales: {t, country}, drgNameOptions: {national, combined, loading}}) => ({t, combined, national, loading, country}),
    {...actionsWrapper(store, {doFetchDrgNameOptions, doFetchDrgNameCombinedOptions})},
)(DrgNameMenu);
