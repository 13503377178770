import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import CommonGridScene from '../CommonGridScene';
import {removeStringFromKeys} from '../../common/utils';
import {actions, selectors} from '../../store/mbc/dgPlus';
import {actionsWrapper, selectorsWrapper} from '../../store/resources/reducerUtils';
import store from '../../store';

const domain = 'mbcDgPlus';
const MbcDgPlus = ({t, schema, ...rest}) => {
    const schemaProps = {
        mbc: {
            enableAddRow: true,
            enableRemoveRow: true,
            enableRowSelect: true,
            newRowEditables: ['plus_dg'],
            storableColumn: 'plus_dg',
        },
        public: {
            customTitle: t.titles.ndmsMbcDgPlus,
            readOnly: true,
        },
    };

    const viewProps = removeStringFromKeys(rest, domain);
    const {doStoreCodes} = viewProps;

    useEffect(() => {
        const codes = JSON.parse(localStorage.getItem('dgStore')) || [];
        if (Array.isArray(codes)) doStoreCodes(codes);
    }, [doStoreCodes]);

    return (
        <CommonGridScene
          domain={domain}
          identifier="plus_dg"
          columnTypes={{
            inactivated_at: 'InactivatedAt',
            errors: 'Errors',
            plus_dg: 'plus_dg',
          }}
          {...schemaProps[schema]}
          {...viewProps}/>
    );
};

export default connect(
    ({locales: {t}, meta: {schema}}) => ({t, schema, ...selectorsWrapper(store, selectors)}),
    {...actionsWrapper(store, actions)},
)(MbcDgPlus);
