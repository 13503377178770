import {memoizedMap} from '../../common/utils';

const CLEAR_ROW_FOCUS = 'PROC_PLUS::CLEAR_ROW_FOCUS';
const SET_PROC_PLUS_OPTIONS = 'PROC_PLUS::SET_PROC_PLUS_OPTIONS';
const FETCH_PROC_PLUS_OPTIONS = 'PROC_PLUS::FETCH_PROC_PLUS_OPTIONS';
const FETCH_PROC_PLUS_OPTIONS_ERROR = 'PROC_PLUS::FETCH_PROC_PLUS_OPTIONS_ERROR';

const initialState = {
    procPlusOptionsData: [],
    loading: false,
};
export default function procPlusOptionsReducer(state = initialState, {payload, type}) {
    switch (type) {
    case FETCH_PROC_PLUS_OPTIONS:
        return {...state, loading: true, procPlusOptionsData: []};
    case FETCH_PROC_PLUS_OPTIONS_ERROR:
        return {...state, loading: state.loading};
    case SET_PROC_PLUS_OPTIONS:
        return {...state, procPlusOptionsData: payload, loading: false};
    default: return state;
    }
}

function doFetchProcPlusOptions() {
    return async function fetchCommon(dispatch, getState, Api) {
        dispatch({type: FETCH_PROC_PLUS_OPTIONS});
        try {
            dispatch({type: CLEAR_ROW_FOCUS});
            const result = await Api.post('/mbc/proc_plus/fetch');
            dispatch({type: SET_PROC_PLUS_OPTIONS, payload: result.rows});
        } catch (e) {
            dispatch({type: FETCH_PROC_PLUS_OPTIONS_ERROR});
            throw e;
        }
    };
}

export const actions = {
    doFetchProcPlusOptions,
};

export const types = {
    CLEAR_ROW_FOCUS,
    SET_PROC_PLUS_OPTIONS,
    FETCH_PROC_PLUS_OPTIONS,
    FETCH_PROC_PLUS_OPTIONS_ERROR,
};

export const selectors = {
    getIndexedProcPlusOptions: memoizedMap(({ procPlusOptionsData, country }) => procPlusOptionsData
        .filter((opt) => (country && country !== 'com' ? opt.nat_ver && opt.nat_ver.includes(country) : opt))
        .map((opt) => ({label: `${opt.plus_proc} - ${opt.text}`, value: opt.plus_proc}))),
};
