import React from 'react';
import {string, bool, object, func} from 'prop-types';
import Button from '../../Button';
import RemoveRowsButton from '../../RemoveRowsButton';
import './styles.scss';

export default function TestCaseMenuItem({
    t, domain, addRowText, enableRunTestCases, onRunTestCases, runTestCasesText,
    clearRowAdditionText, onAddRow, onClearRowAddition, pendingAddition, copyTestResultsText,
    onCopyTestCasesResultsUsingCurrentFilter, testCaseListIsfiltered, selectedRows, doRemoveSelectedRows,
}) {
    return (
        <div className="menu-item test-case-menu-item">
            <Button disabled={!enableRunTestCases} text={runTestCasesText} onClick={onRunTestCases} />
            {!pendingAddition && <Button text={addRowText} onClick={onAddRow}/>}
            {pendingAddition && <Button text={clearRowAdditionText} onClick={onClearRowAddition}/>}
            <Button disabled={!enableRunTestCases} text={copyTestResultsText} onClick={onCopyTestCasesResultsUsingCurrentFilter} />
            <RemoveRowsButton t={t} domain={domain} testCaseListIsfiltered={testCaseListIsfiltered} selectedRows={selectedRows} doRemoveSelectedRows={doRemoveSelectedRows} />
        </div>
    );
}

TestCaseMenuItem.propTypes = {
    addRowText: string.isRequired,
    clearRowAdditionText: string.isRequired,
    onAddRow: func.isRequired,
    onClearRowAddition: func.isRequired,
    pendingAddition: bool,
    runTestCasesText: string.isRequired,
    onRunTestCases: func.isRequired,
    enableRunTestCases: bool,
    copyTestResultsText: string.isRequired,
    onCopyTestCasesResultsUsingCurrentFilter: func.isRequired,
    testCaseListIsfiltered: bool,
    selectedRows: object,
    doRemoveSelectedRows: func.isRequired,
};
