import React from 'react';
import './styles.scss';
import { func, object } from 'prop-types';

export default class RemoveDialog extends React.Component {
    state = {
        removeRowClicked: false,
    };

    render() {
        const { onRemoveRow, t } = this.props;
        return (
            <span>{this.state.removeRowClicked
            ? (
                <div className="menu-item-dialog">
                    <p>Are you sure?</p>
                    <button onClick={onRemoveRow} className="remove-row-button" type="button">Yes</button>
                    <button onClick={this.confirmationDialog} className="cancel-remove-row-button" type="button">Cancel</button>
                </div>
            ) : <div onClick={onRemoveRow ? this.confirmationDialog : undefined} className={onRemoveRow ? '' : 'menu-item-disabled'}>{t.remove}</div>}
            </span>
        );
    }

    confirmationDialog = () => this.setState(prevState => ({ removeRowClicked: !prevState.removeRowClicked }));
}

RemoveDialog.propTypes = {
    onRemoveRow: func,
    t: object,
};
