import { withExtraArgument } from 'redux-thunk';
import Api from '../common/Api';
import {toCamelCase} from '../common/utils';

const thunkWithExtraArgument = withExtraArgument(Api);

const historyMiddleware = (store) => (next) => (action) => {
    next(action);
    const [DOMAIN_NAME, intent] = action.type.split('::');
    if (intent === 'UPDATE_COLUMN' || intent === 'UNDO_CHANGE') {
        const domainName = toCamelCase(DOMAIN_NAME);
        const subState = store.getState()[domainName];
        if (!subState) {
            return console.error(`Action type ${action.type} should have a store state ${domainName} but non was found`);
        }
        localStorage.setItem(`norddrg_${domainName}_history`, JSON.stringify(subState.history));
    }
};

export default [
    thunkWithExtraArgument,
    historyMiddleware,
];
