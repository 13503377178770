import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider as ReduxProvider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import Scenes from './Scenes';
import store from './store';
import './styles.scss';

if (!localStorage.getItem('norddrg_cache')) localStorage.setItem('norddrg_cache', {});

const Root = () => (
    <ReduxProvider store={store}>
        <BrowserRouter>
            <Scenes/>
        </BrowserRouter>
    </ReduxProvider>
);

const container = document.getElementById('app');
const root = createRoot(container);
root.render(<Root />);
