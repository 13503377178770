import React from 'react';
import {connect} from 'react-redux';
import './styles.scss';
import {createResolveLatestIf} from '../../../common/utils';
import {doChangeCountry} from '../../../store/locales';
import {actionsWrapper} from '../../../store/resources/reducerUtils';
import store from '../../../store';

class CountrySelection extends React.Component {
    state = {open: false};

    unMounted = false;

    componentDidMount() {
        const resolveLatestIf = createResolveLatestIf();
        this.debounce = (delay) => resolveLatestIf({initialDelay: delay, cancelOn: () => this.unMounted});
    }

    render() {
        const {open} = this.state;
        const {t, country, countryOptions} = this.props;
        const selectedCountry = (countryOptions.find((it) => it.code === country) || {}).name;
        return (
            <div onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut} onClick={() => this.setState({open: !open})} className="country-selection">
                <p id="select-country-text">{t.selectCountry}</p>
                <p id="selected-country-text">{selectedCountry}</p>
                {open && (
                    <div id="country-options">
                        {countryOptions.map(({code, name}) => (
                            <div
                              key={code}
                              className={`country-option${country === code ? ' selected-country-option' : ''}`}
                              onClick={() => this.onSelectCountry(code)}>{name}
                            </div>
                        ))}
                    </div>
                    )}
            </div>
        );
    }

    onMouseOver = async () => {
        await this.debounce(0);
        this.setState(() => ({open: true}));
    };

    onMouseOut = async () => {
        await this.debounce(400);
        this.setState(() => ({open: false}));
    };

    onSelectCountry = (selection) => {
        this.props.doChangeCountry(selection);
        this.setState({ open: false });
    };

    componentWillUnmount() {
        this.unMounted = true;
    }
}
export default connect(
    ({locales: {t, country}, admin: {countryOptions}}) => ({t, country, countryOptions}),
    {...actionsWrapper(store, {doChangeCountry})},
)(CountrySelection);
