import React from 'react';
import {func} from 'prop-types';
import './styles.scss';

export default function Close({onClick}) {
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    return (
        <svg className="close-icon" width="10px" height="10px" viewBox="0 0 10 10" version="1.1" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
            <g transform="translate(-142.000000, -7.000000)" >
                <polygon
                  points="151.656 8.688 147.944 12.4 151.656 16.112 150.712 17.056 147 13.344 143.288 17.056 142.344 16.112 146.056 12.4 142.344 8.688 143.288 7.744 147 11.456 150.712 7.744"/>
            </g>
        </svg>
    );
}

Close.propTypes = {
    onClick: func.isRequired,
};
