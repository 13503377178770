import {memoizedMap} from '../../common/utils';

const CLEAR_ROW_FOCUS = 'ATC_PLUS::CLEAR_ROW_FOCUS';
const SET_ATC_PLUS_OPTIONS = 'ATC_PLUS::SET_ATC_PLUS_OPTIONS';
const FETCH_ATC_PLUS_OPTIONS = 'ATC_PLUS::FETCH_ATC_PLUS_OPTIONS';
const FETCH_ATC_PLUS_OPTIONS_ERROR = 'ATC_PLUS::FETCH_ATC_PLUS_OPTIONS_ERROR';

const initialState = {
    atcPlusOptionsData: [],
    loading: false,
};
export default function atcPlusOptionsReducer(state = initialState, {payload, type}) {
    switch (type) {
    case FETCH_ATC_PLUS_OPTIONS:
        return {...state, loading: true, atcPlusOptionsData: []};
    case FETCH_ATC_PLUS_OPTIONS_ERROR:
        return {...state, loading: state.loading};
    case SET_ATC_PLUS_OPTIONS:
        return {...state, atcPlusOptionsData: payload, loading: false};
    default: return state;
    }
}

function doFetchAtcPlusOptions() {
    return async function fetchCommon(dispatch, getState, Api) {
        dispatch({type: FETCH_ATC_PLUS_OPTIONS});
        try {
            dispatch({type: CLEAR_ROW_FOCUS});
            const result = await Api.post('/mbc/atc_plus/fetch');
            dispatch({type: SET_ATC_PLUS_OPTIONS, payload: result.rows});
        } catch (e) {
            dispatch({type: FETCH_ATC_PLUS_OPTIONS_ERROR});
            throw e;
        }
    };
}

export const actions = {
    doFetchAtcPlusOptions,
};

export const types = {
    CLEAR_ROW_FOCUS,
    SET_ATC_PLUS_OPTIONS,
    FETCH_ATC_PLUS_OPTIONS,
    FETCH_ATC_PLUS_OPTIONS_ERROR,
};

export const selectors = {
    getIndexedAtcPlusOptions: memoizedMap(({ atcPlusOptionsData, country }) => atcPlusOptionsData
        .filter((opt) => (country && country !== 'com' ? opt.nat_ver && opt.nat_ver.includes(country) : opt))
        .map((opt) => ({label: `${opt.plus_atc} - ${opt.text}`, value: opt.plus_atc}))),
};
