import React from 'react';
import {string, func} from 'prop-types';
import {connect} from 'react-redux';
import {doFetchMdcNameCombinedOptions, selectors} from '../../store/mdcNameOptions';
import ContextMenu from './ContextMenu';
import {actionsWrapper} from '../../store/resources/reducerUtils';
import store from '../../store';

const identifier = 'mdc';
const MdcNameMenu = ({t, id, combined, onChange, onClose, loading, allowEmpty, doFetchMdcNameCombinedOptions}) => {
    const options = loading ? [] : selectors.getIndexedMdcNameOptions({ combined });

    const item = combined.find((it) => it[identifier] === id);
    const value = {label: id ? (`${id} - ${item ? item.mdc_text_com : ''}`) : '--', value: id};
    return (
        <ContextMenu
          onChange={onChange}
          onClose={onClose}
          options={options}
          value={value}
          label={identifier}
          allowEmpty={allowEmpty}
          fetchContent={doFetchMdcNameCombinedOptions}>
            {item && (
            <div className="cell-context-menu-description-item" >
                <p>{t.code}: {item[identifier] || '--'}</p>
                <p>{t.description}: {item.mdc_text_com || '--'}</p>
            </div>
            )}
        </ContextMenu>
    );
};

MdcNameMenu.propTypes = {
    id: string,
    onChange: func.isRequired,
    onClose: func.isRequired,
};

export default connect(
    ({locales: {t}, mdcNameOptions: {combined, loading}}) => ({t, combined, loading}),
    {...actionsWrapper(store, {doFetchMdcNameCombinedOptions})},
)(MdcNameMenu);
