import React, {useState, useEffect, useCallback} from 'react';
import {string, func} from 'prop-types';
import {connect} from 'react-redux';
import { debounce } from 'lodash';
import RadioButton from '../../RadioButton';
import InputLine from '../../InputLine';
import './styles.scss';
import Close from '../../Close';

const AgeMenu = ({ t, value, onChange, onClose }) => {
    const [direction, setDirection] = useState('<');
    const [format, setFormat] = useState('days');
    const [age, setAge] = useState('');
    const lengthOfYear = 365.25;

    useEffect(() => {
        if (value) {
            const initialAge = parseInt(value.substring(1));
            setDirection(value[0]);
            setAge(initialAge);
        }
    }, [value]);

    useEffect(() => {
        if (value) {
            const initialAge = parseInt(value.substring(1));
            if (parseInt(initialAge) < 100) setFormat('years');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleOnChange = useCallback(
        debounce((updatedValue) => {
            onChange(updatedValue);
        }, 300),
        [onChange],
    );

    useEffect(() => {
        if (age !== '' && !Number.isNaN(age)) {
            handleOnChange({ value: direction + Math.trunc(age) });
        } else if (age === '') {
            handleOnChange({ value: '' });
        }
    }, [age, direction, handleOnChange]);

    const handleFormatChange = (newFormat) => {
        setFormat(newFormat);
    };

    const handleAgeChange = (newAge) => {
        if (!newAge || newAge === '') {
            setAge('');
        } else {
            const parsedAge = parseInt(newAge);
            if (!Number.isNaN(parsedAge)) {
                setAge(format === 'years' ? parsedAge * lengthOfYear : parsedAge);
            }
        }
    };

    let input = '';

    if (age === '') {
        input = '';
    } else if (format === 'years') {
        input = `${Math.trunc(age / lengthOfYear)}`;
    } else {
        input = `${Math.trunc(age)}`;
    }

    return (
        <div className="age-menu">
            <Close onClick={onClose} />
            <div className="age-format">
                <RadioButton checked={direction === '<'} label={t.less} onChange={() => setDirection('<')} />
                <RadioButton checked={direction === '>'} label={t.more} onChange={() => setDirection('>')} />
            </div>
            <div className="age-input">
                <label>{t.than}</label>
                <InputLine onChange={handleAgeChange} value={input} />
            </div>
            <div className="age-direction">
                <RadioButton checked={format === 'days'} label={t.days} onChange={() => handleFormatChange('days')}/>
                <RadioButton checked={format === 'years'} label={t.years} onChange={() => handleFormatChange('years')}/>
            </div>
        </div>
    );
};

AgeMenu.propTypes = {
    value: string,
    onChange: func.isRequired,
    onClose: func.isRequired,
};

export default connect(({ locales: { t } }) => ({ t }))(AgeMenu);
