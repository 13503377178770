import React from 'react';
import {string, func} from 'prop-types';
import {connect} from 'react-redux';
import Spinner from '../Spinner';
import './ContextMenu/styles.scss';

const DrgNameExtendedListMenu = ({t, drgNat, drgCom, onClose, loading}) => {
    if (!drgNat && !drgCom) {
        return <div />;
    }
    return (
        <div className="cell-context-menu" onClick={onClose}>
            <Spinner hidden={!loading} className="context-menu-spinner" />
            <div className="cell-context-description-menu">
                <div className="cell-context-menu-description-item">
                    <p>{t.drgCom}: {drgNat?.drg_com || '--'}</p>
                    <p>{t.drgNat}: {drgNat?.drg_nat || '--'}</p>
                    <p>{t.description}: {drgNat?.drg_text_nat || '--'}</p>
                    <p>{t.mdc}: {drgNat?.mdc || '--'}</p>
                </div>
                <div className="cell-context-menu-description-item">
                    <p>{t.combined}:</p>
                    <p>{t.description}: {drgCom?.drg_text_com || '--'}</p>
                    <p>{t.shortName}: {drgCom?.drg_text_short_com || '--'}</p>
                    <p>{t.mdc}: {drgCom?.mdc || '--'}</p>
                </div>
            </div>
        </div>
    );
};

DrgNameExtendedListMenu.propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    id: string.isRequired, // used in mapStateToProps
    onClose: func.isRequired,
};

export default connect(({locales: {t, country}, drgNameOptions: {national, combined, loading}}, {id}) => {
    const drgNat = national.find((row) => row.drg_nat === id && row.country === country) || undefined;
    const drgCom = combined.find((row) => row.drg_com === drgNat?.drg_com) || undefined;
    return ({t, drgNat, drgCom, loading});
})(DrgNameExtendedListMenu);
