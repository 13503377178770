import React from 'react';
import {connect} from 'react-redux';
import CommonGridScene from '../CommonGridScene';
import {removeStringFromKeys} from '../../common/utils';
import {actions, selectors} from '../../store/mbc/dgNat';
import {actionsWrapper, selectorsWrapper} from '../../store/resources/reducerUtils';
import store from '../../store';

const domain = 'mbcDgNat';
const MbcDgNat = ({t, schema, ...rest}) => {
    const schemaProps = {
        mbc: {
            enableAddRow: true,
            enableRemoveRow: true,
            enableRowSelect: true,
            newRowEditables: ['dg1', 'dg2', 'country'],
            linkableColumns: ['plus1', 'plus2'],
        },
        public: {
            customTitle: t.titles.ndmsMbcDgNat,
            readOnly: true,
        },
    };
    const viewProps = removeStringFromKeys(rest, domain);

    return (
        <CommonGridScene
          domain={domain}
          identifier="rid"
          columnTypes={{
            inactivated_at: 'InactivatedAt',
            errors: 'Errors',
            plus1: 'plus1',
            plus2: 'plus2',
          }}
          {...schemaProps[schema]}
          {...viewProps}/>
    );
};

export default connect(
    ({locales: {t}, meta: {schema}}) => ({t, schema, ...selectorsWrapper(store, selectors)}),
    {...actionsWrapper(store, actions)},
)(MbcDgNat);
