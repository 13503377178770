import React from 'react';
import {func, string, bool} from 'prop-types';
import './styles.scss';

export default function Checkbox({disabled, checked, onChange, label, className, style}) {
    return (
        <span onClick={disabled ? undefined : onChange} className={`styled-checkbox${className ? ` ${className}` : ''}`} style={style}>
            <input
              type="checkbox"
              disabled={disabled}
              checked={disabled ? false : checked}
              onChange={() => {}}/>
            <label htmlFor="styled-checkbox-1">{label}</label>
        </span>
    );
}

Checkbox.propTypes = {
    onChange: func,
    label: string,
    disabled: bool,
    checked: bool,
};