import React from 'react';
import {connect} from 'react-redux';
import Button from '../../../components/Button';
import {actions as adminActions} from '../../../store/admin';
import {doFullBackup} from '../../../store/dumps';
import {actionsWrapper} from '../../../store/resources/reducerUtils';
import store from '../../../store';

const allowedCountries = ['com'];

const ImportCodes = (props) => {
    const importCodes = async (type) => {
        await props.doFullBackup('public');
        await props.doImportCodes(type);
    };
    const {t, country, loading} = props;
    const importAllowed = allowedCountries.includes(country);

    return (
        <div className="section-container">
            <div className="config-form">
                <div className="config-item">
                    <h2>{t.importCodes}</h2>
                    <p>{t.importCodesText}{!importAllowed && ` ${t.importCodesDisabledText}`}</p>
                    <Button onClick={() => importCodes('dg')} text={`${t.importDiagnoses}`} disabled={loading || !importAllowed}/>
                    <Button onClick={() => importCodes('proc')} text={`${t.importProcedures}`} disabled={loading || !importAllowed}/>
                </div>
            </div>
        </div>
    );
};

export default connect(
    ({locales: {t, country}, admin: {loading}}) => ({t, country, loading}),
    {...actionsWrapper(store, {...adminActions, doFullBackup})},
)(ImportCodes);
