import React from 'react';
import {string, func} from 'prop-types';
import './styles.scss';

const ConfigInput = ({i, id = 'config-input', label, placeholder = '', onChange, value = ''}) => (
    <div className="config-input">
        <label htmlFor={`${id}-${i}`}>{label}</label>
        <input id={`${id}-${i}`} placeholder={placeholder} onChange={(e) => onChange(e.target.value)} value={value}/>
    </div>
);

ConfigInput.propTypes = {
    id: string,
    label: string,
    onChange: func.isRequired,
    value: string,
};

export default ConfigInput;
