import React from 'react';
import {connect} from 'react-redux';
import Button from '../Button';

const StoreCode = ({storage, storableColumn, columns, doStoreCodes, doFetchContent, reduxDomain: {stored = []}}) => {
    const getCodes = () => {
        const codes = JSON.parse(localStorage.getItem(storage)) || [];
        if (Array.isArray(codes)) doStoreCodes(codes);
    };

    window.onstorage = (e) => {
        if (e.key === storage) getCodes();
        if (e.key === 'link') {
            doFetchContent(0);
            localStorage.removeItem('link');
        }
    };

    const code = columns[storableColumn];
    const isStored = stored.find((c) => c === code);

    const handleClick = () => {
        if (!isStored) {
            const newCodes = [...stored, code];
            doStoreCodes(newCodes);
            localStorage.setItem(storage, JSON.stringify(newCodes));
        } else {
            const newCodes = stored.filter((c) => c !== code);
            doStoreCodes(newCodes);
            localStorage.setItem(storage, JSON.stringify(newCodes));
        }
    };

    return (
        <div className="store-code-container">
            <Button className="store-and-link-button" text={isStored ? '-' : '+'} onClick={() => handleClick()}/>
        </div>
    );
};

export default connect(({...states}, {domain}) => ({reduxDomain: states[domain]}))(StoreCode);