import createReduxDomain from './resources/domainFactory';
import { NON_EDITABLE_FIELDS } from '../common/constants';

const {reducer, types, selectors, actions, middlewares} = createReduxDomain({
    URL: '/drg/names',
    DOMAIN: 'DRG_NAME',
    IDENTIFIER: 'rid',
    excludeActions: [
        'doLink$Code',
        'doLinkAll$Codes',
        'doSelectLinkable$Code',
        'doSet$VartypeFilter',
        'doSet$ExclusiveToORZeroFilter',
        'doSet$ExclusiveToDGCATZeroFilter',
        'doStore$Codes',
    ],
    excludeHeaders: ['rid'],
    nonEditableFields: NON_EDITABLE_FIELDS.drgName,
    typingDisabledFields: {
        drg_com: true,
        mdc: true,
        active: true,
    },
});

export default reducer;

export {types, actions, selectors, middlewares};
