import React from 'react';
import {func, string} from 'prop-types';
import {connect} from 'react-redux';
import {doFetchDiagnosisRelationVarvals} from '../../store/diagnosisRelations';
import {doFetchProcedureRelationVarvals} from '../../store/procedureRelations';
import ContextMenu from './ContextMenu';
import {actionsWrapper} from '../../store/resources/reducerUtils';
import store from '../../store';

const identifier = 'varval';
class VarvalMenu extends React.Component {
    state = {loading: true};

    componentDidMount() {
        this.fetchVarvals();
    }

    render() {
        const {value, varvals, onChange, onClose, allowEmpty} = this.props;
        const item = varvals.find((val) => val[identifier] === value);

        return (
            <ContextMenu
              onBlur={onClose}
              label={identifier}
              loading={this.state.loading}
              value={{value, label: item ? `${item[identifier]} - ${item.varval_text_com}` : value}}
              onChange={onChange}
              onClose={onClose}
              fetchContent={this.fetchVarvals}
              allowEmpty={allowEmpty}
              options={varvals.map((v) => ({value: v[identifier], label: `${v[identifier]} - ${v.varval_text_com}`}))}>
                {item && (
                    <div className="cell-context-menu-description-item" >
                        <p>{item[identifier]}</p>
                        <p>{item.varval_text_com}</p>
                    </div>
                )}
            </ContextMenu>
        );
    }

    componentWillUnmount() {
        this.unMounted = true;
    }

    fetchVarvals = async () => {
        this.setState({loading: true});
        const { vartype, domain, doFetchDiagnosisRelationVarvals, doFetchProcedureRelationVarvals} = this.props;
        await (
            domain === 'diagnosisFeature'
                ? doFetchDiagnosisRelationVarvals(vartype)
                : doFetchProcedureRelationVarvals(vartype)
        );
        if (!this.unMounted) {
            this.setState({loading: false});
        }
    };
}

VarvalMenu.propTypes = {
    value: string,
    onChange: func.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    useCase: string.isRequired, // used in mapStateToProps
    domain: string.isRequired,
};

export default connect(
    (state, {domain, useCase}) => {
        const domainState = state[domain];
        const relations = {
            diagnosisFeature: state.diagnosisRelations,
            procedureFeature: state.procedureRelations,
        }[domain];
        const { varvals } = relations;
        const vartype = {
            add: domainState.pendingRow?.vartype,
            edit: domainState.focusedItemMenu?.row?.vartype,
        }[useCase];
        return {...state, domainState, varvals, vartype};
    },
    {...actionsWrapper(store, {doFetchDiagnosisRelationVarvals, doFetchProcedureRelationVarvals})},
)(VarvalMenu);
