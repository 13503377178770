import {ENDPOINT} from './constants';
import {NETWORK_ERROR, LOGOUT} from '../store/meta';

console.log({ENDPOINT});

function resolveNever() {
    return new Promise(() => {});
}

export default class Api {
    // eslint-disable-next-line no-undef
    static dispatch = null;

    static getState = null;

    static async getHeaders() {
        const headers = {
            Accept: 'application/json',
            Pragma: 'no-cache',
            Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
        };
        const {token, expires} = Api.getState().meta.auth;
        if (token && expires > Date.now()) {
            headers.Authorization = token;
        }
        return headers;
    }

    static get(url, {cancelled, headers} = {}) {
        return Api.handleRequest(url, {method: 'GET', cancelled, headers});
    }

    static getFile(url, {cancelled, headers} = {}) {
        return Api.handleRequest(url, {method: 'GET', cancelled, headers, json: false});
    }

    static post(url, {payload = {}, cancelled, headers, json} = {}) {
        return Api.handleRequest(url, {method: 'POST', body: JSON.stringify(payload), cancelled, headers, json});
    }

    static postRaw(url, {data, cancelled, headers} = {}) {
        return Api.handleRequest(url, {method: 'POST', body: data, cancelled, headers});
    }

    static async put(url, {payload = {}, cancelled, headers} = {}) {
        return Api.handleRequest(url, {method: 'PUT', body: JSON.stringify(payload), cancelled, headers});
    }

    static del(url, {cancelled, headers} = {}) {
        return Api.handleRequest(url, {method: 'DELETE', cancelled, headers});
    }

    static async handleRequest(url, {method, body, cancelled = () => false, headers = {}, json = true}) {
        headers = {...await Api.getHeaders(), ...headers};
        if (cancelled()) {
            return resolveNever();
        }

        const response = await fetch(`${ENDPOINT}${url}`, {method, headers, body});
        if (cancelled()) {
            return resolveNever();
        }
        if (response.status >= 200 && response.status < 300) {
            if (response.status === 204) {
                return;
            }
            if (json) {
                const result = await response.json();
                if (cancelled()) {
                    return resolveNever();
                }
                return result;
            }
            return response;
        }
        // Error
        if (!response.status) {
            Api.dispatch({type: NETWORK_ERROR});
        } else if (response.status === 401) {
            Api.dispatch({type: LOGOUT});
        }

        throw new Error(await response.text());
    }
}
