import React, {forwardRef, useCallback} from 'react';
import {any, number, array, bool, object, func, string} from 'prop-types';
import TableCell from './TableCell';
import TableCellFillRest from './TableCellFillRest';
import Checkbox from '../../Checkbox';
import LinkCode from '../../LinkCode';
import StoreCode from '../../StoreCode';
import { formatTimestamp } from '../../../common/utils';
import './styles.scss';

const TableRow = forwardRef(({
    domain,
    country,
    doFetchContent,
    id,
    rowKey,
    dimensions,
    nonEditableFields,
    headers,
    rowIndex,
    columns,
    cursorColumn,
    onColumnClick,
    onColumnDoubleClick,
    selected,
    onUpdateColumn,
    selectable,
    storableColumn,
    linkableColumns,
    doStoreCodes,
    doLinkCode,
    selectedCode,
    highlighted,
    toggleRowSelection,
    onColumnRightClick,
    sortOrder,
    height = 30,
    columnMenu = {},
}, ref) => {
    const {errorsList = [], notificationsList = [], ignoreCodesList = []} = columns;

    const getClassName = useCallback(() => {
        let className = `grid-row ${columnMenu.Component ? ' grid-row-important' : ''}`;
        if (ignoreCodesList.length) {
            if (errorsList.filter(e => !ignoreCodesList.includes(e.split(':')[0])).length) {
                className += ' grid-row-error';
            }
        } else if (errorsList.length) {
            className += ' grid-row-error';
        } else if (notificationsList.length) {
            className += ' grid-row-notification';
        }

        if (highlighted) {
            className += ' grid-row-highlighted';
        }
        return className;
    }, [highlighted, columnMenu, errorsList, notificationsList, ignoreCodesList]);

    const decideColumnValue = (column) => {
        const value = columns[column];
        if (value === null || value === undefined) {
            return '';
        }
        if (['created_at', 'updated_at', 'inactivated_at'].includes(column)) {
            return formatTimestamp(value);
        }
        return value;
    };

    const onToggleRowSelection = () => {
        toggleRowSelection(rowIndex);
    };

    const createOnMouseDown = (columnIndex) => (e) => {
        const {button, nativeEvent} = e;
        if (button === 2) onColumnRightClick({rowIndex, columnIndex, left: nativeEvent.offsetX});
    };

    const defineColumnClassName = (column) => {
        if (sortOrder[column]) {
            return sortOrder[column] === 'ASC' ? 'column-ascending' : 'column-descending';
        }
        return '';
    };

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    return (
        <div
          className={getClassName()}
          style={{height: `${height}px`}}
          onBlur={stopPropagation}
          ref={ref}>
            {storableColumn
            && (
            <StoreCode
              domain={domain}
              storage={`${domain === 'mbcDgPlus' ? 'dg' : 'proc'}Store`}
              storableColumn={storableColumn}
              columns={columns}
              doStoreCodes={doStoreCodes}
              doFetchContent={doFetchContent}/>
            )}
            <div className="table-row-meta" style={{height: `${height - 1}px`}}>
                <div className="cell-row-number" >{rowIndex + 1}</div>
                {selectable && <Checkbox checked={selected} className="row-column-checkbox" onChange={onToggleRowSelection}/>}
            </div>
            {headers.map((column, columnIndex) => (

                <React.Fragment key={`column-${column}`}>
                    <TableCell
                      key={`column-${column}`}
                      readOnly={columns._pending || nonEditableFields.indexOf(column) !== -1}
                      width={dimensions[column] || dimensions.defaultColumnWidth}
                      height={height - 1}
                      className={defineColumnClassName(column, columnIndex)}
                      focused={columnIndex === cursorColumn}
                      onMouseDown={createOnMouseDown(columnIndex)}
                      onDoubleClick={onColumnDoubleClick}
                      onClick={({button}) => button === 0 && onColumnClick({rowIndex, columnIndex})}
                      onChange={(e) => onUpdateColumn({[rowKey]: id, column, value: e.target.value})}
                      value={decideColumnValue(column)}>
                        {columnMenu.column === column && columnMenu.Component}
                    </TableCell>
                    {(linkableColumns?.includes(column) && selectedCode && country !== 'com')
                  && (
                  <LinkCode
                    rowKey={rowKey}
                    id={id}
                    column={column}
                    value={decideColumnValue(column)}
                    selectedCode={selectedCode}
                    doLinkCode={doLinkCode}/>
                  )}
                </React.Fragment>
          ))}
            <TableCellFillRest/>
        </div>
    );
});

export default TableRow;

TableRow.propTypes = {
    domain: string,
    country: string,
    doFetchContent: func,
    id: any,
    rowKey: string.isRequired,
    rowIndex: number.isRequired,
    cursorColumn: number,
    dimensions: object.isRequired,
    columns: object.isRequired,
    onColumnClick: func.isRequired,
    onColumnDoubleClick: func.isRequired,
    sortOrder: object.isRequired,
    height: number,
    onColumnRightClick: func,
    selected: bool,
    toggleRowSelection: func,
    highlighted: bool,
    headers: array,
    onUpdateColumn: func,
    columnMenu: object,
    nonEditableFields: array.isRequired,
    selectable: bool,
    storableColumn: string,
    linkableColumns: array,
    doStoreCodes: func,
    doLinkCode: func,
    selectedCode: string,
};
