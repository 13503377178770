import React, {useState, useEffect} from 'react';
import {func, object} from 'prop-types';
import {connect} from 'react-redux';
import RadioButton from '../../RadioButton';
import Close from '../../Close';
import './styles.scss';

const InactivatedAtMenu = ({t, value: originalValue, onClose, onChange}) => {
    const [checked, setChecked] = useState({label: '', value: originalValue});
    const thisYear = new Date().getFullYear();
    const options = [
        {label: thisYear, value: new Date(Date.UTC(thisYear, 11, 31))},
        {label: thisYear - 1, value: new Date(Date.UTC(thisYear - 1, 11, 31))},
        {label: t.none, value: null},
    ];

    useEffect(() => {
        if (checked.value === originalValue) {
            return;
        }
        onChange(checked);
        onClose();
    }, [checked, onChange, onClose, originalValue]);

    return (
        <div className="inactivated-at-menu">
            <Close onClick={onClose}/>
            <p>{t.selectInactivationYear}:</p>
            {options.map((o) => <RadioButton key={o.label} checked={checked.label === o.label} label={o.label} onChange={() => setChecked(o)}/>)}
        </div>
    );
};

InactivatedAtMenu.propTypes = {
    t: object.isRequired,
    onClose: func.isRequired,
    onChange: func.isRequired,
};

export default connect(({locales: {t}}) => ({t}))(InactivatedAtMenu);
