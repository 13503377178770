/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {connect} from 'react-redux';
import {actions, selectors} from '../store/logic';
import {actionsWrapper, selectorsWrapper} from '../store/resources/reducerUtils';
import store from '../store';
import DataTable from '../components/DataTable';
import TopMenu from '../components/TopMenu';
import ContainerTitle from '../components/ContainerTitle';
import {range, lessThanOneDayAgo} from '../common/utils';
import {
    DiagnosisNamesMenu,
    LogicRightClickMenu,
    AgeMenu,
    DrgNameMenu,
    BooleanMenu,
    DischargeModeMenu,
    DiagnosisGroupingPropertiesMenu,
    MdcNameMenu,
    ProcedureNamesMenu,
    PrincipalDiagnosisPropertiesMenu,
    ErrorsAndNotificationsListMenu,
} from '../components/Menus';

const COLUMN_TYPES = {
    agelim: 'Age',
    active: 'Boolean',
    disch: 'DischargeMode',
    drg_com: 'DrgName',
    mdc: 'MdcName',
    pdgprop: 'PdgName',
    secpro: 'ProcedureNameWithNegatives',
    procpro: 'ProcedureName',
    dgcat: 'DiagnosisName',
    errors: 'Errors',
    notifications: 'Notifications',
    ...range(1, 5).reduce((acc, n) => ({...acc, [`dgprop${n}`]: 'DiagnosisGroupingProperties'}), {}),
};

const ALLOW_EMPTY_OPTION_COLUMNS = {
    mdc: true,
    procpro: true,
    pdgprop: true,
    dgcat: true,
    secpro: true,
    ...range(1, 5).reduce((acc, n) => ({...acc, [`dgprop${n}`]: true}), {}),
};

class Logic extends React.Component {
    render() {
        const {
            t,
            country,
            doSetLogicActiveFilter,
            doSetLogicErrorsFilter,
            doFetchLogicContent,
            doSetLogicFilterText,
            doUpdateLogicFilterFields,
            doUpdateLogicOrder,
            doToggleLogicFieldOrder,
            clearLogicRowFocus,
            toggleLogicRowSelection,
            doUpdateLogicColumn,
            doSetLogicSearchFields,
            doSetLogicSearchText,
            moveLogicSearchResultPointer,
            doUpdateLogicDimensions,
            doSetFocusedLogicItem,
            logicGridArrowDown,
            logicGridArrowUp,
            logicGridArrowRight,
            logicGridArrowLeft,
            setLogicGridCursorLocation,
            clearLogicRowSelections,
            selectAllLogicRows,
            logicGridTab,
            logic,
            getSelectedLogicRows,
        } = this.props;
        const {
            loadingContent,
            rows,
            selectedRows,
            cursor,
            dimensions,
            nonEditableFields,
            nonSortableFields,
            headers,
            order,
            filter,
            rowHighlight,
            activeFilter,
            errorsFilter,
            search,
            searchResults,
        } = logic;
        const searchFilterOptions = headers.filter((it) => it !== 'created_at' && it !== 'updated_at');
        const sortOrderOptions = headers.filter((it) => !nonSortableFields[it]);
        return (
            <div className="scene-main-content">
                <ContainerTitle text={t.drgLogicRules} loading={loadingContent}/>
                <TopMenu
                  t={t}
                  selectedRows={selectedRows}
                  activeFilter={activeFilter}
                  onActiveFilterChanged={doSetLogicActiveFilter}
                  errorsFilter={errorsFilter}
                  onErrorsFilterChanged={doSetLogicErrorsFilter}
                  searchText={search.text}
                  searchCursorLocation={searchResults && searchResults.pointer}
                  searchResultCount={searchResults && searchResults.rowNumbers.length}
                  onSearchTextChanged={doSetLogicSearchText}
                  onSearchFieldsChanged={(searchFields) => doSetLogicSearchFields(searchFields.map((obj) => obj.value))}
                  moveSearchResultPointer={moveLogicSearchResultPointer}
                  searchOptions={searchFilterOptions}
                  sortOrder={order}
                  sortOrderOptions={sortOrderOptions}
                  onOrderChanged={(sortOrders) => doUpdateLogicOrder(sortOrders.map((obj) => obj.value))}
                  filterText={filter.text}
                  filterOptions={searchFilterOptions}
                  rowCount={rows.length}
                  onFilterTextChanged={doSetLogicFilterText}
                  onFilterFieldsChanged={(filters) => doUpdateLogicFilterFields(filters.map((obj) => obj.value))}/>
                <DataTable
                  enableRowSelect={country === 'com'}
                  rowKey="uuid"
                  onSelectAllRows={selectAllLogicRows}
                  onUnSelectAllRows={clearLogicRowSelections}
                  moveTo={searchResults && searchResults.rowNumbers[searchResults.pointer]}
                  highlightedRow={rowHighlight}
                  toggleRowSelection={toggleLogicRowSelection}
                  nonEditableFields={nonEditableFields}
                  setCursorLocation={setLogicGridCursorLocation}
                  selectedRows={getSelectedLogicRows(rows, selectedRows)}
                  headers={headers}
                  cursor={cursor}
                  onArrowRight={logicGridArrowRight}
                  onArrowDown={logicGridArrowDown}
                  onArrowLeft={logicGridArrowLeft}
                  onArrowUp={logicGridArrowUp}
                  onTab={logicGridTab}
                  columnMenu={this.createMenuEntry()}
                  onColumnClick={doSetFocusedLogicItem}
                  onUpdateColumn={doUpdateLogicColumn}
                  dimensions={dimensions}
                  onColumnRightClick={this.onColumnRightClick}
                  sortOrder={order.reduce((acc, [column, direction]) => ({...acc, [column]: direction}), {})}
                  onSort={doToggleLogicFieldOrder}
                  rows={rows}
                  onResize={doUpdateLogicDimensions}
                  clearRowFocus={clearLogicRowFocus}
                  onRowScopeChange={doFetchLogicContent}>
                    {this.renderRightClickMenu()}
                </DataTable>
            </div>
        );
    }

    createMenuEntry() {
        const {doUpdateFocusedLogicItem, clearLogicRowFocus} = this.props;
        const {focusedItemMenu} = this.props.logic;
        if (focusedItemMenu) {
            const {column, row} = focusedItemMenu;
            const allowEmpty = ALLOW_EMPTY_OPTION_COLUMNS[column];
            const viewProps = {onChange: doUpdateFocusedLogicItem, onClose: clearLogicRowFocus, className: 'inline-cell-context-menu'};
            switch (COLUMN_TYPES[column]) {
            case 'Age': return {
                ...focusedItemMenu,
                Component: <AgeMenu value={row[column]} {...viewProps}/>,
            };
            case 'DrgName': return {
                ...focusedItemMenu,
                Component: <DrgNameMenu id={row[column]} label={column} value={row[column]} allowEmpty={allowEmpty} {...viewProps} />,
            };
            case 'Boolean': return {
                ...focusedItemMenu,
                Component: <BooleanMenu value={row[column]} {...viewProps}/>,
            };
            case 'DischargeMode': return {
                ...focusedItemMenu,
                Component: <DischargeModeMenu value={row[column]} includeOptionN {...viewProps}/>,
            };
            case 'MdcName': return {
                ...focusedItemMenu,
                Component: <MdcNameMenu id={row[column]} allowEmpty={allowEmpty} {...viewProps}/>,
            };
            case 'PdgName': return {
                ...focusedItemMenu,
                Component: <PrincipalDiagnosisPropertiesMenu id={row[column]} allowEmpty={allowEmpty} {...viewProps}/>,
            };
            case 'ProcedureNameWithNegatives': return {
                ...focusedItemMenu,
                Component: <ProcedureNamesMenu id={row[column]} allowEmpty={allowEmpty} withNegatives {...viewProps}/>,
            };
            case 'ProcedureName': return {
                ...focusedItemMenu,
                Component: <ProcedureNamesMenu id={row[column]} allowEmpty={allowEmpty} {...viewProps}/>,
            };
            case 'DiagnosisName': return {
                ...focusedItemMenu,
                Component: <DiagnosisNamesMenu id={row[column]} allowEmpty={allowEmpty} {...viewProps}/>,
            };
            case 'DiagnosisGroupingProperties': return {
                ...focusedItemMenu,
                Component: <DiagnosisGroupingPropertiesMenu id={row[column]} allowEmpty={allowEmpty} withNegatives {...viewProps} />,
            };
            case 'Errors':
                if (row.errorsList.length > 1) {
                    return {
                        ...focusedItemMenu,
                        Component: <ErrorsAndNotificationsListMenu list={row.errorsList} {...viewProps} />,
                    };
                }
                break;
            case 'Notifications':
                if (row.notificationsList.length > 1) {
                    return {
                        ...focusedItemMenu,
                        Component: <ErrorsAndNotificationsListMenu list={row.notificationsList} {...viewProps} />,
                    };
                }
                break;
            default:
                break;
            }
        }
    }

    renderRightClickMenu() { // absolutely positioned component
        const {logic: {rightClickSelection}} = this.props;
        if (rightClickSelection) {
            const {
                t,
                country,
                doAddLogicRow,
                doMoveLogicRows,
                clearLogicRowFocus,
                doRemoveLogicRow,
                doUndoLogicRowChange,
                doPasteCopiedLogicRowBelow,
                doPasteCopiedLogicRowAbove,
                copySelectedLogicRow,
                logic: {
                    selectedRows,
                    rowCopy,
                    errorsFilter,
                    order,
                    filter,
                    history,
                },
            } = this.props;
            const hasSelectedRows = !!Object.keys(selectedRows).length;
            const {top, left, row} = rightClickSelection;
            const {uuid} = row;
            const hasDefaultContent = !filter.text && (!order.length || (order[0][0] === 'ord' && order[0][1] === 'ASC'));
            const rightClickSelectionIsSelected = selectedRows[uuid] || selectedRows['*'];
            const enableAddition = hasDefaultContent && errorsFilter === undefined;
            const enableMove = country === 'com' && enableAddition && hasSelectedRows && !rightClickSelectionIsSelected;
            const enableRemoveRow = lessThanOneDayAgo(row.created_at);
            const hasHistory = history[uuid] && history[uuid].length;
            return (
                <LogicRightClickMenu
                  t={t}
                  styles={{top: `${top}px`, left: `${left}px`}}
                  onClose={clearLogicRowFocus}
                  onAddRowAbove={enableAddition ? (() => doAddLogicRow('above')) : undefined}
                  onAddRowBelow={enableAddition ? (() => doAddLogicRow('below')) : undefined}
                  onMoveRowsAbove={enableMove ? (() => doMoveLogicRows('above')) : undefined}
                  onMoveRowsBelow={enableMove ? (() => doMoveLogicRows('below')) : undefined}
                  onRemoveRow={enableRemoveRow ? (() => doRemoveLogicRow()) : undefined}
                  onUndoLastChange={hasHistory ? (() => doUndoLogicRowChange({uuid})) : undefined}
                  onCopyRow={copySelectedLogicRow}
                  onPasteRowAbove={doPasteCopiedLogicRowAbove}
                  onPasteRowBelow={doPasteCopiedLogicRowBelow}
                  enablePaste={!!rowCopy}/>
            );
        }
    }

    onColumnRightClick = ({rowIndex, columnIndex, left}) => {
        this.props.rightClickSelectLogicRow({rowIndex, columnIndex, leftOffset: left});
    };
}

export default connect(
    ({logic, locales: {t, country}}) => ({logic, t, country, ...selectorsWrapper(store, selectors)}),
    {...actionsWrapper(store, actions)},
)(Logic);
