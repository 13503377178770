/* eslint-disable no-unused-expressions */

import React from 'react';
import {connect} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {NavigationDropDown, NavigationLink} from './NavigationItem';
import CountrySelection from './CountrySelection';
import Logout from './Logout';
import './styles.scss';
import ErrorNotifications from './ErrorNotifications';
import Notifications from './Notifications';
import { VERSION } from '../../common/constants';
import MbcNdmsToggle from './MbcNdmsToggle';

const ndmsNavigationItems = (locales) => (
    <div className="navigation-locations">
        <div className="header-title">
            <h1>{locales.NDMS}</h1>
            <p>{VERSION}</p>
        </div>
        <NavigationLink to="/logic" text={locales.logic}/>
        <NavigationLink to="/test_case" text={locales.testCase}/>
        <NavigationDropDown
          base="/names"
          text={locales.names}
          childRoutes={[
                          {to: '/grouping_property', title: locales.groupingPropertyName},
                          {to: '/procedure_property', title: locales.procedurePropertyName},
                          {to: '/diagnosis_category', title: locales.diagnosisCategoryName},
                          {to: '/principal_diagnosis_property', title: locales.principalDiagnosisProperty},
                          {to: '/complication_category', title: locales.complicationCategoryName},
                          {to: '/drg', title: locales.drgName},
                          {to: '/drg_combined', title: locales.drgNameCombined},
                          {to: '/mdc', title: locales.mdcName},
                          {to: '/mdc_combined', title: locales.mdcNameCombined},
                      ]}/>
        <NavigationDropDown
          base="/codes"
          text={locales.codes}
          childRoutes={[
                        {to: '/dg_nat', title: locales.dgNat},
                        {to: '/dg_plus', title: locales.dgPlus},
                        {to: '/proc_nat', title: locales.procNat},
                        {to: '/proc_plus', title: locales.procPlus},
                    ]}/>
        <NavigationDropDown
          base="/features"
          text={locales.features}
          childRoutes={[
                        {to: '/diagnosis', title: locales.diagnosis},
                        {to: '/procedure', title: locales.procedure},
                    ]}/>
        <NavigationDropDown
          base="/codes"
          text={locales.mbc.headerTitle}
          childRoutes={[
                            {to: '/mbc_dg_nat', title: locales.mbc.dgNat},
                            {to: '/mbc_dg_plus', title: locales.mbc.dgPlus},
                            {to: '/mbc_proc_nat', title: locales.mbc.procNat},
                            {to: '/mbc_proc_plus', title: locales.mbc.procPlus},
                            {to: '/mbc_atc_nat', title: locales.mbc.atcNat},
                            {to: '/mbc_atc_plus', title: locales.mbc.atcPlus},
                        ]}/>
        <NavigationDropDown
          base="/config"
          small
          text={locales.config}
          childRoutes={[
                          {to: '/dumps', title: locales.dumps},
                          {to: '/admin', title: locales.admin},
                        ]}/>
    </div>
);

const mbcNavigationItems = (locales) => (
    <div className="navigation-locations">
        <div className="header-title">
            <h1 className="mbc">{locales.mbc.headerTitle}</h1>
            <p>{VERSION}</p>
        </div>
        <NavigationLink to="/mbc/dg_nat" text={locales.mbc.dgNat}/>
        <NavigationLink to="/mbc/dg_plus" text={locales.mbc.dgPlus}/>
        <NavigationLink to="/mbc/proc_nat" text={locales.mbc.procNat}/>
        <NavigationLink to="/mbc/proc_plus" text={locales.mbc.procPlus}/>
        <NavigationLink to="/mbc/atc_nat" text={locales.mbc.atcNat}/>
        <NavigationLink to="/mbc/atc_plus" text={locales.mbc.atcPlus}/>
        <NavigationDropDown
          base="/mbc/config"
          small
          text={locales.config}
          childRoutes={[
              {to: '/dumps', title: locales.dumps},
              {to: '/admin', title: locales.admin},
            ]}/>
    </div>
);

const isMbc = (path) => path.substring(0, 4) === '/mbc';

const getNavigationItemsByRequestedPath = (isMbc, locales) => {
    if (isMbc) {
        return mbcNavigationItems(locales);
    }
    return ndmsNavigationItems(locales);
};

const Navigation = ({locales: {t}}) => {
    const location = useLocation();
    const pathSpecificNavigationItems = getNavigationItemsByRequestedPath(isMbc(location.pathname), t);
    return (
        <div className="page-navigation" >
            {pathSpecificNavigationItems}
            <ErrorNotifications/>
            <Notifications/>
            <div className="navigation-right-content">
                <MbcNdmsToggle isMbc={isMbc(location.pathname)}/>
                <CountrySelection />
                <Logout />
            </div>
        </div>
    );
};

export default connect(({locales}) => ({locales}))(Navigation);
