import {createDistinctLastTimeout} from '../../common/utils';

const CLEAR_ROW_FOCUS = 'DG_NAT_LIST_OPTIONS::CLEAR_ROW_FOCUS';
const SET_DG_NAT_LIST_OPTIONS = 'DG_NAT_LIST_OPTIONS::SET_DG_NAT_LIST_OPTIONS';
const CLEAR_DG_NAT_LIST_OPTIONS = 'DG_NAT_LIST_OPTIONS::CLEAR_DG_NAT_LIST_OPTIONS';
const FETCH_DG_NAT_LIST_OPTIONS = 'DG_NAT_LIST_OPTIONS::FETCH_DG_NAT_LIST_OPTIONS';
const FETCH_DG_NAT_LIST_ERROR = 'DG_NAT_LIST_OPTIONS::FETCH_DG_NAT_LIST_ERROR';

const initialState = {
    dgNatItems: [],
    loading: false,
};
export default function dgNatOptionsReducer(state = initialState, {payload, type}) {
    switch (type) {
    case FETCH_DG_NAT_LIST_OPTIONS:
        return {...state, loading: true, dgNatItems: []};
    case FETCH_DG_NAT_LIST_ERROR:
        return {...state, loading: false};
    case SET_DG_NAT_LIST_OPTIONS:
        return {...state, dgNatItems: payload, loading: false};
    case CLEAR_DG_NAT_LIST_OPTIONS:
        return {...state, dgNatItems: []};
    default: return state;
    }
}
const resolveLast = createDistinctLastTimeout();

function doFetchDgNatOptions() {
    const waitForResolve = resolveLast(0);
    return async function fetchNational(dispatch, getState, Api) {
        try {
            dispatch({type: FETCH_DG_NAT_LIST_OPTIONS});
            const {cancelled} = await waitForResolve;
            const payload = {globalFilter: {}};
            dispatch({type: CLEAR_ROW_FOCUS});
            const result = await Api.post('/mbc/dg_nat/fetch', {payload, cancelled});
            dispatch({type: SET_DG_NAT_LIST_OPTIONS, payload: result.rows});
        } catch (e) {
            dispatch({type: FETCH_DG_NAT_LIST_ERROR});
            throw e;
        }
    };
}

export const actions = {
    doFetchDgNatOptions,
};

export const types = {
    CLEAR_ROW_FOCUS,
    SET_DG_NAT_LIST_OPTIONS,
    CLEAR_DG_NAT_LIST_OPTIONS,
    FETCH_DG_NAT_LIST_OPTIONS,
    FETCH_DG_NAT_LIST_ERROR,
};
