import React from 'react';
import './styles.scss';

function stopPropagation(event) {
    event.stopPropagation();
}

export default function FillRest() {
    return (
        <span
          className="grid-header-cell-fill-rest"
          onMouseDown={stopPropagation}
          onClick={stopPropagation} />
    );
}
