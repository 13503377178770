import { range } from './utils';

export const { RUN_ENV, VERSION, ENDPOINT, NODE_ENV } = process.env;
export const JWT_TOKEN = 'JWT_TOKEN';
export const SELECTED_COUNTRY = 'selected_country';
export const SELECTED_LANGUAGE = 'selected_language';
export const DEFAULT_COLUMN_WIDTH = 100;
export const DEFAULT_ROW_HEIGHT = 30;
export const LOGIC_COLUMN_WIDTH = {
    drg_com: 60,
    agelim: 60,
    sex: 51,
    compl: 60,
    mdc: 51,
    dur: 51,
    disch: 60,
    rtc: 51,
    icd: 51,
    procpro: 70,
    pdgprop: 70,
    dgcat: 70,
    secpro: 70,
    dgprop1: 70,
    dgprop2: 70,
    dgprop3: 70,
    dgprop4: 70,
    errors: 170,
    active: 51,
    created_at: 170,
    updated_at: 170,
};
export const TESTCASE_COLUMN_WIDTH = {
    id: 51,
    drg_nat: 60,
    drg_nat_previous: 60,
    drg_logic_id: 60,
    drg_logic_id_previous: 135,
    death: 60,
    diagnosis_features: 125,
    procedure_features: 125,
    primary_diagnose_feature: 160,
    sex: 51,
    duration: 70,
    age: 60,
    compl: 60,
    rem: 51,
    dg1_plus: 80,
    dg2_plus: 80,
    dg3_plus: 80,
    dg4_plus: 80,
    dg5_plus: 80,
    dg6_plus: 80,
    dg7_plus: 80,
    dg8_plus: 80,
    dg9_plus: 80,
    d_dg1_plus: 80,
    d_dg2_plus: 80,
    d_dg3_plus: 80,
    d_dg4_plus: 80,
    d_dg5_plus: 80,
    d_dg6_plus: 80,
    d_dg7_plus: 80,
    d_dg8_plus: 80,
    d_dg9_plus: 80,
    proc1_plus: 80,
    proc2_plus: 80,
    proc3_plus: 80,
    proc4_plus: 80,
    proc5_plus: 80,
    proc6_plus: 80,
    proc7_plus: 80,
    proc8_plus: 80,
    proc9_plus: 80,
    errors: 170,
    created_at: 170,
    updated_at: 170,
};

// see counterpart in backend/src/constants.js
const common = ['errors', 'ignore_errors', 'created_at', 'updated_at'];
export const NON_EDITABLE_FIELDS = {
    complicationCategoryName: ['compl', 'inclprop', ...common],
    diagnosisFeature: ['dg_nat', 'd_dg_nat', ...common],
    diagnosisCategoryName: ['dgcat', ...common],
    drgName: ['country', ...common],
    drgNameCombined: ['drg_com', ...common],
    grouperTestCase: ['id', 'drg_nat', 'drg_nat_previous', 'drg_logic_id', 'drg_logic_id_previous', ...common],
    groupingPropertyName: ['dgprop', ...common],
    logic: ['drg_nat', 'drg_text_nat', 'ord', 'notifications', ...common],
    mdcName: ['country', ...common],
    mdcNameCombined: ['mdc', ...common],
    principalDiagnosisProperty: ['pdgprop', ...common],
    procedureFeature: ['or_val', 'proc_nat', ...common],
    procedurePropertyName: ['procprop', ...common],
    mbcDgNat: ['plus1', 'plus2', 'dg1', 'dg2', 'text_plus', 'country', ...common],
    mbcDgPlus: ['plus_dg', ...common],
    mbcProcNat: ['plus1', 'proc1', 'text_plus', 'country', ...common],
    mbcProcPlus: ['plus_proc', ...common],
    mbcAtcNat: ['plus1', 'atc1', 'text_plus', 'country', ...common],
    mbcAtcPlus: ['plus_atc', ...common],
};

export const NON_SORTABLE_FIELDS = {
    diagnosisFeature: {
        dg_nat: true,
        d_dg_nat: true,
    },
    logic: {
        drg_nat: true,
        drg_text_nat: true,
    },
    procedureFeature: {
        proc_nat: true,
    },
    grouperTestCase: {
        drg_nat: true,
        drg_nat_previous: true,
        drg_logic_id: true,
        drg_logic_id_previous: true,
    },
};

export const MENU_DIMENSIONS = {
    agelim: {height: 107, width: 157},
    ...([
        'mdc',
        'dgcat',
        'drg_nat',
        'drg_text_nat',
        'drg_text_nat_short',
        'drg_com',
        'drg_nat_previous',
        'pdgprop',
        'procpro',
        'secpro',
        'varval',
        'vartype',
        'dg_nat',
        'd_dg_nat',
        'dg_plus',
        'd_dg_plus',
        'nat_ver',
        'plus1',
        'plus2',
        'plus_dg',
        'plus_proc',
        'proc_nat',
        'proc_plus',
        'errors',
        'inactivated_at',
        ...range(1, 10).map((n) => `dg${n}_plus`),
        ...range(1, 10).map((n) => `d_dg${n}_plus`),
        ...range(1, 10).map((n) => `proc${n}_plus`),
        ...range(1, 10).map((n) => `dgprop${n}`),
    ].reduce((acc, n) => ({...acc, [n]: {height: 150, width: 300}}), {})),
    active: {height: 52, width: 52},
    death: {height: 52, width: 52},
    or_1: { height: 52, width: 52 },
    extens: { height: 52, width: 52 },
    disch: { height: 52, width: 52 },
};

export const DIAGNOSIS_FEATURE_VARTYPES = ['COMPL', 'COMPLEX', 'DGCAT', 'DGPROP', 'MDC', 'OR', 'PDGPRO', 'PROCPR', 'SDGPRO'];
export const PROCEDURE_FEATURE_VARTYPES = ['CC', 'DGPROP', 'OR', 'PROCPR'];

const ALL_TABLE_OPTIONS = [
    {label: 'Complication Category Name', value: 'complication_category_name'},
    {label: 'Complication Exclusion', value: 'complication_exclusion'},
    {label: 'Dg Codes', value: 'dg_nat'},
    {label: 'Dg Plus Codes', value: 'dg_plus'},
    {label: 'Diagnosis Feature', value: 'diagnosis_feature'},
    {label: 'Diagnosis Category Name', value: 'diagnosis_category_name'},
    {label: 'Drg Logic', value: 'drg_logic'},
    {label: 'Drg Name', value: 'drg_name'},
    {label: 'Drg Name Combined', value: 'drg_name_combined'},
    {label: 'Grouping Property Name', value: 'grouping_property_name'},
    {label: 'Mdc Name', value: 'mdc_name'},
    {label: 'Mdc Name Combined', value: 'mdc_name_combined'},
    {label: 'Principal Diagnosis Property Name', value: 'principal_diagnosis_property'},
    {label: 'Proc Codes', value: 'proc_nat'},
    {label: 'Proc Plus Codes', value: 'proc_plus'},
    {label: 'Procedure Feature', value: 'procedure_feature'},
    {label: 'Procedure Property Name', value: 'procedure_property_name'},
    {label: 'Rtc', value: 'rtc'},
    {label: 'Testcase', value: 'testcase'},
    {label: 'Testcase Result', value: 'testcase_result'},
];

export const RAW_COM_TABLE_OPTIONS = ALL_TABLE_OPTIONS
    .filter(it => it.value !== 'complication_exclusion');

export const RAW_TABLE_OPTIONS = RAW_COM_TABLE_OPTIONS
    .filter(it => ![
        'dg_plus',
        'drg_name_combined',
        'mdc_name_combined',
        'proc_plus',
    ].includes(it.value));

export const COM_TABLE_OPTIONS = ALL_TABLE_OPTIONS
    .filter(it => it.value !== 'testcase_result');

export const TABLE_OPTIONS = COM_TABLE_OPTIONS
    .filter(it => ![
        'dg_plus',
        'proc_plus',
        'drg_name_combined',
        'mdc_name_combined',
    ].includes(it.value));

export const MBC_TABLE_OPTIONS = [
    {label: 'Dg Codes', value: 'mbc.dg_nat'},
    {label: 'Dg Plus Codes', value: 'mbc.dg_plus'},
    {label: 'Proc Codes', value: 'mbc.proc_nat'},
    {label: 'Proc Plus Codes', value: 'mbc.proc_plus'},
    {label: 'Atc Codes', value: 'mbc.atc_nat'},
    {label: 'Atc Plus Codes', value: 'mbc.atc_plus'},
];

// compare: excelSheetNames in backend/src/services/dbDumpService.js
export const tableShortNames = {
    drg_logic: 'drg-logic',
    diagnosis_feature: 'dg-feat',
    procedure_feature: 'proc-feat',
    complication_exclusion: 'compl-excl',
    complication_category_name: 'compl-name',
    drg_name: 'drg-name',
    drg_name_combined: 'drg-name-com',
    mdc_name: 'mdc-name',
    mdc_name_combined: 'mdc-name-com',
    diagnosis_category_name: 'dgcat-name',
    grouping_property_name: 'grprop-name',
    principal_diagnosis_property: 'pdgprop-name',
    procedure_property_name: 'procprop-name',
    rtc: 'rtc',
    dg_nat: 'dg',
    dg_plus: 'dg-plus',
    proc_nat: 'proc',
    proc_plus: 'proc-plus',
    testcase: 'testcase',
    'mbc.dg_nat': 'dg',
    'mbc.dg_plus': 'dg-plus',
    'mbc.proc_nat': 'proc',
    'mbc.proc_plus': 'proc-plus',
    'mbc.atc_nat': 'atc',
    'mbc.atc_plus': 'atc-plus',
};
