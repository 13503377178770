import React from 'react';
import {string, func} from 'prop-types';
import {connect} from 'react-redux';
import Spinner from '../Spinner';
import './ContextMenu/styles.scss';

const DrgNameListMenu = ({t, nationalDrgs, onClose, loading, country}) => {
    if (nationalDrgs.length === 0) {
        return <div />;
    }
    return (
        <div className="cell-context-menu" onClick={onClose}>
            <Spinner hidden={!loading} className="context-menu-spinner" />
            <div className="cell-context-description-menu">
                {nationalDrgs.map((drg) => (
                    <div key={drg.rid} className="cell-context-menu-description-item">
                        {country === 'com' && <p>{t.country}: {drg.country || '--'}</p>}
                        <p>{t.drgNat}: {drg.drg_nat || '--'}</p>
                        <p>{t.description}: {drg.drg_text_nat || '--'}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

DrgNameListMenu.propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    id: string.isRequired, // used in mapStateToProps
    onClose: func.isRequired,
};

export default connect(({locales: {t, country}, drgNameOptions: {national, loading}}, {id}) => {
    const nationalDrgs = country === 'com'
        ? national.filter((row) => row.drg_com === id && row.active)
        : national.filter((row) => row.drg_com === id && row.country === country && row.active) || undefined;
    return ({t, nationalDrgs, loading, country});
})(DrgNameListMenu);
