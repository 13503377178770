import {actions as notificationActions} from './notification';
import {actions as errorNotificationActions} from './errorNotification';

const FETCH_COUNTRY_OPTIONS = 'COUNTRY::FETCH_COUNTRY_OPTIONS';
const SET_COUNTRY_OPTIONS = 'COUNTRY::SET_COUNTRY_OPTIONS';
const FETCH_COUNTRIES_TO_ADD = 'COUNTRY::FETCH_COUNTRIES_TO_ADD';
const SET_COUNTRIES_TO_ADD = 'COUNTRY::SET_COUNTRIES_TO_ADD';
const ADD_COUNTRY = 'COUNTRY::ADD_COUNTRY';
const NOT_LOADING = 'COUNTRY::NOT_LOADING';
const IMPORT_CODES = 'CODES::IMPORT_CODES';
const VALIDATE_TABLES = 'VALIDATE_TABLES';
const NOT_VALIDATING = 'NOT_VALIDATING';

const combined = {code: 'com', name: 'Combined'};

const initialState = {
    countryOptions: [combined],
    countriesToAdd: {},
    loading: false,
    validating: false,
};

export default function adminReducer(state = initialState, {type, payload}) {
    switch (type) {
    case SET_COUNTRY_OPTIONS:
        return {...state, countryOptions: [combined, ...payload]};
    case FETCH_COUNTRIES_TO_ADD:
        return {...state, countriesToAdd: [], loading: true};
    case SET_COUNTRIES_TO_ADD:
        return {...state, countriesToAdd: payload};
    case ADD_COUNTRY:
        return {...state, loading: true};
    case NOT_LOADING:
        return {...state, loading: false};
    case IMPORT_CODES:
        return {...state, loading: true};
    case VALIDATE_TABLES:
        return {...state, validating: true};
    case NOT_VALIDATING:
        return {...state, validating: false};
    default: return state;
    }
}

export const doFetchCountryOptions = () => async (dispatch, getState, Api) => {
    dispatch({type: FETCH_COUNTRY_OPTIONS});
    const countries = await Api.post('/country/fetch');
    dispatch({type: SET_COUNTRY_OPTIONS, payload: countries});
};

export const doFetchCountriesToAdd = () => async (dispatch, getState, Api) => {
    dispatch({type: FETCH_COUNTRIES_TO_ADD});
    const subject = 'Fetch countries to add';
    try {
        const response = await Api.post('/country/fetchToAdd');
        const payload = {};
        response?.forEach((it) => payload[it] = {});
        dispatch({type: SET_COUNTRIES_TO_ADD, payload });
    } catch (e) {
        dispatch(errorNotificationActions.createErrorNotification({subject, error: e.message}));
    }
    dispatch({type: NOT_LOADING});
};

export const doSetCountryName = (code, countryName) => async (dispatch, getState) => {
    const {countriesToAdd} = getState().admin;
    dispatch({
        type: SET_COUNTRIES_TO_ADD,
        payload: {
            ...countriesToAdd,
            [code]: {...countriesToAdd[code], countryName},
        },
    });
};

export const doSetBasedOnCountry = (code, basedOnCountry) => (dispatch, getState) => {
    const {countriesToAdd} = getState().admin;
    dispatch({
        type: SET_COUNTRIES_TO_ADD,
        payload: {
            ...countriesToAdd,
            [code]: {...countriesToAdd[code], basedOnCountry},
        },
    });
};

export const doAddCountry = (code) => async (dispatch, getState, Api) => {
    dispatch({type: ADD_COUNTRY});
    const {countryName, basedOnCountry} = getState().admin.countriesToAdd[code];
    const subject = 'Add country';
    try {
        const response = await Api.post('/country/add', {payload: {code, countryName, basedOnCountry}});
        dispatch(notificationActions.createNotification({subject, data: response}));
        dispatch(doFetchCountryOptions());
        dispatch(doFetchCountriesToAdd());
    } catch (e) {
        dispatch(errorNotificationActions.createErrorNotification({subject, error: e.message}));
        dispatch({type: NOT_LOADING});
    }
};

export const doImportCodes = (type) => async (dispatch, getState, Api) => {
    dispatch({type: IMPORT_CODES});
    const subject = `Import ${type} codes`;
    try {
        const response = await Api.post(`/import/${type}Codes`);
        dispatch(notificationActions.createNotification({subject, data: response}));
    } catch (e) {
        dispatch(errorNotificationActions.createErrorNotification({subject, error: e.message}));
    }
    dispatch({type: NOT_LOADING});
};

export const doValidateTables = (schema) => async (dispatch, getState, Api) => {
    dispatch({type: VALIDATE_TABLES});
    const subject = 'Validate tables';
    try {
        const response = await Api.get(`/validate/${schema}`);
        dispatch(notificationActions.createNotification({subject, data: response}));
    } catch (e) {
        dispatch(errorNotificationActions.createErrorNotification({subject, error: e.message}));
    }
    dispatch({type: NOT_VALIDATING});
};

export const actions = {
    doFetchCountryOptions,
    doFetchCountriesToAdd,
    doSetCountryName,
    doSetBasedOnCountry,
    doAddCountry,
    doImportCodes,
    doValidateTables,
};
