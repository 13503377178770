import React from 'react';
import {string, func} from 'prop-types';
import './styles.scss';

const MenuItem = ({onChange, value, option}) => (
    <div
      onMouseDown={() => onChange({value: option})}
      className={value === option ? 'cell-menu-item-selected' : 'cell-menu-item'}>
        {option}
    </div>
);

export default function DischargeModeMenu({value, includeOptionN = false, onChange, onClose, style}) {
    return (
        <div className={`cell-menu disch-menu${includeOptionN ? '-lg' : ''}`} style={style} onClick={onClose}>
            {MenuItem({onChange, value, option: ''})}
            {MenuItem({onChange, value, option: 'E'})}
            {MenuItem({onChange, value, option: 'R'})}
            {includeOptionN && MenuItem({onChange, value, option: 'N'})}
        </div>
    );
}

DischargeModeMenu.propTypes = {
    value: string.isRequired,
    onChange: func.isRequired,
};
