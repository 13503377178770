import React from 'react';
import {connect} from 'react-redux';
import CommonGridScene from '../CommonGridScene';
import {removeStringFromKeys} from '../../common/utils';
import {actions, selectors} from '../../store/procedurePropertyName';
import {actionsWrapper, selectorsWrapper} from '../../store/resources/reducerUtils';
import store from '../../store';

const domain = 'procedurePropertyName';
const ProcedurePropertyName = (props) => {
    const viewProps = removeStringFromKeys(props, domain);

    return (
        <CommonGridScene
          domain={domain}
          identifier="procprop"
          columnTypes={{
            or_1: 'Boolean',
            extens: 'BooleanWithNull',
            active: 'Boolean',
            errors: 'Errors',
            // notifications: 'Notifications',
          }}
          enableAddRow
          enableRemoveRow
          newRowEditables={['procprop', 'or_1', 'extens']}
          {...viewProps}/>
    );
};

export default connect(
    () => ({...selectorsWrapper(store, selectors)}),
    {...actionsWrapper(store, actions)},
)(ProcedurePropertyName);
