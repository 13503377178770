/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import './styles.scss';

export default function RadioButton({onChange, label, checked, className, style}) {
    return (
        <span onClick={onChange} className={`styled-radiobutton${className ? ` ${className}` : ''}`} style={style}>
            <input
              id="styled-radiobutton"
              className="radio-button-input"
              onChange={(e) => {
                  e.stopPropagation();
                  onChange();
              }}
              type="radio"
              checked={checked}/>
            <label className="radio-button-label" htmlFor="styled-checkbox-1">{label || 'Label'}</label>
        </span>
    );
}
