import React from 'react';
import {connect} from 'react-redux';
import {Navigate} from 'react-router-dom';
import Button from '../../components/Button';
import {doLogin} from '../../store/meta';

class Login extends React.Component {
    state = {email: '', password: ''};

    render() {
        const {auth, t} = this.props;
        const {pending, token} = auth;
        const {email, password} = this.state;
        if (token) {
            return <Navigate to="/"/>;
        }
        return (
            <form className="auth-form" onSubmit={this.handleSubmit}>
                <input
                  placeholder={t.email}
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => this.setState({email: e.target.value})}/>
                <input
                  placeholder={t.password}
                  type="password"
                  name="password"
                  value={password}
                  onChange={(e) => this.setState({password: e.target.value})}/>
                <Button type="submit" disabled={pending} text={t.submit} />
            </form>
        );
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const {email, password} = this.state;
        if (!this.props.pending) {
            this.props.doLogin({email, password});
        }
    };
}

export default connect(
    ({locales: {t}, meta: {auth}}) => ({auth, t}),
    {doLogin},
)(Login);
