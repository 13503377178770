import React from 'react';
import {connect} from 'react-redux';
import {doFetchDiagnosisRelationDGPropRelations, doFetchDiagnosisRelationGroupingPropertyNames} from '../../store/diagnosisRelations';
import ContextMenu from './ContextMenu';
import {ProcedureRelationCardItem, DiagnosisRelationCardItem} from './CardItems';
import {normalizeCode} from '../../common/utils';
import {actionsWrapper} from '../../store/resources/reducerUtils';
import store from '../../store';

const identifier = 'dgprop';

const selectOptions = (groupingProperties, withNegatives) => {
    const options = groupingProperties.map((it) => ({value: it[identifier], label: `${it[identifier]} - ${it.varval_text_com}`}));
    if (withNegatives) {
        return options.concat(options.map((it) => ({value: `-${it.value}`, label: `-${it.label}`})));
    }

    return options;
};

class DiagnosisGroupingPropertiesMenu extends React.Component {
    state = {loading: false};

    render() {
        const {t, id, groupingProperties, onClose, onChange, allowEmpty, withNegatives} = this.props;
        const item = groupingProperties.find((it) => it[identifier] === normalizeCode(id));
        const value = {label: id ? (`${id} - ${item ? item.varval_text_com : ''}`) : '--', value: id};
        const {relations = {}} = item || {};
        const {loading} = this.state;
        return (
            <ContextMenu
              onChange={onChange}
              onClose={onClose}
              value={value}
              label={identifier}
              loading={loading || relations.loading}
              fetchContent={this.onFetchContent}
              allowEmpty={allowEmpty}
              options={selectOptions(groupingProperties, withNegatives)}>
                {(relations.procedures || []).map(p => ProcedureRelationCardItem(t, p))}
                {(relations.diagnoses || []).map(d => DiagnosisRelationCardItem(t, d))}
            </ContextMenu>
        );
    }

    onFetchContent = () => {
        this.setState({loading: true});
        this.props.doFetchDiagnosisRelationGroupingPropertyNames().then(() => !this.unMounted && this.setState({loading: false}));
    };

    componentDidMount() {
        const {id} = this.props;
        if (id) {
            this.props.doFetchDiagnosisRelationDGPropRelations(normalizeCode(id));
        }
    }

    componentDidUpdate(prevProps) {
        const {id} = this.props;
        if (id !== prevProps.id) {
            this.props.doFetchDiagnosisRelationDGPropRelations(normalizeCode(id));
        }
    }

    componentWillUnmount() {
        this.unMounted = true;
    }
}

export default connect(
    ({locales: {t}, diagnosisRelations: {groupingProperties}}) => ({t, groupingProperties}),
    {...actionsWrapper(store, {doFetchDiagnosisRelationDGPropRelations, doFetchDiagnosisRelationGroupingPropertyNames})},
)(DiagnosisGroupingPropertiesMenu);
