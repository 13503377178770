import React from 'react';
import {func, string} from 'prop-types';
import {connect} from 'react-redux';
import {doFetchDiagnosisRelationCodes, selectors} from '../../store/diagnosisRelations';
import ContextMenu from './ContextMenu';
import {actionsWrapper} from '../../store/resources/reducerUtils';
import store from '../../store';

const identifier = 'dg_plus';
class DgPlusMenu extends React.Component {
    state = {loading: true};

    render() {
        const {value, label, onChange, onClose, codes, allowEmpty} = this.props;
        const {loading} = this.state;
        const items = codes.filter((val) => val[identifier] === value);
        const options = loading ? [] : selectors.getDiagnosisRelationsCodeOptions(codes);
        return (
            <ContextMenu
              onBlur={onClose}
              label={label}
              loading={loading}
              value={{value, label: value}}
              onChange={onChange}
              onClose={onClose}
              fetchContent={this.onFetchContent}
              allowEmpty={allowEmpty}
              options={options}>
                {items.map((item) => (
                    <div key={item[identifier]} className="cell-context-menu-description-item" >
                        <p>{item[identifier]}</p>
                        <p>{item.dg_text_plus}</p>
                    </div>
                ))}
            </ContextMenu>
        );
    }

    componentDidMount() {
        setTimeout(() => !this.unMounted && this.setState({loading: false}), 0);
    }

    onFetchContent = () => {
        this.setState({loading: true});
        this.props.doFetchDiagnosisRelationCodes().then(() => !this.unMounted && this.setState({loading: false}));
    };

    componentWillUnmount() {
        this.unMounted = true;
    }
}

DgPlusMenu.propTypes = {
    value: string,
    onChange: func.isRequired,
};

export default connect(
    ({diagnosisRelations: {codes}}) => ({codes}),
    {...actionsWrapper(store, {doFetchDiagnosisRelationCodes})},
)(DgPlusMenu);
