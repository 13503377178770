import React from 'react';
import './styles.scss';

export default class StopChildScrollOnBodyScroll extends React.Component {
    state = {scrolling: false};

    componentDidMount() {
        let lastTimeout = Date.now();
        document.addEventListener('scroll', () => {
            lastTimeout = Date.now();
            if (!this.state.scrolling) {
                this.setState({scrolling: true});
                const debounceLast = () => {
                    if ((Date.now() - lastTimeout) < 30) {
                        setTimeout(debounceLast, 30);
                    } else {
                        this.setState({scrolling: false});
                    }
                };
                debounceLast();
            }
        });
    }

    render() {
        return (
            <div className={this.state.scrolling ? 'no-child-scroll' : ''}>
                {this.props.children}
            </div>
        );
    }
}
