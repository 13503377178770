import React from 'react';
import {Routes, Route} from 'react-router-dom';
import DiagnosisCategoryName from './DiagnosisCategoryName';
import GroupingPropertyName from './GroupingPropertyName';
import ProcedurePropertyName from './ProcedurePropertyName';
import PrincipalDiagnosisProperty from './PrincipalDiagnosisProperty';
import ComplicationCategoryName from './ComplicationCategoryName';
import DrgName from './DrgName';
import DrgNameCombined from './DrgNameCombined';
import MdcName from './MdcName';
import MdcNameCombined from './MdcNameCombined';

const Names = () => (
    <Routes>
        <Route path="grouping_property" element={<GroupingPropertyName />} />
        <Route path="procedure_property" element={<ProcedurePropertyName />} />
        <Route path="complication_category" element={<ComplicationCategoryName />} />
        <Route path="diagnosis_category" element={<DiagnosisCategoryName />} />
        <Route path="principal_diagnosis_property" element={<PrincipalDiagnosisProperty />} />
        <Route path="drg" element={<DrgName />} />
        <Route path="drg_combined" element={<DrgNameCombined />} />
        <Route path="mdc" element={<MdcName />} />
        <Route path="mdc_combined" element={<MdcNameCombined />} />
    </Routes>
);

export default Names;
