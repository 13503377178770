/* eslint-disable import/no-named-default */
import './styles.scss';
import _LogicRightClickMenu from './LogicRightClickMenu';
import _AgeMenu from './AgeMenu';
import _BooleanMenu from './BooleanMenu';
import _DischargeModeMenu from './DischargeModeMenu';
import _TestCaseRightClickMenu from './TestCaseRightClickMenu';
import _RightClickMenu from './RightClickMenu';
import _DrgNameMenu from './DrgNameMenu';
import _DiagnosisGroupingPropertiesMenu from './DiagnosisGroupingPropertiesMenu';
import _DiagnosisNamesMenu from './DiagnosisNamesMenu';
import _DgPlusMenu from './DgPlusMenu';
import _DgCodeMenu from './DgNatMenu';
import _ProcCodeMenu from './ProcNatMenu';
import _ProcPlusMenu from './ProcPlusMenu';
import _DgNatListMenu from './Mbc/DgNatListMenu';
import _ProcNatListMenu from './Mbc/ProcNatListMenu';
import _MdcNameMenu from './MdcNameMenu';
import _PrincipalDiagnosisPropertiesMenu from './PrincipalDiagnosisPropertiesMenu';
import _ProcedureNamesMenu from './ProcedureNamesMenu';
import _VartypeMenu from './VartypeMenu';
import _VarvalMenu from './VarvalMenu';
import _DrgNameListMenu from './DrgNameListMenu';
import _DrgNameExtendedListMenu from './DrgNameExtendedListMenu';
import _MdcNameListMenu from './MdcNameListMenu';
import _ErrorsAndNotificationsListMenu from './ErrorsAndNotificationsListMenu';
import _InactivatedAtMenu from './InactivatedAtMenu';

export const TestCaseRightClickMenu = _TestCaseRightClickMenu;
export const RightClickMenu = _RightClickMenu;
export const LogicRightClickMenu = _LogicRightClickMenu;
export const AgeMenu = _AgeMenu;
export const BooleanMenu = _BooleanMenu;
export const DischargeModeMenu = _DischargeModeMenu;
export const DrgNameMenu = _DrgNameMenu;
export const DiagnosisGroupingPropertiesMenu = _DiagnosisGroupingPropertiesMenu;
export const DiagnosisNamesMenu = _DiagnosisNamesMenu;
export const MdcNameMenu = _MdcNameMenu;
export const PrincipalDiagnosisPropertiesMenu = _PrincipalDiagnosisPropertiesMenu;
export const ProcedureNamesMenu = _ProcedureNamesMenu;
export const VartypeMenu = _VartypeMenu;
export const VarvalMenu = _VarvalMenu;
export const DgPlusMenu = _DgPlusMenu;
export const DgCodeMenu = _DgCodeMenu;
export const ProcPlusMenu = _ProcPlusMenu;
export const ProcCodeMenu = _ProcCodeMenu;
export const DgNatListMenu = _DgNatListMenu;
export const ProcNatListMenu = _ProcNatListMenu;
export const DrgNameListMenu = _DrgNameListMenu;
export const DrgNameExtendedListMenu = _DrgNameExtendedListMenu;
export const MdcNameListMenu = _MdcNameListMenu;
export const ErrorsAndNotificationsListMenu = _ErrorsAndNotificationsListMenu;
export const InactivatedAtMenu = _InactivatedAtMenu;
