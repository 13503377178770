/* eslint-disable react/jsx-props-no-spreading */
import React, {useState} from 'react';
import {func, object, string} from 'prop-types';
import {connect} from 'react-redux';
import Dropzone from 'react-dropzone';
import Api from '../../../common/Api';
import {createNotification} from '../../../store/notification';
import {createErrorNotification} from '../../../store/errorNotification';
import {doFullBackup} from '../../../store/dumps';
import {getFileContents} from '../../../common/utils';
import UploadOptions from '../../../components/UploadOptions';
import {actionsWrapper} from '../../../store/resources/reducerUtils';
import store from '../../../store';

const HundredMegs = 100000000;

const MergeUploadAndReplaceCodes = ({t, country, schema, doFullBackup, createNotification, createErrorNotification}) => {
    const [mergeOptions, setMergeOptions] = useState({updateNatVer: false, removeFromNatVer: false});
    const [replaceOptions, setReplaceOptions] = useState({updateNatVer: false, removeFromNatVer: false});

    const mergeUpload = async (first, rest) => {
        const files = [...first, ...rest];
        const data = await getFileContents({files});
        const payload = data.reduce((acc, {name, data}) => ({...acc, [name]: data}), {});
        const subject = 'Merge upload';
        try {
            const response = await Api.post(`/import/merge/${schema}`, {payload: {...payload, options: mergeOptions}});
            createNotification({subject, data: response});
        } catch (e) {
            console.error(e);
            createErrorNotification({subject, error: e.message});
        }
    };

    const replaceCodes = async (first, rest) => {
        await doFullBackup(schema);
        const files = [...first, ...rest];
        const data = await getFileContents({files});
        const payload = data.reduce((acc, {name, data}) => ({...acc, [name]: data}), {});
        const subject = 'Replace national codes';
        try {
            const response = await Api.post(`/import/replace/${country}`, {payload: {...payload, options: replaceOptions}});
            createNotification({subject, data: response});
        } catch (e) {
            console.error(e);
            createErrorNotification({subject, error: e.message});
        }
    };

    return (
        <div className="section-container">
            <div className="config-form">
                <div className="config-form-content">
                    <h2>{t.mergeUploadCsv}</h2>
                    <Dropzone
                      onDrop={mergeUpload}
                      multiple
                      maxSize={HundredMegs /* 100MB */}>
                        {({getRootProps, getInputProps}) => (
                            <div {...getRootProps()} className="drag-and-drop-label-container">
                                <input {...getInputProps()}/>
                                <p>{t.dragAndDrop}</p>
                            </div>
                        )}
                    </Dropzone>
                    {schema === 'mbc' && <UploadOptions t={t} setOptions={setMergeOptions}/>}
                    <p>{t.mergeUploadInstructions}</p>
                </div>
                {(schema === 'mbc' && country !== 'com')
                && (
                    <div className="config-form-content">
                        <h2>{t.replaceNationalCodes}</h2>
                        <Dropzone
                          onDrop={replaceCodes}
                          multiple={false}
                          maxSize={HundredMegs /* 100MB */}>
                            {({getRootProps, getInputProps}) => (
                                <div {...getRootProps()} className="drag-and-drop-label-container">
                                    <input {...getInputProps()}/>
                                    <p>{t.dragAndDrop}</p>
                                </div>
                            )}
                        </Dropzone>
                        <UploadOptions t={t} setOptions={setReplaceOptions}/>
                        <p>{t.replaceNationalCodesInstructions}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

MergeUploadAndReplaceCodes.propTypes = {
    t: object.isRequired,
    country: string.isRequired,
    doFullBackup: func.isRequired,
};

export default connect(
    ({locales: {t, country}, meta: {schema}}) => ({t, country, schema}),
    {...actionsWrapper(store, {doFullBackup, createNotification, createErrorNotification})},
)(MergeUploadAndReplaceCodes);
