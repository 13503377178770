import React, {useState, useEffect, useCallback} from 'react';
import {connect} from 'react-redux';
import Select from '../Select';
import Button from '../Button';
import './styles.scss';

const SelectCode = ({t, storage, doSelectLinkableCode, reduxDomain: {selectedCode}}) => {
    const [options, setOptions] = useState([]);

    const getOptions = useCallback(() => {
        const codes = JSON.parse(localStorage.getItem(storage)) || [];
        setOptions(codes.map((c) => ({label: c, value: c})));
        doSelectLinkableCode(codes[codes.length - 1]);
    }, [doSelectLinkableCode, storage]);

    useEffect(() => {
        getOptions();
    }, [getOptions]);

    window.onstorage = (e) => e.key === storage && getOptions();

    const handleOnChange = (code) => {
        if (code) {
            doSelectLinkableCode(code.value);
        } else {
            const remainingOptions = options.filter((o) => o.value !== selectedCode);
            const newSelected = remainingOptions.length ? remainingOptions[remainingOptions.length - 1].value : null;
            doSelectLinkableCode(newSelected);
            setOptions(remainingOptions);
            localStorage.setItem(storage, JSON.stringify(remainingOptions.map((o) => o.value)));
        }
    };

    const handleClear = () => {
        doSelectLinkableCode(null);
        setOptions([]);
        localStorage.setItem(storage, JSON.stringify([]));
    };

    return (
        <div>
            {options.length > 0
            && (
                <>
                    <p className="dropdown-menu-title code-to-link">
                        {t.linkCode}
                        <Button text={t.clear} onClick={handleClear} className="clear-stored-codes"/>
                    </p>
                    <Select
                      isSearchable
                      isMulti={false}
                      placeholder={t.selectCode}
                      options={options}
                      value={options[options.length - 1]}
                      onChange={(code) => handleOnChange(code)}/>
                </>
            )}
        </div>
    );
};

export default connect(({locales: {t}, ...states}, {domain}) => ({reduxDomain: states[domain], t}))(SelectCode);
