import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import Button from '../../../components/Button';
import {actions as adminActions} from '../../../store/admin';
import {doFullBackup} from '../../../store/dumps';
import ConfigInput from '../../../components/ConfigInput';
import Select from '../../../components/Select';

class AddCountry extends React.Component {
    render() {
        const {t, countryOptions, countriesToAdd, loading = false} = this.props;
        const basedOnOptions = countryOptions
            .filter((opt) => opt.code !== 'com')
            .map((opt) => ({value: opt.code, label: opt.name}));
        return (
            <div className="section-container">
                <div className="config-form">
                    <div className="config-item">
                        <h2>{t.addCountry.title}</h2>
                        <p>{t.addCountry.text1}</p>
                        <p>1. <Link to="/mbc/config/dumps" target="_blank">{t.addCountry.uploadLinkText}</Link> {t.addCountry.uploadLinkTextAfter}</p>
                        <p>2. <Link to="/config/dumps" target="_blank">{t.addCountry.uploadLinkText2}</Link> {t.addCountry.uploadLinkText2After}</p>
                        <p>{t.addCountry.text2}</p>
                        <p>{t.addCountry.text3}</p>
                        <p>{t.addCountry.text4}</p>
                        <Button onClick={() => this.fetchCountriesToAdd()} text={t.refresh}/>
                        <hr/>
                        {!Object.keys(countriesToAdd).length && <strong>{t.addCountry.notFound}</strong>}
                        {Object.entries(countriesToAdd).map(([code, values], i) => {
                            const {countryName = '', basedOnCountry = ''} = values;
                            return (
                                <div key={code} className="config-settings">
                                    <h2>{t.addCountry.foundDataFor} {'\''}{code}{'\''}</h2>
                                    <p>3. {t.addCountry.countryDisplayName}</p>
                                    <ConfigInput i={i} onChange={(text) => this.setCountryName(code, text)} value={countryName}/>
                                    <p>4. {t.addCountry.updateNatVerBasedOn}</p>
                                    <Select
                                      i={i}
                                      containerClassName="narrow"
                                      isSearchable
                                      placeholder={`${t.selectCountry}...`}
                                      options={basedOnOptions}
                                      onChange={(obj) => this.setBasedOnCountry(code, obj)}/>
                                    <Button onClick={() => this.addCountry(code)} text={t.addCountry.title} disabled={loading || !countryName || !basedOnCountry}/>
                                    {i < Object.keys(countriesToAdd).length - 1 && <hr/>}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }

    setCountryName = (code, text) => {
        this.props.doSetCountryName(code, text);
    };

    setBasedOnCountry = (code, obj) => {
        this.props.doSetBasedOnCountry(code, obj.value);
    };

    fetchCountriesToAdd = async () => {
        await this.props.doFetchCountriesToAdd();
    };

    addCountry = async (code) => {
        await this.props.doFullBackup('public');
        await this.props.doAddCountry(code);
    };
}

const mapStateToProps = ({locales: {t}, admin: {countryOptions, countriesToAdd, loading}}) => ({t, countryOptions, countriesToAdd, loading});
export default connect(mapStateToProps, {...adminActions, doFullBackup})(AddCountry);
