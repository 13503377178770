import createReduxDomain from '../resources/domainFactory';
import { NON_EDITABLE_FIELDS } from '../../common/constants';

const {reducer, types, selectors, actions, middlewares} = createReduxDomain({
    URL: '/mbc/atc_nat',
    DOMAIN: 'MBC_ATC_NAT',
    IDENTIFIER: 'rid',
    excludeActions: [
        'doSet$VartypeFilter',
        'doSet$ExclusiveToORZeroFilter',
        'doSet$ExclusiveToDGCATZeroFilter',
        'doStore$Codes',
    ],
    excludeHeaders: ['rid'],
    nonEditableFields: NON_EDITABLE_FIELDS.mbcAtcNat,
});
export default reducer;

export {types, actions, selectors, middlewares};
