import React from 'react';
import {connect} from 'react-redux';
import Button from '../../../components/Button';
import {actions as adminActions} from '../../../store/admin';
import {actionsWrapper} from '../../../store/resources/reducerUtils';
import store from '../../../store';

const ValidateTables = (props) => {
    const {t, validating, mbc} = props;

    const validateTables = async () => {
        const schema = mbc ? 'mbc' : 'public';
        await props.doValidateTables(schema);
    };

    return (
        <div className="section-container">
            <div className="config-form">
                <div className="config-item">
                    <h2>{t.validateTables}</h2>
                    <p>{t.validateTablesText}</p>
                    <Button onClick={() => validateTables()} text={`${t.validateTables}`} disabled={validating}/>
                </div>
            </div>
        </div>
    );
};

export default connect(
    ({locales: {t}, admin: {validating}}) => ({t, validating}),
    {...actionsWrapper(store, {...adminActions})},
)(ValidateTables);
