import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import CommonGridScene from '../CommonGridScene';
import {removeStringFromKeys} from '../../common/utils';
import {actions, selectors} from '../../store/mbc/atcPlus';
import {actionsWrapper, selectorsWrapper} from '../../store/resources/reducerUtils';
import store from '../../store';

const domain = 'mbcAtcPlus';
const MbcAtcPlus = ({t, schema, ...rest}) => {
    const schemaProps = {
        mbc: {
            enableAddRow: true,
            enableRemoveRow: true,
            enableRowSelect: true,
            newRowEditables: ['plus_atc'],
            storableColumn: 'plus_atc',
        },
        public: {
            customTitle: t.titles.ndmsMbcAtcPlus,
            readOnly: true,
        },
    };

    const viewProps = removeStringFromKeys(rest, domain);
    const {doStoreCodes} = viewProps;

    useEffect(() => {
        const codes = JSON.parse(localStorage.getItem('atcStore')) || [];
        if (Array.isArray(codes)) doStoreCodes(codes);
    }, [doStoreCodes]);

    return (
        <CommonGridScene
          domain={domain}
          identifier="plus_atc"
          columnTypes={{
            inactivated_at: 'InactivatedAt',
            errors: 'Errors',
            plus_atc: 'plus_atc',
          }}
          {...schemaProps[schema]}
          {...viewProps}/>
    );
};

export default connect(
    ({locales: {t}, meta: {schema}}) => ({t, schema, ...selectorsWrapper(store, selectors)}),
    {...actionsWrapper(store, actions)},
)(MbcAtcPlus);
