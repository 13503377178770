/* eslint-disable max-len */
export default {
    _localeFor: 'EN',
    languages: {
        FI: 'FI',
        EN: 'EN',
        SW: 'SV',
    },
    submit: 'Submit',
    notification: {},
    email: 'email',
    password: 'password',
    combined: 'Combined',
    serviceBusy: 'Service busy!',
    drg: 'Drg',
    drgCom: 'Drg Com',
    mdc: 'Mdc',
    mdcCom: 'Mdc Com',
    copyRow: 'Copy Row',
    pasteRowAbove: 'Paste Row Above',
    pasteRow: 'Paste Row',
    pasteRowBelow: 'Paste Row Below',
    titles: {
        dgNat: 'Dg Codes',
        procNat: 'Proc Codes',
        procedurePropertyName: 'Procedure Property Name',
        groupingPropertyName: 'Grouping Property Name',
        diagnosisCategoryName: 'Diagnosis Category Name',
        principalDiagnosisProperty: 'Principal Diagnosis Property Name',
        complicationCategoryName: 'Complication Category Name',
        diagnosisFeature: 'Diagnosis Feature',
        procedureFeature: 'Procedure Feature',
        dumps: 'Dumps',
        admin: 'Admin',
        drgName: 'Drg Name',
        drgNameCombined: 'Drg Name Combined',
        mdcName: 'Mdc Name',
        mdcNameCombined: 'Mdc Name Combined',
        dgPlus: 'Dg Plus Codes',
        procPlus: 'Proc Plus Codes',
        ndmsMbcDgNat: 'MBC: Dg Codes',
        ndmsMbcDgPlus: 'MBC: Dg Plus Codes',
        ndmsMbcProcNat: 'MBC: Proc Codes',
        ndmsMbcProcPlus: 'MBC: Proc Plus Codes',
        mbcDgNat: 'Dg Codes',
        mbcDgPlus: 'Dg Plus Codes',
        mbcProcNat: 'Proc Codes',
        mbcProcPlus: 'Proc Plus Codes',
        mbcAtcNat: 'Atc Codes',
        mbcAtcPlus: 'Atc Plus Codes',
    },
    undo: 'Undo',
    message: 'message',
    fullDownload: 'Download export',
    downloadSelectedTables: 'Download selected tables',
    downloadAllTables: 'Download all tables',
    export: 'Export',
    fullDownloadText: 'data in CSV or Excel format.',
    fullDownloadNatText: 'national data in CSV or Excel format. Only rows for the selected country version are included, except that all Diagnosis Features with DGCAT 00M00 are included.',
    mbcFullDownloadNatText: 'national data in CSV or Excel format. Only rows for the selected country version are included.',
    optionally: 'Optionally',
    optDateRange: 'select a date range to limit when the data was last modified',
    optActive: 'export only active rows',
    optNotInUse: 'include rows not in use in any country',
    optUse: 'use',
    optAsActiveVersion: 'as the active version for diagnosis and procedure codes',
    optTables: 'select tables to include in the export',
    optRaw: 'export raw data as xlsx',
    fullBackup: 'Download backup .zip',
    downloadBackup: 'Download backup',
    fullPublicBackupText: 'Download a full backup of NDMS database tables in CSV format. A backup is also downloaded when restoring from a backup, recreating table(s) or logging out.',
    fullMbcBackupText: 'Download a full backup of MBC database tables in CSV format. A backup is also downloaded when restoring from a backup, recreating table(s) or logging out.',
    fullUploadZip: 'Restore from backup .zip',
    fullUploadInstructions: 'Restore all tables from a backup .zip file. Backups are named "<timestamp>_backup.zip".',
    recreateTables: 'Recreate table(s) from .csv(s)',
    recreateTableInstructions: 'Recreate table(s) from one or more .csv files. Refer to the backup for file names and column names.',
    nationalJSONDownload: 'National JSON download',
    nationalJSONDownloadText: 'Download a national JSON file for use with DRG Grouper.',
    dragAndDrop: 'Drag and drop',
    mergeUploadCsv: 'Merge upload .csv(s)',
    mergeUploadInstructions: 'Insert or update rows from one or more .csv files. Refer to the backup for file names and column names.',
    replaceNationalCodes: 'Remove and replace national codes from .csv',
    replaceNationalCodesInstructions: 'Replace all Dg Nat or Proc Nat codes for the selected national version from a .csv file.',
    dateFrom: 'From',
    dateTo: 'To',
    onlyActive: 'only active rows',
    includeUnused: 'include rows not in use',
    exportXlsx: 'export as .xlsx',
    exportRaw: 'export raw data',
    addCountry: {
        title: 'Add country',
        text1: 'A new country can be created if its prerequisite national data is uploaded.',
        uploadLinkText: 'Upload national basic classifications data',
        uploadLinkTextAfter: '(Dg Nat and Proc Nat) for the new country.',
        uploadLinkText2: 'Upload Drg Name and Mdc Name data',
        uploadLinkText2After: 'for the new country',
        text2: 'The country\'s code is added to nat_ver columns based on an existing country. All rows having the existing country in nat_ver will also get the new country\'s code. This includes Dg Plus and Proc Plus tables in MBC.',
        text3: 'Basic classifications should be imported from MBC after the country is added and any required corrections are made to the basic classifications in MBC.',
        text4: 'Rtc codes are initialized for the new country with English texts.',
        notFound: 'No new national data found',
        foundDataFor: 'Found national data for',
        countryDisplayName: 'Input the display name of the country',
        updateNatVerBasedOn: 'Select the country that should determine which rows have the new country\'s code in the nat_ver column',
    },
    refresh: 'Refresh',
    importCodes: 'Import codes from MBC',
    importCodesDisabledText: 'Importing is possible only in Combined version.',
    importCodesText: 'Import basic classifications from MBC tables. Existing diagnosis and procedure codes will be replaced.',
    importDiagnoses: 'Import diagnoses',
    importProcedures: 'Import procedures',
    noErrors: 'No errors',
    errors: 'Error(s)',
    noUnreadErrors: 'No unread errors',
    unreadErrors: 'unread error(s)',
    noNotifications: 'No notifications',
    notifications: 'Notification(s)',
    noUnreadNotifications: 'No unread notifications',
    unreadNotifications: 'unread notification(s)',
    onlyORZeroFeature: 'Procedure has only OR 0 feature',
    onlyZeroDGCAT: 'Diagnosis has only DGCAT 00M00',
    download: 'Download',
    upload: 'Upload',
    import: 'Import',
    dumps: 'Dumps',
    admin: 'Admin',
    config: 'Config',
    active: 'Active',
    summary: 'Summary',
    addGroupingPropertyName: 'Add Grouping propname',
    type: 'Type',
    plusCode: 'Plus code',
    procedure: 'procedure',
    diagnosis: 'diagnosis',
    code: 'Code',
    description: 'Description',
    drgNat: 'Drg Nat',
    shortName: 'Short name',
    country: 'Country',
    runTestCases: 'Run test cases',
    remove: 'Remove',
    saveText: 'Save',
    autoGenerated: 'Auto generated',
    NDMS: 'NDMS',
    drgLogicRules: 'DRG Logic Rules',
    searchFromCategory: 'Search from category',
    filterFromCategory: 'Filter from category',
    filterByVartype: 'Filter by vartype',
    filter: 'filter',
    search: 'search',
    sortBy: 'Sort by',
    logic: 'Logic',
    testCase: 'Test Case',
    procedureFeature: 'Procedure Features',
    diagnosisFeature: 'Diagnosis Features',
    addRow: 'Add Row',
    names: 'Names',
    procedure_proprety_name: 'Procedure Property Name',
    procedurePropertyName: 'Procedure Property Name',
    grouping_property_name: 'Grouping Property Name',
    drgName: 'Drg Name',
    drg_name: 'Drg Name',
    drgNameCombined: 'Drg Name Combined',
    drg_name_combined: 'Drg Name Combined',
    mdcName: 'Mdc Name',
    mdc_name: 'Mdc Name',
    mdcNameCombined: 'Mdc Name Combined',
    mdc_name_combined: 'Mdc Name Combined',
    complication_category_name: 'Complication Category Name',
    complicationCategoryName: 'Complication Category Name',
    diagnosis_category_name: 'Diagnosis Category Name',
    diagnosisCategoryName: 'Diagnosis Category Name',
    principal_diagnosis_property: 'Principal Diagnosis Property Name',
    principalDiagnosisProperty: 'Principal Diagnosis Property Name',
    dgNat: 'Dg Codes',
    dgPlus: 'Dg Plus Codes',
    procNat: 'Proc Codes',
    procPlus: 'Proc Plus Codes',
    codes: 'Codes',
    features: 'Features',
    results: 'results',
    error: 'Error',
    changedResults: 'Changed results',
    yes: 'Yes',
    no: 'No',
    prev: 'Prev',
    next: 'Next',
    selectCountry: 'Select country',
    selectTable: 'Select table(s)',
    selectCode: 'Select code to link',
    linkCode: 'Code to link',
    versionAsActive: 'version as active',
    groupingPropertyName: 'Grouping Property Name',
    more: 'More',
    less: 'Less',
    years: 'Years',
    days: 'Days',
    than: 'Than',
    addTestCase: '+ Add test case',
    cancel: 'Cancel',
    cancelAddition: 'Cancel addition',
    addRowAbove: 'Add row above',
    addRowBelow: 'Add row below',
    removeAllRows: 'Remove all rows',
    removeSelectedRows: 'Remove selected rows',
    cancelRemove: 'Cancel',
    moveRowsAbove: 'Move row(s) above',
    moveRowsBelow: 'Move row(s) below',
    grouperTestCases: 'Grouper Test Cases',
    copyTestResultsText: 'Copy results',
    complete: 'complete',
    and: 'and',
    timeTaken: 'Time taken',
    toImport: 'to import',
    toValidateLogicRows: 'to validate logic rows',
    checkingInBackground: 'Tables are still being validated in the background. Error and notification columns should be updated in a few minutes.',
    logicRowImported: 'logic row was imported',
    logicRowsImported: 'logic rows were imported',
    logicRowUpdated: 'logic row was updated during validations',
    logicRowsUpdated: 'logic rows were updated during validations',
    logicRowErroneous: 'logic row contains error',
    logicRowsErroneous: 'logic rows contain errors',
    textNat: 'text_nat',
    selectInactivationYear: 'Select inactivation year',
    none: 'None',
    addNatVer: 'add missing countries to nat_ver',
    removeNatVer: 'remove unused countries from nat_ver',
    clear: 'Clear',
    natVer: 'nat_ver',
    text: 'text',
    mbc: {
        headerTitle: 'MBC',
        title: 'Mbc',
        dgNat: 'Dg Codes',
        dgPlus: 'Dg Plus Codes',
        procNat: 'Proc codes',
        procPlus: 'Proc Plus Codes',
        dgNatOneColumnName: 'dg1',
        dgNatTwoColumnName: 'dg2',
        natVer: 'nat_ver',
        plusProc: 'plus_proc',
        plusDg: 'plus_dg',
        procOneColumnName: 'proc1',
        codesDropDownTitle: 'Codes',
        atcNat: 'Atc codes',
        atcPlus: 'Atc Plus Codes',
    },
    validateTables: 'Validate tables',
    validateTablesText: 'Validate all tables.',
};
