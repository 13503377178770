import React from 'react';
import {func, string, any} from 'prop-types';
import './styles.scss';

export default function InputLine({icon, ...props}) {
    return (
        <div className="input-line-component" >
            <input {...props} onChange={(e) => props.onChange(e.target.value)}/>
            <img src={icon} alt=""/>
        </div>
    );
}

InputLine.propTypes = {
    onChange: func.isRequired,
    value: string.isRequired,
    icon: any,
};
