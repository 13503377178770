import React from 'react';
import {connect} from 'react-redux';
import FlipMove from 'react-flip-move';
import exclamationMark from '../../../images/mark.svg';
import Close from '../../Close';
import {doToggleShowErrorNotifications, doDiscardErrorNotification} from '../../../store/errorNotification';
import {actionsWrapper} from '../../../store/resources/reducerUtils';
import store from '../../../store';
import '../Notifications/styles.scss';
import './styles.scss';

const formatSubject = (subject) => subject.replace(/^do/, '').replace(/([A-Z])/g, ' $1').trim();

class ErrorNotifications extends React.Component {
    render() {
        return (
            <div>
                <div className={this.getClassName()} onClick={this.props.doToggleShowErrorNotifications}>
                    <p id="errors" className="notification-status-summary">{this.getNotificationsSummary()}</p>
                    <img className="exclamation-mark-icon" src={exclamationMark}/>
                </div>
                {this.props.open && this.renderNotificationContent()}
            </div>
        );
    }

    getNotificationsSummary() {
        const {content, t} = this.props;
        if (content.length) {
            const unReadCount = content.filter((n) => !n.seen).length;
            if (unReadCount) {
                return `${unReadCount} ${t.errors}`;
            }
            return t.noUnreadErrors;
        }
        return t.noErrors;
    }

    getClassName() {
        const {content} = this.props;
        let className = 'notifications';
        if (content.some((n) => !n.seen)) {
            className += ' errors-need-attention';
        } else if (content.length) {
            className += ' notifications-seen';
        }
        return className;
    }

    renderNotificationContent() {
        const {content, doDiscardErrorNotification} = this.props;
        return (
            <div className="notification-list-position">
                <FlipMove
                  className="notifications-list"
                  duration={150}
                  staggerDelayBy={0}
                  maintainContainerHeight
                  enterAnimation="fade"
                  leaveAnimation="fade">
                    {content.map(({id, seen, subject, error}) => (
                        <div key={id} className={`notification-description-item${seen ? '' : ' notification-description-item-unseen'}`}>
                            <Close onClick={() => doDiscardErrorNotification(id)}/>
                            <p>Error: {error}</p>
                            {subject && <p>Subject: {formatSubject(subject)}</p>}
                        </div>
                    ))}
                </FlipMove>
            </div>
        );
    }
}
export default connect(
    ({locales: {t}, errorNotification}) => ({t, ...errorNotification}),
    {...actionsWrapper(store, {doToggleShowErrorNotifications, doDiscardErrorNotification})},
)(ErrorNotifications);
