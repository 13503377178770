import React from 'react';
import {func, string} from 'prop-types';
import {connect} from 'react-redux';

const VartypeMenu = ({vartypes, value, onChange, onClose}) => (
    <div className="cell-menu vartype-menu" onClick={onClose}>
        {vartypes.map((opt) => (
            <div
              key={opt}
              className={value === opt ? 'cell-menu-item-selected' : 'cell-menu-item'}
              onMouseDown={() => onChange({value: opt})}>{opt}
            </div>
                ))}
    </div>
);

VartypeMenu.propTypes = {
    value: string,
    onChange: func.isRequired,
};

export default connect((state, {domain}) => {
    if (domain === 'diagnosisFeature') {
        return {vartypes: state.diagnosisRelations.vartypes};
    } else if (domain === 'procedureFeature') {
        return {vartypes: state.procedureRelations.vartypes};
    }
    throw new Error(`Unknown domain ${domain}`);
})(VartypeMenu);
