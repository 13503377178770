/* eslint-disable max-len */
import React from 'react';
import SpinnerDefault from 'react-spinkit';
import {bool} from 'prop-types';
import './styles.scss';

export default class Spinner extends React.Component {
    state = {gone: this.props.hidden};

    timeout;

    render() {
        const {className, hidden, small, light} = this.props;
        const {gone} = this.state;
        return (
            <SpinnerDefault
              className={`spinner-default${small ? ' spinner-small' : ''}${light ? ' spinner-light' : ''}${!hidden ? ' spinner-visible' : ''}${className ? (` ${className}`) : ''}${gone ? ' spinner-gone' : ''}`}/>
        );
    }

    componentDidUpdate(prevProps) {
        if (this.props.hidden !== prevProps.hidden) {
            clearTimeout(this.timeout);
            if (this.props.hidden) {
                this.timeout = setTimeout(() => this.setState({gone: true}), 400);
            } else {
                this.setState({gone: false});
            }
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }
}

Spinner.propTypes = {
    hidden: bool,
    small: bool,
};
