import React, { useEffect } from 'react';
import {Routes, Route, Navigate, useLocation} from 'react-router-dom';
import {connect} from 'react-redux';
import Config from '../Scenes/Config';
import DgNat from '../Scenes/DgNat';
import DgPlus from '../Scenes/DgPlus';
import DiagnosisFeature from '../Scenes/DiagnosisFeature';
import Logic from '../Scenes/Logic';
import Names from '../Scenes/Names';
import ProcNat from '../Scenes/ProcNat';
import ProcPlus from '../Scenes/ProcPlus';
import ProcedureFeature from '../Scenes/ProcedureFeature';
import TestCase from '../Scenes/TestCase';
import MbcDgNat from '../Scenes/Mbc/DgNat';
import MbcDgPlus from '../Scenes/Mbc/DgPlus';
import MbcProcNat from '../Scenes/Mbc/ProcNat';
import MbcProcPlus from '../Scenes/Mbc/ProcPlus';
import MbcAtcNat from '../Scenes/Mbc/AtcNat';
import MbcAtcPlus from '../Scenes/Mbc/AtcPlus';
import {notifyRouteChange, doLongPollMaintenanceStatus} from '../store/meta';
import {actionsWrapper} from '../store/resources/reducerUtils';
import store from '../store';

const sleep = (ms) => new Promise((res) => {
    setTimeout(res, ms);
});

const Main = ({notifyRouteChange, doLongPollMaintenanceStatus}) => {
    const location = useLocation();
    const redirectPath = location.pathname.startsWith('/mbc') ? '/mbc/dg_nat' : '/logic';

    useEffect(() => {
        notifyRouteChange(location.pathname);
    }, [location, notifyRouteChange]);

    useEffect(() => {
        const pollStatus = async () => {
            await doLongPollMaintenanceStatus();
            await sleep(2000);
            pollStatus();
        };
        pollStatus();
    }, [doLongPollMaintenanceStatus]);

    return (
        <Routes>
            <Route path="/logic" element={<Logic />} />
            <Route path="/test_case" element={<TestCase />} />
            <Route path="/codes/dg_nat" element={<DgNat />} />
            <Route path="/codes/dg_plus" element={<DgPlus />} />
            <Route path="/codes/proc_nat" element={<ProcNat />} />
            <Route path="/codes/proc_plus" element={<ProcPlus />} />
            <Route path="/features/procedure" element={<ProcedureFeature />} />
            <Route path="/features/diagnosis" element={<DiagnosisFeature />} />
            <Route path="/codes/mbc_dg_nat" element={<MbcDgNat />} />
            <Route path="/codes/mbc_dg_plus" element={<MbcDgPlus />} />
            <Route path="/codes/mbc_proc_nat" element={<MbcProcNat />} />
            <Route path="/codes/mbc_proc_plus" element={<MbcProcPlus />} />
            <Route path="/codes/mbc_atc_nat" element={<MbcAtcNat />} />
            <Route path="/codes/mbc_atc_plus" element={<MbcAtcPlus />} />
            <Route path="/mbc/dg_nat" element={<MbcDgNat />} />
            <Route path="/mbc/dg_plus" element={<MbcDgPlus />} />
            <Route path="/mbc/proc_nat" element={<MbcProcNat />} />
            <Route path="/mbc/proc_plus" element={<MbcProcPlus />} />
            <Route path="/mbc/atc_nat" element={<MbcAtcNat />} />
            <Route path="/mbc/atc_plus" element={<MbcAtcPlus />} />
            <Route path="/names/*" element={<Names />} />
            <Route path="/config/*" element={<Config />} />
            <Route path="/mbc/config/*" element={<Config mbc />} />
            <Route path="*" element={<Navigate to={redirectPath} />}/>
        </Routes>
    );
};

export default connect(
    null,
    {...actionsWrapper(store, {notifyRouteChange, doLongPollMaintenanceStatus})},
)(Main);
