import React from 'react';
import {connect} from 'react-redux';
import CommonGridScene from './CommonGridScene';
import {removeStringFromKeys} from '../common/utils';
import {actions, selectors} from '../store/procPlus';
import {actionsWrapper, selectorsWrapper} from '../store/resources/reducerUtils';
import store from '../store';

const domain = 'procPlus';
const ProcPlus = (props) => {
    const viewProps = removeStringFromKeys(props, domain);

    return (
        <CommonGridScene
          domain={domain}
          readOnly
          identifier="proc_plus"
          columnTypes={{
            errors: 'Errors',
          }}
          {...viewProps}/>
    );
};

export default connect(
    () => ({...selectorsWrapper(store, selectors)}),
    {...actionsWrapper(store, actions)},
)(ProcPlus);
