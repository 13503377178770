/* eslint-disable import/prefer-default-export */
export const styles = {
    container: (provided) => ({
        ...provided,
        fontSize: '12px',
        minHeight: '26px',
    }),
    control: (provided) => ({
        ...provided,
        minHeight: '26px',
        alignItems: 'normal',
    }),
    valueContainer: (provided) => ({
        ...provided,
        minHeight: '26px',
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        minHeight: '26px',
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none',
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        padding: '0',
        marginRight: '4px',
        color: '#149DB8',
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    }),
    clearIndicator: (provided) => ({
        ...provided,
        padding: '0',
        color: '#C1C1C1',
        '&:hover': {
            color: '#4A4A4A',
        },
    }),
    multiValue: (provided) => ({
        ...provided,
        color: '#149DB8',
        border: '1px solid #149DB8',
        backgroundColor: 'transparent',
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        color: '#149DB8',
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        color: '#C1C1C1',
        '&:hover': {
            backgroundColor: 'transparent',
            color: '#4A4A4A',
        },
    }),
    option: (provided) => ({
        ...provided,
        height: 30,
    }),
    noOptionsMessage: (provided) => ({
        ...provided,
        textAlign: 'left',
    }),
};
