import React, {useState, useEffect} from 'react';
import _ from 'lodash';
import {object, bool} from 'prop-types';
import Button from '../Button';
import './styles.scss';

function RemoveRowsButton({t, domain, listIsfiltered, testCaseListIsfiltered, selectedRows, doRemoveSelectedRows}) {
    const [confirmation, setConfirmation] = useState(false);
    const [text, setText] = useState('');
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        setConfirmation(false);
    }, [selectedRows]);

    useEffect(() => {
        if (domain === 'testCase') {
            setText(selectedRows['*'] ? t.removeAllRows : t.removeSelectedRows);
            setDisabled((testCaseListIsfiltered && selectedRows['*']) || _.isEmpty(selectedRows));
        } else if (domain.includes('Feature')) {
            setText(selectedRows['*'] ? t.removeAllRows : t.removeSelectedRows);
            setDisabled((listIsfiltered && selectedRows['*']) || _.isEmpty(selectedRows));
        } else {
            setText(selectedRows['*'] && !listIsfiltered ? t.removeAllRows : t.removeSelectedRows);
            setDisabled(_.isEmpty(selectedRows));
        }
    }, [selectedRows, listIsfiltered, testCaseListIsfiltered, domain, t.removeAllRows, t.removeSelectedRows]);

    const timeoutConfirmation = () => setTimeout(() => setConfirmation(false), 5000);

    const removeRows = () => {
        doRemoveSelectedRows(selectedRows);
        setConfirmation(false);
    };

    return (
        <div>
            {doRemoveSelectedRows && !confirmation
                && <Button text={text} disabled={disabled} onClick={() => setConfirmation(true)} />}
            {confirmation
                && (
                    <div>
                        <Button className="button-remove" text={text} onClick={removeRows} onMouseLeave={timeoutConfirmation} />
                        <Button text={t.cancelRemove} onClick={() => setConfirmation(false)}/>
                    </div>
                )}
        </div>
    );
}

RemoveRowsButton.propTypes = {
    listIsfiltered: bool,
    testCaseListIsfiltered: bool,
    selectedRows: object,
};

export default RemoveRowsButton;
