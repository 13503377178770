import React from 'react';
import {connect} from 'react-redux';
import CommonGridScene from '../CommonGridScene';
import {removeStringFromKeys} from '../../common/utils';
import {actions, selectors} from '../../store/diagnosisCategoryName';
import {actionsWrapper, selectorsWrapper} from '../../store/resources/reducerUtils';
import store from '../../store';

const domain = 'diagnosisCategoryName';
const DiagnosisCategoryName = (props) => {
    const viewProps = removeStringFromKeys(props, domain);

    return (
        <CommonGridScene
          domain={domain}
          identifier="dgcat"
          columnTypes={{
            active: 'Boolean',
            errors: 'Errors',
            // notifications: 'Notifications',
          }}
          enableAddRow
          enableRemoveRow
          newRowEditables={['dgcat']}
          {...viewProps}/>
    );
};

export default connect(
    () => ({...selectorsWrapper(store, selectors)}),
    {...actionsWrapper(store, actions)},
)(DiagnosisCategoryName);
