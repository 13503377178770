import {doFetchLogicContent, CLEAR_ROWS as CLEAR_LOGIC_ROWS} from './logic';
import {actions as drgNameOptionActions} from './drgNameOptions';
import {actions as mdcNameOptionActions} from './mdcNameOptions';
import {actions as mbcDgNatOptionsOptionActions} from './mbc/dgNatOptions';
import {actions as mbcDgPlusOptionActions} from './mbc/dgPlusOptions';
import {actions as mbcProcNatListOptionActions} from './mbc/procNatOptions';
import {actions as mbcProcPlusOptionActions} from './mbc/procPlusOptions';
import {actions as mbcAtcNatListOptionActions} from './mbc/atcNatOptions';
import {actions as mbcAtcPlusOptionActions} from './mbc/atcPlusOptions';
import {actions as procedureRelationsActions} from './procedureRelations';
import {actions as dgNatActions, types as dgNatTypes} from './dgNat';
import {actions as dgPlusActions, types as dgPlusTypes} from './dgPlus';
import {actions as procNatActions, types as procNatTypes} from './procNat';
import {actions as procPlusActions, types as procPlusTypes} from './procPlus';
import {actions as complicationCategoryNameActions, types as complicationCategoryNameTypes} from './complicationCategoryName';
import {actions as groupingPropertyNameActions, types as groupingPropertyNameTypes} from './groupingPropertyName';
import {actions as diagnosisCategoryNameActions, types as diagnosisCategoryNameTypes} from './diagnosisCategoryName';
import {actions as procedurePropertyNameActions, types as procedurePropertyNameTypes} from './procedurePropertyName';
import {actions as principalDiagnosisPropertyActions, types as principalDiagnosisPropertyTypes} from './principalDiagnosisProperty';
import {actions as drgNameActions, types as drgNameTypes} from './drgName';
import {actions as drgNameCombinedActions, types as drgNameCombinedTypes} from './drgNameCombined';
import {actions as mdcNameActions, types as mdcNameTypes} from './mdcName';
import {actions as mdcNameCombinedActions, types as mdcNameCombinedTypes} from './mdcNameCombined';
import {actions as diagnosisFeatureActions, types as diagnosisFeatureTypes} from './diagnosisFeature';
import {actions as procedureFeatureActions, types as procedureFeatureTypes} from './procedureFeature';
import {actions as diagnosisRelationsActions} from './diagnosisRelations';
import {actions as mbcDgPlusActions, types as mbcDgPlusTypes} from './mbc/dgPlus';
import {actions as mbcDgNatActions, types as mbcDgNatTypes} from './mbc/dgNat';
import {actions as mbcProcNatActions, types as mbcProcNatTypes} from './mbc/procNat';
import {actions as mbcProcPlusActions, types as mbcProcPlusTypes} from './mbc/procPlus';
import {actions as mbcAtcNatActions, types as mbcAtcNatTypes} from './mbc/atcNat';
import {actions as mbcAtcPlusActions, types as mbcAtcPlusTypes} from './mbc/atcPlus';
import {CLEAR_ROWS as CLEAR_TESTCASE_ROWS, doFetchTestCaseContent} from './grouperTestCase';
import {SELECTED_LANGUAGE, SELECTED_COUNTRY} from '../common/constants';
import en from './resources/languages/en';

const languages = {EN: en};

// TYPES
export const CHANGE_LANGUAGE = 'LOCALE::CHANGE_LANGUAGE';
export const CHANGE_COUNTRY = 'LOCALE::CHANGE_COUNTRY';

// INITIAL STATE
const defaultLocale = localStorage.getItem(SELECTED_LANGUAGE) || 'EN';
const country = localStorage.getItem(SELECTED_COUNTRY) || 'com';

// REDUCER
export default function locales(state = {t: languages[defaultLocale], country}, {type, payload}) {
    switch (type) {
    case CHANGE_LANGUAGE:
        return {...state, t: locales[payload]};
    case CHANGE_COUNTRY:
        return {...state, country: payload};
    default:
        return state;
    }
}

// ACTION CREATORS
export function doChangeLanguage(language) {
    return function changeLanguage(dispatch) {
        dispatch({type: CHANGE_LANGUAGE, payload: language});
        localStorage.setItem(SELECTED_LANGUAGE, language);
    };
}

export function doChangeCountry(country) {
    return function changeCountry(dispatch, getState) {
        dispatch({type: CHANGE_COUNTRY, payload: country });
        const {activity} = getState().meta;

        console.log(`change to country: ${country}`);
        console.log(`current activity: ${activity}`);

        switch (activity) {
        case 'logic': {
            dispatch({type: CLEAR_LOGIC_ROWS});
            dispatch(doFetchLogicContent(0));
            break;
        } case 'testCase': {
            dispatch({type: CLEAR_TESTCASE_ROWS});
            dispatch(doFetchTestCaseContent(0));
            if (country === 'com') {
                dispatch(diagnosisRelationsActions.doFetchDiagnosisRelationCodes());
                dispatch(procedureRelationsActions.doFetchProcedureRelationCodes());
            } else {
                dispatch(diagnosisRelationsActions.doFetchDiagnosisRelationNatCodes());
                dispatch(procedureRelationsActions.doFetchProcedureRelationNatCodes());
            }
            break;
        } case 'dgNat': {
            dispatch({type: dgNatTypes.CLEAR_ROWS});
            dispatch(dgNatActions.doFetchDgNatContent(0));
            break;
        } case 'procNat': {
            dispatch({type: procNatTypes.CLEAR_ROWS});
            dispatch(procNatActions.doFetchProcNatContent(0));
            break;
        } case 'complicationCategoryName': {
            dispatch({type: complicationCategoryNameTypes.CLEAR_ROWS});
            dispatch(complicationCategoryNameActions.doFetchComplicationCategoryNameContent(0));
            break;
        } case 'groupingPropertyName': {
            dispatch({type: groupingPropertyNameTypes.CLEAR_ROWS});
            dispatch(groupingPropertyNameActions.doFetchGroupingPropertyNameContent(0));
            break;
        } case 'diagnosisCategoryName': {
            dispatch({type: diagnosisCategoryNameTypes.CLEAR_ROWS});
            dispatch(diagnosisCategoryNameActions.doFetchDiagnosisCategoryNameContent(0));
            break;
        } case 'procedurePropertyName': {
            dispatch({type: procedurePropertyNameTypes.CLEAR_ROWS});
            dispatch(procedurePropertyNameActions.doFetchProcedurePropertyNameContent(0));
            break;
        } case 'principalDiagnosisProperty': {
            dispatch({type: principalDiagnosisPropertyTypes.CLEAR_ROWS});
            dispatch(principalDiagnosisPropertyActions.doFetchPrincipalDiagnosisPropertyContent(0));
            break;
        } case 'drgName': {
            dispatch({type: drgNameTypes.CLEAR_ROWS});
            dispatch(drgNameActions.doFetchDrgNameContent(0));
            dispatch(drgNameOptionActions.doFetchDrgNameOptions());
            break;
        } case 'drgNameCombined': {
            dispatch({type: drgNameCombinedTypes.CLEAR_ROWS});
            dispatch(drgNameCombinedActions.doFetchDrgNameCombinedContent(0));
            break;
        } case 'mdcName': {
            dispatch({type: mdcNameTypes.CLEAR_ROWS});
            dispatch(mdcNameActions.doFetchMdcNameContent(0));
            dispatch(mdcNameOptionActions.doFetchMdcNameOptions());
            break;
        } case 'mdcNameCombined': {
            dispatch({type: mdcNameCombinedTypes.CLEAR_ROWS});
            dispatch(mdcNameCombinedActions.doFetchMdcNameCombinedContent(0));
            break;
        } case 'diagnosisFeatures': {
            dispatch({type: diagnosisFeatureTypes.CLEAR_ROWS});
            dispatch(diagnosisFeatureActions.doFetchDiagnosisFeatureContent(0));
            break;
        } case 'dgPlus': {
            dispatch({type: dgPlusTypes.CLEAR_ROWS});
            dispatch(dgPlusActions.doFetchDgPlusContent(0));
            dispatch(mbcDgNatOptionsOptionActions.doFetchDgNatOptions());
            break;
        } case 'procedureFeatures': {
            dispatch({type: procedureFeatureTypes.CLEAR_ROWS});
            dispatch(procedureFeatureActions.doFetchProcedureFeatureContent(0));
            break;
        } case 'procPlus': {
            dispatch({type: procPlusTypes.CLEAR_ROWS});
            dispatch(procPlusActions.doFetchProcPlusContent(0));
            break;
        } case 'mbcDgNat':
        case 'ndmsMbcDgNat': {
            dispatch({type: mbcDgNatTypes.CLEAR_ROWS});
            dispatch(mbcDgNatActions.doFetchMbcDgNatContent(0));
            dispatch(mbcDgPlusOptionActions.doFetchDgPlusOptions(0));
            break;
        } case 'mbcDgPlus':
        case 'ndmsMbcDgPlus': {
            dispatch({type: mbcDgPlusTypes.CLEAR_ROWS});
            dispatch(mbcDgPlusActions.doFetchMbcDgPlusContent(0));
            dispatch(mbcDgNatOptionsOptionActions.doFetchDgNatOptions());
            break;
        } case 'mbcProcNat':
        case 'ndmsMbcProcNat': {
            dispatch({type: mbcProcNatTypes.CLEAR_ROWS});
            dispatch(mbcProcNatActions.doFetchMbcProcNatContent(0));
            dispatch(mbcProcPlusOptionActions.doFetchProcPlusOptions(0));
            break;
        } case 'mbcProcPlus':
        case 'ndmsMbcProcPlus': {
            dispatch({type: mbcProcPlusTypes.CLEAR_ROWS});
            dispatch(mbcProcPlusActions.doFetchMbcProcPlusContent(0));
            dispatch(mbcProcNatListOptionActions.doFetchProcNatOptions(0));
            break;
        } case 'mbcAtcNat':
        case 'ndmsMbcAtcNat': {
            dispatch({type: mbcAtcNatTypes.CLEAR_ROWS});
            dispatch(mbcAtcNatActions.doFetchMbcAtcNatContent(0));
            dispatch(mbcAtcPlusOptionActions.doFetchAtcPlusOptions(0));
            break;
        } case 'mbcAtcPlus':
        case 'ndmsMbcAtcPlus': {
            dispatch({type: mbcAtcPlusTypes.CLEAR_ROWS});
            dispatch(mbcAtcPlusActions.doFetchMbcAtcPlusContent(0));
            dispatch(mbcAtcNatListOptionActions.doFetchAtcNatOptions(0));
            break;
        }
        default: break;
        }
        localStorage.setItem(SELECTED_COUNTRY, country);
    };
}

export const actions = {
    doChangeLanguage, doChangeCountry,
};
