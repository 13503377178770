import {Routes, Route} from 'react-router-dom';
import React from 'react';
import Login from './Login';

const Auth = () => (
    <Routes>
        <Route path="login" element={<Login />}/>
    </Routes>
);

export default Auth;
