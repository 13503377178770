/* eslint-disable max-len */
import React from 'react';
import {func, string, number, bool, any} from 'prop-types';
import Button from '../../../Button';
import {
    BooleanMenu,
    DischargeModeMenu,
    VartypeMenu,
    VarvalMenu,
    DgPlusMenu,
    DgCodeMenu,
    ProcPlusMenu,
    ProcCodeMenu,
    DrgNameMenu,
    MdcNameMenu,
} from '../../../Menus';
import './styles.scss';

export default class StickyTableCell extends React.PureComponent {
    state = {showMenuOnFocus: true};

    render() {
        const {width, placeholder, value, onFocus, selectedCode, linkable} = this.props;
        const finalValue = value || '';
        return (
            <>
                <span
                  className="sticky-cell-container"
                  style={{width: `${width}px`}}
                  onFocus={onFocus}
                  onKeyDown={this.onKeyPress}
                  onClick={this.onClick}>
                    <input
                      style={{width: `${width}px`}}
                      value={finalValue}
                      placeholder={linkable ? '' : placeholder}
                      className={this.className}
                      onChange={this.handleInputChange} />
                    {this.renderContextMenu()}
                </span>
                {linkable && (
                <Button
                  className="sticky-cell-link-button"
                  text={finalValue !== selectedCode ? '+' : ''}
                  onClick={this.handleLink}
                  disabled={finalValue === selectedCode}/>
                )}
            </>
        );
    }

    onKeyPress = ({key}) => {
        const {type, onChange, value} = this.props;
        const finalType = type || 'text';
        const finalValue = value || '';
        if (finalType === 'Boolean') {
            switch (key) {
            case 'ArrowDown':
            case 'ArrowUp': onChange(!finalValue);
            // no default
            }
        }
    };

    handleInputChange = (e) => {
        const {type, onChange, typingDisabled} = this.props;
        const finalType = type || 'text';
        if (typingDisabled || !onChange) { return; }

        if (finalType === 'Integer') {
            const value = parseInt(e.target.value);
            if (!Number.isNaN(value)) {
                onChange({value});
            }
        } else if (type === 'Float') {
            const value = parseFloat(e.target.value);
            if (!Number.isNaN(value)) {
                onChange({value});
            }
        } else {
            onChange({value: e.target.value});
        }
    };

    renderContextMenu() {
        const {column, focused, disabled, value, type, domain, allowEmpty} = this.props;
        const {showMenuOnFocus} = this.state;
        const finalType = type || 'text';
        const finalValue = value || '';

        if (focused && showMenuOnFocus && !disabled) {
            switch (finalType) {
            case 'Boolean':
                return <BooleanMenu onChange={this.handleClickChange} value={typeof (finalValue) === 'boolean' && finalValue} style={{ marginTop: '2px' }}/>;
            case 'BooleanWithNull':
                return <BooleanMenu onChange={this.handleClickChange} value={typeof (finalValue) === 'boolean' ? finalValue : null} style={{ marginTop: '2px' }} allowNull />;
            case 'DischargeMode':
                return <DischargeModeMenu onChange={this.handleClickChange} value={finalValue} style={{ marginTop: '2px' }}/>;
            case 'Vartype':
                return <VartypeMenu domain={domain} onChange={this.handleClickChange} value={finalValue} style={{marginTop: '2px'}} />;
            case 'DgPlus':
                if (domain === 'testCase') {
                    return <DgCodeMenu newRow onChange={this.handleClickChange} onClose={this.hideContextMenu} value={finalValue} label={column} allowEmpty={allowEmpty} style={{top: '32px', left: '0'}} />;
                }
                return <DgPlusMenu onChange={this.handleClickChange} onClose={this.hideContextMenu} value={finalValue} label={column} allowEmpty={allowEmpty} style={{top: '32px', left: '0'}} />;
            case 'ProcPlus':
                if (domain === 'testCase') {
                    return <ProcCodeMenu newRow onChange={this.handleClickChange} onClose={this.hideContextMenu} value={finalValue} label={column} allowEmpty={allowEmpty} style={{top: '32px', left: '0'}} />;
                }
                return <ProcPlusMenu onChange={this.handleClickChange} onClose={this.hideContextMenu} value={finalValue} label={column} allowEmpty={allowEmpty} style={{top: '32px', left: '0'}} />;
            case 'Varval':
                return <VarvalMenu domain={domain} onChange={this.handleClickChange} useCase="add" value={finalValue} allowEmpty={allowEmpty} style={{top: '32px', left: '0'}} onClose={this.hideContextMenu}/>;
            case 'DrgName':
                if (domain !== 'testcase') {
                    return <DrgNameMenu id={finalValue} label={column} onChange={this.handleClickChange} allowEmpty={allowEmpty} style={{top: '32px', left: '0'}} onClose={this.hideContextMenu} />;
                }
                break;
            case 'MdcName':
                return <MdcNameMenu id={finalValue} onChange={this.handleClickChange} allowEmpty={allowEmpty} style={{top: '32px', left: '0'}} onClose={this.hideContextMenu} />;
            default:
                break;
            }
        }
    }

    handleClickChange = (value) => {
        const {onChange} = this.props;
        if (onChange) {
            onChange(value);
        }
    };

    hideContextMenu = () => {
        this.setState({showMenuOnFocus: false});
    };

    componentDidUpdate(prevProps, prevState) {
        if (!prevState.showMenuOnFocus && this.props.focused !== prevProps.focused) {
            this.setState({showMenuOnFocus: true});
        }
    }

    get className() {
        const {disabled, typingDisabled, linkable} = this.props;
        let className = 'sticky-cell-input';
        if (disabled) {
            className += ' sticky-cell-input-disabled';
        }
        if (disabled || typingDisabled) {
            className += ' sticky-cell-input-notextcursor';
        }
        if (linkable) {
            className += ' linkable';
        }
        return className;
    }

    onClick = () => {
        const {onClick} = this.props;
        if (onClick) {
            onClick();
        }
    };

    handleLink = () => {
        const {onChange, selectedCode} = this.props;
        if (onChange) {
            onChange({ value: selectedCode });
        }
    };
}

StickyTableCell.propTypes = {
    width: number.isRequired,
    placeholder: string.isRequired,
    disabled: bool.isRequired,
    typingDisabled: bool,
    onClick: func,
    value: any,
    onChange: func,
    type: string,
    domain: string.isRequired,
    focused: bool,
    onFocus: func.isRequired,
};
