import React, {useState, useEffect} from 'react';
import CheckBox from '../Checkbox';
import './styles.scss';

const UploadOptions = ({t, setOptions}) => {
    const [addNatVer, setAddNatVer] = useState(false);
    const [removeNatVer, setRemoveNatVer] = useState(false);

    useEffect(() => {
        setOptions({addNatVer, removeNatVer});
    }, [addNatVer, removeNatVer, setOptions]);

    return (
        <div className="upload-options">
            <CheckBox label={t.addNatVer} checked={addNatVer} onChange={() => setAddNatVer(!addNatVer)}/>
            <CheckBox label={t.removeNatVer} checked={removeNatVer} onChange={() => setRemoveNatVer(!removeNatVer)}/>
        </div>
    );
};

export default UploadOptions;
