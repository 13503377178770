import React, {useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {arrayOf, shape, string, bool} from 'prop-types';
import './styles.scss';
import { createResolveLatestIf } from '../../../common/utils';
import useIsMounted from '../../../hooks/useIsMounted';

export const NavigationLink = ({to, text}) => {
    const location = useLocation();
    return (
        <Link to={to} className={`navigation-link${location.pathname === to ? ' active-link' : ''}`}><div className="vertical-center"><span>{text}</span></div></Link>
    );
};

NavigationLink.propTypes = {
    to: string,
    text: string,
};

export const NavigationDropDown = ({childRoutes, fullRedirects, small, text, base}) => {
    const [open, setOpen] = useState(false);
    const location = useLocation();
    const isMounted = useIsMounted();
    const resolveLatestIf = createResolveLatestIf();
    const debounce = (delay) => resolveLatestIf({initialDelay: delay, cancelOn: () => !isMounted()});
    const childRoutePaths = childRoutes.map(({to}) => base + to);

    const onMouseOver = () => {
        debounce(0);
        setOpen(true);
    };

    const onMouseOut = () => {
        debounce(0);
        setOpen(false);
    };

    return (
        <div
          className={`navigation-drop-down${childRoutePaths.includes(location.pathname) ? ' navigation-drop-down-active' : ''}${small ? ' navigation-drop-down-small' : ''}`}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          onClick={() => setOpen(!open)}>
            <div className="vertical-center ">
                <a className="drop-down-domain">{text}</a>
                {open && (
                    <div className="drop-down-navigation-links">
                        {childRoutes.map(({to, title}) => <NavigationLink key={to} text={title} to={base + to}/>)}
                        {fullRedirects && fullRedirects.map(({to, title}) => <NavigationLink key={to} text={title} to={to}/>)}
                    </div>
                )}
            </div>
        </div>
    );
};

NavigationDropDown.propTypes = {
    base: string.isRequired,
    text: string.isRequired,
    small: bool,
    childRoutes: arrayOf(shape({to: string, text: string})).isRequired,
    fullRedirects: arrayOf(shape({to: string, text: string})),
};
