/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {connect} from 'react-redux';
import {func, bool, string, object, array} from 'prop-types';
import DataTable from '../components/DataTable';
import TopMenu from '../components/TopMenu';
import ContainerTitle from '../components/ContainerTitle';
import StickyTableRow from '../components/DataTable/StickyTableRow';
import {
    BooleanMenu,
    VarvalMenu,
    VartypeMenu,
    DgPlusMenu,
    ProcPlusMenu,
    MdcNameMenu,
    DrgNameMenu,
    RightClickMenu,
    DrgNameListMenu,
    MdcNameListMenu,
    ErrorsAndNotificationsListMenu,
    DgNatListMenu,
    ProcNatListMenu,
    InactivatedAtMenu,
} from '../components/Menus';
import NameMenuItem from '../components/TopMenu/NameMenuItem';
import Spinner from '../components/Spinner';

class CommonGridScene extends React.Component {
    render() {
        const {
            t,
            domain,
            renameColumns,
            doFetchContent,
            doUpdateDimensions,
            doUpdateOrder,
            doUpdateFilterFields,
            doSetSearchText,
            doSetSearchFields,
            moveSearchResultPointer,
            doSetFilterText,
            toggleRowSelection,
            doSetFocusedItem,
            doUpdateColumn,
            doToggleFieldOrder,
            doRemoveSelectedRows,
            clearRowFocus,
            doSetActiveFilter,
            doSetErrorsFilter,
            // doSetNotificationsFilter,
            doSetExclusiveToORZeroFilter,
            doSetExclusiveToDGCATZeroFilter,
            enableAddRow,
            gridArrowRight,
            gridArrowLeft,
            gridArrowUp,
            gridArrowDown,
            setGridCursorLocation,
            removePendingRow,
            doInitPendingRow,
            selectAllRows,
            clearRowSelections,
            getSelectedRows,
            identifier,
            columnMenu,
            gridTab,
            country,
            doSetVartypeFilter,
            storableColumn,
            linkableColumns,
            doStoreCodes,
            doSelectLinkableCode,
            doLinkCode,
            doLinkAllCodes,
            enableRowSelect,
            customTitle,
            readOnly,
            reduxDomain: {
                loadingContent,
                rows,
                selectedRows,
                spinner,
                cursor,
                pendingRow,
                dimensions,
                nonEditableRowRules,
                nonSortableFields,
                headers,
                order,
                filter,
                rowHighlight,
                search,
                searchResults,
                activeFilter,
                errorsFilter,
                // notificationsFilter,
                exclusiveToORZeroFilter,
                exclusiveToDGCATZeroFilter,
                selectedCode,
            },
        } = this.props;
        const nonEditableFields = this.props.nonEditableFields || this.props.reduxDomain.nonEditableFields;
        const searchFilterOptions = headers.filter((it) => ['created_at', 'updated_at', 'inactivated_at'].indexOf(it) === -1);
        const sortOrderOptions = headers.filter((it) => !nonSortableFields[it]);

        const finalReadOnly = readOnly || false;
        const finalEnableAddRow = enableAddRow || false;
        const finalEnableRowSelect = enableRowSelect || false;

        return (
            <div className="scene-main-content">
                <ContainerTitle text={customTitle || t.titles[domain]} loading={loadingContent}/>
                <Spinner hidden={!spinner} />
                <TopMenu
                  t={t}
                  domain={domain}
                  activeFilter={activeFilter}
                  onActiveFilterChanged={doSetActiveFilter}
                  errorsFilter={errorsFilter}
                  onErrorsFilterChanged={doSetErrorsFilter}
                //   notificationsFilter={notificationsFilter}
                //   onNotificationsFilterChanged={doSetNotificationsFilter}
                  exclusiveToORZeroFilter={exclusiveToORZeroFilter}
                  onExclusiveToORZeroFilterChanged={doSetExclusiveToORZeroFilter}
                  exclusiveToDGCATZeroFilter={exclusiveToDGCATZeroFilter}
                  onExclusiveToDGCATZeroFilterChanged={doSetExclusiveToDGCATZeroFilter}
                  searchText={search.text}
                  searchCursorLocation={searchResults && searchResults.pointer}
                  searchResultCount={searchResults && searchResults.rowNumbers.length}
                  onSearchTextChanged={doSetSearchText}
                  onSearchFieldsChanged={(fields) => doSetSearchFields(fields.map((field) => field.value))}
                  moveSearchResultPointer={moveSearchResultPointer}
                  searchOptions={searchFilterOptions}
                  sortOrder={order}
                  sortOrderOptions={sortOrderOptions}
                  onOrderChanged={(sortOrders) => doUpdateOrder(sortOrders.map((obj) => obj.value))}
                  filterText={filter.text}
                  filterOptions={searchFilterOptions.filter((it) => it !== 'vartype')}
                  rowCount={rows.length}
                  onFilterTextChanged={doSetFilterText}
                  onFilterFieldsChanged={(filters) => doUpdateFilterFields(filters.map((obj) => obj.value))}
                  renameColumns={renameColumns}
                  onVartypeChanged={doSetVartypeFilter}>
                    {finalEnableAddRow && (
                    <NameMenuItem
                      domain={domain}
                      country={country}
                      rows={rows}
                      enableRowSelect={finalEnableRowSelect}
                      onAddRow={doInitPendingRow}
                      onClearRowAddition={removePendingRow}
                      pendingAddition={!!pendingRow}
                      listIsfiltered={
                          !!filter.text
                          || filter.vartype.length > 0
                          || activeFilter !== undefined
                          || errorsFilter !== undefined
                        //   || notificationsFilter !== undefined
                          || exclusiveToORZeroFilter !== undefined
                          || exclusiveToDGCATZeroFilter !== undefined
                        }
                      selectedRows={selectedRows}
                      doRemoveSelectedRows={doRemoveSelectedRows}
                      linkableColumns={linkableColumns}
                      doSelectLinkableCode={doSelectLinkableCode}/>
                    )}
                </TopMenu>
                <DataTable
                  domain={domain}
                  country={country}
                  doFetchContent={doFetchContent}
                  renameColumns={renameColumns}
                  enableRowSelect={finalEnableRowSelect}
                  storableColumn={storableColumn}
                  linkableColumns={linkableColumns}
                  doStoreCodes={doStoreCodes}
                  doLinkCode={doLinkCode}
                  doLinkAllCodes={doLinkAllCodes}
                  selectedCode={selectedCode}
                  onSelectAllRows={selectAllRows}
                  onUnSelectAllRows={clearRowSelections}
                  moveTo={searchResults && searchResults.rowNumbers[searchResults.pointer]}
                  highlightedRow={rowHighlight}
                  onColumnClick={doSetFocusedItem}
                  onColumnRightClick={this.onColumnRightClick}
                  rowKey={identifier}
                  cursor={cursor}
                  nonEditableFields={nonEditableFields}
                  nonEditableRowRules={nonEditableRowRules}
                  onArrowUp={gridArrowUp}
                  onArrowDown={gridArrowDown}
                  onArrowLeft={gridArrowLeft}
                  onArrowRight={gridArrowRight}
                  onTab={gridTab}
                  setCursorLocation={setGridCursorLocation}
                  toggleRowSelection={toggleRowSelection}
                  selectedRows={getSelectedRows(rows, selectedRows)}
                  headers={headers}
                  columnMenu={columnMenu || this.createMenuEntry()}
                  onShowMenuColumn={doSetFocusedItem}
                  onUpdateColumn={spinner ? undefined : doUpdateColumn}
                  dimensions={dimensions}
                  sortOrder={order.reduce((acc, [column, direction]) => ({...acc, [column]: direction}), {})}
                  onSort={doToggleFieldOrder}
                  rows={rows}
                  onResize={doUpdateDimensions}
                  clearRowFocus={clearRowFocus}
                  stickyRow={finalEnableAddRow && this.renderAddRow()}
                  onRowScopeChange={doFetchContent}
                  readOnly={finalReadOnly}>
                    {this.renderRightClickMenu()}
                </DataTable>
            </div>
        );
    }

    renderRightClickMenu() {
        const {reduxDomain: {spinner, rightClickSelection}} = this.props;
        if (!spinner && rightClickSelection) {
            const {
                t,
                doUndoRowChange,
                enableRemoveRow,
                reduxDomain: {history, IDENTIFIER},
            } = this.props;
            const {doRemoveRow, doClearRowFocus} = this.props;
            const {top, left, row} = rightClickSelection;
            const canUndo = history[row[IDENTIFIER]] && history[row[IDENTIFIER]].length && doUndoRowChange;
            const finalEnableRemoveRow = enableRemoveRow || false;

            return (
                <RightClickMenu
                  t={t}
                  styles={{top: `${top}px`, left: `${left}px`}}
                  onRemoveRow={finalEnableRemoveRow ? doRemoveRow : undefined}
                  onUndoLastChange={canUndo ? (() => doUndoRowChange({id: row[IDENTIFIER]})) : undefined}
                  onClose={doClearRowFocus}/>
            );
        }
    }

    createMenuEntry() {
        const {focusedItemMenu} = this.props.reduxDomain;
        if (focusedItemMenu) {
            const {
                identifier,
                doUpdateFocusedItem,
                clearRowFocus,
                domain,
                readOnly,
                columnTypes,
                allowEmptyOptionColumns,
                country,
            } = this.props;
            const {style, column, row} = focusedItemMenu;
            const finalAllowEmptyOptionColumns = allowEmptyOptionColumns || {};
            const finalColumnTypes = columnTypes || {};
            const finalReadOnly = readOnly || false;
            const viewProps = {style, onChange: finalReadOnly ? () => undefined : doUpdateFocusedItem, onClose: clearRowFocus};

            switch (finalColumnTypes[column]) {
            case 'Boolean': return {
                ...focusedItemMenu,
                Component: <BooleanMenu value={row[column]} identifier={identifier} {...viewProps} />,
            };
            case 'BooleanWithNull': return {
                ...focusedItemMenu,
                Component: <BooleanMenu value={row[column]} identifier={identifier} {...viewProps} allowNull />,
            };
            case 'Varval': return {
                ...focusedItemMenu,
                Component: <VarvalMenu domain={domain} value={row[column]} useCase="edit" identifier={identifier} allowEmpty={!!finalAllowEmptyOptionColumns[column]} {...viewProps} />,
            };
            case 'Vartype': return {
                ...focusedItemMenu,
                Component: <VartypeMenu domain={domain} identifier={identifier} value={row[column]} {...viewProps}/>,
            };
            case 'DgPlus': return {
                ...focusedItemMenu,
                Component: <DgPlusMenu value={row[column]} identifier={identifier} label={column} allowEmpty={!!finalAllowEmptyOptionColumns[column]} {...viewProps} />,
            };
            case 'ProcPlus': return {
                ...focusedItemMenu,
                Component: <ProcPlusMenu value={row[column]} identifier={identifier} label={column} allowEmpty={!!finalAllowEmptyOptionColumns[column]} {...viewProps} />,
            };
            case 'MdcName': return {
                ...focusedItemMenu,
                Component: <MdcNameMenu id={row[column]} allowEmpty={!!finalAllowEmptyOptionColumns[column]} value={row[column]} {...viewProps}/>,
            };
            case 'DrgName': return {
                ...focusedItemMenu,
                Component: <DrgNameMenu id={row[column]} label={column} allowEmpty={!!finalAllowEmptyOptionColumns[column]} value={row[column]} {...viewProps} />,
            };
            case 'DrgNameList':
                if (domain === 'drgNameCombined' && (country === 'com' || (row.nat_ver || '').includes(country))) {
                    return {
                        ...focusedItemMenu,
                        Component: <DrgNameListMenu id={row[column]} allowEmpty={!!finalAllowEmptyOptionColumns[column]} value={row[column]} country={country} {...viewProps} />,
                    };
                }
                break;
            case 'MdcNameList':
                if (domain === 'mdcNameCombined' && (country === 'com' || (row.nat_ver || '').includes(country))) {
                    return {
                        ...focusedItemMenu,
                        Component: <MdcNameListMenu id={row[column]} allowEmpty={!!finalAllowEmptyOptionColumns[column]} value={row[column]} country={country} {...viewProps} />,
                    };
                }
                break;
            case 'plus_dg':
                if (domain === 'mbcDgPlus' && (row.plus_dg)) {
                    return {
                        ...focusedItemMenu,
                        Component: <DgNatListMenu id={row[column]} allowEmpty={!!finalAllowEmptyOptionColumns[column]} value={row[column]} country={country} {...viewProps} />,
                    };
                }
                break;
            case 'plus_proc':
                if (domain === 'mbcProcPlus' && (row.plus_proc)) {
                    return {
                        ...focusedItemMenu,
                        Component: <ProcNatListMenu id={row[column]} allowEmpty={!!finalAllowEmptyOptionColumns[column]} value={row[column]} country={country} {...viewProps} />,
                    };
                }
                break;
            case 'InactivatedAt':
                if (!finalReadOnly) {
                    return {
                        ...focusedItemMenu,
                        Component: <InactivatedAtMenu value={row[column]} {...viewProps} />,
                    };
                }
                break;
            case 'Errors':
                if (row.errorsList && row.errorsList.length > 1) {
                    return {
                        ...focusedItemMenu,
                        Component: <ErrorsAndNotificationsListMenu list={row.errorsList} {...viewProps} />,
                    };
                }
                break;
            // case 'Notifications':
            //     if (row.notificationsList && row.notificationsList.length > 1) {
            //         return { ...focusedItemMenu,
            //             Component: <ErrorsAndNotificationsListMenu list={row.notificationsList} {...viewProps} />
            //         };
            //     }
            default:
                break;
            }
        }
    }

    renderAddRow() {
        const {reduxDomain: {pendingRow}} = this.props;
        if (pendingRow) {
            const {
                t,
                newRowEditables,
                country,
                domain,
                columnTypes,
                linkableColumns,
                storableColumn,
                allowEmptyOptionColumns,
                updatePendingRow,
                doAddRow,
                doLinkCode,
                reduxDomain: {
                    spinner,
                    dimensions,
                    headers,
                    selectedCode,
                    typingDisabledFields,
                    nonEditableFields,
                },
            } = this.props;
            const finalAllowEmptyOptionColumns = allowEmptyOptionColumns || {};
            const finalNewRowEditables = newRowEditables || [];
            const finalColumnTypes = columnTypes || {};
            const newRowNonEditables = nonEditableFields.filter((field) => finalNewRowEditables.indexOf(field) === -1);
            return (
                <StickyTableRow
                  domain={domain}
                  country={country}
                  dimensions={dimensions}
                  fields={headers}
                  columnTypes={finalColumnTypes}
                  storableColumn={storableColumn}
                  linkableColumns={linkableColumns}
                  selectedCode={selectedCode}
                  doLinkCode={doLinkCode}
                  allowEmptyOptionColumns={finalAllowEmptyOptionColumns}
                  values={pendingRow}
                  nonEditableFields={newRowNonEditables}
                  typingDisabledFields={typingDisabledFields}
                  nonEditableRowsPlaceholder={t.autoGenerated}
                  onChange={spinner ? undefined : updatePendingRow}
                  onSubmit={doAddRow}/>
            );
        }
    }

    onColumnRightClick = ({rowIndex, columnIndex, left}) => {
        this.props.rightClickSelectRow({rowIndex, columnIndex, leftOffset: left});
    };
}

CommonGridScene.propTypes = {
    readOnly: bool,
    columnTypes: object,
    doFetchContent: func.isRequired,
    doUpdateDimensions: func.isRequired,
    doUpdateOrder: func.isRequired,
    doUpdateFilterFields: func.isRequired,
    doSetSearchText: func.isRequired,
    doSetSearchFields: func.isRequired,
    moveSearchResultPointer: func.isRequired,
    doSetFilterText: func.isRequired,
    toggleRowSelection: func.isRequired,
    doSetFocusedItem: func.isRequired,
    doUpdateColumn: func,
    doToggleFieldOrder: func.isRequired,
    clearRowFocus: func.isRequired,
    doSetActiveFilter: func,
    doSetErrorsFilter: func,
    // doSetNotificationsFilter: func,
    doSetExclusiveToORZeroFilter: func,
    doSetExclusiveToDGCATZeroFilter: func,
    gridArrowRight: func.isRequired,
    gridArrowLeft: func.isRequired,
    gridArrowUp: func.isRequired,
    gridArrowDown: func.isRequired,
    gridTab: func.isRequired,
    setGridCursorLocation: func.isRequired,
    removePendingRow: func.isRequired,
    doInitPendingRow: func.isRequired,
    selectAllRows: func.isRequired,
    clearRowSelections: func.isRequired,
    getSelectedRows: func.isRequired,
    doUpdateFocusedItem: func.isRequired,
    updatePendingRow: func.isRequired,
    doAddRow: func,
    doRemoveRow: func,
    rightClickSelectRow: func.isRequired,
    enableAddRow: bool,
    enableRemoveRow: bool,
    columnMenu: object,
    allowEmptyOptionColumns: object,
    newRowEditables: array,
    domain: string.isRequired,
    identifier: string.isRequired,
    renameColumns: object,
    doSetVartypeFilter: func,
    storableColumn: string,
    linkableColumns: array,
    doStoreCodes: func,
    doSelectLinkableCode: func,
    doLinkCode: func,
    doLinkAllCodes: func,
    enableRowSelect: bool,
    customTitle: string,
};

export default connect(({locales: {t, country}, ...states}, {domain}) => ({reduxDomain: states[domain], t, country}))(CommonGridScene);
