import React from 'react';
import {func, string, bool, shape, arrayOf, any} from 'prop-types';
import WindowedSelect, {createFilter} from 'react-windowed-select';
import {styles} from './styles';

const Select = ({i, id = 'select-component', containerClassName, label, onChange, options, isMulti, isSearchable, placeholder, value}) => (
    <div className={`select-container${containerClassName ? ` ${containerClassName}` : ''}`}>
        {label && <label htmlFor={id}>{label}</label>}
        <WindowedSelect
          menuPosition="fixed"
          isMulti={isMulti}
          isSearchable={isSearchable}
          options={options}
          placeholder={placeholder}
          value={value}
          noOptionsMessage={() => '--'}
          onChange={(val) => onChange(val ?? [])}
          id={label ? `${id}-${i}` : undefined}
          filterOption={createFilter({ ignoreAccents: false })}
          styles={styles}/>
    </div>
);

Select.propTypes = {
    id: string,
    containerClassName: string,
    options: arrayOf(shape({label: any, value: any})),
    isSearchable: bool,
    isMulti: bool,
    label: string,
    placeholder: string,
    value: any,
    onChange: func.isRequired,
};

export default Select;
