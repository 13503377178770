import createReduxDomain from './resources/domainFactory';
import { NON_SORTABLE_FIELDS, NON_EDITABLE_FIELDS } from '../common/constants';

const {reducer, types, selectors, actions, middlewares} = createReduxDomain({
    URL: '/procedureFeature',
    DOMAIN: 'PROCEDURE_FEATURE',
    IDENTIFIER: 'rid',
    excludeActions: [
        'doLink$Code',
        'doLinkAll$Codes',
        'doSelectLinkable$Code',
        'doSet$ExclusiveToDGCATZeroFilter',
        'doStore$Codes',
    ],
    excludeHeaders: ['rid'],
    nonEditableFields: NON_EDITABLE_FIELDS.procedureFeature,
    nonSortableFields: NON_SORTABLE_FIELDS.procedureFeature,
    typingDisabledFields: {
        vartype: true,
        varval: true,
        proc_plus: true,
        active: true,
    },
});
export default reducer;

export {types, actions, selectors, middlewares};
