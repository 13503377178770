import React from 'react';
import {bool, string} from 'prop-types';
import {connect} from 'react-redux';
import Spinner from '../Spinner';
import './styles.scss';

function ContainerTitle({ text, busy, loading, t, schema}) {
    return (
        <div className={`grid-title-container ${schema === 'mbc' && 'mbc-bg'}`}>
            <h2>{text}</h2>{loading && <Spinner small light/>}
            <h2 className={`grid-title-busy grid-title-busy--${busy ? 'visible' : 'hidden'}`}>{t.serviceBusy}</h2>
        </div>
    );
}

ContainerTitle.propTypes = {
    text: string,
    loading: bool,
};

export default connect(({meta: {schema, serviceStatus}, locales: {t}}) => ({busy: serviceStatus === 'BUSY', t, schema}))(ContainerTitle);
