import {
    createResolveLatestIf,
    createDistinctLastTimeout,
    memoizedMap,
    getCommonRowFormatter,
    getDiagnosisFeatureRowFormatter,
    getProcedureFeatureRowFormatter,
    orderNullsFix,
} from '../../common/utils';
import {
    onSetContent,
    onClearRows,
    onToggleRowSelection,
    onAddRow,
    onFieldOrderToggle,
    onUpdateOrder,
    onMoveSearchResultPointer,
    onSetFocusedItem,
    onUpdateColumn,
    onRightClickSelectRow,
    getFieldSpecificTimeout,
    onClearRowFocus,
    onArrowLeft,
    onArrowUp,
    onArrowDown,
    onArrowRight,
    onCursorLocationChange,
    onPreRemoveRow,
    onTab,
    onSetSearchResults,
    onInitPendingRow,
    onUndoChange,
    onUpdateRow,
} from './reducerUtils';
import {DEFAULT_COLUMN_WIDTH} from '../../common/constants';
import {LOGOUT} from '../meta';

export default function createReduxDomain({
    DOMAIN, IDENTIFIER, URL,
    nonEditableFields = [],
    nonEditableRowRules,
    nonSortableFields = {},
    typingDisabledFields = {},
    excludeHeaders = [],
    excludeActions = [],
    updateExtraParams = [],
    headerOrder,
}) {
    const domain = DOMAIN.split('_').map((word, i) => {
        const wordLowerCase = word.toLowerCase();
        if (i === 0) {
            return wordLowerCase;
        }
        return word[0].toUpperCase() + wordLowerCase.substring(1, wordLowerCase.length);
    }).join('');
    const Domain = DOMAIN.split('_').map((word) => word[0] + word.substring(1, word.length).toLowerCase()).join('');
    // TYPES
    const CLEAR_FOCUSED_ITEM = `${DOMAIN}::CLEAR_FOCUSED_ITEM`;
    const SET_FOCUSED_ITEM = `${DOMAIN}::SET_FOCUSED_ITEM`;
    const SET_FOCUSED_ITEM_INFO = `${DOMAIN}::SET_FOCUSED_ITEM_INFO`;
    const FETCH_CONTENT = `${DOMAIN}::FETCH_CONTENT`;
    const SET_CONTENT = `${DOMAIN}::SET_CONTENT`;
    const SET_FILTER_TEXT = `${DOMAIN}::SET_FILTER_TEXT`;
    const CLEAR_ROWS = `${DOMAIN}::CLEAR_ROWS`;
    const UPDATE_FILTER_FIELDS = `${DOMAIN}::UPDATE_FILTER_FIELDS`;
    const UPDATE_ORDER = `${DOMAIN}::UPDATE_ORDER`;
    const FIELD_ORDER_TOGGLE = `${DOMAIN}::FIELD_ORDER_TOGGLE`;
    const SET_ACTIVE_FILTER = `${DOMAIN}::SET_ACTIVE_FILTER`;
    const SET_ERRORS_FILTER = `${DOMAIN}::SET_ERRORS_FILTER`;
    // const SET_NOTIFICATIONS_FILTER = `${DOMAIN}::SET_NOTIFICATIONS_FILTER`;
    const SET_VARTYPE_FILTER = `${DOMAIN}::SET_VARTYPE_FILTER`;
    const SET_EXCLUSIVE_TO_OR_ZERO_FILTER = `${DOMAIN}::SET_EXCLUSIVE_TO_OR_ZERO_FILTER`;
    const SET_EXCLUSIVE_TO_DGCAT_ZERO_FILTER = `${DOMAIN}::SET_EXCLUSIVE_TO_DGCAT_ZERO_FILTER`;
    const ADD_ROW = `${DOMAIN}::ADD_ROW`;
    const TOGGLE_ROW_SELECTION = `${DOMAIN}::TOGGLE_ROW_SELECTION`;
    const CLEAR_ROW_FOCUS = `${DOMAIN}::CLEAR_ROW_FOCUS`;
    const CLEAR_ROW_SELECTIONS = `${DOMAIN}::CLEAR_ROW_SELECTIONS`;
    const UPDATE_COLUMN = `${DOMAIN}::UPDATE_COLUMN`;
    const SET_SEARCH_TEXT = `${DOMAIN}::SET_SEARCH_TEXT`;
    const UPDATE_SEARCH_FIELDS = `${DOMAIN}::UPDATE_SEARCH_FIELDS`;
    const SET_SEARCH_RESULT_ROWS = `${DOMAIN}::SET_SEARCH_RESULT_ROWS`;
    const MOVE_SEARCH_RESULT_POINTER = `${DOMAIN}::MOVE_SEARCH_RESULT_POINTER`;
    const RIGHT_CLICK_SELECT_ROW = `${DOMAIN}::RIGHT_CLICK_SELECT_ROW`;
    const UPDATE_COLUMN_DIMENSIONS = `${DOMAIN}::UPDATE_COLUMN_DIMENSIONS`;
    const UPDATE_PENDING_ROW = `${DOMAIN}::UPDATE_PENDING_ROW`;
    const REMOVE_PENDING_ROW = `${DOMAIN}::REMOVE_PENDING_ROW`;
    const ARROW_LEFT = `${DOMAIN}::ARROW_LEFT`;
    const ARROW_RIGHT = `${DOMAIN}::ARROW_RIGHT`;
    const ARROW_DOWN = `${DOMAIN}::ARROW_DOWN`;
    const ARROW_UP = `${DOMAIN}::ARROW_UP`;
    const TAB = `${DOMAIN}::TAB`;
    const SET_CURSOR_LOCATION = `${DOMAIN}::SET_CURSOR_LOCATION`;
    const INIT_PENDING_ROW = `${DOMAIN}::INIT_PENDING_ROW`;
    const CLEAR_ALL = `${DOMAIN}::CLEAR_ALL`;
    const PRE_REMOVE_ROW = `${DOMAIN}::PRE_REMOVE_ROW`;
    const SELECT_ALL_ROWS = `${DOMAIN}::SELECT_ALL_ROWS`;
    const FIND_SEARCH_RESULTS = `${DOMAIN}::FIND_SEARCH_RESULTS`;
    const UNDO_CHANGE = `${DOMAIN}::UNDO_CHANGE`;
    const UPDATE_ROW = `${DOMAIN}::UPDATE_ROW`;
    const STORE_CODE = `${DOMAIN}::STORE_CODE`;
    const SELECT_CODE = `${DOMAIN}::SELECT_CODE`;

    const types = {
        UNDO_CHANGE,
        CLEAR_FOCUSED_ITEM,
        SET_FOCUSED_ITEM,
        SET_FOCUSED_ITEM_INFO,
        FETCH_CONTENT,
        SET_CONTENT,
        SET_FILTER_TEXT,
        CLEAR_ROWS,
        UPDATE_FILTER_FIELDS,
        UPDATE_ORDER,
        FIELD_ORDER_TOGGLE,
        SET_ACTIVE_FILTER,
        SET_ERRORS_FILTER,
        // SET_NOTIFICATIONS_FILTER,
        SET_EXCLUSIVE_TO_OR_ZERO_FILTER,
        SET_EXCLUSIVE_TO_DGCAT_ZERO_FILTER,
        SET_VARTYPE_FILTER,
        ADD_ROW,
        TOGGLE_ROW_SELECTION,
        CLEAR_ROW_FOCUS,
        CLEAR_ROW_SELECTIONS,
        UPDATE_COLUMN,
        SET_SEARCH_TEXT,
        UPDATE_SEARCH_FIELDS,
        SET_SEARCH_RESULT_ROWS,
        MOVE_SEARCH_RESULT_POINTER,
        RIGHT_CLICK_SELECT_ROW,
        UPDATE_COLUMN_DIMENSIONS,
        UPDATE_PENDING_ROW,
        REMOVE_PENDING_ROW,
        ARROW_LEFT,
        ARROW_RIGHT,
        ARROW_DOWN,
        ARROW_UP,
        SET_CURSOR_LOCATION,
        INIT_PENDING_ROW,
        CLEAR_ALL,
        PRE_REMOVE_ROW,
        SELECT_ALL_ROWS,
        TAB,
        FIND_SEARCH_RESULTS,
        UPDATE_ROW,
        STORE_CODE,
        SELECT_CODE,
    };

    const dimensions = JSON.parse(localStorage.getItem(`${domain}_dimensions`)) || {};
    dimensions.rowHeight = dimensions.rowHeight || 30;
    dimensions.defaultColumnWidth = DEFAULT_COLUMN_WIDTH;

    let history = localStorage.getItem(`norddrg_${domain}_history`);
    if (history) history = JSON.parse(history);
    else history = {};
    const initialState = {
        IDENTIFIER,
        spinner: false,
        nonEditableFields,
        nonEditableRowRules,
        nonSortableFields,
        typingDisabledFields,
        rows: [], /* [RowData] */
        headers: [], /* [string] */
        order: [], /* [ [fieldName: string, ASC/DESC: string] ] */
        history,
        historySize: 50,
        filter: {text: '', fields: [], vartype: []},
        activeFilter: undefined, /* bool */
        errorsFilter: undefined, /* bool */
        // notificationsFilter: undefined, /* bool */
        exclusiveToORZeroFilter: undefined, /* bool */
        exclusiveToDGCATZeroFilter: undefined, /* bool */
        selectedRows: {}, /* {[rowId]: true, [rowId2]: true} | {[*]: true} */
        rowHighlight: undefined, /* number */
        search: {text: '', fields: []}, /* {text: string, fields: [string]} */
        searchResults: undefined, /* {pointer: number?, rowNumbers: [number]} */
        focusedItemMenu: undefined, // {rowIndex: number, column: string, row: RowData, style: {top: number, left: number}}
        pendingRow: undefined, /* RowData? */
        dimensions,
        cursor: {status: 'scroll', target: {}}, /* status: 'intent' | 'active', target: {row: number?, column: number?} */
        loadingContent: false,
    };

    // REDUCER
    function reducer(state = initialState, {type, payload}) {
        switch (type) {
        case SELECT_ALL_ROWS: return {...state, selectedRows: {'*': true}};
        case PRE_REMOVE_ROW:
            return onPreRemoveRow({state, payload});
        case ARROW_RIGHT:
            return onArrowRight({state});
        case ARROW_LEFT:
            return onArrowLeft({state});
        case ARROW_UP:
            return onArrowUp({state});
        case ARROW_DOWN:
            return onArrowDown({state});
        case TAB:
            return onTab({state});
        case SET_CURSOR_LOCATION:
            return onCursorLocationChange({state, payload});
        case INIT_PENDING_ROW:
            return onInitPendingRow({state, payload});
        case UPDATE_PENDING_ROW:
            return {...state, pendingRow: {...state.pendingRow, ...payload}};
        case REMOVE_PENDING_ROW:
            return {...state, pendingRow: undefined};
        case MOVE_SEARCH_RESULT_POINTER:
            return onMoveSearchResultPointer({state, payload});
        case UPDATE_COLUMN_DIMENSIONS: {
            const { dimensions } = state;
            return {...state, dimensions: {...dimensions, [payload.field]: payload.width}};
        }
        case TOGGLE_ROW_SELECTION:
            return onToggleRowSelection({state, payload, identifier: IDENTIFIER});
        case SET_SEARCH_RESULT_ROWS:
            return onSetSearchResults({state, payload});
        case UPDATE_COLUMN:
            return onUpdateColumn({state, payload, identifier: IDENTIFIER});
        case CLEAR_ROW_SELECTIONS:
            return {...state, selectedRows: {}};
        case RIGHT_CLICK_SELECT_ROW:
            return onRightClickSelectRow({state, payload});
        case ADD_ROW:
            return onAddRow({state, payload, identifier: IDENTIFIER});
        case CLEAR_ROW_FOCUS:
            return onClearRowFocus({state});
        case FIND_SEARCH_RESULTS:
        case FETCH_CONTENT: return {...state, loadingContent: true};
        case SET_CONTENT: {
            const nextState = onSetContent({state, payload, identifier: IDENTIFIER, headerOrder});
            const exclude = [...excludeHeaders, 'errorsList', 'ignoreCodesList'];
            // const exclude = [...excludeHeaders, 'errorsList', 'ignoreCodesList', 'notificationsList'];
            nextState.headers = nextState.headers.filter(header => !exclude.includes(header));
            return {...nextState};
        }
        case SET_FILTER_TEXT:
            return {...state, filter: {...state.filter, text: payload}};
        case SET_SEARCH_TEXT:
            return {...state, search: {...state.search, text: payload}, searchResults: undefined};
        case UPDATE_FILTER_FIELDS:
            return {...state, filter: {...state.filter, fields: payload}};
        case UPDATE_SEARCH_FIELDS:
            return {...state, search: {...state.search, fields: payload}, searchResults: undefined};
        case UPDATE_ORDER:
            return onUpdateOrder({state, payload});
        case FIELD_ORDER_TOGGLE:
            return onFieldOrderToggle({state, payload});
        case SET_ACTIVE_FILTER:
            return {...state, filter: {...state.filter}, rows: [], activeFilter: payload};
        case SET_ERRORS_FILTER:
            return {...state, filter: {...state.filter}, rows: [], errorsFilter: payload};
        // case SET_NOTIFICATIONS_FILTER:
        //     return {...state, filter: {...state.filter}, rows: [], notificationsFilter: payload };
        case SET_VARTYPE_FILTER:
            return {...state, filter: {...state.filter, vartype: payload}};
        case SET_EXCLUSIVE_TO_OR_ZERO_FILTER:
            return {...state, filter: {...state.filter}, rows: [], exclusiveToORZeroFilter: payload};
        case SET_EXCLUSIVE_TO_DGCAT_ZERO_FILTER:
            return {...state, filter: {...state.filter}, rows: [], exclusiveToDGCATZeroFilter: payload};
        case CLEAR_ROWS:
            return onClearRows({state});
        case SET_FOCUSED_ITEM:
            return onSetFocusedItem({state, payload});
        case SET_FOCUSED_ITEM_INFO:
            return state;
        case CLEAR_FOCUSED_ITEM:
            return state;
        case CLEAR_ALL:
            return {...initialState, headers: state.headers};
        case UNDO_CHANGE:
            return onUndoChange({state, payload, identifier: IDENTIFIER});
        case UPDATE_ROW:
            return onUpdateRow({state, payload, identifier: IDENTIFIER});
        case STORE_CODE:
            return {...state, stored: payload};
        case SELECT_CODE:
            return {...state, selectedCode: payload};
        default: return state;
        }
    }

    // ACTION CREATORS
    const clear$RowFocus = () => ({type: CLEAR_ROW_FOCUS});
    const clear$RowSelections = () => ({type: CLEAR_ROW_SELECTIONS});
    const selectAll$Rows = () => ({type: SELECT_ALL_ROWS});
    const clearFocused$Item = () => ({type: CLEAR_FOCUSED_ITEM});
    const $GridArrowRight = () => ({type: ARROW_RIGHT});
    const $GridArrowLeft = () => ({type: ARROW_LEFT});
    const $GridArrowUp = () => ({type: ARROW_UP});
    const $GridArrowDown = () => ({type: ARROW_DOWN});
    const $GridTab = () => ({type: TAB});
    const removePending$Row = () => ({type: REMOVE_PENDING_ROW});
    const set$GridCursorLocation = ({rowIndex, columnIndex}) => ({type: SET_CURSOR_LOCATION, payload: {rowIndex, columnIndex}});
    const toggle$RowSelection = (rowIndex) => ({type: TOGGLE_ROW_SELECTION, payload: rowIndex});
    const move$SearchResultPointer = (direction) => ({type: MOVE_SEARCH_RESULT_POINTER, payload: direction});
    const updatePending$Row = (update) => ({type: UPDATE_PENDING_ROW, payload: update});
    const rightClickSelect$Row = ({rowIndex, columnIndex, leftOffset, menuWidth, absoluteLeftOffset}) => ({
        type: RIGHT_CLICK_SELECT_ROW,
        payload: {rowIndex, columnIndex, leftOffset, menuWidth, absoluteLeftOffset},
    });

    // Thunk helpers
    const resolveLast = createDistinctLastTimeout();
    const resolveDimensionSave = createDistinctLastTimeout();
    const resolveFetchSearchResults = createResolveLatestIf();
    const {entries} = Object;
    let currentRowIndex = 0;

    // NOT_EXPORTED - ONLY CALLED BY OTHER OTHER THUNKS
    function doFetchSearchMatchingRowNumbers() {
        return async function fetchSearchMatchingRowNumbers(dispatch, getState, Api) {
            const {[domain]: subState, locales: {country}} = getState();
            if (!subState.loadingContent) {
                dispatch({type: FIND_SEARCH_RESULTS});
            }
            const {
                filter,
                search,
                activeFilter,
                errorsFilter,
                // notificationsFilter
                exclusiveToORZeroFilter,
                exclusiveToDGCATZeroFilter,
            } = subState;
            const order = orderNullsFix(subState.order);
            const {cancelled} = await resolveFetchSearchResults({
                initialDelay: 500,
                cancelOn() {
                    const {locales: {country: countryNow}, [domain]: subStateNow} = getState();
                    return !subStateNow.search.text
                        || country !== countryNow
                        || entries({
                            order: subState.order,
                            filter,
                            search,
                            activeFilter,
                            errorsFilter,
                            // notificationsFilter
                            exclusiveToORZeroFilter,
                            exclusiveToDGCATZeroFilter,
                        }).some(([k, initialValue]) => initialValue !== subStateNow[k]);
                },
            });
            const payload = {
                order,
                search,
                filter,
                globalFilter: {
                    country,
                    active: activeFilter,
                    errors: errorsFilter,
                    // notifications: notificationsFilter
                    exclusiveToORZero: exclusiveToORZeroFilter,
                    exclusiveToDGCATZero: exclusiveToDGCATZeroFilter,
                },
            };
            const {matchingRowNumbers} = await Api.post(`${URL}/search`, {payload, cancelled});
            dispatch({type: SET_SEARCH_RESULT_ROWS, payload: matchingRowNumbers});
        };
    }

    // THUNKS
    function doFetch$Content(rowIndex) {
        currentRowIndex = rowIndex;
        const waitForResolve = resolveLast(1000);
        const offset = (currentRowIndex - 200) < 0 ? 0 : (currentRowIndex - 200);
        return async function fetchContent(dispatch, getState, Api) {
            const {[domain]: subState, locales: {country}} = getState();
            if (!subState.loadingContent) {
                dispatch({type: FETCH_CONTENT});
            }
            const {
                filter,
                activeFilter,
                errorsFilter,
                // notificationsFilter
                exclusiveToORZeroFilter,
                exclusiveToDGCATZeroFilter,
            } = subState;
            const order = orderNullsFix(subState.order);
            const {cancelled} = await waitForResolve;
            const payload = {
                order,
                filter,
                offset,
                limit: 400,
                globalFilter: {
                    country,
                    active: activeFilter,
                    errors: errorsFilter,
                    // notifications: notificationsFilter
                    exclusiveToORZero: exclusiveToORZeroFilter,
                    exclusiveToDGCATZero: exclusiveToDGCATZeroFilter,
                },
            };
            const result = await Api.post(`${URL}/fetch`, {payload, cancelled});

            switch (domain) {
            case 'diagnosisFeature': {
                const formatter = country === 'com' ? getCommonRowFormatter(country) : getDiagnosisFeatureRowFormatter(country);
                result.rows = result.rows.map(formatter);
                break;
            } case 'procedureFeature': {
                const formatter = country === 'com' ? getCommonRowFormatter(country) : getProcedureFeatureRowFormatter(country);
                result.rows = result.rows.map(formatter);
                break;
            } default: {
                result.rows = result.rows.map(getCommonRowFormatter(country));
            }
            }
            dispatch({type: SET_CONTENT, payload: {...result, offset}});
        };
    }

    function doSetFocused$Item({rowIndex, columnIndex}) {
        return async function setFocusedItem(dispatch) {
            dispatch({type: SET_FOCUSED_ITEM, payload: {rowIndex, columnIndex}});
        };
    }

    function doUpdate$Dimensions({field, width}) {
        return async function updateDimensions(dispatch, getState) {
            if (width > 50) {
                dispatch({type: UPDATE_COLUMN_DIMENSIONS, payload: {field, width}});
                await resolveDimensionSave(200);
                const {dimensions} = getState()[domain];
                localStorage.setItem(`${domain}_dimensions`, JSON.stringify(dimensions));
            }
        };
    }

    function doClear$RowFocus({clearItemFocus}) {
        return function clearRowHighlight(dispatch, getState) {
            const {rightClickSelection, rowHighlight, focusedItemMenu} = getState()[domain];
            if (rightClickSelection || rowHighlight || (focusedItemMenu && clearItemFocus)) {
                dispatch({type: CLEAR_ROW_FOCUS, payload: {clearItemFocus}});
            }
        };
    }

    const initRowObj = (country = null) => {
        const active = true;
        return {
            diagnosisFeature: { nat_ver: country, active },
            procedureFeature: { nat_ver: country, active },
            groupingPropertyName: { nat_ver: country, active },
            procedurePropertyName: { or_1: false, extens: false, nat_ver: country, active },
            diagnosisCategoryName: { nat_ver: country, active },
            principalDiagnosisProperty: { nat_ver: country, active },
            complicationCategoryName: { nat_ver: country, active },
            drgName: { country, active },
            drgNameCombined: { nat_ver: country, active },
            mdcName: { country, active },
            mdcNameCombined: { nat_ver: country, active },
            mbcDgNat: { country },
            mbcDgPlus: { nat_ver: country },
            mbcProcNat: { country },
            mbcProcPlus: { nat_ver: country },
            mbcAtcNat: { country },
            mbcAtcPlus: { nat_ver: country },
        };
    };

    function doInitPending$Row() {
        return function initPendingRow(dispatch, getState) {
            const {country} = getState().locales;
            const pendingRow = initRowObj(country !== 'com' ? country : null)[domain];
            dispatch({type: INIT_PENDING_ROW, payload: pendingRow});
        };
    }

    function doAdd$Row() {
        return async function addRow(dispatch, getState, Api) {
            const state = getState();
            const {pendingRow} = state[domain];
            await Api.post(`${URL}/add`, {payload: pendingRow});
            dispatch({type: CLEAR_ROWS});
            dispatch(doFetch$Content(0));
        };
    }

    function doSet$FilterText(text) {
        const waitForResolve = resolveLast(500);
        return async function setFilterText(dispatch) {
            dispatch({type: SET_FILTER_TEXT, payload: text});
            await waitForResolve;
            dispatch({type: CLEAR_ROWS});
            dispatch(doFetch$Content(0));
        };
    }

    function doSet$SearchText(text) {
        return async function setFilterText(dispatch) {
            dispatch({type: SET_SEARCH_TEXT, payload: text});
            if (text) {
                dispatch(doFetchSearchMatchingRowNumbers());
            }
        };
    }

    function doUpdate$FilterFields(fields) {
        return function updateFilterFields(dispatch, getState) {
            dispatch({type: UPDATE_FILTER_FIELDS, payload: fields});
            if (getState()[domain].filter.text) {
                dispatch({type: CLEAR_ROWS});
                dispatch(doFetch$Content(0));
            }
        };
    }

    function doSet$SearchFields(fields) {
        return async function setFilterText(dispatch, getState) {
            dispatch({type: UPDATE_SEARCH_FIELDS, payload: fields});
            if (getState()[domain].search.text) {
                dispatch(doFetchSearchMatchingRowNumbers());
            }
        };
    }

    function doUpdate$Order(fields) {
        return function updateOrder(dispatch) {
            dispatch({ type: UPDATE_ORDER, payload: fields });
            dispatch({ type: CLEAR_ROWS });
            dispatch(doFetch$Content(0));
        };
    }

    function doToggle$FieldOrder(field) {
        return function toggleFieldOrder(dispatch, getState) {
            const {nonSortableFields} = getState()[domain];
            if (!nonSortableFields[field]) {
                dispatch({type: FIELD_ORDER_TOGGLE, payload: field});
                dispatch({type: CLEAR_ROWS});
                dispatch(doFetch$Content(0));
            }
        };
    }

    function doSet$ActiveFilter(bool) {
        return function setActiveFilter(dispatch) {
            dispatch({type: SET_ACTIVE_FILTER, payload: bool});
            dispatch({type: CLEAR_ROWS});
            dispatch(doFetch$Content(0));
        };
    }

    function doSet$ErrorsFilter(bool) {
        return function setErrorsFilter(dispatch) {
            dispatch({ type: SET_ERRORS_FILTER, payload: bool });
            dispatch({ type: CLEAR_ROWS });
            dispatch(doFetch$Content(0));
        };
    }

    // function doSet$NotificationsFilter(bool) {
    //     return function setNotificationsFilter(dispatch) {
    //         dispatch({ type: SET_NOTIFICATIONS_FILTER, payload: bool });
    //         dispatch({ type: CLEAR_ROWS });
    //         dispatch(doFetch$Content(0));
    //     };
    // }

    function doSet$VartypeFilter(vartype) {
        return function setVartypeFilter(dispatch) {
            dispatch({type: SET_VARTYPE_FILTER, payload: vartype.map((vt) => vt.value)});
            dispatch({type: CLEAR_ROWS});
            dispatch(doFetch$Content(0));
        };
    }

    function doSet$ExclusiveToORZeroFilter(bool) {
        return function setExclusiveToORZeroFilter(dispatch) {
            dispatch({ type: SET_EXCLUSIVE_TO_OR_ZERO_FILTER, payload: bool });
            dispatch({ type: CLEAR_ROWS });
            dispatch(doFetch$Content(0));
        };
    }

    function doSet$ExclusiveToDGCATZeroFilter(bool) {
        return function setExclusiveToDGCATZeroFilter(dispatch) {
            dispatch({ type: SET_EXCLUSIVE_TO_DGCAT_ZERO_FILTER, payload: bool });
            dispatch({ type: CLEAR_ROWS });
            dispatch(doFetch$Content(0));
        };
    }

    function doUndo$RowChange({id}) {
        return async function undoRowChange(dispatch, getState, Api) {
            const {rows, history} = getState()[domain];
            const {value, column} = history[id][0];
            const payload = {[IDENTIFIER]: id, column, value, undo: true};
            if (updateExtraParams.length) {
                const target = rows.find((row) => row[IDENTIFIER] === id);
                updateExtraParams.forEach((k) => payload[k] = target[k]);
            }
            dispatch({type: UNDO_CHANGE, payload: id});
            const {cancelled} = await getFieldSpecificTimeout({domain, row: id, column})(1000);
            await Api.put(`${URL}/update`, {payload, cancelled});
        };
    }

    function doUpdate$Column({[IDENTIFIER]: id, column, value, timestamp = Date.now()}) {
        return async function updateColumn(dispatch, getState, Api) {
            const {country} = getState().locales;
            const {nonEditableFields, rows} = getState()[domain];
            if (nonEditableFields.indexOf(column) === -1) {
                const payload = {[IDENTIFIER]: id, column, value};
                if (updateExtraParams.length) {
                    const target = rows.find((row) => row[IDENTIFIER] === id);
                    updateExtraParams.forEach((k) => payload[k] = target[k]);
                }
                dispatch({type: UPDATE_COLUMN, payload: {...payload, timestamp}});
                const {cancelled} = await getFieldSpecificTimeout({domain, row: id, column})(1000);
                const result = await Api.put(`${URL}/update`, {payload, cancelled});
                const { updatedRow } = result;
                if (updatedRow) {
                    let row;
                    switch (domain) {
                    case 'diagnosisFeature': {
                        row = getDiagnosisFeatureRowFormatter(country)(updatedRow);
                        break;
                    } case 'procedureFeature': {
                        row = getProcedureFeatureRowFormatter(country)(updatedRow);
                        break;
                    } case 'dgPlus': case 'procPlus': {
                        row = updatedRow;
                        break;
                    } default: {
                        row = getCommonRowFormatter(country)(updatedRow);
                    }
                    }
                    if (row) dispatch({type: UPDATE_ROW, payload: row});
                }
                if (domain === 'diagnosisFeature') {
                    const { updatedMdcRow } = result;
                    if (updatedMdcRow) {
                        dispatch({type: UPDATE_COLUMN, payload: {[IDENTIFIER]: updatedMdcRow.rid, column, value: updatedMdcRow[column], vartype: updatedMdcRow.vartype, timestamp}});
                    }
                }
            }
        };
    }

    function doUpdateFocused$Item({value}) {
        return function (dispatch, getState) {
            const {column, row: {[IDENTIFIER]: id}} = getState()[domain].focusedItemMenu;
            return dispatch(doUpdate$Column({[IDENTIFIER]: id, column, value}));
        };
    }

    function doRemove$Row() {
        return async function removeRow(dispatch, getState, Api) {
            const {[domain]: {rightClickSelection: {row: {[IDENTIFIER]: id}}}} = getState(); // must be read before dispatch
            const {rows} = getState()[domain];
            const rowIndex = rows.findIndex((row) => row[IDENTIFIER] === id);
            dispatch({type: PRE_REMOVE_ROW, payload: {rowIndex}});
            await Api.del(`${URL}/${id}`);
            await dispatch(doFetch$Content(rowIndex));
        };
    }

    function doRemoveSelected$Rows() {
        return async function removeRows(dispatch, getState, Api) {
            const {
                [domain]: {
                    selectedRows,
                    filter,
                    activeFilter,
                    errorsFilter,
                    // notificationsFilter
                },
                locales: {country},
            } = getState();

            if (selectedRows['*']) {
                if (domain.includes('Feature')) {
                    await Api.del(`${URL}/all/`);
                } else {
                    const filters = encodeURIComponent(JSON.stringify({
                        ...filter,
                        activeFilter,
                        errorsFilter,
                        // notificationsFilter
                    }));
                    await Api.del(`${URL}/all/${country}/${filters}`);
                }
            } else {
                const ids = Object.keys(selectedRows).join('&');
                await Api.del(`${URL}/${ids}`);
            }
            await dispatch(doFetch$Content(0));
        };
    }

    function doStore$Codes(codes) {
        return function storeCode(dispatch) {
            dispatch({type: STORE_CODE, payload: codes});
        };
    }

    function doSelectLinkable$Code(code) {
        return function selectCode(dispatch) {
            dispatch({type: SELECT_CODE, payload: code});
        };
    }

    function doLink$Code({[IDENTIFIER]: id, column, value, timestamp = Date.now()}) {
        return async function linkCode(dispatch, getState, Api) {
            const {country} = getState().locales;
            const payload = {[IDENTIFIER]: id, column, value, country};
            dispatch({type: UPDATE_COLUMN, payload: {...payload, timestamp}});
            const {cancelled} = await getFieldSpecificTimeout({domain, row: id, column})(1000);
            const result = await Api.put(`${URL}/link`, {payload, cancelled});
            const {updatedRows} = result;
            updatedRows.forEach((updatedRow, i) => {
                const row = getCommonRowFormatter(country)(updatedRow);
                if (row) dispatch({type: UPDATE_ROW, payload: row});
                if (i === 0) localStorage.setItem('link', Date.now()); // send trigger to another window
            });
        };
    }

    function doLinkAll$Codes({[IDENTIFIER]: id, column, value}) {
        return async function linkAllCodes(dispatch, getState, Api) {
            const {[domain]: {filter, errorsFilter}, locales: {country}} = getState();
            const payload = {column, value, country, filter, errors: errorsFilter};
            const {cancelled} = await getFieldSpecificTimeout({domain, row: id, column})(1000);
            const {count} = await Api.put(`${URL}/link_all`, {payload, cancelled});
            if (count) {
                dispatch({type: CLEAR_ROWS});
                dispatch(doFetch$Content(0));
                localStorage.setItem('link', Date.now()); // send trigger to another window
            }
        };
    }

    const actions = Object.entries({
        doRemove$Row,
        doRemoveSelected$Rows,
        doFetch$Content,
        doSetFocused$Item,
        doUpdate$Column,
        doUpdate$Dimensions,
        doInitPending$Row,
        doAdd$Row,
        doSet$FilterText,
        doSet$ActiveFilter,
        doSet$ErrorsFilter,
        // doSet$NotificationsFilter,
        doSet$VartypeFilter,
        doSet$ExclusiveToORZeroFilter,
        doSet$ExclusiveToDGCATZeroFilter,
        doSet$SearchFields,
        doSet$SearchText,
        doUpdate$Order,
        doToggle$FieldOrder,
        doUpdateFocused$Item,
        doUpdate$FilterFields,
        doClear$RowFocus,
        clear$RowFocus,
        clear$RowSelections,
        selectAll$Rows,
        clearFocused$Item,
        $GridArrowRight,
        $GridArrowLeft,
        $GridArrowUp,
        $GridArrowDown,
        $GridTab,
        removePending$Row,
        set$GridCursorLocation,
        toggle$RowSelection,
        move$SearchResultPointer,
        updatePending$Row,
        rightClickSelect$Row,
        doUndo$RowChange,
        doStore$Codes,
        doSelectLinkable$Code,
        doLink$Code,
        doLinkAll$Codes,
    }).filter(([k, _v]) => !excludeActions.includes(k)).reduce((acc, [k, v]) => {
        let key;
        if (k[0] === '$') {
            key = k.replace('$', domain);
        } else {
            key = k.replace('$', Domain);
        }
        return {...acc, [key]: v};
    }, {});
    const selectors = {
        [`getSelected${Domain}Rows`]: memoizedMap((rows, selectedRows) => {
            if (selectedRows['*']) {
                return selectedRows;
            }
            return Object.keys(selectedRows)
                .map((id) => rows.findIndex((row) => (row[IDENTIFIER] || '').toString() === (id || '').toString()))
                .filter((it) => it !== -1)
                .reduce((acc, index) => ({...acc, [index]: true}), {});
        }),
    };

    const middlewares = [
        () => (next) => (action) => {
            if (action.type === LOGOUT) {
                console.log('remove', `norddrg_${domain}_history`);
                localStorage.removeItem(`norddrg_${domain}_history`);
            }
            return next(action);
        },
    ];
    return {reducer, types, actions, middlewares, selectors};
}
