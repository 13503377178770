import {createDistinctLastTimeout} from '../../common/utils';

const CLEAR_ROW_FOCUS = 'PROC_NAT_LIST_OPTIONS::CLEAR_ROW_FOCUS';
const SET_PROC_NAT_LIST_OPTIONS = 'PROC_NAT_LIST_OPTIONS::SET_PROC_NAT_LIST_OPTIONS';
const CLEAR_PROC_NAT_LIST_OPTIONS = 'PROC_NAT_LIST_OPTIONS::CLEAR_PROC_NAT_LIST_OPTIONS';
const FETCH_PROC_NAT_LIST_OPTIONS = 'PROC_NAT_LIST_OPTIONS::FETCH_PROC_NAT_LIST_OPTIONS';
const FETCH_PROC_NAT_LIST_ERROR = 'PROC_NAT_LIST_OPTIONS::FETCH_PROC_NAT_LIST_ERROR';

const initialState = {
    procNatItems: [],
    loading: false,
};
export default function procNatOptionsReducer(state = initialState, {payload, type}) {
    switch (type) {
    case FETCH_PROC_NAT_LIST_OPTIONS:
        return {...state, loading: true, procNatItems: []};
    case FETCH_PROC_NAT_LIST_ERROR:
        return {...state, loading: false};
    case SET_PROC_NAT_LIST_OPTIONS:
        return {...state, procNatItems: payload, loading: false};
    case CLEAR_PROC_NAT_LIST_OPTIONS:
        return {...state, procNatItems: []};
    default: return state;
    }
}
const resolveLast = createDistinctLastTimeout();

function doFetchProcNatOptions() {
    const waitForResolve = resolveLast(0);
    return async function fetchNational(dispatch, getState, Api) {
        try {
            dispatch({type: FETCH_PROC_NAT_LIST_OPTIONS});
            const {cancelled} = await waitForResolve;
            const payload = {globalFilter: {}};
            dispatch({type: CLEAR_ROW_FOCUS});
            const result = await Api.post('/mbc/proc_nat/fetch', {payload, cancelled});
            dispatch({type: SET_PROC_NAT_LIST_OPTIONS, payload: result.rows});
        } catch (e) {
            dispatch({type: FETCH_PROC_NAT_LIST_ERROR});
            throw e;
        }
    };
}

export const actions = {
    doFetchProcNatOptions,
};

export const types = {
    CLEAR_ROW_FOCUS,
    SET_PROC_NAT_LIST_OPTIONS,
    CLEAR_PROC_NAT_LIST_OPTIONS,
    FETCH_PROC_NAT_LIST_OPTIONS,
    FETCH_PROC_NAT_LIST_ERROR,
};
