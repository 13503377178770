import {saveAs} from 'file-saver';
import {actions as errorNotificationActions} from './errorNotification';
import Api from '../common/Api';
import {RUN_ENV, tableShortNames} from '../common/constants';

// TYPES
export const BEGIN_DOWNLOAD = 'DUMPS::BEGIN_DOWNLOAD';
export const FINISH_DOWNLOAD = 'DUMPS::FINISH_DOWNLOAD';

const initialState = {
    downloading: false,
};

const envString = (schema) => {
    const appEnv = schema === 'mbc' ? 'mbc' : 'ndms';
    const runEnv = RUN_ENV === 'prod' ? '' : `${RUN_ENV}_`;
    return `${appEnv}_${runEnv}`;
};

// REDUCER
export default function dumpsReducer(state = initialState, {type}) {
    switch (type) {
    case BEGIN_DOWNLOAD: return {...state, downloading: true};
    case FINISH_DOWNLOAD: return {...state, downloading: false};
    default: return state;
    }
}

export const doFullDownload = ({schema, path, country, excel, tables}) => async (dispatch) => {
    try {
        dispatch({type: BEGIN_DOWNLOAD});
        const response = await Api.getFile(path);
        const blob = await response.blob();
        const now = new Date().toISOString().replace(/:/g, '-').substr(0, 19);
        let fileName = `${now}_${envString(schema)}${country}`;
        if (excel && tables.length === 1) fileName += `_${tableShortNames[tables[0].value]}`;
        saveAs(blob, `${fileName}.${excel ? 'xlsx' : 'zip'}`);
        dispatch({type: FINISH_DOWNLOAD});
    } catch (e) {
        dispatch({type: FINISH_DOWNLOAD});
        dispatch(errorNotificationActions.createErrorNotification({subject: 'Download export', error: e.message}));
    }
};

export const doFullBackup = (schema) => async (dispatch) => {
    try {
        dispatch({type: BEGIN_DOWNLOAD});
        const response = await Api.getFile(`/export/backup/${schema}`);
        const blob = await response.blob();
        const now = `${new Date().toISOString().replace(/:/g, '-').substr(0, 19)}`;
        const filename = `${now}_${envString(schema)}backup.zip`;
        saveAs(blob, filename);
        dispatch({type: FINISH_DOWNLOAD});
    } catch (e) {
        dispatch({type: FINISH_DOWNLOAD});
        dispatch(errorNotificationActions.createErrorNotification({subject: 'Download backup', error: e.message}));
    }
};

export const doJSONDownload = (country) => async (dispatch, getState, Api) => {
    const fileName = () => {
        const now = new Date();
        const onlyDigits = (str) => str.replace(/\D/g, '');
        const dateTimeString = `${onlyDigits(now.toLocaleDateString())}_${onlyDigits(now.toLocaleTimeString())}`;
        return `${country}_nationalJSON_${dateTimeString}.json`;
    };

    dispatch({type: BEGIN_DOWNLOAD});
    const content = await Api.get(`/test_case/nationalJson/${country}`);
    const blob = new Blob([`\ufeff${JSON.stringify(content, null, 2)}`], {type: 'text/plain;charset=utf-8'});
    saveAs(blob, fileName());
    dispatch({type: FINISH_DOWNLOAD});
};

export const actions = {
    doFullDownload,
    doFullBackup,
    doJSONDownload,
};
