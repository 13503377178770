import React from 'react';
import Button from '../Button';

const LinkCode = ({header = false, rowKey, id, column, value, selectedCode, doLinkCode, doLinkAllCodes}) => {
    const handleClick = () => {
        if (!header) {
            doLinkCode({header, [rowKey]: id, column, value: selectedCode});
        } else {
            doLinkAllCodes({header, column, value: selectedCode});
        }
    };

    return (
        <Button
          className={`store-and-link-button${header ? '-header' : ''}`}
          text={value !== selectedCode ? '+' : ''}
          onClick={handleClick}
          disabled={value === selectedCode}/>
    );
};

export default LinkCode;