import React, {useState, useEffect} from 'react';
import {func} from 'prop-types';
import {connect} from 'react-redux';
import {
    COM_TABLE_OPTIONS,
    TABLE_OPTIONS,
    RAW_COM_TABLE_OPTIONS,
    RAW_TABLE_OPTIONS,
    MBC_TABLE_OPTIONS,
} from '../../../common/constants';
import ConfigInput from '../../../components/ConfigInput';
import Button from '../../../components/Button';
import CheckBox from '../../../components/Checkbox';
import Select from '../../../components/Select';
import {actions} from '../../../store/dumps';
import {actionsWrapper} from '../../../store/resources/reducerUtils';
import store from '../../../store';

const thisYear = new Date().getFullYear();

const Download = ({t, country, downloading, schema, doFullDownload, doFullBackup, doJSONDownload}) => {
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState(new Date().toISOString().split('T')[0]);
    const [excel, setExcel] = useState(false);
    const [raw, setRaw] = useState(false);
    const [includeUnused, setIncludeUnused] = useState(false);
    const [onlyActive, setOnlyActive] = useState(false);
    const [tables, setTables] = useState([]);
    const [thisYearActive, setThisYearAcive] = useState(false);
    const [texts, setTexts] = useState({});

    useEffect(() => {
        const publicTexts = {
            fullDownloadText: `${t.export} ${thisYear + 1} ${country === 'com' ? t.fullDownloadText : t.fullDownloadNatText}`,
            fullBackupText: t.fullPublicBackupText,
        };
        const mbcTexts = {
            fullDownloadText: `${t.export} ${thisYear + 1} ${country === 'com' ? t.fullDownloadText : t.mbcFullDownloadNatText}`,
            fullBackupText: t.fullMbcBackupText,
        };
        setTexts(schema === 'public' ? publicTexts : mbcTexts);
    }, [schema, country, t.export, t.fullDownloadText, t.fullDownloadNatText, t.fullPublicBackupText, t.mbcFullDownloadNatText, t.fullMbcBackupText]);

    const fullDownload = async () => {
        const tableString = tables.length ? tables.map((e) => e.value).join('&') : 'all_tables';
        const path = `/export/range/${dateFrom || '*'}/${dateTo || '*'}/${schema}/${tableString}/${country}/${thisYearActive}/${onlyActive}/${includeUnused}/${excel}/${raw}`;
        await doFullDownload({schema, path, country, excel, raw, tables});
    };

    const fullBackup = async () => {
        await doFullBackup(schema);
    };

    const JsonDownload = async () => {
        await doJSONDownload(country);
    };

    const getTableOptions = () => {
        if (schema === 'mbc') {
            return MBC_TABLE_OPTIONS;
        }

        if (raw) {
            return country === 'com' ? RAW_COM_TABLE_OPTIONS : RAW_TABLE_OPTIONS;
        } else {
            return country === 'com' ? COM_TABLE_OPTIONS : TABLE_OPTIONS;
        }
    };

    return (
        <div className="section-container">
            <div className="config-form">
                <div className="config-item">
                    <h2>{t.fullDownload}</h2>
                    <p>{texts.fullDownloadText}</p>
                    <p>{t.optionally}:</p>
                    <ul>
                        <li>{t.optDateRange}</li>
                        <li>{t.optActive}</li>
                        {country === 'com' && <li>{t.optNotInUse}</li>}
                        <li>{`${t.optUse} ${thisYear} ${t.optAsActiveVersion}`}</li>
                        <li>{t.optTables}</li>
                        <li>{t.optRaw}</li>
                    </ul>
                    <div className="config-settings">
                        <ConfigInput i={0} label={t.dateFrom} placeholder="YYYY-MM-DD" onChange={(dateFrom) => setDateFrom(dateFrom)} value={dateFrom}/>
                        <ConfigInput i={1} label={t.dateTo} placeholder="YYYY-MM-DD" onChange={(dateTo) => setDateTo(dateTo)} value={dateTo}/>
                    </div>
                    <div className="config-settings">
                        <CheckBox label={t.onlyActive} checked={onlyActive} onChange={() => setOnlyActive(!onlyActive)}/>
                        <CheckBox label={t.includeUnused} disabled={country !== 'com'} checked={includeUnused} onChange={() => setIncludeUnused(!includeUnused)}/>
                        <CheckBox label={`${thisYear} ${t.versionAsActive}`} checked={thisYearActive} onChange={() => setThisYearAcive(!thisYearActive)}/>
                        <CheckBox label={t.exportXlsx} checked={excel} onChange={() => setExcel(!excel)}/>
                        <CheckBox label={t.exportRaw} disabled={!excel} checked={raw} onChange={() => setRaw(!raw)}/>
                    </div>
                    <div className="config-settings">
                        <Select
                          isSearchable
                          isMulti
                          placeholder={t.selectTable}
                          options={getTableOptions()}
                          onChange={(tables) => setTables(tables)}/>
                    </div>
                    <Button onClick={fullDownload} text={tables.length ? t.downloadSelectedTables : t.downloadAllTables} disabled={downloading}/>
                </div>
                <div className="config-item">
                    <h2>{t.fullBackup}</h2>
                    <p>{texts.fullBackupText}</p>
                    <Button onClick={fullBackup} text={t.downloadBackup} disabled={downloading}/>
                </div>
                {schema === 'public' && (
                <div className="config-item">
                    <h2>{t.nationalJSONDownload}</h2>
                    <p>{t.nationalJSONDownloadText}</p>
                    <Button onClick={JsonDownload} text={`${t.download} ${country !== 'com' ? country : ''}`} disabled={downloading || country === 'com'}/>
                </div>
                )}
            </div>
        </div>
    );
};

Download.propTypes = {
    doFullDownload: func.isRequired,
    doFullBackup: func.isRequired,
    doJSONDownload: func.isRequired,
};

export default connect(
    ({locales: {t, country}, dumps: {downloading}, meta: {schema}}) => ({t, country, downloading, schema}),
    {...actionsWrapper(store, actions)},
)(Download);
