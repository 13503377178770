import React from 'react';
import {connect} from 'react-redux';
import FlipMove from 'react-flip-move';
import Close from '../../Close';
import ImportSuccessInfo from '../ImportSuccessInfo';
import {doToggleShowNotifications, doDiscardNotification} from '../../../store/notification';
import {actionsWrapper} from '../../../store/resources/reducerUtils';
import store from '../../../store';
import './styles.scss';

class Notifications extends React.Component {
    render() {
        return (
            <div id="notifications-container">
                <div className={this.getClassName()} onClick={this.props.doToggleShowNotifications}>
                    <p id="notifications" className="notification-status-summary">{this.getNotificationsSummary()}</p>
                </div>
                {this.props.open && this.renderNotificationContent()}
            </div>
        );
    }

    getNotificationsSummary() {
        const {content, t} = this.props;
        if (content.length) {
            const unReadCount = content.filter((n) => !n.seen).length;
            if (unReadCount) {
                return `${unReadCount} ${t.notifications}`;
            }
            return t.noUnreadNotifications;
        }
        return t.noNotifications;
    }

    getClassName() {
        const {content} = this.props;
        let className = 'notifications';
        if (content.some((n) => !n.seen)) {
            className += ' notifications-need-attention';
        } else if (content.length) {
            className += ' notifications-seen';
        }
        return className;
    }

    renderNotificationContent() {
        const {content, t, doDiscardNotification} = this.props;
        return (
            <div className="notification-list-position">
                <FlipMove
                  className="notifications-list"
                  duration={150}
                  staggerDelayBy={0}
                  maintainContainerHeight
                  enterAnimation="fade"
                  leaveAnimation="fade">
                    {content.map(({id, seen, subject, data}) => (
                        <div key={id} className={`notification-description-item${seen ? '' : ' notification-description-item-unseen'}`}>
                            <Close onClick={() => doDiscardNotification(id)}/>
                            {subject && <p>{subject} {t.complete}.</p>}
                            {data && <ImportSuccessInfo data={data} t={t}/>}
                        </div>
                    ))}
                </FlipMove>
            </div>
        );
    }
}
export default connect(
    ({locales: {t}, notification}) => ({t, ...notification}),
    {...actionsWrapper(store, {doToggleShowNotifications, doDiscardNotification})},
)(Notifications);
