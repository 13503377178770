/*
mdc_name context options
national mdc:s should be updated when ever country is changed
*/

import {memoizedMap, createDistinctLastTimeout} from '../common/utils';

const CLEAR_ROW_FOCUS = 'MDC_NAME_OPTIONS::CLEAR_ROW_FOCUS';
const SET_NATIONAL_OPTIONS = 'MDC_NAME_OPTIONS::SET_NATIONAL_OPTIONS';
const SET_COMBINED_OPTIONS = 'MDC_NAME_OPTIONS::SET_COMBINED_OPTIONS';
const CLEAR_NATIONAL_OPTIONS = 'MDC_NAME_OPTIONS::CLEAR_NATIONAL_OPTIONS';
const FETCH_NATIONAL_OPTIONS = 'MDC_NAME_OPTIONS::FETCH_NATIONAL_OPTIONS';
const FETCH_COMBINED_OPTIONS = 'MDC_NAME_OPTIONS::FETCH_COMBINED_OPTIONS';
const FETCH_COMBINED_ERROR = 'MDC_NAME_OPTIONS::FETCH_COMBINED_ERROR';
const FETCH_NATIONAL_ERROR = 'MDC_NAME_OPTIONS::FETCH_NATIONAL_ERROR';

const initialState = {
    national: [],
    combined: [],
    loading: false,
    loadingNational: false,
    loadingCombined: false,
};
export default function mdcNameOptionsReducer(state = initialState, {payload, type}) {
    switch (type) {
    case FETCH_COMBINED_OPTIONS:
        return {...state, loading: true, loadingCombined: true, combined: []};
    case FETCH_NATIONAL_OPTIONS:
        return {...state, loading: true, loadingNational: true, national: []};
    case FETCH_COMBINED_ERROR:
        return {...state, loadingCombined: false, loading: state.loadingNational};
    case FETCH_NATIONAL_ERROR:
        return {...state, loadingNational: false, loading: state.loadingCombined};
    case SET_NATIONAL_OPTIONS:
        return {...state, national: payload, loadingNational: false, loading: state.loadingCombined};
    case SET_COMBINED_OPTIONS:
        return {...state, combined: payload, loadingCombined: false, loading: state.loadingNational};
    case CLEAR_NATIONAL_OPTIONS:
        return {...state, national: []};
    default: return state;
    }
}
const resolveLast = createDistinctLastTimeout();

export function doFetchMdcNameCombinedOptions() {
    return async function fetchCommon(dispatch, getState, Api) {
        dispatch({type: FETCH_COMBINED_OPTIONS});
        try {
            const result = await Api.post('/mdc_combined/names/fetch');
            dispatch({type: SET_COMBINED_OPTIONS, payload: result.rows});
        } catch (e) {
            dispatch({type: FETCH_COMBINED_ERROR});
            throw e;
        }
    };
}

export function doFetchMdcNameOptions() {
    const waitForResolve = resolveLast(0);
    return async function fetchNational(dispatch, getState, Api) {
        try {
            dispatch({type: FETCH_NATIONAL_OPTIONS});
            const {cancelled} = await waitForResolve;
            const payload = {globalFilter: {}};
            dispatch({type: CLEAR_ROW_FOCUS});
            const result = await Api.post('/mdc/names/fetch', {payload, cancelled});
            dispatch({type: SET_NATIONAL_OPTIONS, payload: result.rows});
        } catch (e) {
            dispatch({type: FETCH_NATIONAL_ERROR});
            throw e;
        }
    };
}

export const actions = {
    doFetchMdcNameCombinedOptions,
    doFetchMdcNameOptions,
};

export const types = {
    CLEAR_ROW_FOCUS,
    SET_NATIONAL_OPTIONS,
    SET_COMBINED_OPTIONS,
    CLEAR_NATIONAL_OPTIONS,
    FETCH_NATIONAL_OPTIONS,
    FETCH_COMBINED_OPTIONS,
    FETCH_COMBINED_ERROR,
    FETCH_NATIONAL_ERROR,
};

export const selectors = {
    getIndexedMdcNameOptions: memoizedMap(({ combined, national, country }) => {
        let options = {};
        if (combined) {
            options = combined
                .map((opt) => ({label: `${opt.mdc} - ${opt.mdc_text_com}`, value: opt.mdc}));
        } else if (national && country) {
            options = national
                .filter((opt) => opt.country === country)
                .map((opt) => ({label: `${opt.mdc} - ${opt.mdc_text_nat}`, value: opt.mdc}));
        }
        return options;
    }),
};
