import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import CommonGridScene from '../CommonGridScene';
import {removeStringFromKeys} from '../../common/utils';
import {actions, selectors} from '../../store/mbc/procPlus';
import {actionsWrapper, selectorsWrapper} from '../../store/resources/reducerUtils';
import store from '../../store';

const domain = 'mbcProcPlus';
const MbcProcPlus = ({t, schema, ...rest}) => {
    const schemaProps = {
        mbc: {
            enableAddRow: true,
            enableRemoveRow: true,
            enableRowSelect: true,
            newRowEditables: ['plus_proc'],
            storableColumn: 'plus_proc',
        },
        public: {
            customTitle: t.titles.ndmsMbcProcPlus,
            readOnly: true,
        },
    };

    const viewProps = removeStringFromKeys(rest, domain);
    const {doStoreCodes} = viewProps;

    useEffect(() => {
        const codes = JSON.parse(localStorage.getItem('procStore')) || [];
        if (Array.isArray(codes)) doStoreCodes(codes);
    }, [doStoreCodes]);

    return (
        <CommonGridScene
          domain={domain}
          identifier="plus_proc"
          columnTypes={{
            inactivated_at: 'InactivatedAt',
            errors: 'Errors',
            plus_proc: 'plus_proc',
          }}
          {...schemaProps[schema]}
          {...viewProps}/>
    );
};

export default connect(
    ({locales: {t}, meta: {schema}}) => ({t, schema, ...selectorsWrapper(store, selectors)}),
    {...actionsWrapper(store, actions)},
)(MbcProcPlus);
