import React from 'react';
import Draggable from 'react-draggable';
import {func, string, number, bool} from 'prop-types';
import arrowUp from '../../../../images/arrow_upward-iconl.svg';
import arrowDown from '../../../../images/arrow_downward-icon.svg';
import './styles.scss';

export default class HeaderCell extends React.PureComponent {
    render() {
        const {width, title, onResize} = this.props;
        const finalWidth = width || 100;
        return (
            <span>
                <span
                  className={this.className}
                  style={{width: `${finalWidth}px`/* compensate for 2*5px padding on left and right */}}
                  onMouseDown={() => { this.mouseDown = true; }}
                  onClick={this.onClick}>
                    {title}
                    { onResize && (
                    <span
                      onMouseDown={HeaderCell.stopPropagation}
                      onClick={HeaderCell.stopPropagation}>
                        <Draggable
                          axis="x"
                          position={{x: finalWidth - this.initialWidth}}
                          onDrag={this.handleResize}>
                            <span className="cell-handle" />
                        </Draggable>
                    </span>
                    )}
                    {this.icon}
                </span>
            </span>
        );
    }

    static stopPropagation(event) {
        event.stopPropagation();
    }

    get icon() {
        const {sortOrder} = this.props;
        if (sortOrder) {
            if (sortOrder === 'ASC') {
                return <img alt="ASC" src={arrowDown}/>;
            }
            return <img alt="DESC" src={arrowUp} />;
        }
        return undefined;
    }

    onClick = () => {
        const {onClick} = this.props;
        if (this.mouseDown && onClick) {
            onClick();
        }
        this.mouseDown = false;
    };

    handleResize = (e) => {
        const {width, onResize} = this.props;
        const finalWidth = width || 100;
        if (onResize) {
            onResize(finalWidth + (e.movementX));
        }
    };

    get className() {
        const {sortOrder, centeredText, sticky} = this.props;
        let className = 'grid-header-cell';
        if (sortOrder) {
            className += sortOrder === 'ASC' ? ' grid-header-asc' : ' grid-header-desc';
        }
        if (centeredText) {
            className += ' grid-header-centered-text';
        }
        if (sticky) {
            className += ' grid-header-sticky';
        }
        return className;
    }
}

HeaderCell.propTypes = {
    onResize: func,
    width: number,
    title: string,
    sortOrder: string,
    onClick: func,
    centeredText: bool,
};
