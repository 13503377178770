/* eslint-disable jsx-a11y/no-autofocus,prefer-destructuring */
import React from 'react';
import {bool, number} from 'prop-types';
import './styles.scss';

// todo: update to a functional component
export default class TableCell extends React.Component {
    static focusTimeout;

    render() {
        const {height, width, readOnly, focused, children, ...props} = this.props;
        const style = {
            width: `${width}px`,
            height: `${height}px`,
        };

        return (
            <span style={style} className="table-cell-container">
                <input {...props} ref={this.setRef} autoFocus={focused} className={this.className} style={style} readOnly={!!children || readOnly} />
                {children}
            </span>
        );
    }

    get className() {
        const {focused, className, readOnly} = this.props;
        let cssClass = 'table-cell-input';
        if (className) {
            cssClass += ` ${className}`;
        }
        if (focused) {
            cssClass += ' table-cell-input-focused';
        }
        if (readOnly) {
            cssClass += ' table-cell-input-disabled';
        }
        return cssClass;
    }

    setRef = (ref) => { this.ref = ref; };

    componentDidMount() {
        if (this.props.focused) {
            this.ref.focus();
        }
    }

    componentDidUpdate({focused}) {
        if (focused && !this.props.focused) {
            this.ref.blur();
        } else if (!focused && this.props.focused) {
            const timeout = TableCell.focusTimeout = setTimeout(() => {
                if (TableCell.focusTimeout === timeout) {
                    this.ref.focus();
                }
            }, 0);
        }
    }
}

TableCell.propTypes = {
    height: number,
    width: number,
    readOnly: bool,
    focused: bool,
};
