import React from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {connect} from 'react-redux';

const AuthRedirect = ({auth, children}) => {
    const location = useLocation();

    if (location.pathname.startsWith('/auth') || auth.token) {
        return children;
    }
    return <Navigate to="/auth/login"/>;
};

export default connect(({meta: {auth}}) => ({ auth }))(AuthRedirect);
