import {
    doFetchProcedureRelationCodes,
    doFetchProcedureRelationNatCodes,
    doFetchProcedureRelationPropertyNames,
    doFetchProcedureRelationVartypes,
    doFetchProcedureRelationVarvals,
} from './procedureRelations';
import {actions as drgActions} from './drgNameOptions';
import {actions as mdcActions} from './mdcNameOptions';
import {actions as dgNatOptionsActions} from './mbc/dgNatOptions';
import {actions as dgPlusActions} from './mbc/dgPlusOptions';
import {actions as procNatListActions} from './mbc/procNatOptions';
import {actions as procPlusActions} from './mbc/procPlusOptions';
import {
    doFetchDiagnosisRelationCodes,
    doFetchDiagnosisRelationNatCodes,
    doFetchDiagnosisRelationPrincipalDiagnosisProperties,
    doFetchDiagnosisRelationCategoryNames,
    doFetchDiagnosisRelationGroupingPropertyNames,
    doFetchDiagnosisRelationVartypes,
} from './diagnosisRelations';
import {actions as adminActions} from './admin';

// TYPES
export const NETWORK_ERROR = 'META::NETWORK_ERROR';
export const CLEAR_NETWORK_ERROR = 'META::CLEAR_NETWORK_ERROR';
export const ON_ROUTE_CHANGE = 'META::ON_ROUTE_CHANGE';
export const SET_SCHEMA = 'META::SET_SCHEMA';
export const SET_OVERLAY_CONTENT = 'META::SET_OVERLAY_CONTENT';
export const SHOW_OVERLAY = 'META::SHOW_OVERLAY ';
export const HIDE_OVERLAY = 'META::HIDE_OVERLAY';
export const LOGOUT = 'META::LOGOUT';
export const LOGIN = 'META::LOGIN';
export const LOGIN_SUCCESS = 'META::LOGIN_SUCCESS';
export const LOGIN_ERROR = 'META::LOGIN_ERROR';
export const SET_SERVICE_STATUS = 'META::SET_SERVICE_STATUS';

// REDUCER
const authJSON = localStorage.getItem('norddrg_auth');
let auth;
if (authJSON) {
    auth = JSON.parse(authJSON);
    if (auth.expires < Date.now()) {
        auth = {};
    }
} else auth = {};

const fetchActionsPerSchema = {
    common: [
        adminActions.doFetchCountryOptions,
        dgNatOptionsActions.doFetchDgNatOptions,
        dgPlusActions.doFetchDgPlusOptions,
        procNatListActions.doFetchProcNatOptions,
        procPlusActions.doFetchProcPlusOptions,
    ],
    public: [
        doFetchDiagnosisRelationPrincipalDiagnosisProperties,
        doFetchDiagnosisRelationCategoryNames,
        doFetchProcedureRelationPropertyNames,
        doFetchDiagnosisRelationGroupingPropertyNames,
        doFetchDiagnosisRelationVartypes,
        doFetchProcedureRelationVartypes,
        doFetchProcedureRelationVarvals,
        doFetchProcedureRelationVartypes,
        doFetchDiagnosisRelationCodes,
        doFetchProcedureRelationCodes,
        drgActions.doFetchDrgNameCombinedOptions,
        drgActions.doFetchDrgNameOptions,
        mdcActions.doFetchMdcNameCombinedOptions,
        mdcActions.doFetchMdcNameOptions,
    ],
    mbc: [
        // moved to common for read only tables
    ],
};

const fetchActionsBySchemaAndCountry = (schema, country) => {
    const commonFetchActions = fetchActionsPerSchema.common;
    const domainSpecificFetchActions = fetchActionsPerSchema[schema];
    const fetchActions = [].concat(commonFetchActions, domainSpecificFetchActions);
    if (schema === 'public' && country !== 'com') {
        fetchActions.push(doFetchDiagnosisRelationNatCodes);
        fetchActions.push(doFetchProcedureRelationNatCodes);
    }
    return fetchActions;
};

const routeToActivityMap = {
    '/logic': 'logic',
    '/test_case': 'testCase',
    '/names/grouping_property': 'groupingPropertyName',
    '/names/procedure_property': 'procedurePropertyName',
    '/names/diagnosis_category': 'diagnosisCategoryName',
    '/names/principal_diagnosis_property': 'principalDiagnosisProperty',
    '/names/complication_category': 'complicationCategoryName',
    '/names/drg': 'drgName',
    '/names/drg_combined': 'drgNameCombined',
    '/names/mdc': 'mdcName',
    '/names/mdc_combined': 'mdcNameCombined',
    '/codes/dg_nat': 'dgNat',
    '/codes/dg_plus': 'dgPlus',
    '/codes/proc_nat': 'procNat',
    '/codes/proc_plus': 'procPlus',
    '/codes/mbc_dg_nat': 'ndmsMbcDgNat',
    '/codes/mbc_dg_plus': 'ndmsMbcDgPlus',
    '/codes/mbc_proc_nat': 'ndmsMbcProcNat',
    '/codes/mbc_proc_plus': 'ndmsMbcProcPlus',
    '/codes/mbc_atc_nat': 'ndmsMbcAtcNat',
    '/codes/mbc_atc_plus': 'ndmsMbcAtcPlus',
    '/features/diagnosis': 'diagnosisFeatures',
    '/features/procedure': 'procedureFeatures',
    '/config/dumps': 'dumps',
    '/config/admin': 'admin',
    '/mbc/dg_nat': 'mbcDgNat',
    '/mbc/dg_plus': 'mbcDgPlus',
    '/mbc/proc_nat': 'mbcProcNat',
    '/mbc/proc_plus': 'mbcProcPlus',
    '/mbc/atc_nat': 'mbcAtcNat',
    '/mbc/atc_plus': 'mbcAtcPlus',
    '/mbc/config/dumps': 'mbcDumps',
    '/auth': 'auth',
};

export default function meta(state = {
    serviceStatus: 'NORMAL', auth, notifications: {}, networkError: false, activity: undefined, schema: undefined, suspended: false, overlay: {visible: false, content: {}},
}, {type, payload}) {
    switch (type) {
    case NETWORK_ERROR: return {...state, networkError: true};
    case SET_SERVICE_STATUS: return {...state, serviceStatus: payload};
    case CLEAR_NETWORK_ERROR: return {...state, networkError: false};
    case ON_ROUTE_CHANGE: return {...state, activity: routeToActivityMap[payload]};
    case SET_SCHEMA: return {...state, schema: payload};
    case SHOW_OVERLAY: return {...state, overlay: {visible: true, content: {}, loading: true}};
    case SET_OVERLAY_CONTENT: return {...state, overlay: {...state.overlay, content: payload, loading: false}};
    case HIDE_OVERLAY: return {...state, overlay: {...state.overlay, visible: false }};
    case LOGIN: return {...state, auth: {error: false, pending: true }};
    case LOGIN_SUCCESS: return {...state, auth: {...payload, error: false, pending: false}};
    case LOGIN_ERROR: return {...state, auth: {error: true, pending: false }};
    case LOGOUT: return {...state, auth: {}};
    default: return state;
    }
}

// ACTIONS
export const showOverlay = {type: SHOW_OVERLAY};

// ACTION CREATORS
export const notifyRouteChange = (location) => async (dispatch, getState) => {
    const {activity: prevActivity} = getState().meta;
    const {country} = getState().locales;
    let schema;
    dispatch({type: ON_ROUTE_CHANGE, payload: location});
    if (location.substring(0, 4) === '/mbc') {
        schema = 'mbc';
        dispatch({type: SET_SCHEMA, payload: 'mbc'});
    } else {
        schema = 'public';
        dispatch({type: SET_SCHEMA, payload: 'public'});
    }

    const {meta: {activity}} = getState();
    if (!prevActivity || (prevActivity === 'auth' && activity !== 'auth')) {
        const fetchActions = fetchActionsBySchemaAndCountry(schema, country);
        fetchActions.forEach((action) => dispatch(action()));
    }
};

export const setOverlayContent = (content) => ({type: SET_OVERLAY_CONTENT, payload: content});
export const hideOverlay = () => ({type: HIDE_OVERLAY});

// Should be called only once per app
export const doLongPollMaintenanceStatus = () => async (dispatch, getState, Api) => {
    try {
        const result = await Api.get(`/maintenance/pollstatus/${getState().meta.serviceStatus}`);
        dispatch({type: SET_SERVICE_STATUS, payload: result.status});
    } catch (e) {
        console.error('Error with pollstatus', e);
    }
};

export const doLogin = ({email, password}) => async (dispatch, getState, Api) => {
    dispatch({type: LOGIN});
    try {
        const response = await Api.post('/auth/login', {payload: {email, password}});
        localStorage.setItem('norddrg_auth', JSON.stringify(response));
        dispatch({type: LOGIN_SUCCESS, payload: response});
    } catch (e) {
        dispatch({type: LOGIN_ERROR});
    }
};

export const logout = () => {
    localStorage.removeItem('norddrg_auth');
    return {type: LOGOUT};
};

export const types = {
    NETWORK_ERROR,
    CLEAR_NETWORK_ERROR,
    ON_ROUTE_CHANGE,
    SET_OVERLAY_CONTENT,
    SHOW_OVERLAY,
    HIDE_OVERLAY,
    LOGOUT,
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    SET_SERVICE_STATUS,
};
export const actions = {
    doLogin,
    doLongPollMaintenanceStatus,
    notifyRouteChange,
    showOverlay,
    hideOverlay,
    logout,
};
