import React from 'react';
import {number} from 'prop-types';
import './styles.scss';

const TableCellFillRest = ({height}) => {
    const style = {
        height: `${height}px`,
    };
    return (
        <span style={style} className="table-cell-fill-rest"/>
    );
};

TableCellFillRest.propTypes = {
    height: number,
};

export default TableCellFillRest;
