import React from 'react';
import { connect } from 'react-redux';
import {logout} from '../../../store/meta';
import {ENDPOINT} from '../../../common/constants';
import {actionsWrapper} from '../../../store/resources/reducerUtils';
import store from '../../../store';
import './styles.scss';

class Logout extends React.Component {
    render() {
        const {logout, token, isMbc} = this.props;
        return token ? (
            <a
              href={(`${ENDPOINT}/export/backup/${isMbc ? 'mbc' : 'public'}`)}
              className="logout-button"
              onClick={logout}
              download>
                Logout
            </a>
        ) : null;
    }
}
export default connect(
    ({meta}) => ({token: meta.auth.token}),
    {...actionsWrapper(store, {logout})},
)(Logout);
