import {createDistinctLastTimeout} from '../../common/utils';

const CLEAR_ROW_FOCUS = 'ATC_NAT_LIST_OPTIONS::CLEAR_ROW_FOCUS';
const SET_ATC_NAT_LIST_OPTIONS = 'ATC_NAT_LIST_OPTIONS::SET_ATC_NAT_LIST_OPTIONS';
const CLEAR_ATC_NAT_LIST_OPTIONS = 'ATC_NAT_LIST_OPTIONS::CLEAR_ATC_NAT_LIST_OPTIONS';
const FETCH_ATC_NAT_LIST_OPTIONS = 'ATC_NAT_LIST_OPTIONS::FETCH_ATC_NAT_LIST_OPTIONS';
const FETCH_ATC_NAT_LIST_ERROR = 'ATC_NAT_LIST_OPTIONS::FETCH_ATC_NAT_LIST_ERROR';

const initialState = {
    atcNatItems: [],
    loading: false,
};
export default function atcNatOptionsReducer(state = initialState, {payload, type}) {
    switch (type) {
    case FETCH_ATC_NAT_LIST_OPTIONS:
        return {...state, loading: true, atcNatItems: []};
    case FETCH_ATC_NAT_LIST_ERROR:
        return {...state, loading: false};
    case SET_ATC_NAT_LIST_OPTIONS:
        return {...state, atcNatItems: payload, loading: false};
    case CLEAR_ATC_NAT_LIST_OPTIONS:
        return {...state, atcNatItems: []};
    default: return state;
    }
}
const resolveLast = createDistinctLastTimeout();

function doFetchAtcNatOptions() {
    const waitForResolve = resolveLast(0);
    return async function fetchNational(dispatch, getState, Api) {
        try {
            dispatch({type: FETCH_ATC_NAT_LIST_OPTIONS});
            const {cancelled} = await waitForResolve;
            const payload = {globalFilter: {}};
            dispatch({type: CLEAR_ROW_FOCUS});
            const result = await Api.post('/mbc/atc_nat/fetch', {payload, cancelled});
            dispatch({type: SET_ATC_NAT_LIST_OPTIONS, payload: result.rows});
        } catch (e) {
            dispatch({type: FETCH_ATC_NAT_LIST_ERROR});
            throw e;
        }
    };
}

export const actions = {
    doFetchAtcNatOptions,
};

export const types = {
    CLEAR_ROW_FOCUS,
    SET_ATC_NAT_LIST_OPTIONS,
    CLEAR_ATC_NAT_LIST_OPTIONS,
    FETCH_ATC_NAT_LIST_OPTIONS,
    FETCH_ATC_NAT_LIST_ERROR,
};
