import React from 'react';
import {connect} from 'react-redux';
import CommonGridScene from '../CommonGridScene';
import {removeStringFromKeys} from '../../common/utils';
import {actions, selectors} from '../../store/drgName';
import {actionsWrapper, selectorsWrapper} from '../../store/resources/reducerUtils';
import store from '../../store';

const domain = 'drgName';
const DrgName = (props) => {
    const viewProps = removeStringFromKeys(props, domain);
    const {country} = props;

    return (
        <CommonGridScene
          domain={domain}
          identifier="rid"
          columnTypes={{
            active: 'Boolean',
            drg_com: 'DrgName',
            mdc: 'MdcName',
            errors: 'Errors',
            // Notifications: 'Notifications',
          }}
          allowEmptyOptionColumns={{
            mdc: true,
          }}
          enableAddRow={country !== 'com'}
          enableRemoveRow={country !== 'com'}
          {...viewProps}/>
    );
};

export default connect(
    ({locales: {country}}) => ({country, ...selectorsWrapper(store, selectors)}),
    {...actionsWrapper(store, actions)},
)(DrgName);
