import React from 'react';
import {connect} from 'react-redux';
import {doFetchDiagnosisRelationPrincipalDiagnosisPropertyRelations, doFetchDiagnosisRelationPrincipalDiagnosisProperties} from '../../store/diagnosisRelations';
import ContextMenu from './ContextMenu';
import {DiagnosisRelationCardItem} from './CardItems';
import {actionsWrapper} from '../../store/resources/reducerUtils';
import store from '../../store';

const identifier = 'pdgprop';

const selectOptions = (principal) => principal.map((it) => ({value: it[identifier], label: `${it[identifier]} - ${it.varval_text_com}`}));

class PrincipalDiagnosisPropertiesMenu extends React.Component {
    state = {loading: false};

    render() {
        const {t, id, principal, onClose, onChange, allowEmpty} = this.props;
        const item = principal.find((it) => it[identifier] === id);
        const value = {label: id ? (`${id} - ${item ? item.varval_text_com : ''}`) : '--', value: id};
        const {relations = {}} = item || {};
        const {loading} = this.state;
        return (
            <ContextMenu
              onChange={onChange}
              onClose={onClose}
              value={value}
              label={identifier}
              loading={loading || relations.loading}
              fetchContent={this.onFetchContent}
              allowEmpty={allowEmpty}
              options={selectOptions(principal)}>
                {(relations.diagnoses || []).map(d => DiagnosisRelationCardItem(t, d))}
            </ContextMenu>
        );
    }

    onFetchContent = () => {
        const {doFetchDiagnosisRelationPrincipalDiagnosisProperties} = this.props;
        this.setState({loading: true});
        doFetchDiagnosisRelationPrincipalDiagnosisProperties().then(() => !this.unMounted && this.setState({loading: false}));
    };

    componentDidMount() {
        const {id, doFetchDiagnosisRelationPrincipalDiagnosisPropertyRelations} = this.props;
        if (id) {
            doFetchDiagnosisRelationPrincipalDiagnosisPropertyRelations(id);
        }
    }

    componentDidUpdate(prevProps) {
        const {id, doFetchDiagnosisRelationPrincipalDiagnosisPropertyRelations} = this.props;
        if (id !== prevProps.id) {
            doFetchDiagnosisRelationPrincipalDiagnosisPropertyRelations(id);
        }
    }

    componentWillUnmount() {
        this.unMounted = true;
    }
}

export default connect(
    ({locales: {t}, diagnosisRelations: {principal}}) => ({t, principal}),
    {...actionsWrapper(store, {doFetchDiagnosisRelationPrincipalDiagnosisPropertyRelations, doFetchDiagnosisRelationPrincipalDiagnosisProperties})},
)(PrincipalDiagnosisPropertiesMenu);
