import React from 'react';
import {connect} from 'react-redux';
import CommonGridScene from '../CommonGridScene';
import {removeStringFromKeys} from '../../common/utils';
import {actions, selectors} from '../../store/mdcNameCombined';
import {actionsWrapper, selectorsWrapper} from '../../store/resources/reducerUtils';
import store from '../../store';

const domain = 'mdcNameCombined';
const MdcNameCombined = (props) => {
    const viewProps = removeStringFromKeys(props, domain);

    return (
        <CommonGridScene
          domain={domain}
          identifier="mdc"
          columnTypes={{
            mdc: 'MdcNameList',
            errors: 'Errors',
            // notifications: 'Notifications',
          }}
          allowEmptyOptionColumns={{
            mdc: true,
          }}
          enableAddRow
          enableRemoveRow
          newRowEditables={['mdc']}
          {...viewProps}/>
    );
};

export default connect(
    () => ({...selectorsWrapper(store, selectors)}),
    {...actionsWrapper(store, actions)},
)(MdcNameCombined);
