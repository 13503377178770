import React from 'react';
import {connect} from 'react-redux';
import {actions, selectors} from '../store/grouperTestCase';
import {actionsWrapper, selectorsWrapper} from '../store/resources/reducerUtils';
import store from '../store';
import DataTable from '../components/DataTable';
import TopMenu from '../components/TopMenu';
import ContainerTitle from '../components/ContainerTitle';
import StickyTableRow from '../components/DataTable/StickyTableRow';
import TestCaseMenuItem from '../components/TopMenu/TestCaseMenuItem';
import {
    TestCaseRightClickMenu,
    BooleanMenu,
    DischargeModeMenu,
    DrgNameExtendedListMenu,
    DgPlusMenu,
    DgCodeMenu,
    ProcPlusMenu,
    ProcCodeMenu,
    ErrorsAndNotificationsListMenu,
} from '../components/Menus';
import Spinner from '../components/Spinner';
import { range } from '../common/utils';

const COLUMN_TYPES = {
    death: 'Boolean',
    disch: 'DischargeMode',
    drg_nat: 'DrgNameList',
    drg_nat_previous: 'DrgNameList',
    ...range(1, 10).reduce((acc, n) => ({...acc, [`dg${n}_plus`]: 'DgPlus'}), {}),
    ...range(1, 10).reduce((acc, n) => ({...acc, [`d_dg${n}_plus`]: 'DgPlus'}), {}),
    ...range(1, 10).reduce((acc, n) => ({...acc, [`proc${n}_plus`]: 'ProcPlus'}), {}),
    errors: 'Errors',
    // notifications: 'Notifications',
};

const ALLOW_EMPTY_OPTION_COLUMNS = {
    drg_nat_previous: true,
    ...range(2, 10).reduce((acc, n) => ({...acc, [`dg${n}_plus`]: true}), {}),
    ...range(1, 10).reduce((acc, n) => ({...acc, [`d_dg${n}_plus`]: true}), {}),
    ...range(1, 10).reduce((acc, n) => ({...acc, [`proc${n}_plus`]: true}), {}),
};

const domain = 'testCase';
class TestCase extends React.Component {
    render() {
        const {
            t,
            country,
            grouperTestCase: {
                loadingContent,
                rows,
                selectedRows,
                spinner,
                cursor,
                pendingRow,
                dimensions,
                nonEditableFields,
                nonSortableFields,
                headers,
                order,
                filter,
                rowHighlight,
                errorsFilter,
                // notificationsFilter,
                search,
                searchResults,
                types,
                changedResultsFilter,
            },
            doFetchTestCaseContent,
            doUpdateTestCaseDimensions,
            doUpdateTestCaseOrder,
            doUpdateTestCaseFilterFields,
            doSetTestCaseSearchText,
            doSetTestCaseSearchFields,
            moveTestCaseSearchResultPointer,
            doSetTestCaseFilterText,
            toggleTestCaseRowSelection,
            doSetFocusedTestCaseItem,
            doUpdateTestCaseColumn,
            doToggleTestCaseFieldOrder,
            doRemoveSelectedTestCaseRows,
            clearTestCaseRowFocus,
            doSetTestCaseUnexpectedResultsFilter,
            doSetTestCaseErrorsFilter,
            // doSetTestCaseNotificationsFilter,
            testCaseGridArrowRight,
            testCaseGridArrowLeft,
            testCaseGridArrowUp,
            testCaseGridArrowDown,
            testCaseGridTab,
            setTestCaseGridCursorLocation,
            removePendingTestCaseRow,
            initPendingTestCaseRow,
            selectAllTestCaseRows,
            clearTestCaseRowSelections,
            getSelectedTestCaseRows,
            doRunTestCases,
            doCopyTestCaseResults,
        } = this.props;
        const selectedRowsList = getSelectedTestCaseRows(rows, selectedRows);
        const searchFilterOptions = headers.filter((it) => it !== 'created_at' && it !== 'updated_at');
        const sortOrderOptions = headers.filter((it) => !nonSortableFields[it]);
        const enableRunTestCases = country !== 'com' && !spinner && !!Object.keys(selectedRowsList).length;
        return (
            <div className="scene-main-content">
                <ContainerTitle text={t.grouperTestCases} loading={loadingContent}/>
                <Spinner hidden={!spinner} />
                <TopMenu
                  t={t}
                  selectedRows={selectedRows}
                  doRemoveSelectedRows={doRemoveSelectedTestCaseRows}
                  changedResultsFilter={changedResultsFilter}
                  onChangedResultsFilterChanged={country !== 'com' ? doSetTestCaseUnexpectedResultsFilter : undefined}
                  errorsFilter={errorsFilter}
                  onErrorsFilterChanged={doSetTestCaseErrorsFilter}
                //   notificationsFilter={notificationsFilter}
                //   onNotificationsFilterChanged={doSetTestCaseNotificationsFilter}
                  searchText={search.text}
                  searchCursorLocation={searchResults && searchResults.pointer}
                  searchResultCount={searchResults && searchResults.rowNumbers.length}
                  onSearchTextChanged={doSetTestCaseSearchText}
                  onSearchFieldsChanged={(fields) => doSetTestCaseSearchFields(fields.map((field) => field.value))}
                  moveSearchResultPointer={moveTestCaseSearchResultPointer}
                  searchOptions={searchFilterOptions}
                  sortOrder={order}
                  sortOrderOptions={sortOrderOptions}
                  onOrderChanged={(sortOrders) => doUpdateTestCaseOrder(sortOrders.map((obj) => obj.value))}
                  filterText={filter.text}
                  filterOptions={searchFilterOptions}
                  rowCount={rows.length}
                  onFilterTextChanged={doSetTestCaseFilterText}
                  onFilterFieldsChanged={(filters) => doUpdateTestCaseFilterFields(filters.map((obj) => obj.value))}>
                    <TestCaseMenuItem
                      t={t}
                      domain={domain}
                      enableRunTestCases={enableRunTestCases}
                      onAddRow={initPendingTestCaseRow}
                      onClearRowAddition={removePendingTestCaseRow}
                      pendingAddition={!!pendingRow}
                      addRowText={t.addTestCase}
                      onRunTestCases={doRunTestCases}
                      runTestCasesText={t.runTestCases}
                      clearRowAdditionText={t.cancelAddition}
                      copyTestResultsText={t.copyTestResultsText}
                      onCopyTestCasesResultsUsingCurrentFilter={doCopyTestCaseResults}
                      testCaseListIsfiltered={!!filter.text || changedResultsFilter !== undefined}
                      selectedRows={selectedRows}
                      doRemoveSelectedRows={doRemoveSelectedTestCaseRows}/>
                </TopMenu>
                <DataTable
                  types={types}
                  onSelectAllRows={selectAllTestCaseRows}
                  onUnSelectAllRows={clearTestCaseRowSelections}
                  moveTo={searchResults && searchResults.rowNumbers[searchResults.pointer]}
                  highlightedRow={rowHighlight}
                  onColumnClick={doSetFocusedTestCaseItem}
                  onColumnRightClick={this.onColumnRightClick}
                  rowKey="id"
                  cursor={cursor}
                  nonEditableFields={nonEditableFields}
                  onArrowUp={testCaseGridArrowUp}
                  onArrowDown={testCaseGridArrowDown}
                  onArrowLeft={testCaseGridArrowLeft}
                  onArrowRight={testCaseGridArrowRight}
                  onTab={testCaseGridTab}
                  setCursorLocation={setTestCaseGridCursorLocation}
                  toggleRowSelection={toggleTestCaseRowSelection}
                  enableRowSelect
                  selectedRows={selectedRowsList}
                  headers={headers}
                  columnMenu={this.createMenuEntry()}
                  onShowMenuColumn={doSetFocusedTestCaseItem}
                  onUpdateColumn={spinner ? undefined : doUpdateTestCaseColumn}
                  dimensions={dimensions}
                  sortOrder={order.reduce((acc, [column, direction]) => ({...acc, [column]: direction}), {})}
                  onSort={doToggleTestCaseFieldOrder}
                  rows={rows}
                  onResize={doUpdateTestCaseDimensions}
                  clearRowFocus={clearTestCaseRowFocus}
                  stickyRow={this.renderAddRow()}
                  onRowScopeChange={doFetchTestCaseContent}>
                    {this.renderRightClickMenu()}
                </DataTable>
            </div>
        );
    }

    renderRightClickMenu() {
        const {grouperTestCase: {spinner, rightClickSelection}} = this.props;
        if (!spinner && rightClickSelection) {
            const {
                t,
                doRemoveTestCaseRow,
                clearTestCaseRowFocus,
                doShowTestCaseSummary,
                doUndoTestCaseRowChange,
                grouperTestCase: {history},
            } = this.props;
            console.log('doRemoveTestCaseRow', doRemoveTestCaseRow);
            const {top, left, row} = rightClickSelection;
            const hasHistory = history[row.id] && history[row.id].length;
            return (
                <TestCaseRightClickMenu
                  t={t}
                  styles={{top: `${top}px`, left: `${left}px`}}
                  onRemoveRow={doRemoveTestCaseRow}
                  onDisplaySummary={doShowTestCaseSummary}
                  onUndoLastChange={hasHistory ? (() => doUndoTestCaseRowChange({id: row.id})) : undefined}
                  onClose={clearTestCaseRowFocus}/>
            );
        }
    }

    createMenuEntry() {
        const {grouperTestCase: {focusedItemMenu}} = this.props;
        if (focusedItemMenu) {
            const {
                country,
                doUpdateFocusedTestCaseItem,
                clearTestCaseRowFocus,
            } = this.props;
            const {style} = focusedItemMenu;
            const {column, row} = focusedItemMenu;
            const allowEmpty = ALLOW_EMPTY_OPTION_COLUMNS[column];
            const viewProps = {style, onChange: doUpdateFocusedTestCaseItem, onClose: clearTestCaseRowFocus};
            switch (COLUMN_TYPES[column]) {
            case 'Boolean': return {
                ...focusedItemMenu,
                Component: <BooleanMenu value={row[column]} {...viewProps} />,
            };
            case 'DischargeMode': return {
                ...focusedItemMenu,
                Component: <DischargeModeMenu value={row[column]} {...viewProps}/>,
            };
            case 'DrgNameList': return {
                ...focusedItemMenu,
                Component: <DrgNameExtendedListMenu id={row[column]} allowEmpty={allowEmpty} value={row[column]} {...viewProps} />,
            };
            case 'DgPlus':
                if (country === 'com') {
                    return {
                        ...focusedItemMenu,
                        Component: <DgPlusMenu id={row[column]} value={row[column]} label={column} allowEmpty={allowEmpty} {...viewProps} />,
                    };
                }
                return {
                    ...focusedItemMenu,
                    Component: <DgCodeMenu id={row[column]} value={row[column]} label={column} allowEmpty={allowEmpty} {...viewProps} />,
                };
            case 'ProcPlus':
                if (country === 'com') {
                    return {
                        ...focusedItemMenu,
                        Component: <ProcPlusMenu id={row[column]} value={row[column]} label={column} allowEmpty={allowEmpty} {...viewProps} />,
                    };
                }
                return {
                    ...focusedItemMenu,
                    Component: <ProcCodeMenu id={row[column]} value={row[column]} label={column} allowEmpty={allowEmpty} {...viewProps} />,
                };
            case 'Errors':
                if (row.errorsList.length > 1) {
                    return {
                        ...focusedItemMenu,
                        Component: <ErrorsAndNotificationsListMenu list={row.errorsList} {...viewProps} />,
                    };
                }
                break;
            // case 'Notifications':
            //     if (row.notificationsList.length > 1) {
            //         return { ...focusedItemMenu,
            //             Component: <ErrorsAndNotificationsListMenu list={row.notificationsList} {...viewProps} />
            //         };
            //     }
            default: break;
            }
        }
    }

    renderAddRow() {
        const {grouperTestCase: {pendingRow}} = this.props;
        if (pendingRow) {
            const {
                t,
                updatePendingTestCaseRow,
                doAddTestCaseRow,
                grouperTestCase: {
                    spinner,
                    dimensions,
                    headers,
                    nonEditableFields,
                    typingDisabledFields,
                },
            } = this.props;
            return (
                <StickyTableRow
                  domain={domain}
                  dimensions={dimensions}
                  fields={headers}
                  columnTypes={COLUMN_TYPES}
                  allowEmptyOptionColumns={ALLOW_EMPTY_OPTION_COLUMNS}
                  values={pendingRow}
                  nonEditableFields={nonEditableFields}
                  typingDisabledFields={typingDisabledFields}
                  nonEditableRowsPlaceholder={t.autoGenerated}
                  onChange={spinner ? undefined : updatePendingTestCaseRow}
                  onSubmit={doAddTestCaseRow}/>
            );
        }
    }

    onColumnRightClick = ({rowIndex, columnIndex, left}) => {
        this.props.rightClickSelectTestCaseRow({rowIndex, columnIndex, leftOffset: left});
    };
}

export default connect(
    ({grouperTestCase, locales: {t, country}}) => ({grouperTestCase, t, country, ...selectorsWrapper(store, selectors)}),
    {...actionsWrapper(store, actions)},
)(TestCase);
