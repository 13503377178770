import React from 'react';
import {Routes, Route} from 'react-router-dom';
import Navigation from '../components/Navigation';
import Overlay from '../components/Overlay';
import Auth from './Auth';
import StopChildScrollOnBodyScroll from '../components/StopChildScrollOnBodyScroll';
import AuthRedirect from '../components/AuthRedirect';
import Main from '../components/Main';
import './styles.scss';

const App = () => (
    <StopChildScrollOnBodyScroll>
        <AuthRedirect>
            <Navigation/>
            <Overlay />
            <div className="main-content" >
                <Routes>
                    <Route path="/auth/*" element={<Auth />} />
                    <Route path="*" element={<Main />} />
                </Routes>
            </div>
        </AuthRedirect>
    </StopChildScrollOnBodyScroll>
);

export default App;
