import createReduxDomain from './resources/domainFactory';

const {reducer, types, selectors, actions, middlewares} = createReduxDomain({
    URL: '/diagnosis/common',
    DOMAIN: 'DG_PLUS',
    IDENTIFIER: 'dg_plus',
    excludeActions: [
        'doAdd$Row',
        'doLink$Code',
        'doLinkAll$Codes',
        'doRemove$Row',
        'doRemoveSelected$Rows',
        'doSelectLinkable$Code',
        'doSet$ActiveFilter',
        'doSet$VartypeFilter',
        'doSet$ExclusiveToORZeroFilter',
        'doSet$ExclusiveToDGCATZeroFilter',
        'doStore$Codes',
        'doUndo$RowChange',
        'doUpdate$Column',
    ],
});
export default reducer;

export {types, actions, selectors, middlewares};
