import {
    createResolveLatestIf,
    createDistinctLastTimeout,
    memoizedMap,
    testCaseCombinedRowFormatter,
    getTestCaseNationalRowFormatter,
    range,
} from '../common/utils';
import {
    onSetContent,
    onClearRows,
    onToggleRowSelection,
    onAddRow,
    onFieldOrderToggle,
    onUpdateOrder,
    onMoveSearchResultPointer,
    onSetFocusedItem,
    onUpdateColumn,
    onRightClickSelectRow,
    getFieldSpecificTimeout,
    onClearRowFocus,
    onArrowLeft,
    onArrowUp,
    onArrowDown,
    onArrowRight,
    onCursorLocationChange,
    onPreRemoveRow,
    onSetSearchResults,
    onTab,
    onInitPendingRow,
    onUpdateRow,
    onUndoChange,
} from './resources/reducerUtils';
import {showOverlay, setOverlayContent, LOGOUT} from './meta';
import {
    NON_EDITABLE_FIELDS,
    NON_SORTABLE_FIELDS,
    DEFAULT_COLUMN_WIDTH,
    TESTCASE_COLUMN_WIDTH,
} from '../common/constants';

// TYPES

export const CLEAR_FOCUSED_ITEM = 'GROUPER_TEST_CASE::CLEAR_FOCUSED_ITEM';
export const SET_FOCUSED_ITEM = 'GROUPER_TEST_CASE::SET_FOCUSED_ITEM';
export const FETCH_CONTENT = 'GROUPER_TEST_CASE::FETCH_CONTENT';
export const SET_CONTENT = 'GROUPER_TEST_CASE::SET_CONTENT';
export const SET_FILTER_TEXT = 'GROUPER_TEST_CASE::SET_FILTER_TEXT';
export const CLEAR_ROWS = 'GROUPER_TEST_CASE::CLEAR_ROWS';
export const UPDATE_FILTER_FIELDS = 'GROUPER_TEST_CASE::UPDATE_FILTER_FIELDS';
export const UPDATE_ORDER = 'GROUPER_TEST_CASE::UPDATE_ORDER';
export const FIELD_ORDER_TOGGLE = 'GROUPER_TEST_CASE::FIELD_ORDER_TOGGLE';
export const SET_UNEXPECTED_RESULTS_FILTER = 'GROUPER_TEST_CASE::SET_UNEXPECTED_RESULTS_FILTER';
export const SET_ERRORS_FILTER = 'GROUPER_TEST_CASE::SET_ERRORS_FILTER';
// export const SET_NOTIFICATIONS_FILTER = 'GROUPER_TEST_CASE::SET_NOTIFICATIONS_FILTER';
export const ADD_ROW = 'GROUPER_TEST_CASE::ADD_ROW';
export const TOGGLE_ROW_SELECTION = 'GROUPER_TEST_CASE::TOGGLE_ROW_SELECTION';
export const CLEAR_ROW_FOCUS = 'GROUPER_TEST_CASE::CLEAR_ROW_FOCUS';
export const CLEAR_ROW_SELECTIONS = 'GROUPER_TEST_CASE::CLEAR_ROW_SELECTIONS';
export const UPDATE_COLUMN = 'GROUPER_TEST_CASE::UPDATE_COLUMN';
export const SET_SEARCH_TEXT = 'GROUPER_TEST_CASE::SET_SEARCH_TEXT';
export const UPDATE_SEARCH_FIELDS = 'GROUPER_TEST_CASE::UPDATE_SEARCH_FIELDS';
export const SET_SEARCH_RESULT_ROWS = 'GROUPER_TEST_CASE::SET_SEARCH_RESULT_ROWS';
export const MOVE_SEARCH_RESULT_POINTER = 'GROUPER_TEST_CASE::MOVE_SEARCH_RESULT_POINTER';
export const RIGHT_CLICK_SELECT_ROW = 'GROUPER_TEST_CASE::RIGHT_CLICK_SELECT_ROW';
export const UPDATE_COLUMN_DIMENSIONS = 'GROUPER_TEST_CASE::UPDATE_COLUMN_DIMENSIONS';
export const UPDATE_PENDING_ROW = 'GROUPER_TEST_CASE::UPDATE_PENDING_ROW';
export const REMOVE_PENDING_ROW = 'GROUPER_TEST_CASE::REMOVE_PENDING_ROW';
export const ARROW_LEFT = 'GROUPER_TEST_CASE::ARROW_LEFT';
export const ARROW_RIGHT = 'GROUPER_TEST_CASE::ARROW_RIGHT';
export const ARROW_DOWN = 'GROUPER_TEST_CASE::ARROW_DOWN';
export const ARROW_UP = 'GROUPER_TEST_CASE::ARROW_UP';
export const SET_CURSOR_LOCATION = 'GROUPER_TEST_CASE::SET_CURSOR_LOCATION';
export const INIT_PENDING_ROW = 'GROUPER_TEST_CASE::INIT_PENDING_ROW';
export const CLEAR_ALL = 'GROUPER_TEST_CASE::CLEAR_ALL';
export const PRE_REMOVE_ROW = 'GROUPER_TEST_CASE::PRE_REMOVE_ROW';
export const SELECT_ALL_ROWS = 'GROUPER_TEST_CASE::SELECT_ALL_ROWS';
export const DISPLAY_SPINNER = 'GROUPER_TEST_CASE::DISPLAY_SPINNER';
export const HIDE_SPINNER = 'GROUPER_TEST_CASE::HIDE_SPINNER';
export const TAB = 'GROUPER_TEST_CASE::TAB';
export const FIND_SEARCH_RESULTS = 'GROUPER_TEST_CASE::FIND_SEARCH_RESULTS';
export const UPDATE_ROW = 'GROUPER_TEST_CASE::UPDATE_ROW';
export const UNDO_CHANGE = 'GROUPER_TEST_CASE::UNDO_CHANGE';

const dimensions = JSON.parse(localStorage.getItem('grouperTestCase_dimensions')) || TESTCASE_COLUMN_WIDTH;
dimensions.rowHeight = dimensions.rowHeight || 30;
dimensions.defaultColumnWidth = DEFAULT_COLUMN_WIDTH;

let history = localStorage.getItem('norddrg_grouperTestCase_history');
if (history) history = JSON.parse(history);
else history = {};
const initialState = {
    IDENTIFIER: 'id',
    spinner: false,
    nonEditableFields: NON_EDITABLE_FIELDS.grouperTestCase,
    nonSortableFields: NON_SORTABLE_FIELDS.grouperTestCase,
    typingDisabledFields: {
        drg_nat: true,
        drg_nat_previous: true,
        death: true,
        ...range(1, 10).reduce((acc, n) => ({...acc, [`dg${n}_plus`]: true}), {}),
        ...range(1, 10).reduce((acc, n) => ({...acc, [`d_dg${n}_plus`]: true}), {}),
        ...range(1, 10).reduce((acc, n) => ({...acc, [`proc${n}_plus`]: true}), {}),
    },
    rows: [], /* [RowData] */
    historySize: 50,
    history,
    headers: [], /* [string] */
    order: [], /* [ [fieldName: string, ASC/DESC: string] ] */
    filter: {text: '', fields: []},
    changedResultsFilter: undefined, /* bool */
    errorsFilter: undefined, /* bool */
    // notificationsFilter: undefined, /* bool */
    selectedRows: {}, /* {[rowId]: true, [rowId2]: true} | {[*]: true} */
    rowHighlight: undefined, /* number */
    search: {text: '', fields: []}, /* {text: string, fields: [string]} */
    searchResults: undefined, /* {pointer: number?, rowNumbers: [number]} */
    focusedItemMenu: undefined, // {rowIndex: number, column: string, row: RowData, style: {top: number, left: number}}
    pendingRow: undefined, /* RowData? */
    dimensions,
    cursor: {status: 'scroll', target: {}}, /* status: 'intent' | 'active', target: {row: number?, column: number?} */
    types: {
        death: 'boolean',
    },
};

// REDUCER

export default function grouperTestCaseReducer(state = initialState, {type, payload}) {
    switch (type) {
    case DISPLAY_SPINNER: return {...state, spinner: true};
    case HIDE_SPINNER: return {...state, spinner: false};
    case SELECT_ALL_ROWS: return {...state, selectedRows: {'*': true}};
    case PRE_REMOVE_ROW:
        return onPreRemoveRow({state, payload});
    case ARROW_RIGHT:
        return onArrowRight({state});
    case ARROW_LEFT:
        return onArrowLeft({state});
    case ARROW_UP:
        return onArrowUp({state});
    case ARROW_DOWN:
        return onArrowDown({state});
    case TAB:
        return onTab({state});
    case SET_CURSOR_LOCATION:
        return onCursorLocationChange({state, payload});
    case INIT_PENDING_ROW:
        return onInitPendingRow({state});
    case UPDATE_PENDING_ROW:
        return {...state, pendingRow: {...state.pendingRow, ...payload}};
    case REMOVE_PENDING_ROW:
        return {...state, pendingRow: undefined};
    case MOVE_SEARCH_RESULT_POINTER:
        return onMoveSearchResultPointer({state, payload});
    case UPDATE_COLUMN_DIMENSIONS: {
        const { dimensions } = state;
        return {...state, dimensions: {...dimensions, [payload.field]: payload.width}};
    }
    case TOGGLE_ROW_SELECTION:
        return onToggleRowSelection({state, payload, identifier: 'id'});
    case SET_SEARCH_RESULT_ROWS:
        return onSetSearchResults({state, payload});
    case UPDATE_COLUMN:
        return onUpdateColumn({state, payload, identifier: 'id'});
    case CLEAR_ROW_SELECTIONS:
        return {...state, selectedRows: {}};
    case RIGHT_CLICK_SELECT_ROW:
        return onRightClickSelectRow({state, payload});
    case ADD_ROW:
        return onAddRow({state, payload, identifier: 'id'});
    case CLEAR_ROW_FOCUS:
        return onClearRowFocus({state});
    case FIND_SEARCH_RESULTS:
    case FETCH_CONTENT: return {...state, loadingContent: true};
    case SET_CONTENT: {
        const nextState = onSetContent({state, payload, identifier: 'id'});
        nextState.headers = nextState.headers.filter((key) => !['errorsList'].includes(key));
        // nextState.headers = nextState.headers.filter(key => !['errorsList', 'notificationsList'].includes(key));
        return nextState;
    } case SET_FILTER_TEXT:
        return {...state, filter: {...state.filter, text: payload}};
    case SET_SEARCH_TEXT:
        return {...state, search: {...state.search, text: payload}, searchResults: undefined};
    case UPDATE_FILTER_FIELDS:
        return {...state, filter: {...state.filter, fields: payload}};
    case UPDATE_SEARCH_FIELDS:
        return {...state, search: {...state.search, fields: payload}, searchResults: undefined};
    case UPDATE_ORDER:
        return onUpdateOrder({state, payload});
    case FIELD_ORDER_TOGGLE:
        return onFieldOrderToggle({state, payload});
    case SET_UNEXPECTED_RESULTS_FILTER:
        return {...state, filter: {...state.filter}, rows: [], changedResultsFilter: payload};
    case SET_ERRORS_FILTER:
        return {...state, filter: {...state.filter}, rows: [], errorsFilter: payload};
    // case SET_NOTIFICATIONS_FILTER:
    //     return {...state, filter: {...state.filter}, rows: [], notificationsFilter: payload};
    case CLEAR_ROWS:
        return onClearRows({state});
    case SET_FOCUSED_ITEM:
        return onSetFocusedItem({state, payload});
    case CLEAR_FOCUSED_ITEM:
        return state;
    case CLEAR_ALL:
        return {...initialState, headers: state.headers};
    case UPDATE_ROW:
        return onUpdateRow({state, payload, identifier: 'id'});
    case UNDO_CHANGE:
        return onUndoChange({state, payload, identifier: 'id'});
    default: return state;
    }
}

// ACTION CREATORS
export const clearTestCaseRowFocus = () => ({type: CLEAR_ROW_FOCUS});
export const clearTestCaseRowSelections = () => ({type: CLEAR_ROW_SELECTIONS});
export const selectAllTestCaseRows = () => ({type: SELECT_ALL_ROWS});
export const clearFocusedTestCaseItem = () => ({type: CLEAR_FOCUSED_ITEM});
export const initPendingTestCaseRow = () => ({type: INIT_PENDING_ROW});
export const testCaseGridArrowRight = () => ({type: ARROW_RIGHT});
export const testCaseGridArrowLeft = () => ({type: ARROW_LEFT});
export const testCaseGridArrowUp = () => ({type: ARROW_UP});
export const testCaseGridArrowDown = () => ({type: ARROW_DOWN});
export const testCaseGridTab = () => ({type: TAB});
export const setTestCaseGridCursorLocation = ({rowIndex, columnIndex}) => ({type: SET_CURSOR_LOCATION, payload: {rowIndex, columnIndex}});
export const toggleTestCaseRowSelection = (rowIndex) => ({type: TOGGLE_ROW_SELECTION, payload: rowIndex});
export const moveTestCaseSearchResultPointer = (direction) => ({type: MOVE_SEARCH_RESULT_POINTER, payload: direction});
export const updatePendingTestCaseRow = (update) => ({type: UPDATE_PENDING_ROW, payload: update});
export const removePendingTestCaseRow = () => ({type: REMOVE_PENDING_ROW});
export const rightClickSelectTestCaseRow = ({
    absoluteLeftOffset,
    rowIndex,
    columnIndex,
    leftOffset,
}) => ({type: RIGHT_CLICK_SELECT_ROW, payload: {rowIndex, columnIndex, leftOffset, absoluteLeftOffset}});

// THUNKS

const resolveLast = createDistinctLastTimeout();
const resolveDimensionSave = createDistinctLastTimeout();
const resolveFocusedItemMenuInfo = createResolveLatestIf();
const resolveFetchSearchResults = createResolveLatestIf();
const {entries} = Object;
let testCaseRowIndex = 0;

// NOT_EXPORTED - ONLY CALLED BY OTHER OTHER THUNKS
function doSearchMatchingRowNumbers() {
    return async function fetchSearchMatchingRowNumbers(dispatch, getState, Api) {
        const state = getState();
        if (!state.grouperTestCase.loadingContent) {
            dispatch({type: FIND_SEARCH_RESULTS});
        }
        const {
            grouperTestCase: {
                order,
                filter,
                search,
                changedResultsFilter,
                errorsFilter,
                // notificationsFilter
            },
            locales: {country},
        } = state;
        const {cancelled} = await resolveFetchSearchResults({
            initialDelay: 500,
            cancelOn() {
                const {locales, grouperTestCase} = getState();
                return !grouperTestCase.search.text
                    || country !== locales.country
                    || entries({
                        order,
                        filter,
                        search,
                        changedResultsFilter,
                        errorsFilter,
                        // notificationsFilter
                    }).some(([k, initialValue]) => initialValue !== grouperTestCase[k]);
            },
        });
        const payload = {
            order,
            filter,
            search,
            globalFilter: {
                country,
                changedResults: changedResultsFilter,
                errors: errorsFilter,
                // notifications: notificationsFilter
            },
        };
        const {matchingRowNumbers} = await Api.post('/test_case/search', {payload, cancelled});
        dispatch({type: SET_SEARCH_RESULT_ROWS, payload: matchingRowNumbers});
    };
}

export function doFetchTestCaseContent(rowIndex) {
    testCaseRowIndex = rowIndex;
    const waitForResolve = resolveLast(1000);
    const offset = (testCaseRowIndex - 200) < 0 ? 0 : (testCaseRowIndex - 200);
    return async function fetchContent(dispatch, getState, Api) {
        const {grouperTestCase, locales: {country}} = getState();
        if (!grouperTestCase.loadingContent) {
            dispatch({type: FETCH_CONTENT});
        }
        const {cancelled} = await waitForResolve;
        const {
            order,
            filter,
            changedResultsFilter,
            errorsFilter,
            // notificationsFilter
        } = grouperTestCase;
        const payload = {
            order,
            filter,
            offset,
            limit: 400,
            globalFilter: {
                country,
                changedResults: changedResultsFilter,
                errors: errorsFilter,
                // notifications: notificationsFilter
            },
        };
        const result = await Api.post('/test_case/fetch', {payload, cancelled});
        const formatter = country === 'com' ? testCaseCombinedRowFormatter : getTestCaseNationalRowFormatter(country);
        result.rows = result.rows.map(formatter);
        dispatch({type: SET_CONTENT, payload: {...result, offset}});
    };
}

export function doRemoveTestCaseRow() {
    return async function removeRow(dispatch, getState, Api) {
        const { grouperTestCase: { rightClickSelection: { row: { id } } } } = getState(); // must be read before dispatch
        const { rows } = getState().grouperTestCase;
        const rowIndex = rows.findIndex((row) => row.id === id);
        dispatch({
            type: PRE_REMOVE_ROW,
            payload: { rowIndex },
        });
        await Api.del(`/test_case/${id}`);
        await dispatch(doFetchTestCaseContent(rowIndex));
    };
}

export function doRemoveSelectedTestCaseRows() {
    return async function removeRows(dispatch, getState, Api) {
        const {grouperTestCase: {selectedRows}} = getState();

        if (selectedRows['*']) {
            await Api.del('/test_case/all');
        } else {
            const ids = Object.keys(selectedRows).join('&');
            await Api.del(`/test_case/${ids}`);
        }
        await dispatch(doFetchTestCaseContent(0));
    };
}

export function doShowTestCaseSummary() {
    return async function (dispatch, getState, Api) {
        const { id } = getState().grouperTestCase.rightClickSelection.row;
        dispatch(showOverlay);
        const summary = await Api.get(`/test_case/summary/${id}`);
        dispatch(setOverlayContent(summary));
    };
}

export function doSetFocusedTestCaseItem({rowIndex, columnIndex}) {
    return async function setFocusedItem(dispatch, getState) {
        dispatch({type: SET_FOCUSED_ITEM, payload: {rowIndex, columnIndex}});
        const {focusedItemMenu} = getState().grouperTestCase;
        await resolveFocusedItemMenuInfo({
            cancelOn() { return !focusedItemMenu || getState().grouperTestCase.focusedItemMenu !== focusedItemMenu; },
        });
    };
}

export function doUpdateTestCaseDimensions({field, width}) {
    return async function updateDimensions(dispatch, getState) {
        if (width > 50) {
            dispatch({type: UPDATE_COLUMN_DIMENSIONS, payload: {field, width}});
            await resolveDimensionSave(200);
            const {dimensions} = getState().grouperTestCase;
            localStorage.setItem('grouperTestCase_dimensions', JSON.stringify(dimensions));
        }
    };
}

export function doClearTestCaseRowFocus({clearItemFocus}) {
    return function clearRowHighlight(dispatch, getState) {
        const {rightClickSelection, rowHighlight, focusedItemMenu} = getState().grouperTestCase;
        if (rightClickSelection || rowHighlight || (focusedItemMenu && clearItemFocus)) {
            dispatch({type: CLEAR_ROW_FOCUS, payload: {clearItemFocus}});
        }
    };
}

export function doAddTestCaseRow() {
    return async function addRow(dispatch, getState, Api) {
        const {grouperTestCase: {pendingRow}, locales: {country}} = getState();
        await Api.post('/test_case/add', {payload: {row: pendingRow, country}});
        dispatch({type: CLEAR_ROWS});
        dispatch(doFetchTestCaseContent(0));
    };
}

export function doSetTestCaseFilterText(text) {
    const waitForResolve = resolveLast(500);
    return async function setFilterText(dispatch) {
        dispatch({type: SET_FILTER_TEXT, payload: text});
        await waitForResolve;
        dispatch({type: CLEAR_ROWS});
        dispatch(doFetchTestCaseContent(0));
    };
}

export function doSetTestCaseSearchText(text) {
    return async function setFilterText(dispatch) {
        dispatch({type: SET_SEARCH_TEXT, payload: text});
        if (text) {
            dispatch(doSearchMatchingRowNumbers());
        }
    };
}

export function doUpdateTestCaseFilterFields(fields) {
    return function updateFilterFields(dispatch, getState) {
        dispatch({type: UPDATE_FILTER_FIELDS, payload: fields});
        if (getState().grouperTestCase.filter.text) {
            dispatch({type: CLEAR_ROWS});
            dispatch(doFetchTestCaseContent(0));
        }
    };
}

export function doSetTestCaseSearchFields(fields) {
    return async function setFilterText(dispatch, getState) {
        dispatch({type: UPDATE_SEARCH_FIELDS, payload: fields});
        if (getState().grouperTestCase.search.text) {
            dispatch(doSearchMatchingRowNumbers());
        }
    };
}

export function doUpdateTestCaseOrder(fields) {
    return function updateOrder(dispatch, getState) {
        const {nonSortableFields} = getState().grouperTestCase;
        if (fields.every(([fieldName]) => !nonSortableFields[fieldName])) {
            dispatch({type: UPDATE_ORDER, payload: fields});
            dispatch({type: CLEAR_ROWS});
            dispatch(doFetchTestCaseContent(0));
        }
    };
}

export function doToggleTestCaseFieldOrder(field) {
    return function toggleFieldOrder(dispatch, getState) {
        const {nonSortableFields} = getState().grouperTestCase;
        if (!nonSortableFields[field]) {
            dispatch({type: FIELD_ORDER_TOGGLE, payload: field});
            dispatch({type: CLEAR_ROWS});
            dispatch(doFetchTestCaseContent(0));
        }
    };
}

export function doSetTestCaseUnexpectedResultsFilter(bool) {
    return function setUnexpectedResultsFilter(dispatch) {
        dispatch({type: SET_UNEXPECTED_RESULTS_FILTER, payload: bool});
        dispatch({type: CLEAR_ROWS});
        dispatch(doFetchTestCaseContent(0));
    };
}

export function doSetTestCaseErrorsFilter(bool) {
    return function setErrorsFilter(dispatch) {
        dispatch({type: SET_ERRORS_FILTER, payload: bool});
        dispatch({type: CLEAR_ROWS});
        dispatch(doFetchTestCaseContent(0));
    };
}

// export function doSetTestCaseNotificationsFilter(bool) {
//     return function setNotificationsFilter(dispatch) {
//         dispatch({type: SET_NOTIFICATIONS_FILTER, payload: bool});
//         dispatch({type: CLEAR_ROWS});
//         dispatch(doFetchTestCaseContent(0));
//     };
// }

export function doUndoTestCaseRowChange({id}) {
    return async function undoRowChange(dispatch, getState, Api) {
        const {grouperTestCase: {history}, locales: {country}} = getState();
        const {column, value} = history[id][0];
        dispatch({type: UNDO_CHANGE, payload: id});
        const {cancelled} = await getFieldSpecificTimeout({domain: 'test_case', row: id, column})(1000);
        await Api.put('/test_case/update', {payload: {id, column, value, country}, cancelled});
    };
}

export function doUpdateTestCaseColumn({id, column, value, timestamp = Date.now()}) {
    return async function updateColumn(dispatch, getState, Api) {
        const {grouperTestCase: {nonEditableFields}, locales: {country}} = getState();
        if (nonEditableFields.indexOf(column) === -1) {
            const payload = {id, column, value};
            dispatch({type: UPDATE_COLUMN, payload: {...payload, timestamp}});
            const {cancelled} = await getFieldSpecificTimeout({domain: 'test_case', row: id, column})(1000);
            const updatedRow = await Api.put('/test_case/update', {payload: {...payload, country}, cancelled});
            const formatter = country === 'com' ? testCaseCombinedRowFormatter : getTestCaseNationalRowFormatter(country);
            dispatch({type: UPDATE_ROW, payload: formatter(updatedRow)});
        }
    };
}

export function doUpdateFocusedTestCaseItem({value}) {
    return async function (dispatch, getState) {
        const {column, row: {id}} = getState().grouperTestCase.focusedItemMenu;
        return dispatch(doUpdateTestCaseColumn({id, column, value}));
    };
}

export function doRunTestCases() {
    return async function (dispatch, getState, Api) {
        const {grouperTestCase: {selectedRows, filter}, locales: {country}} = getState();
        let ids;
        if (selectedRows['*']) { ids = []; } else { ids = Object.keys(selectedRows); }
        const payload = {ids, filter, globalFilter: {country}};
        dispatch({type: DISPLAY_SPINNER});
        await Api.post('/test_case/grouper', {payload, json: false});
        dispatch({type: HIDE_SPINNER});
        await dispatch(doFetchTestCaseContent(testCaseRowIndex));
    };
}

export function doCopyTestCaseResults() {
    return async function (dispatch, getState, Api) {
        const {grouperTestCase: {selectedRows, filter}, locales: {country}} = getState();
        let ids;
        if (selectedRows['*']) { ids = []; } else { ids = Object.keys(selectedRows); }
        const payload = {ids, filter, globalFilter: {country}};
        dispatch({type: DISPLAY_SPINNER});
        await Api.post('/test_case/copyResults', {payload});
        dispatch({type: HIDE_SPINNER});
        await dispatch(doFetchTestCaseContent(testCaseRowIndex));
        dispatch({type: CLEAR_ROW_SELECTIONS});
    };
}

export const middlewares = [
    () => (next) => (action) => {
        if (action.type === LOGOUT) {
            localStorage.removeItem(`norddrg_${'grouperTestCase'}_history`);
        }
        return next(action);
    },
];

export const selectors = {
    getSelectedTestCaseRows: memoizedMap((rows, selectedRows) => {
        if (selectedRows['*']) {
            return selectedRows;
        }
        return Object.keys(selectedRows)
            .map((id) => rows.findIndex((row) => `${row.id}` === id))
            .filter((it) => it !== -1)
            .reduce((acc, index) => ({...acc, [index]: true}), {});
    }),
};

export const actions = {
    clearTestCaseRowFocus,
    clearTestCaseRowSelections,
    selectAllTestCaseRows,
    clearFocusedTestCaseItem,
    initPendingTestCaseRow,
    testCaseGridArrowRight,
    testCaseGridArrowLeft,
    testCaseGridArrowUp,
    testCaseGridArrowDown,
    testCaseGridTab,
    setTestCaseGridCursorLocation,
    toggleTestCaseRowSelection,
    moveTestCaseSearchResultPointer,
    updatePendingTestCaseRow,
    removePendingTestCaseRow,
    rightClickSelectTestCaseRow,
    doSearchMatchingRowNumbers,
    doFetchTestCaseContent,
    doRemoveTestCaseRow,
    doRemoveSelectedTestCaseRows,
    doShowTestCaseSummary,
    doSetFocusedTestCaseItem,
    doUpdateTestCaseDimensions,
    doClearTestCaseRowFocus,
    doAddTestCaseRow,
    doSetTestCaseFilterText,
    doSetTestCaseSearchText,
    doUpdateTestCaseFilterFields,
    doSetTestCaseSearchFields,
    doUpdateTestCaseOrder,
    doToggleTestCaseFieldOrder,
    doSetTestCaseUnexpectedResultsFilter,
    doSetTestCaseErrorsFilter,
    // doSetTestCaseNotificationsFilter,
    doUndoTestCaseRowChange,
    doUpdateTestCaseColumn,
    doUpdateFocusedTestCaseItem,
    doRunTestCases,
    doCopyTestCaseResults,
};
