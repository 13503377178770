/* eslint-disable  */
import React from 'react';
import {func} from 'prop-types';
import {connect} from 'react-redux';

const DgNatListMenu = ({t, matchingDgNatItems, onClose, country}) => {
    if (matchingDgNatItems.length === 0) {
        return (<div />);
    }
    return (
        <div className="cell-context-menu" onClick={onClose}>
            <div className="cell-context-description-menu">
                {matchingDgNatItems.map((item) => (
                    <div key={item.rid} className="cell-context-menu-description-item">
                        {country === 'com' && <p>{t.country}: {item.country || '--'}</p>}
                        <p>{t.mbc.dgNatOneColumnName}: {item.dg1 || '--'}</p>
                        <p>{t.mbc.dgNatTwoColumnName}: {item.dg2 || '--'}</p>
                        <p>{t.textNat}: {item.text_nat || '--'}</p>
                    </div>
                  ))}
            </div>
        </div>
    );
};

DgNatListMenu.propTypes = {
    onClose: func.isRequired,
};

export default connect(({locales: {t, country}, mbcDgNatOptions: {dgNatItems, loading}}, {id}) => {
    const matchingDgNatItems = country === 'com'
        ? dgNatItems.filter((row) => row.plus1 === id)
        : dgNatItems.filter((row) => row.plus1 === id && row.country === country) || undefined;
    return ({t, matchingDgNatItems, loading});
})(DgNatListMenu);
