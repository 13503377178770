import {memoizedMap} from '../../common/utils';

const CLEAR_ROW_FOCUS = 'DG_PLUS::CLEAR_ROW_FOCUS';
const SET_DG_PLUS_OPTIONS = 'DG_PLUS::SET_DG_PLUS_OPTIONS';
const FETCH_DG_PLUS_OPTIONS = 'DG_PLUS::FETCH_DG_PLUS_OPTIONS';
const FETCH_DG_PLUS_OPTIONS_ERROR = 'DG_PLUS::FETCH_DG_PLUS_OPTIONS_ERROR';

const initialState = {
    dgPlusOptionsData: [],
    loading: false,
};
export default function dgPlusReducer(state = initialState, {payload, type}) {
    switch (type) {
    case FETCH_DG_PLUS_OPTIONS:
        return {...state, loading: true, dgPlusOptionsData: []};
    case FETCH_DG_PLUS_OPTIONS_ERROR:
        return {...state, loading: state.loading};
    case SET_DG_PLUS_OPTIONS:
        return {...state, dgPlusOptionsData: payload, loading: false};
    default: return state;
    }
}

function doFetchDgPlusOptions() {
    return async function fetchCommon(dispatch, getState, Api) {
        dispatch({type: FETCH_DG_PLUS_OPTIONS});
        try {
            dispatch({type: CLEAR_ROW_FOCUS});
            const result = await Api.post('/mbc/dg_plus/fetch');
            dispatch({type: SET_DG_PLUS_OPTIONS, payload: result.rows});
        } catch (e) {
            dispatch({type: FETCH_DG_PLUS_OPTIONS_ERROR});
            throw e;
        }
    };
}

export const actions = {
    doFetchDgPlusOptions,
};

export const types = {
    CLEAR_ROW_FOCUS,
    SET_DG_PLUS_OPTIONS,
    FETCH_DG_PLUS_OPTIONS,
    FETCH_DG_PLUS_OPTIONS_ERROR,
};

export const selectors = {
    getIndexedDgPlusOptions: memoizedMap(({ dgPlusOptionsData, country }) => dgPlusOptionsData
        .filter((opt) => (country && country !== 'com' ? opt.nat_ver && opt.nat_ver.includes(country) : opt))
        .map((opt) => ({label: `${opt.plus_dg} - ${opt.text}`, value: opt.plus_dg}))),
};
