import React from 'react';
import {connect} from 'react-redux';
import CommonGridScene from './CommonGridScene';
import {removeStringFromKeys} from '../common/utils';
import {actions, selectors} from '../store/diagnosisFeature';
import {actionsWrapper, selectorsWrapper} from '../store/resources/reducerUtils';
import store from '../store';

const domain = 'diagnosisFeature';
const DiagnosisFeature = (props) => {
    const viewProps = removeStringFromKeys(props, domain);
    const {country} = props;

    return (
        <CommonGridScene
          domain={domain}
          identifier="rid"
          columnTypes={{
            active: 'Boolean',
            vartype: 'Vartype',
            varval: 'Varval',
            dg_plus: 'DgPlus',
            d_dg_plus: 'DgPlus',
            errors: 'Errors',
            // notifications: 'Notifications',
          }}
          allowEmptyOptionColumns={{
            d_dg_plus: true,
          }}
          enableAddRow
          enableRemoveRow
          enableRowSelect={country === 'com'}
          newRowEditables={['vartype', 'dg_plus', 'd_dg_plus']}
          {...viewProps} />
    );
};

export default connect(
    ({locales: {country}}) => ({country, ...selectorsWrapper(store, selectors)}),
    {...actionsWrapper(store, actions)},
)(DiagnosisFeature);
