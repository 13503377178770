import React from 'react';
import '../Notifications/styles.scss';
import './styles.scss';
import switchIcon from '../../../images/switch-icon.svg';

const MbcNdmsToggle = (props) => {
    const { isMbc } = props;
    return (isMbc ? (
        <a
          href="/"
          className="ndms-toggle">
            <span>NDMS</span>
            <img className="switch-icon" src={switchIcon} alt="Logo" />
        </a>
    ) : (
        <a
          href="/mbc/dg_nat"
          className="mbc-toggle">
            <span>MBC</span>
            <img className="switch-icon" src={switchIcon} alt="Logo" />
        </a>
    ));
};

export default MbcNdmsToggle;