import { applyMiddleware, createStore, compose, combineReducers } from 'redux';
import locales from './locales';
import diagnosisRelations from './diagnosisRelations';
import procedureRelations from './procedureRelations';
import meta from './meta';
import drgNameOptions from './drgNameOptions';
import mdcNameOptions from './mdcNameOptions';
import mbcDgNatOptions from './mbc/dgNatOptions';
import mbcDgPlusOptions from './mbc/dgPlusOptions';
import mbcProcNatOptions from './mbc/procNatOptions';
import mbcProcPlusOptions from './mbc/procPlusOptions';
import mbcAtcNatOptions from './mbc/atcNatOptions';
import mbcAtcPlusOptions from './mbc/atcPlusOptions';
import errorNotification from './errorNotification';
import notification from './notification';
import grouperTestCase, {middlewares as grouperTestCaseMiddlewares} from './grouperTestCase';
import logic, {middlewares as logicMiddlewares} from './logic';
import groupingPropertyName, {middlewares as groupingPropertyNameMiddlewares} from './groupingPropertyName';
import procedurePropertyName, {middlewares as procedurePropertyNameMiddlewares} from './procedurePropertyName';
import complicationCategoryName, {middlewares as complicationCategoryNameMiddlewares} from './complicationCategoryName';
import diagnosisCategoryName, {middlewares as diagnosisCategoryNameMiddlewares} from './diagnosisCategoryName';
import principalDiagnosisProperty, {middlewares as principalDiagnosisPropertyMiddlewares} from './principalDiagnosisProperty';
import procedureFeature, {middlewares as procedureFeatureMiddlewares} from './procedureFeature';
import diagnosisFeature, {middlewares as diagnosisFeatureMiddlewares} from './diagnosisFeature';
import dgNat, {middlewares as dgNatMiddlewares} from './dgNat';
import dgPlus, {middlewares as dgPlusMiddlewares} from './dgPlus';
import procNat, {middlewares as procNatMiddlewares} from './procNat';
import procPlus, {middlewares as procPlusMiddlewares} from './procPlus';
import drgName, {middlewares as drgNameMiddlewares} from './drgName';
import drgNameCombined from './drgNameCombined';
import mdcName, {middlewares as mdcNameMiddlewares} from './mdcName';
import mdcNameCombined from './mdcNameCombined';
import mbcDgNat, {middlewares as mbcDgNatMiddlewares} from './mbc/dgNat';
import mbcDgPlus, {middlewares as mbcDgPlusMiddlewares} from './mbc/dgPlus';
import mbcProcNat, {middlewares as mbcProcNatMiddlewares} from './mbc/procNat';
import mbcProcPlus, {middlewares as mbcProcPlusMiddlewares} from './mbc/procPlus';
import mbcAtcNat, {middlewares as mbcAtcNatMiddlewares} from './mbc/atcNat';
import mbcAtcPlus, {middlewares as mbcAtcPlusMiddlewares} from './mbc/atcPlus';
import dumps from './dumps';
import admin from './admin';
import Api from '../common/Api';
import commonMiddlewares from './middlewares';
import { NODE_ENV } from '../common/constants';

const middlewares = [
    ...commonMiddlewares,
    ...grouperTestCaseMiddlewares,
    ...logicMiddlewares,
    ...groupingPropertyNameMiddlewares,
    ...procedurePropertyNameMiddlewares,
    ...complicationCategoryNameMiddlewares,
    ...diagnosisCategoryNameMiddlewares,
    ...principalDiagnosisPropertyMiddlewares,
    ...procedureFeatureMiddlewares,
    ...diagnosisFeatureMiddlewares,
    ...dgNatMiddlewares,
    ...dgPlusMiddlewares,
    ...procNatMiddlewares,
    ...procPlusMiddlewares,
    ...mbcDgNatMiddlewares,
    ...mbcDgPlusMiddlewares,
    ...mbcProcNatMiddlewares,
    ...mbcProcPlusMiddlewares,
    ...mbcAtcNatMiddlewares,
    ...mbcAtcPlusMiddlewares,
    ...drgNameMiddlewares,
    ...mdcNameMiddlewares,
];

const configureStore = () => {
    const composeEnhancers = typeof window === 'object'
        && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        }) : compose;
    const enhancer = composeEnhancers(applyMiddleware(...middlewares));
    let store;

    const rootReducer = combineReducers({
        drgName,
        drgNameCombined,
        mdcName,
        mdcNameCombined,
        procedurePropertyName,
        drgNameOptions,
        mdcNameOptions,
        mbcDgNatOptions,
        mbcDgPlusOptions,
        mbcProcNatOptions,
        mbcProcPlusOptions,
        mbcAtcNatOptions,
        mbcAtcPlusOptions,
        locales,
        logic,
        meta,
        dgNat,
        procNat,
        mbcDgNat,
        mbcDgPlus,
        dgPlus,
        mbcProcNat,
        mbcProcPlus,
        mbcAtcNat,
        mbcAtcPlus,
        procPlus,
        grouperTestCase,
        diagnosisRelations,
        procedureRelations,
        groupingPropertyName,
        complicationCategoryName,
        diagnosisCategoryName,
        principalDiagnosisProperty,
        procedureFeature,
        diagnosisFeature,
        errorNotification,
        notification,
        dumps,
        admin,
    });
    if (NODE_ENV !== 'production') {
        store = createStore(rootReducer, enhancer);
    } else {
        const middleware = applyMiddleware(...middlewares);
        store = createStore(rootReducer, middleware);
    }

    Api.dispatch = store.dispatch;
    Api.getState = store.getState;
    return store;
};

const store = configureStore();

export default store;
