import {
    createResolveLatestIf,
    createDistinctLastTimeout,
    memoizedMap,
    logicCombinedRowFormatter,
    getLogicNationalRowFormatter,
    orderNullsFix,
} from '../common/utils';
import { DEFAULT_COLUMN_WIDTH, LOGIC_COLUMN_WIDTH, NON_EDITABLE_FIELDS, NON_SORTABLE_FIELDS } from '../common/constants';
import {
    onCursorLocationChange,
    onSetContent,
    onClearRows,
    onMoveRows,
    onToggleRowSelection,
    onAddRow,
    onFieldOrderToggle,
    onUpdateOrder,
    onMoveSearchResultPointer,
    onSetFocusedItem,
    onRightClickSelectRow,
    onUpdateColumn,
    getFieldSpecificTimeout,
    onClearRowFocus,
    onArrowRight,
    onArrowLeft,
    onArrowDown,
    onArrowUp,
    onUpdateRow,
    onTab,
    onSetSearchResults,
    onPreRemoveRow,
    onUndoChange,
    onCopySelectedRow,
} from './resources/reducerUtils';
import {LOGOUT} from './meta';

const { v4: uuidV4 } = require('uuid');

const resolveLast = createDistinctLastTimeout();
const resolveDimensionSave = createDistinctLastTimeout();
const resolveFocusedItemMenuInfo = createResolveLatestIf();
const resolveFetchSearchResults = createResolveLatestIf();

const {entries} = Object;
// TYPES
export const CLEAR_FOCUSED_ITEM = 'LOGIC::CLEAR_FOCUSED_ITEM';
export const SET_FOCUSED_ITEM = 'LOGIC::SET_FOCUSED_ITEM';
export const FETCH_CONTENT = 'LOGIC::FETCH_CONTENT';
export const SET_CONTENT = 'LOGIC::SET_CONTENT';
export const SET_FILTER_TEXT = 'LOGIC::SET_FILTER_TEXT';
export const CLEAR_ROWS = 'LOGIC::CLEAR_ROWS';
export const UPDATE_FILTER_FIELDS = 'LOGIC::UPDATE_FILTER_FIELDS';
export const UPDATE_ORDER = 'LOGIC::UPDATE_ORDER';
export const FIELD_ORDER_TOGGLE = 'LOGIC::FIELD_ORDER_TOGGLE';
export const SET_ACTIVE_FILTER = 'LOGIC::SET_ACTIVE_FILTER';
export const SET_ERRORS_FILTER = 'LOGIC::SET_ERRORS_FILTER';
export const SET_NOTIFICATIONS_FILTER = 'LOGIC::SET_NOTIFICATIONS_FILTER';
export const ADD_ROW = 'LOGIC::ADD_ROW';
export const TOGGLE_ROW_SELECTION = 'LOGIC::TOGGLE_ROW_SELECTION';
export const MOVE_ROWS = 'LOGIC::MOVE_ROWS';
export const CLEAR_ROW_FOCUS = 'LOGIC::CLEAR_ROW_FOCUS';
export const CLEAR_ROW_SELECTIONS = 'LOGIC::CLEAR_ROW_SELECTIONS';
export const UPDATE_COLUMN = 'LOGIC::UPDATE_COLUMN';
export const SET_SEARCH_TEXT = 'LOGIC::SET_SEARCH_TEXT';
export const UPDATE_SEARCH_FIELDS = 'LOGIC::UPDATE_SEARCH_FIELDS';
export const SET_SEARCH_RESULT_ROWS = 'LOGIC::SET_SEARCH_RESULT_ROWS';
export const MOVE_SEARCH_RESULT_POINTER = 'LOGIC::MOVE_SEARCH_RESULT_POINTER';
export const RIGHT_CLICK_SELECT_ROW = 'LOGIC::RIGHT_CLICK_SELECT_ROW';
export const UPDATE_COLUMN_DIMENSIONS = 'LOGIC::UPDATE_COLUMN_DIMENSIONS';
export const ARROW_LEFT = 'LOGIC::ARROW_LEFT';
export const ARROW_RIGHT = 'LOGIC::ARROW_RIGHT';
export const ARROW_DOWN = 'LOGIC::ARROW_DOWN';
export const ARROW_UP = 'LOGIC::ARROW_UP';
export const TAB = 'LOGIC::TAB';
export const SET_CURSOR_LOCATION = 'LOGIC::SET_CURSOR_LOCATION';
export const PRE_REMOVE_ROW = 'LOGIC::PRE_REMOVE_ROW';
export const SELECT_ALL_ROWS = 'LOGIC::SELECT_ALL_ROWS';
export const UPDATE_ROW = 'LOGIC::UPDATE_ROW';
export const FIND_SEARCH_RESULTS = 'LOGIC::FIND_SEARCH_RESULTS';
export const UNDO_CHANGE = 'LOGIC::UNDO_CHANGE';
export const COPY_SELECTED_ROW = 'LOGIC::COPY_SELECTED_ROW';

const dimensions = JSON.parse(localStorage.getItem('logic_dimensions')) || LOGIC_COLUMN_WIDTH;
dimensions.rowHeight = dimensions.rowHeight || 30;
dimensions.defaultColumnWidth = DEFAULT_COLUMN_WIDTH;

let history = localStorage.getItem('norddrg_logic_history');
if (history) history = JSON.parse(history);
else history = {};
const initialState = {
    IDENTIFIER: 'uuid',
    nonEditableFields: NON_EDITABLE_FIELDS.logic, /* read only */
    nonSortableFields: NON_SORTABLE_FIELDS.logic,
    historySize: 50,
    history, /* {[row]: {[column]: [{timestamp: number, value: any}]}} */
    rows: [], /* [RowData] */
    headers: [], /* [string] */
    order: [], /* [ [fieldName: string, ASC/DESC: string] ] */
    filter: {text: '', fields: []},
    selectedRows: {}, /* {[rowId]: true, [rowId2]: true} */
    rowHighlight: undefined, /* number */
    activeFilter: undefined, /* bool */
    errorsFilter: undefined, /* bool */
    notificationsFilter: undefined, /* bool */
    search: {text: '', fields: []}, /* {text: string, fields: [string]} */
    searchResults: undefined, /* {pointer: number?, rowNumbers: [number]} */
    rightClickSelection: undefined, // {top: number, left: number: row: RowData}
    focusedItemMenu: undefined, // {rowIndex: number, column: string, row: RowData, style: {top: number, left: number}}
    dimensions,
    rowCopy: undefined,
    cursor: {status: 'scroll', target: {}}, /* status: 'scroll' | 'intent' | 'active', target: {row: number?, column: number?} */
};

// REDUCER
export default function logicReducer(state = initialState, {type, payload}) {
    switch (type) {
    case SELECT_ALL_ROWS:
        return {...state, selectedRows: {'*': true}};
    case PRE_REMOVE_ROW:
        return onPreRemoveRow({state, payload});
    case ARROW_RIGHT:
        return onArrowRight({state});
    case ARROW_LEFT:
        return onArrowLeft({state});
    case ARROW_UP:
        return onArrowUp({state});
    case ARROW_DOWN:
        return onArrowDown({state});
    case TAB:
        return onTab({state});
    case SET_CURSOR_LOCATION:
        return onCursorLocationChange({state, payload});
    case MOVE_SEARCH_RESULT_POINTER:
        return onMoveSearchResultPointer({state, payload});
    case UPDATE_COLUMN_DIMENSIONS: {
        const { dimensions } = state;
        return {...state, dimensions: {...dimensions, [payload.field]: payload.width}};
    }
    case TOGGLE_ROW_SELECTION:
        return onToggleRowSelection({state, payload, identifier: 'uuid'});
    case SET_SEARCH_RESULT_ROWS:
        return onSetSearchResults({state, payload});
    case UPDATE_COLUMN:
        return onUpdateColumn({state, payload, identifier: 'uuid'});
    case MOVE_ROWS:
        return onMoveRows({state, payload, identifier: 'uuid'});
    case CLEAR_ROW_SELECTIONS:
        return {...state, selectedRows: {}};
    case ADD_ROW:
        return onAddRow({state, payload, identifier: 'uuid'});
    case RIGHT_CLICK_SELECT_ROW:
        return onRightClickSelectRow({state, payload});
    case CLEAR_ROW_FOCUS:
        return onClearRowFocus({state});
    case FIND_SEARCH_RESULTS:
    case FETCH_CONTENT: return {...state, loadingContent: true};
    case SET_CONTENT: {
        const nextState = onSetContent({state, payload, identifier: 'uuid'});
        nextState.headers = nextState.headers.filter(header => !['uuid', 'drg_countries', 'errorsList', 'notificationsList'].includes(header));
        return nextState;
    } case SET_FILTER_TEXT:
        return {...state, filter: {...state.filter, text: payload}};
    case SET_SEARCH_TEXT:
        return {...state, search: {...state.search, text: payload}, searchResults: undefined};
    case UPDATE_FILTER_FIELDS:
        return {...state, filter: {...state.filter, fields: payload}};
    case UPDATE_SEARCH_FIELDS:
        return {...state, search: {...state.search, fields: payload}, searchResults: undefined};
    case UPDATE_ORDER:
        return onUpdateOrder({state, payload});
    case FIELD_ORDER_TOGGLE:
        return onFieldOrderToggle({state, payload});
    case SET_ACTIVE_FILTER:
        return {...state, filter: {...state.filter}, rows: [], activeFilter: payload};
    case SET_ERRORS_FILTER:
        return {...state, filter: {...state.filter}, rows: [], errorsFilter: payload};
    case SET_NOTIFICATIONS_FILTER:
        return {...state, filter: {...state.filter}, rows: [], notificationsFilter: payload};
    case CLEAR_ROWS:
        return onClearRows({state});
    case SET_FOCUSED_ITEM:
        return onSetFocusedItem({state, payload});
    case CLEAR_FOCUSED_ITEM:
        return state;
    case UPDATE_ROW:
        return onUpdateRow({state, payload, identifier: 'uuid'});
    case UNDO_CHANGE:
        return onUndoChange({state, payload, identifier: 'uuid'});
    case COPY_SELECTED_ROW:
        return onCopySelectedRow({state, exclude: ['id', 'uuid', 'updated_at', 'created_at', 'updated_by', 'ord']});
    default: return state;
    }
}

// ACTION CREATORS
export const copySelectedLogicRow = () => ({type: COPY_SELECTED_ROW});
export const clearLogicRowFocus = () => ({type: CLEAR_ROW_FOCUS});
export const logicGridArrowRight = () => ({type: ARROW_RIGHT});
export const logicGridArrowLeft = () => ({type: ARROW_LEFT});
export const logicGridArrowUp = () => ({type: ARROW_UP});
export const logicGridArrowDown = () => ({type: ARROW_DOWN});
export const logicGridTab = () => ({type: TAB});
export const clearLogicRowSelections = () => ({type: CLEAR_ROW_SELECTIONS});
export const selectAllLogicRows = () => ({type: SELECT_ALL_ROWS});
export const rightClickSelectLogicRow = ({rowIndex, columnIndex, leftOffset}) => ({type: RIGHT_CLICK_SELECT_ROW, payload: {rowIndex, columnIndex, leftOffset}});
export const setLogicGridCursorLocation = ({rowIndex, columnIndex}) => ({type: SET_CURSOR_LOCATION, payload: {rowIndex, columnIndex}});
export const toggleLogicRowSelection = (rowIndex) => ({type: TOGGLE_ROW_SELECTION, payload: rowIndex});
export const moveLogicSearchResultPointer = (direction) => ({type: MOVE_SEARCH_RESULT_POINTER, payload: direction});

// THUNKS
let logicRowIndex = 0;

/*
* Re-enabling this feature requires generalizing undo feature,
* so that if content of an other row is pasted on top a existing row,
* update of all fields can be undone by single undo click
function doPasteCopiedLogicRow() {
    return async function pasteCopiedRow(dispatch, getState, Api) {
        const {rowCopy, rightClickSelection} = getState().logic;
        if (!rowCopy) return console.warn('No copyed row');
        if (!rightClickSelection || !rightClickSelection.row) return console.error('Paste should only be called when row is right click selected')
        const {uuid} = rightClickSelection.row;
        const {country} = getState().locales;
        const payload = {uuid, values: rowCopy, globalFilter: {country}};
        const logicRow = await Api.put('/logic/update_row', {payload});
        const formatter = country === 'com' ? logicCombinedRowFormatter : logicNationalRowFormatter(country);
        dispatch({type: UPDATE_ROW, payload: formatter(logicRow)});
    }
}
*/
function doPasteCopiedLogicRowAbove() {
    return function pasteRowAbove(dispatch) {
        return dispatch(doPasteNewLogicRow('above'));
    };
}

function doPasteCopiedLogicRowBelow() {
    return function pasteRowAbove(dispatch) {
        return dispatch(doPasteNewLogicRow('below'));
    };
}

function doPasteNewLogicRow(position) {
    return async function pasteNewRow(dispatch, getState) {
        const {rowCopy, rightClickSelection} = getState().logic;
        if (!rowCopy) return console.warn('No copyed row');
        if (!rightClickSelection || !rightClickSelection.row) return console.error('Paste should only be called when row is right click selected');
        return dispatch(doAddLogicRow(position, rowCopy));
    };
}

// NOT EXPORTED - ONLY CALLED BY OTHER THUNKS
function doFetchSearchMatchingRowNumbers() {
    return async function fetchSearchMatchingRowNumbers(dispatch, getState, Api) {
        const {logic, locales: {country}} = getState();
        if (!logic.loadingContent) {
            dispatch({type: FIND_SEARCH_RESULTS});
        }
        const {filter, search, activeFilter, errorsFilter, notificationsFilter} = logic;
        const order = orderNullsFix(logic.order);
        const {cancelled} = await resolveFetchSearchResults({
            initialDelay: 500,
            cancelOn() {
                const {locales: {country: countryNow}, logic: logicNow} = getState();
                return !logicNow.search.text
                    || country !== countryNow
                    || entries({order: logic.order, filter, search, activeFilter, errorsFilter, notificationsFilter}).some(([k, initialValue]) => initialValue !== logicNow[k]);
            },
        });
        const payload = {search, globalFilter: {active: activeFilter, errors: errorsFilter, notifications: notificationsFilter, country}, order, filter};
        const {matchingRowNumbers} = await Api.post('/logic/search', {payload, cancelled});
        dispatch({type: SET_SEARCH_RESULT_ROWS, payload: matchingRowNumbers});
    };
}

export function doFetchLogicContent(rowIndex) {
    logicRowIndex = rowIndex;
    const waitForResolve = resolveLast(600);
    const offset = (logicRowIndex - 200) < 0 ? 0 : (logicRowIndex - 200);
    return async function fetchContent(dispatch, getState, Api) {
        const {locales: {country}, logic} = getState();
        if (!logic.loadingContent) {
            dispatch({type: FETCH_CONTENT});
        }
        const {filter, activeFilter, errorsFilter, notificationsFilter} = logic;
        const order = orderNullsFix(logic.order);
        const {cancelled} = await waitForResolve;
        const payload = {order, filter, offset, limit: 400, globalFilter: {country, active: activeFilter, errors: errorsFilter, notifications: notificationsFilter}};
        const result = await Api.post('/logic/fetch', {payload, cancelled});
        const formatter = country === 'com' ? logicCombinedRowFormatter : getLogicNationalRowFormatter(country);
        result.rows = result.rows.map(formatter);
        dispatch({type: SET_CONTENT, payload: {...result, offset}});
    };
}

export function doSetFocusedLogicItem({rowIndex, columnIndex}) {
    return async function setFocusedItem(dispatch, getState) {
        dispatch({type: SET_FOCUSED_ITEM, payload: {rowIndex, columnIndex}});
        const {focusedItemMenu} = getState().logic;
        await resolveFocusedItemMenuInfo({
            cancelOn() { return !focusedItemMenu || getState().logic.focusedItemMenu !== focusedItemMenu; },
        });
    };
}

export function doUpdateLogicDimensions({field, width}) {
    return async function updateDimensions(dispatch, getState) {
        if (width > 50) {
            dispatch({type: UPDATE_COLUMN_DIMENSIONS, payload: {field, width}});
            await resolveDimensionSave(200);
            const {dimensions} = getState().logic;
            localStorage.setItem('logic_dimensions', JSON.stringify(dimensions));
        }
    };
}

export function doMoveLogicRows(position) {
    return async function moveRows(dispatch, getState, Api) {
        const {logic: {selectedRows, rightClickSelection: {row: {uuid}}}} = getState(); // must be read before dispatch
        dispatch({type: MOVE_ROWS, payload: position});
        const rowIds = Object.keys(selectedRows);
        await Api.put('/logic/move', {payload: {uuid, position, rowIds}});
        const rowIndex = getState().logic.rows.findIndex((row) => row.uuid === uuid);
        await dispatch(doFetchLogicContent(rowIndex));
        dispatch({type: CLEAR_ROW_SELECTIONS});
    };
}
// Differs from other reducer implementations
export function doAddLogicRow(position, values) {
    return async function addRow(dispatch, getState, Api) {
        const state = getState();
        const uuid = uuidV4().substring(0, 36);
        const {rightClickSelection} = state.logic; // must be read before dispatch
        dispatch({type: ADD_ROW, payload: {position, uuid}});
        const target = rightClickSelection.row.uuid;
        const {country} = state.locales;
        await Api.post('/logic/add', {payload: {uuid, position, target, country, values}});
        const {rows} = getState().logic;
        const rowIndex = rows.findIndex((row) => row.uuid === uuid);
        dispatch(doFetchLogicContent(rowIndex));
    };
}

export function doRemoveLogicRow() {
    return async function removeRow(dispatch, getState, Api) {
        const { logic: { rightClickSelection: { row: { uuid } } } } = getState(); // must be read before dispatch
        const { rows } = getState().logic;
        const rowIndex = rows.findIndex((row) => row.uuid === uuid);
        dispatch({
            type: PRE_REMOVE_ROW,
            payload: { rowIndex },
        });
        await Api.del(`/logic/${uuid}`);
        await dispatch(doFetchLogicContent(rowIndex));
    };
}

// function doRemoveSelectedLogicRows() {
//     return async function removeRows(dispatch, getState, Api) {
//         const {logic: {selectedRows}} = getState();
//         const ids = Object.keys(selectedRows).join('&');
//         await Api.del(`/logic/${ids}`);
//         await dispatch(doFetchLogicContent(0));
//     };
// }

export function doSetLogicFilterText(text) {
    const waitForResolve = resolveLast(500);
    return async function setFilterText(dispatch) {
        dispatch({type: SET_FILTER_TEXT, payload: text});
        await waitForResolve;
        dispatch({type: CLEAR_ROWS});
        dispatch(doFetchLogicContent(0));
    };
}

export function doSetLogicSearchText(text) {
    return async function setFilterText(dispatch) {
        dispatch({type: SET_SEARCH_TEXT, payload: text});
        if (text) {
            dispatch(doFetchSearchMatchingRowNumbers());
        }
    };
}

export function doUpdateLogicFilterFields(fields) {
    return function updateFilterFields(dispatch, getState) {
        dispatch({type: UPDATE_FILTER_FIELDS, payload: fields});
        if (getState().logic.filter.text) {
            dispatch({type: CLEAR_ROWS});
            dispatch(doFetchLogicContent(0));
        }
    };
}

export function doSetLogicSearchFields(fields) {
    return async function setSearchFields(dispatch, getState) {
        dispatch({type: UPDATE_SEARCH_FIELDS, payload: fields});
        if (getState().logic.search.text) {
            dispatch(doFetchSearchMatchingRowNumbers());
        }
    };
}

export function doUpdateLogicOrder(fields) {
    return function updateOrder(dispatch, getState) {
        const {nonSortableFields} = getState().logic;
        if (fields.every(([fieldName]) => !nonSortableFields[fieldName])) {
            dispatch({type: UPDATE_ORDER, payload: fields});
            dispatch({type: CLEAR_ROWS});
            dispatch(doFetchLogicContent(0));
        }
    };
}

export function doToggleLogicFieldOrder(field) {
    return function toggleFieldOrder(dispatch, getState) {
        const {nonSortableFields} = getState().logic;
        if (!nonSortableFields[field]) {
            dispatch({type: FIELD_ORDER_TOGGLE, payload: field});
            dispatch({type: CLEAR_ROWS});
            dispatch(doFetchLogicContent(0));
        }
    };
}

function doSetLogicActiveFilter(bool) {
    return function setActiveFilter(dispatch) {
        dispatch({type: SET_ACTIVE_FILTER, payload: bool});
        dispatch({type: CLEAR_ROWS});
        dispatch(doFetchLogicContent(0));
    };
}

export function doSetLogicErrorsFilter(bool) {
    return function setErrorsFilter(dispatch) {
        dispatch({type: SET_ERRORS_FILTER, payload: bool});
        dispatch({type: CLEAR_ROWS});
        dispatch(doFetchLogicContent(0));
    };
}

export function doSetLogicNotificationsFilter(bool) {
    return function setNotificationsFilter(dispatch) {
        dispatch({type: SET_NOTIFICATIONS_FILTER, payload: bool});
        dispatch({type: CLEAR_ROWS});
        dispatch(doFetchLogicContent(0));
    };
}

export function doUndoLogicRowChange({uuid}) {
    return async function undoRowChange(dispatch, getState, Api) {
        const {value, column} = getState().logic.history[uuid][0];
        dispatch({type: UNDO_CHANGE, payload: uuid});
        const {cancelled} = await getFieldSpecificTimeout({domain: 'logic', row: uuid, column})(1000);
        const {country} = getState().locales;
        const payload = {uuid, column, value, globalFilter: {country}};
        const logicRows = await Api.put('/logic/update', {payload, cancelled});
        const formatter = country === 'com' ? logicCombinedRowFormatter : getLogicNationalRowFormatter(country);
        logicRows.forEach((logicRow) => dispatch({type: UPDATE_ROW, payload: formatter(logicRow)}));
    };
}

export function doUpdateLogicColumn({uuid, column, value, timestamp = Date.now()}) {
    return async function updateColumn(dispatch, getState, Api) {
        const {nonEditableFields} = getState().logic;
        const {country} = getState().locales;
        if (!nonEditableFields[column]) {
            const payload = {uuid, column, value, globalFilter: {country}};
            dispatch({type: UPDATE_COLUMN, payload: {...payload, timestamp}});
            const {cancelled} = await getFieldSpecificTimeout({domain: 'logic', row: uuid, column})(1000);
            const logicRows = await Api.put('/logic/update', {payload, cancelled});
            const formatter = country === 'com' ? logicCombinedRowFormatter : getLogicNationalRowFormatter(country);
            logicRows.forEach((logicRow) => dispatch({type: UPDATE_ROW, payload: formatter(logicRow)}));
        }
    };
}

export function doUpdateFocusedLogicItem({value}) {
    return async function (dispatch, getState) {
        const {column, row: {uuid}} = getState().logic.focusedItemMenu;
        return dispatch(doUpdateLogicColumn({uuid, column, value}));
    };
}

// SELECTOR
export const selectors = {
    getSelectedLogicRows: memoizedMap((rows, selectedRows) => {
        if (selectedRows['*']) {
            return selectedRows;
        }
        return Object.keys(selectedRows)
            .map((uuid) => rows.findIndex((row) => row.uuid === uuid))
            .filter((it) => it !== -1)
            .reduce((acc, index) => ({...acc, [index]: true}), {});
    }),
};

export const middlewares = [
    () => (next) => (action) => {
        if (action.type === LOGOUT) {
            console.log('remove', `norddrg_${'logic'}_history`);
            localStorage.removeItem(`norddrg_${'logic'}_history`);
        }
        return next(action);
    },
];

export const actions = {
    clearLogicRowFocus,
    logicGridArrowRight,
    logicGridArrowLeft,
    logicGridArrowUp,
    logicGridArrowDown,
    logicGridTab,
    clearLogicRowSelections,
    selectAllLogicRows,
    rightClickSelectLogicRow,
    setLogicGridCursorLocation,
    toggleLogicRowSelection,
    moveLogicSearchResultPointer,
    doFetchLogicContent,
    doSetFocusedLogicItem,
    doUpdateLogicDimensions,
    doMoveLogicRows,
    doAddLogicRow,
    doRemoveLogicRow,
    // doRemoveSelectedLogicRows,
    doSetLogicFilterText,
    doSetLogicSearchText,
    doUpdateLogicFilterFields,
    doSetLogicSearchFields,
    doUpdateLogicOrder,
    doToggleLogicFieldOrder,
    doSetLogicActiveFilter,
    doSetLogicErrorsFilter,
    doSetLogicNotificationsFilter,
    doUndoLogicRowChange,
    doUpdateLogicColumn,
    doUpdateFocusedLogicItem,
    copySelectedLogicRow,
    doPasteCopiedLogicRowAbove,
    doPasteCopiedLogicRowBelow,
};
