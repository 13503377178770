/* eslint-disable jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events,jsx-a11y/alt-text */
import React from 'react';
import {func, object} from 'prop-types';
import Close from '../Close';
import './styles.scss';
import RemoveDialog from '../RemoveDialog';

export default function RightClickMenu({t, styles, onRemoveRow, onUndoLastChange, onClose}) {
    return (
        <div className="right-click-menu" style={styles} onClick={(e) => e.stopPropagation()}>
            <Close onClick={onClose}/>
            <div onClick={onUndoLastChange} className={onUndoLastChange ? '' : 'menu-item-disabled'}>{t.undo}</div>
            <RemoveDialog onRemoveRow={onRemoveRow} t={t} className={onRemoveRow ? '' : 'menu-item-disabled'} />
        </div>
    );
}

RightClickMenu.propTypes = {
    styles: object,
    onUndoLastChange: func,
    onRemoveRow: func,
};
