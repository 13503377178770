import React from 'react';

export const ProcedureRelationCardItem = (t, p) => (
    <div key={`${p.proc_plus}-${p.proc_nat}`} className="cell-context-menu-description-item" >
        <p>{t.type}: {t.procedure}</p>
        <p>{t.code}: {p.proc_nat || '--'}</p>
        {p.proc_plus && <p>{t.plusCode}: {p.proc_plus}</p>}
        <p>{t.description}: {p.proc_text_nat || '--'}</p>
    </div>
);

export const DiagnosisRelationCardItem = (t, d) => (
    <div key={`${d.dg_plus}-${d.dg_nat}`} className="cell-context-menu-description-item" >
        <p>{t.type}: {t.diagnosis}</p>
        <p>{t.code}: {d.dg_nat || '--'}</p>
        {d.dg_plus && <p>{t.plusCode}: {d.dg_plus}</p>}
        <p>{t.description}: {d.dg_text_nat || '--'}</p>
    </div>
);

export default {
    ProcedureRelationCardItem,
    DiagnosisRelationCardItem,
};
