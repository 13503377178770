export function timeout(time) {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, time);
    });
}

export function createDistinctLastTimeout() {
    let lastTimeout = 0;
    return function distinctLast(time) {
        const context = this || {};
        clearTimeout(lastTimeout);
        return new Promise((res) => {
            context.timeout = lastTimeout = setTimeout(() => {
                if (lastTimeout === context.timeout) {
                    if (context.extended) {
                        res();
                    } else {
                        res({
                            cancelled() {
                                return context.timeout !== lastTimeout;
                            },
                            waitMore(time) {
                                context.extended = true;
                                return distinctLast.bind(context)(time);
                            },
                            cancel() {
                                context.timeout = undefined;
                            },
                        });
                    }
                }
            }, time);
        });
    };
}

export function createResolveLatestIf() {
    let lastTimeout = 0;
    return function resolveLatestIf({initialDelay, cancelOn}) {
        const context = this || {};
        clearTimeout(lastTimeout);
        return new Promise((res) => {
            context.timeout = lastTimeout = setTimeout(() => {
                if (lastTimeout === context.timeout) {
                    if (context.extended) {
                        res();
                    } else if (!cancelOn()) {
                        res({
                            cancelled() {
                                return context.timeout !== lastTimeout || cancelOn();
                            },
                            waitMore(time) {
                                context.extended = true;
                                return resolveLatestIf.bind(context)(time);
                            },
                            cancel() {
                                context.timeout = undefined;
                            },
                        });
                    }
                }
            }, initialDelay);
        });
    };
}

export function toSet(byKey) {
    return function setReducer(acc, next) {
        acc[next[byKey]] = true;
        return acc;
    };
}

export function reduceBy(key) {
    return function reducer(acc, next) {
        return {...acc, [next[key]]: next};
    };
}

export function range(a = 0, b) {
    const range = [];
    const [from, to] = b === undefined ? [0, a] : [a, b];
    if (!Number.isInteger(from) || !Number.isInteger(to)) {
        throw new Error('Invalid parameters passed to range.');
    }
    const add = from < to ? 1 : -1;
    let current = from;
    while (current !== to) {
        range.push(current);
        current += add;
    }
    return range;
}

export function memoizedMap(mapper) {
    const callbackInputs = [];
    let prev;
    return function memoized(...parameters) {
        const changes = parameters.filter((param, i) => {
            if (callbackInputs[i] !== param) {
                callbackInputs[i] = param;
                return true;
            }
            return false;
        });
        if (changes.length) {
            prev = mapper(...callbackInputs);
        }
        return prev;
    };
}

export function replaceWildCardWithName(key, name) {
    if (key[0] === '$') {
        return key.replace('$', name);
    }
    const Name = name[0].toUpperCase() + name.substring(1, name.length);
    return key.replace('$', Name);
}

export function removeStringFromKeys(obj, key) {
    const Key = key[0].toUpperCase() + key.substring(1, key.length);
    return Object.entries(obj).map(([k, v]) => {
        k = k.replace(key, '').replace(Key, '');
        return [k[0].toLowerCase() + k.substring(1, k.length), v];
    }).reduce((acc, [k, v]) => ({...acc, [k]: v}), {});
}

export function stopPropagation(e) {
    e.stopPropagation();
}

export function lessThanOneDayAgo(timestamp) {
    const oneDayAgo = new Date();
    oneDayAgo.setDate(oneDayAgo.getDate() - 1);
    return new Date(timestamp) > oneDayAgo;
}

export function toCamelCase(text) {
    let acc = '';
    for (let i = 0; i < text.length; i++) {
        if (text[i - 1] === '_') {
            acc += text[i];
        } else if (text[i] !== '_') {
            acc += text[i].toLowerCase();
        }
    }
    return acc;
}

export function formatTimestamp(timestamp) {
    const dtFormat = new Intl.DateTimeFormat(undefined, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    });

    return dtFormat.format(new Date(timestamp));
}

const formatFromList = (list) => {
    if (!list || !list.length) {
        return '';
    }

    let text = list[0];
    if (list.length > 1) {
        text += ` (${list.length - 1} more)`;
    }
    return text;
};

const objectToStringList = (obj) => Object.entries(obj).map(([code, message]) => `${code}: ${message}`);

const createErrorsList = (obj = {}, country) => Object.keys(obj)
    .filter((key) => key === country || key === 'all')
    .reduce((acc, key) => [...acc, ...objectToStringList(obj[key])], []);

const sortErrors = (ignoreCodesList, errorsList) => {
    if (ignoreCodesList.length) {
        errorsList.sort((_, e) => (ignoreCodesList.includes(e.split(':')[0]) ? -1 : 1));
    }
};

export const getCommonRowFormatter = (country) => (row) => {
    const { errors, ignore_errors = '' } = row;
    const errorsList = createErrorsList(errors, country);
    const ignoreCodesList = ignore_errors.split(', ').filter(String);
    sortErrors(errorsList, ignoreCodesList);
    return {
        ...row,
        errors: formatFromList(errorsList),
        errorsList,
        ignoreCodesList,
    };
};

export const getDiagnosisFeatureRowFormatter = (country) => (row) => {
    const { dg_nat, d_dg_nat, errors, ignore_errors = '' } = row;
    const diagnoses = dg_nat ? (dg_nat[country] || []) : [];
    const d_diagnoses = d_dg_nat ? (d_dg_nat[country] || []) : [];
    const errorsList = createErrorsList(errors, country);
    const ignoreCodesList = ignore_errors.split(', ').filter(String);
    sortErrors(errorsList, ignoreCodesList);
    return {
        ...row,
        dg_nat: [...new Set(diagnoses)].join(', '),
        d_dg_nat: [...new Set(d_diagnoses)].join(', '),
        errors: formatFromList(errorsList),
        errorsList,
        ignoreCodesList,
    };
};

export const logicCombinedRowFormatter = (row) => {
    const { errors, notifications } = row;
    const errorsList = createErrorsList(errors, 'com');
    const notificationsList = createErrorsList(notifications);
    return {
        ...row,
        errors: formatFromList(errorsList),
        notifications: formatFromList(notificationsList),
        errorsList,
        notificationsList,
    };
};

export const getLogicNationalRowFormatter = (country) => (row) => {
    const { drg_nat, drg_text_nat, errors, notifications } = row;
    const errorsList = createErrorsList(errors, country);
    const notificationsList = createErrorsList(notifications, country);
    return {
        ...row,
        drg_nat: drg_nat ? drg_nat[country] : '',
        drg_text_nat: drg_text_nat ? drg_text_nat[country] : '',
        errors: formatFromList(errorsList),
        notifications: formatFromList(notificationsList),
        errorsList,
        notificationsList,
    };
};

export const getProcedureFeatureRowFormatter = (country) => (row) => {
    const { proc_nat, errors } = row;
    const procedures = proc_nat ? (proc_nat[country] || []) : [];
    const errorsList = createErrorsList(errors, country);
    return {
        ...row,
        proc_nat: [...new Set(procedures)].join(', '),
        errors: formatFromList(errorsList),
        errorsList,
    };
};

const formatToInteger = (val) => (Number.isNaN(val) || val === '' ? val.trim() : parseInt(val));

export const testCaseCombinedRowFormatter = (row) => {
    const { age, duration, errors } = row;
    const errorsList = createErrorsList(errors, 'com');
    return {
        ...row,
        age: formatToInteger(age),
        duration: formatToInteger(duration),
        errors: formatFromList(errorsList),
        errorsList,
    };
};

export const getTestCaseNationalRowFormatter = (country) => (row) => {
    const { drg_nat, drg_nat_previous, drg_logic_id, drg_logic_id_previous, age, duration, errors } = row;
    const errorsList = createErrorsList(errors, country);
    return {
        ...row,
        drg_nat: drg_nat[country],
        drg_nat_previous: drg_nat_previous[country],
        drg_logic_id: drg_logic_id[country],
        drg_logic_id_previous: drg_logic_id_previous[country],
        age: formatToInteger(age),
        duration: formatToInteger(duration),
        errors: formatFromList(errorsList),
        errorsList,
    };
};

/*
    Replace first '-' character in front of the code. It is the same code
    as without '-'. Minus sign only indicates "not in use", otherwise it
    is identical: same relations, same meaning etc.
*/
export function normalizeCode(code) {
    return code ? code.replace('-', '') : code;
}

export async function readCsvFile({file}) {
    const reader = new FileReader();
    return await new Promise((resolve) => {
        reader.onload = (e) => {
            resolve(e.target.result);
        };
        reader.readAsArrayBuffer(file, 'UTF-8');
    });
}

export const replaceAtIndex = (str, i, char) => str.substr(0, i) + char + str.substr(i + 1);

const CSV_REGEX = /^(\w+)\.(\w+)([-.].*)*\.csv$/gi;
export const getFileContents = ({files}) => Promise.all(files.map((f) => {
    const reader = new FileReader();
    const name = f.name.replace(CSV_REGEX, '$1.$2.csv'); // $1 = schema, $2 = table name
    return new Promise((res) => {
        reader.onload = (e) => res({name, data: e.target.result});
        reader.readAsText(f, 'UTF-8');
    });
}));

export const orderNullsFix = (order) => order.map(([col, dir]) => {
    const direction = dir === 'ASC' ? dir.concat(' NULLS FIRST') : dir.concat(' NULLS LAST');
    return [col, direction];
});
