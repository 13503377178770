import createReduxDomain from '../resources/domainFactory';
import { NON_EDITABLE_FIELDS } from '../../common/constants';

const {reducer, types, selectors, actions, middlewares} = createReduxDomain({
    URL: '/mbc/proc_plus',
    DOMAIN: 'MBC_PROC_PLUS',
    IDENTIFIER: 'plus_proc',
    excludeActions: [
        'doSet$VartypeFilter',
        'doSet$ExclusiveToORZeroFilter',
        'doSet$ExclusiveToDGCATZeroFilter',
        'doSelectLinkable$Code',
        'doLink$Code',
        'doLinkAll$Codes',
    ],
    excludeHeaders: ['rid'],
    nonEditableFields: NON_EDITABLE_FIELDS.mbcProcPlus,
});
export default reducer;

export {types, actions, selectors, middlewares};
