import React from 'react';
import {connect} from 'react-redux';
import AddCountry from './AddCountry';
import ImportCodes from './ImportCodes';
import ValidateTables from './ValidateTables';
import ContainerTitle from '../../../components/ContainerTitle';
import '../styles.scss';

const domain = 'admin';

const Admin = ({t, loading, downloading, mbc}) => (
    <div className="scene-main-content">
        <ContainerTitle text={t.titles[domain]} loading={loading || downloading}/>
        <div className="multi-section-content">
            {!mbc && <AddCountry/>}
            {!mbc && <ImportCodes/>}
            <ValidateTables mbc={mbc}/>
        </div>
    </div>
);

export default connect(({locales: {t}, admin: {loading}, dumps: {downloading}}) => ({t, loading, downloading}))(Admin);
