import React from 'react';
import {connect} from 'react-redux';
import {doFetchDiagnosisRelationDiagnosisNameRelations, doFetchDiagnosisRelationCategoryNames} from '../../store/diagnosisRelations';
import ContextMenu from './ContextMenu';
import {DiagnosisRelationCardItem} from './CardItems';
import {actionsWrapper} from '../../store/resources/reducerUtils';
import store from '../../store';

const identifier = 'dgcat';

const selectOptions = (names) => names.map((it) => ({value: it[identifier], label: `${it[identifier]} - ${it.varval_text_com}`}));

class DiagnosisNamesMenu extends React.Component {
    state = {loading: false};

    render() {
        const {t, id, names, onClose, onChange, allowEmpty} = this.props;
        const item = names.find((it) => it[identifier] === id);
        const value = {label: id ? (`${id} - ${item ? item.varval_text_com : ''}`) : '--', value: id};
        const {relations = {}} = item || {};
        const {loading} = this.state;
        return (
            <ContextMenu
              onChange={onChange}
              onClose={onClose}
              value={value}
              label={identifier}
              loading={loading || relations.loading}
              fetchContent={this.onFetchContent}
              allowEmpty={allowEmpty}
              options={selectOptions(names)}>
                {(relations.diagnoses || []).map(d => DiagnosisRelationCardItem(t, d))}
            </ContextMenu>
        );
    }

    onFetchContent = () => {
        const {doFetchDiagnosisRelationCategoryNames} = this.props;
        this.setState({loading: true});
        doFetchDiagnosisRelationCategoryNames().then(() => !this.unMounted && this.setState({loading: false}));
    };

    componentDidMount() {
        const {id, doFetchDiagnosisRelationDiagnosisNameRelations} = this.props;
        if (id) {
            doFetchDiagnosisRelationDiagnosisNameRelations(id);
        }
    }

    componentDidUpdate(prevProps) {
        const {id, doFetchDiagnosisRelationDiagnosisNameRelations} = this.props;
        if (id !== prevProps.id) {
            doFetchDiagnosisRelationDiagnosisNameRelations(id);
        }
    }

    componentWillUnmount() {
        this.unMounted = true;
    }
}

export default connect(
    ({locales: {t}, diagnosisRelations: {names}}) => ({t, names}),
    {...actionsWrapper(store, {doFetchDiagnosisRelationDiagnosisNameRelations, doFetchDiagnosisRelationCategoryNames})},
)(DiagnosisNamesMenu);
