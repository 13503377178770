import React from 'react';
import {bool, object, func} from 'prop-types';
import {connect} from 'react-redux';
import Button from '../../Button';
import SelectCode from '../../SelectCode';
import RemoveRowsButton from '../../RemoveRowsButton';
import './styles.scss';

function NameMenuItem({
    t, domain, country, rows, enableRowSelect, onAddRow, onClearRowAddition, pendingAddition, listIsfiltered, selectedRows, doRemoveSelectedRows, linkableColumns, doSelectLinkableCode,
}) {
    return (
        <div className="menu-item">
            {pendingAddition
                ? <Button text={t.cancelAddition} onClick={onClearRowAddition}/>
                : <Button text={t.addRow} onClick={onAddRow}/>}
            {enableRowSelect
                && (
                <RemoveRowsButton
                  t={t}
                  domain={domain}
                  listIsfiltered={listIsfiltered}
                  selectedRows={selectedRows}
                  doRemoveSelectedRows={doRemoveSelectedRows} />
                )}
            {linkableColumns && country !== 'com'
                && (
                <SelectCode
                  domain={domain}
                  storage={`${domain === 'mbcDgNat' ? 'dg' : 'proc'}Store`}
                  linkableColumns={linkableColumns}
                  rows={rows}
                  doSelectLinkableCode={doSelectLinkableCode}/>
                )}
        </div>
    );
}

NameMenuItem.propTypes = {
    onAddRow: func.isRequired,
    onClearRowAddition: func.isRequired,
    pendingAddition: bool,
    selectedRows: object,
    listIsfiltered: bool,
    doRemoveSelectedRows: func.isRequired,
};

export default connect(({locales: {t}}) => ({t}))(NameMenuItem);
